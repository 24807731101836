import { Col, Row, DatePicker } from "antd";
import { Fragment, useState } from "react";
import infoCircleIcon from "../../../assets/images/icons/info-circle.svg";
import ColumnChart from "./ColumnChart";
import DailyVisitsChart from "./DailyVisitsChart";
import ProgressChart from "./ProgressChart";
import VerifiedRateChart from "./VerifiedRateChart";
import DashboardUpcomingEvents from "./DashboardUpcomingEvents";
import DashboardPendingApprovals from "./DashboardPendingApprovals";
import DashboardTopPerformingStudents from "./DashboardTopPerformingStudents";
import DashboardNeedsHelpBoard from "./DashboardNeedsHelpBoard";
import FacultyMembers from "./FacultyMembers";
import DashboardDistribution from "./DashboardDistribution";
import { connect } from "react-redux";

const { RangePicker } = DatePicker;

const Dashboard = (props: any) => {
  const { flutterConnect } = props;
  const [tabActive, setTabActive] = useState(1);
  const [graphFilter, setGraphFilter] = useState(4);

  // var flutterConnect = true;

  const sizeCommon = flutterConnect ? undefined : 24;
  const spanFlutter = flutterConnect ? 24 : undefined;
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <div className="app-main-content">
          <Row gutter={[24, 0]}>
            <Col
              sm={sizeCommon}
              md={sizeCommon}
              lg={sizeCommon}
              span={spanFlutter}
              className="gutter-row"
            >
              <DashboardUpcomingEvents />
            </Col>
            <Col
              sm={sizeCommon}
              md={sizeCommon}
              lg={flutterConnect ? undefined : 24}
              span={spanFlutter}
              className="gutter-row"
            >
              <DashboardPendingApprovals />
            </Col>
          </Row>

          {
            !flutterConnect && (
              <Row gutter={[24, 24]}>
              {/* <Col span={6} className="gutter-row">
                <div className="widget-container h-100">
                  <div className="d-flex-column h-100">
                    <div className="d-flex">
                      <div className="widget-title">Total User Engagement</div>
                      <div className="ml-auto">
                        <img src={infoCircleIcon} />
                      </div>
                    </div>
                    <div>
                      <div className="text-head mt-1">126,560</div>
                      <div className="text-desc mt-1">Clicks Percentage 12%</div>
                      <div className="text-desc">Clicks Percentage 12%</div>
                    </div>
                    <div className="mt-auto widget-footer">
                      <div className="widget-border">
                        <hr />
                      </div>
                      Daily Clicks <span>12,423</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={6} className="gutter-row">
                <div className="widget-container h-100">
                  <div className="d-flex-column h-100">
                    <div className="d-flex">
                      <div className="widget-title">Visits</div>
                      <div className="ml-auto">
                        <img src={infoCircleIcon} />
                      </div>
                    </div>
                    <div className="text-head mt-1">8,846</div>
                    <div className="mt-auto">
                      <DailyVisitsChart />
                    </div>
                    <div className="widget-footer">
                      <div className="widget-border">
                        <hr />
                      </div>
                      Daily Clicks <span>1,242</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={6} className="gutter-row">
                <div className="widget-container h-100">
                  <div className="d-flex-column h-100">
                    <div className="d-flex">
                      <div className="widget-title">Registration</div>
                      <div className="ml-auto">
                        <img src={infoCircleIcon} />
                      </div>
                    </div>
                    <div className="mt-auto text-head mt-1 pb-2">6,560</div>
                    <div>
                      <VerifiedRateChart />
                    </div>
                    <div className="widget-footer">
                      <div className="widget-border">
                        <hr />
                      </div>
                      Verified Rate <span>60%</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={6} className="gutter-row">
                <div className="widget-container h-100">
                  <div className="d-flex-column h-100">
                    <div className="d-flex">
                      <div className="widget-title">Operational Effect</div>
                      <div className="ml-auto">
                        <img src={infoCircleIcon} />
                      </div>
                    </div>
                    <div>
                      <div className="text-head mt-1">78%</div>
                    </div>
                    <div className="mt-auto progress-chart">
                      <ProgressChart />
                    </div>
                    <div className="widget-footer">
                      <div className="widget-border">
                        <hr />
                      </div>
                      Event Registered <span>12%</span>
                    </div>
                  </div>
                </div>
              </Col> */}
              <Col span={24} className="gutter-row mb-4">
                <div className="widget-container h-100 pt-3">
                  <div className="d-flex-column h-100">
                    <div className="d-flex custom-tab">
                      <div className="d-flex">
                        <div
                          onClick={() => setTabActive(1)}
                          className={`tab-item mr-4 ${
                            tabActive === 1 && "tab-active"
                          }`}
                        >
                          Events
                        </div>
                        <div
                          onClick={() => setTabActive(2)}
                          className={`tab-item mr-4 ${
                            tabActive === 2 && "tab-active"
                          }`}
                        >
                          Visits
                        </div>
                      </div>
                      <div className="d-flex align-items-center ml-auto graph-filter-container">
                        <div
                          onClick={() => setGraphFilter(1)}
                          className={`graph-filter ${
                            graphFilter === 1 && "graph-filter-active"
                          }`}
                        >
                          All day
                        </div>
                        <div
                          onClick={() => setGraphFilter(2)}
                          className={`graph-filter ${
                            graphFilter === 2 && "graph-filter-active"
                          }`}
                        >
                          All week
                        </div>
                        <div
                          onClick={() => setGraphFilter(3)}
                          className={`graph-filter ${
                            graphFilter === 3 && "graph-filter-active"
                          }`}
                        >
                          All month
                        </div>
                        <div
                          onClick={() => setGraphFilter(4)}
                          className={`graph-filter ${
                            graphFilter === 4 && "graph-filter-active"
                          }`}
                        >
                          All year
                        </div>
                        <div>
                          <RangePicker />
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col span={18} className="gutter-row">
                        <div className="plot-container pt-5 pb-3">
                          <p className="plot-title">Events Created</p>
                          <ColumnChart />
                        </div>
                      </Col>
                      <Col span={6} className="gutter-row">
                        <div className="widget-container">
                          <div className="d-flex-column">
                            <div className="widget-title-2">Popular Events</div>
                            <div className="d-flex-column mt-3 widget-list-container">
                              <ul>
                                <li>
                                  <div className="circle-bg">1</div>
                                  <div>Name of Event #1</div>
                                  <div>323,234</div>
                                </li>
                                <li>
                                  <div className="circle-bg">2</div>
                                  <div>Name of Event #2</div>
                                  <div>323,234</div>
                                </li>
                                <li>
                                  <div className="circle-bg">3</div>
                                  <div>Name of Event #3</div>
                                  <div>323,234</div>
                                </li>
                                <li>
                                  <div className="circle-bg">4</div>
                                  <div>Name of Event #4</div>
                                  <div>323,234</div>
                                </li>
                                <li>
                                  <div className="circle-bg">5</div>
                                  <div>Name of Event #5</div>
                                  <div>323,234</div>
                                </li>
                                <li>
                                  <div className="circle-bg">6</div>
                                  <div>Name of Event #6</div>
                                  <div>323,234</div>
                                </li>
                                <li>
                                  <div className="circle-bg">7</div>
                                  <div>Name of Event #7</div>
                                  <div>323,234</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            )
          }
          <Row gutter={[24, 24]}>
            <Col span={24} className="gutter-row">
              <DashboardDistribution />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col
            sm={sizeCommon}
            md={sizeCommon}
            lg={flutterConnect ? undefined : 12}
            span={spanFlutter}
            className="gutter-row">
              <DashboardTopPerformingStudents />
            </Col>
            <Col
            sm={sizeCommon}
            md={sizeCommon}
            lg={flutterConnect ? undefined : 12}
            span={spanFlutter}
            className="gutter-row">
              <DashboardNeedsHelpBoard />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24} className="gutter-row">
              <FacultyMembers />
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  flutterConnect: state.flutterConnect,
});

export default connect(mapStateToProps)(Dashboard);
