import { Button, Col, Row, Spin } from "antd";
import { Fragment, useEffect, useState } from "react";
import { Menu } from "antd";
import { getProfilePoints } from "../../../actions/profile";
import { connect } from "react-redux";
import SoftSkillAttributes from "./SoftSkillAttributes";
import { softSkillColors } from "../../../helpers/softSkillColors";
import { neutralSkillColors } from "../../../helpers/neutralSkillColors";
import { MyProfileSummary } from "../../../interface/Profile";
import moment from "moment";
import flutterConnect from "../../../reducers/flutterConnect";

const getWeekofTheMonth = (date: any) => {
  let prefixes = [1, 2, 3, 4, 5];
  return prefixes[0 | (moment(date).date() / 7)];
};
export const SoftSkillWidget = (props: {
  getProfilePoints: Function;
  member: any;
  flutterConnect: Boolean;
}) => {
  const { getProfilePoints, member, flutterConnect } = props;
  const [selectedMenu, setSelectedMenu] = useState("weekly/monthly");
  const [chartData, setChartData] = useState<any>();
  const [myProfilePoints, setMyProfilePoints] = useState<any>();
  const [monthlyPoints, setMonthlyPoints] = useState<any>();
  const [totalWeeklyPoint, setTotalWeeklyPoint] = useState<any>(0);

  const [myProfileSummary, setMyProfileSummary] = useState<MyProfileSummary>();
  const [totalMonthlyPoints, setTotalMonthlyPoints] = useState<any>(0);

  const [myProfilMonthlyPoints, setMyProfilMonthlyPoints] = useState<any>();
  const [chartMonthlyData, setChartMonthlyData] = useState<any>();
  const [currentQuarter, setCurrentQuarter] = useState<any>(1);

  const [isLoading, setIsloading] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState<any>(
    getWeekofTheMonth(moment())
  );

  // var flutterConnect = true;

  const SetIntSuffix = (int: number) => {
    return["st","nd","rd"][((int+90)%100-10)%10-1]||"th"
  }

  const NavigateDiffDate = (navigateType: String) => {
    if (navigateType === "prev") {
      let objWeek = selectedMonth - 1;

      let activeWeekData = monthlyPoints?.points?.filter((item: any) => {
        if (item.week == objWeek) {
          return item;
        }
      });

      if (selectedMenu === "weekly/monthly") {
        setActiveData(activeWeekData[0]);
        setSelectedMonth(objWeek);
      } else {
        setCurrentQuarter(currentQuarter - 1);
        computeQuarterAndYearlyPoints(currentQuarter - 1);
      }

      // console.log(objWeek);
    } else if (navigateType === "next") {
      let objWeek = selectedMonth + 1;
      let activeWeekData = monthlyPoints?.points?.filter((item: any) => {
        if (item.week == objWeek) {
          return item;
        }
      });
      if (selectedMenu === "weekly/monthly") {
        setActiveData(activeWeekData[0]);
        setSelectedMonth(objWeek);
      } else {
        setCurrentQuarter(currentQuarter + 1);
        computeQuarterAndYearlyPoints(currentQuarter + 1);
      }

      // console.log(objWeek);
    }
  };

  const setActiveData = (pointItem: any) => {
    if (pointItem === undefined) {
      setMyProfilePoints([]);
      setChartData([]);
      setTotalWeeklyPoint(0);
      return;
    }
    let totalPoints = pointItem.pointsBreakdown.reduce(function (
      sum: any,
      current: { earnedPoints: any }
    ) {
      return sum + parseFloat(current.earnedPoints);
    },
    0);
    setTotalWeeklyPoint(totalPoints);

    let count = 0;
    let pointsPercentage = pointItem.pointsBreakdown.map(
      (point: any, index: any) => {
        return {
          earnedPoints: ((point.earnedPoints / totalPoints) * 100).toFixed(2),
          earnedPointsFloat: point.earnedPoints.toFixed(2),
          softskill: point.softskill,
          softskillId: point.softskillId,
          color:
            softSkillColors[index] ??
            neutralSkillColors[index % neutralSkillColors.length],
        };
      }
    );

    setMyProfilePoints(pointsPercentage);

    setChartData({
      labels: pointsPercentage.map((item: any) => {
        return item.softskill;
      }),
      datasets: [
        {
          label: "Soft Skills Distribution",
          data: pointsPercentage.map((item: any) => {
            return item.earnedPoints;
          }),
          backgroundColor: pointsPercentage.map((item: any) => {
            return item.color;
          }),
          hoverOffset: 4,
        },
      ],
    });
  };

  const mergePoints = (data: any) => {
    let dataforMonth = data?.map((objItem: any) => {
      return objItem.pointsBreakdown;
    });
    dataforMonth = dataforMonth?.flat();

    dataforMonth = dataforMonth?.map((item: any) => {
      return {
        ...item,
        earnedPoints: item.earnedPoints,
      };
    });

    const mergedArr: any = {};

    for (let i = 0; i < dataforMonth?.length; i++) {
      const currentObj = dataforMonth[i];

      if (mergedArr[currentObj.softskillId]) {
        mergedArr[currentObj.softskillId].earnedPoints +=
          currentObj.earnedPoints;
      } else {
        mergedArr[currentObj.softskillId] = currentObj;
      }
    }

    let totalPoints: any;
    const result = Object.values(mergedArr);
    totalPoints = result.reduce((sum: any, current: any) => {
      return sum + parseFloat(current.earnedPoints);
    }, 0);

    let pointsPercentage = result.map((point: any, index: any) => {
      return {
        earnedPoints: ((point.earnedPoints / totalPoints) * 100).toFixed(2),
        earnedPointsFloat: point.earnedPoints.toFixed(2),
        softskill: point.softskill,
        softskillId: point.softskillId,
        color:
          softSkillColors[index] ??
          neutralSkillColors[index % neutralSkillColors.length],
      };
    });
    // console.log(pointsPercentage);

    return {
      pointData: pointsPercentage,
      chartData: {
        labels: pointsPercentage.map((item: any) => {
          return item.softskill;
        }),
        datasets: [
          {
            label: "Soft Skills Distribution",
            data: pointsPercentage.map((item: any) => {
              return item.earnedPoints;
            }),
            backgroundColor: pointsPercentage.map((item: any) => {
              return item.color;
            }),
            hoverOffset: 4,
          },
        ],
      },
    };

    // setChartMonthlyData({
    //   labels: pointsPercentage.map((item: any) => {
    //     return item.softskill;
    //   }),
    //   datasets: [
    //     {
    //       label: "Soft Skills Distribution",
    //       data: pointsPercentage.map((item: any) => {
    //         return item.earnedPoints;
    //       }),
    //       backgroundColor: pointsPercentage.map((item: any) => {
    //         return item.color;
    //       }),
    //       hoverOffset: 4,
    //     },
    //   ],
    // });
  };

  const getCurrentQuarterRange = (quarter: any) => {
    quarter = quarter - 1;
    const fromDate = moment("2022-01-01");
    const toDate = moment("2023-2-01");
    // Define the number of intervals
    const numberOfIntervals = 4;

    // Calculate the duration between the dates
    const duration = moment.duration(toDate.diff(fromDate));

    // Calculate the interval size in months
    const intervalSize = Math.ceil(duration.asMonths() / numberOfIntervals);

    const mergedArr: any = {};

    // Divide the range into intervals
    for (let i = 0; i < numberOfIntervals; i++) {
      const start = fromDate.clone().add(intervalSize * i, "months");
      const end = fromDate
        .clone()
        .add(intervalSize * (i + 1), "months")
        .subtract(1, "day");

      // console.log(
      //   `Interval ${i + 1}: ${start.format("YYYY-MM-DD")} to ${end.format(
      //     "YYYY-MM-DD"
      //   )}`
      // );
      mergedArr[i] = {
        from: start,
        to: end,
      };
    }
    return mergedArr[quarter];
  };

  const computeQuarterAndYearlyPoints = (quarter?: Number) => {
    let rangeToFilter = getCurrentQuarterRange(quarter);

    var startDate = rangeToFilter.from; // Specify your start date here
    var endDate = rangeToFilter.to; // Specify your end date here
    var filteredCollection: any = [];
    var currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
      var startOfMonth = currentDate.clone().startOf("month");
      var endOfMonth = currentDate.clone().endOf("month");
      var filteredObjects = monthlyPoints?.points?.filter(function (obj: any) {
        var objDate = moment(obj.dateStart);
        return objDate.isBetween(startOfMonth, endOfMonth, null, "[]");
      });
      filteredCollection = filteredCollection.concat(filteredObjects);
      currentDate.add(1, "month"); // Increment the current date by one day
    }

    let myProfilePointsTemp = mergePoints(filteredCollection);
    // console.log(myProfilePoints.pointData);

    setMyProfilePoints(myProfilePointsTemp.pointData);
    setChartData(myProfilePointsTemp.chartData);
    let totalPoints = myProfilePointsTemp.pointData.reduce(function (
      sum: any,
      current: { earnedPointsFloat: any }
    ) {
      return sum + parseFloat(current.earnedPointsFloat);
    },
    0);
    setTotalWeeklyPoint(totalPoints);
  };

  const getMyProfileSummary = async (type: String) => {
    let params = {
      memberId: member.memberId,
      type,
      from:
        type === "0"
          ? moment().subtract(1,'month').startOf("month").toISOString()
          : null,
      to:
        type === "0" ? moment().subtract(1,'month').endOf("month").toISOString() : null,
    };
    let totalPoints: any = 0;
    const data = await getProfilePoints(params);

    const dataItems = data?.data?.summary?.map((objItem: any) => {
      totalPoints += objItem.pointsBreakdown.reduce(
        (sum: any, current: { earnedPoints: any }) => {
          return sum + parseFloat(current.earnedPoints);
        },
        0
      );
      return {
        ...objItem,
        week: getWeekofTheMonth(moment(objItem.dateStart)),
      };
    });
    setMonthlyPoints({points:dataItems, start:(data.data.dateStart), end:(data.data.dateEnd)});

    // computeQuarterAndYearlyPoints(data);

    let array: any = [];

    setTotalMonthlyPoints(totalPoints);
    // mergePoints(dataItems);
    let myProfilePointsTemp = mergePoints(dataItems);
    setMyProfilMonthlyPoints(myProfilePointsTemp.pointData);
    setChartMonthlyData(myProfilePointsTemp.chartData);
    setIsloading(false);
  };

  const getSkillProgress = (type: String) => {
    if (type === "weekly/monthly") {
      getMyProfileSummary("0");
    } else if (type === "quarterly/schoolYear") {
      getMyProfileSummary("1");
    }
  };

  let menuItems = [
    {
      key: "weekly/monthly",
      label: <p> Weekly/Monthly</p>,
    },
    {
      key: "quarterly/schoolYear",
      label: <p> Quarterly/School Year</p>,
    },
  ];

  useEffect(() => {
    const getPoints = async () => {
      let res = await getMyProfileSummary("0");
    };
    getPoints();
  }, []);

  useEffect(() => {
    if (monthlyPoints) {
      let objWeek = selectedMonth;
      if (selectedMenu === "weekly/monthly") {
        let activeWeekData = monthlyPoints?.points?.filter((item: any) => {
          if (item.week == objWeek) {
            return item;
          }
        });
        if (activeWeekData?.length > 0) {
          setActiveData(activeWeekData[0]);
        } else {
          setActiveData(undefined);
          
        }
      } else if (
        selectedMenu === "quarterly/schoolYear" &&
        monthlyPoints?.points?.length > 0
      ) {
        // console.log(monthlyPoints);
        // setActiveData(monthlyPoints);
        computeQuarterAndYearlyPoints(currentQuarter);
      }
    }
  }, [monthlyPoints]);

  let disabledPrev =
    selectedMenu == "weekly/monthly"
      ? selectedMonth === 1
        ? true
        : false
      : currentQuarter === 1
      ? true
      : false;
  let disabledNext =
    selectedMenu == "weekly/monthly"
      ? selectedMonth === 5
        ? true
        : false
      : currentQuarter === 4
      ? true
      : false;

  return (
    <div className="dashboard-common-container skill-progress-container mt-20px">
      <div className="d-flex justify-content-space-between mb-5 skill-header">
        <div className="skill-info flex-column">
          <h1 className="container-title">Skill Progress</h1>
          <label className="date-label">{selectedMenu == "weekly/monthly" ?
            moment().subtract(1, "month").format('MMMM YYYY')
            :`S.Y. ${monthlyPoints?.start?.slice(0,4)}-${monthlyPoints?.end?.slice(0,4)}`
          }</label>
        </div>
        <div className="menu-wrapper app">
          <Menu
            className="menus"
            mode="horizontal"
            items={menuItems}
            selectedKeys={[selectedMenu]}
            onClick={(e) => {
              setIsloading(true);
              setSelectedMenu(e.key);
              getSkillProgress(e.key);
            }}
            disabledOverflow={true}
          />
        </div>
        <div className="menu-wrapper">
          <Menu
            className="menus"
            mode="horizontal"
            items={menuItems}
            selectedKeys={[selectedMenu]}
            onClick={(e) => {
              setIsloading(true);
              setSelectedMenu(e.key);
              getSkillProgress(e.key);
            }}
          />
        </div>
      </div>
      <Row
        gutter={[40, 24]}
        className='skill-content'>
        <Col
          md={flutterConnect ? 24 : 12}
          span={flutterConnect ? 24 : undefined}
          className='skill-item'
        >
          <Spin spinning={isLoading} tip="Loading...">
            <div className="skill-progress-item d-flex-column">
              <h1 className="week-label">
                <span>
                  {selectedMenu === "weekly/monthly" ? "Week" : `${currentQuarter}${SetIntSuffix(currentQuarter)}`}{" "}
                  {selectedMenu === "weekly/monthly"
                    ? selectedMonth
                    : "Quarter"}{" "}
                  Points -
                </span>{" "}
                {totalWeeklyPoint.toFixed(2)}
              </h1>
              {myProfilePoints && myProfilePoints.length > 0 ? (
                <Fragment>
                  <SoftSkillAttributes
                    chartData={chartData}
                    myProfilePoints={myProfilePoints}
                    lgChartSize={true}
                    metricType="points"
                    legendHeaderOn={true}
                  />
                </Fragment>
              ) : (
                <div className="no-data d-flex justify-content-center pt-5">
                  <h2 className="pt-3">No Data</h2>
                </div>
              )}
              <div className="mt-auto">
                <Button
                  className="mr-2"
                  onClick={() => {
                    NavigateDiffDate("prev");
                  }}
                  disabled={disabledPrev}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => {
                    NavigateDiffDate("next");
                  }}
                  disabled={disabledNext}
                >
                  Next
                </Button>
              </div>
              {/* <div className="no-data d-flex justify-content-center">
              <h3>
                No Data for Week {getWeekofTheMonth(moment(selectedMonth))}{" "}
              </h3>
            </div> */}
            </div>
          </Spin>
        </Col>
        <Col
          md={flutterConnect ? 24 : 12}
          span={flutterConnect ? 24 : undefined}
          className='skill-item'
        >
          <Spin spinning={isLoading} tip="Loading...">
            <div className="skill-progress-item">
              <h1 className="week-label">
                <span>
                  {selectedMenu === "weekly/monthly"
                    ? "Monthly"
                    : "Total"}{" "}
                  Points -
                </span>{" "}
                {totalMonthlyPoints.toFixed(2)}
              </h1>
              {myProfilMonthlyPoints && myProfilMonthlyPoints.length > 0 ? (
                <Fragment>
                  <SoftSkillAttributes
                    chartData={chartMonthlyData}
                    myProfilePoints={myProfilMonthlyPoints}
                    lgChartSize={true}
                    metricType="points"
                    legendHeaderOn={true}
                  />
                </Fragment>
              ) : (
                <div className="no-data d-flex justify-content-center pt-5">
                  <h2 className="pt-3">No Data</h2>
                </div>
              )}
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  member: state.auth?.member,
  flutterConnect: state.flutterConnect,
});

export default connect(mapStateToProps, { getProfilePoints })(SoftSkillWidget);
