import { Button, Col, Collapse, Dropdown, Menu, Row, Skeleton } from "antd";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { joinClub, leaveClub } from "../../../actions/club";
import placeholderImg from "../../../assets/images/club-banner-default.png";
import {
  StarOutlined,
  UserOutlined,
  EllipsisOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
const { Panel } = Collapse;

const MyClubsItemList = (props: {
  clubs: any;
  // myClubs: any;
  joinClub: Function;
  onHandleConfirm: Function;
  onHandleClubInvitation: Function;
  onHandleDeclineClubInvitation: Function;
  leaveClub: Function;
  isListLoading: boolean;
  auth: any;
  actionIsLoading: boolean;
}) => {
  const {
    clubs,
    onHandleConfirm,
    onHandleClubInvitation,
    onHandleDeclineClubInvitation,
    isListLoading,
    auth,
    actionIsLoading,
  } = props;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const [myClubs, setMyClubs] = useState([]);
  const [activeGroup, setActiveGroup]: any = useState([]);

  const onLeaveClub = async (e: any, clubItem: any) => {
    onHandleConfirm(clubItem);
  };

  useEffect(() => {
    let group = clubs.map((item: any) => {
      return item.category;
    });
    setActiveGroup(group);
  }, [clubs]);
  return (
    <Fragment>
      {isListLoading && (
        <Row gutter={24}>
          <Col span={24} className="mb-5">
            <div className="club-item-list-view">
              <div className="club-item-image">
                <Skeleton.Image />
              </div>
              <Skeleton
                className="custom-skeleton"
                active
                paragraph={{ rows: 3 }}
              />
            </div>
          </Col>
          <Col span={24} className="mb-5">
            <div className="club-item-list-view">
              <div className="club-item-image">
                <Skeleton.Image />
              </div>
              <Skeleton
                className="custom-skeleton"
                active
                paragraph={{ rows: 3 }}
              />
            </div>
          </Col>
          <Col span={24} className="mb-5">
            <div className="club-item-list-view">
              <div className="club-item-image">
                <Skeleton.Image />
              </div>
              <Skeleton
                className="custom-skeleton"
                active
                paragraph={{ rows: 3 }}
              />
            </div>
          </Col>
          <Col span={24} className="mb-5">
            <div className="club-item-list-view">
              <div className="club-item-image">
                <Skeleton.Image />
              </div>
              <Skeleton
                className="custom-skeleton"
                active
                paragraph={{ rows: 3 }}
              />
            </div>
          </Col>
          <Col span={24} className="mb-5">
            <div className="club-item-list-view">
              <div className="club-item-image">
                <Skeleton.Image />
              </div>
              <Skeleton
                className="custom-skeleton"
                active
                paragraph={{ rows: 3 }}
              />
            </div>
          </Col>
          <Col span={24} className="mb-5">
            <div className="club-item-list-view">
              <div className="club-item-image">
                <Skeleton.Image />
              </div>
              <Skeleton
                className="custom-skeleton"
                active
                paragraph={{ rows: 3 }}
              />
            </div>
          </Col>
        </Row>
      )}
      {activeGroup.length !== 0 && (
        <div className="my-club-collapse">
          {clubs &&
            clubs.map((group: any, index: string) => {
              return (
                <Fragment key={index}>
                  {group && group.clubs && group.clubs.length !== 0 && (
                    <Fragment>
                      <div className="my-club-container mb-4">
                        <h4 className="filter-title">{group.category}</h4>
                        <div className="badge-count">{group.clubs.length}</div>
                      </div>
                      <Row gutter={24}>
                        {group.clubs.map((clubItem: any) => {
                          return (
                            <Col span={24} className="mb-5">
                              <Link to={`/club/${clubItem.memberGroupId}`}>
                                <div className="club-item-list-view">
                                  {/* uncomment this if club image is available */}
                                  <div className="club-item-image">
                                    {/* FOR DECLINED PROPOSALS */}
                                    {/* FOR PENDING PROPOSALS */}
                                    {/* FOR APPROVED PROPOSALS OR CLUBS*/}
                                    {clubItem &&
                                    clubItem.status === "Rejected" ? (
                                      <Fragment>
                                        <div className="badge-status">
                                          <span
                                            className={`text-bold color-error`}
                                          >
                                            Declined
                                          </span>
                                        </div>
                                      </Fragment>
                                    ) : clubItem &&
                                      clubItem.status === "Pending" ? (
                                      <Fragment>
                                        <div className="badge-status">
                                          <span
                                            className={`text-bold color-warning`}
                                          >
                                            Pending
                                          </span>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {clubItem.userMembership &&
                                          clubItem.userMembership.joinStatus ===
                                            "Approved" && (
                                            <div className="badge-status">
                                              <span
                                                className={`text-bold color-success`}
                                              >
                                                Joined
                                              </span>
                                            </div>
                                          )}
                                        {clubItem.userMembership &&
                                          clubItem.userMembership.joinStatus ===
                                            "Pending" && (
                                            <div className="badge-status">
                                              <span
                                                className={`text-bold color-warning`}
                                              >
                                                Pending
                                              </span>
                                            </div>
                                          )}
                                      </Fragment>
                                    )}
                                    <img
                                      src={
                                        clubItem && clubItem.bannerFileUrl
                                          ? clubItem.bannerFileUrl +
                                            "?" +
                                            performance.now()
                                          : placeholderImg
                                      }
                                      height="170px"
                                      width="auto"
                                    />
                                  </div>
                                  <div className="club-item-content">
                                    <div className="club-content-wrapper">
                                      <div className="d-flex-column mb-4">
                                        <div className="d-flex align-items-center justify-content-space-between">
                                          <h4 className="club-item-title">
                                            {clubItem.name}
                                          </h4>
                                          <div className="d-flex align-items-center">
                                            <div>
                                              <Fragment>
                                                {clubItem.userMembership &&
                                                  clubItem.userMembership
                                                    .joinStatus ===
                                                    "Pending" && (
                                                    <Fragment>
                                                      {clubItem.userMembership
                                                        .createdBy !==
                                                        clubItem.userMembership
                                                          .memberId && (
                                                        <div className="d-flex">
                                                          <Button
                                                            style={{
                                                              height: "40px",
                                                            }}
                                                            htmlType="button"
                                                            className="btn-radius px-4 mr-1"
                                                            type="primary"
                                                            loading={
                                                              clubItem.loading
                                                            }
                                                            disabled={
                                                              actionIsLoading
                                                            }
                                                            onClick={(e) =>
                                                              onHandleClubInvitation(
                                                                e,
                                                                {
                                                                  type: "accept",
                                                                  clubId:
                                                                    clubItem.memberGroupId,
                                                                  clubName:
                                                                    clubItem.name,
                                                                }
                                                              )
                                                            }
                                                          >
                                                            Accept
                                                          </Button>
                                                          <Button
                                                            style={{
                                                              height: "40px",
                                                            }}
                                                            htmlType="button"
                                                            className="btn-radius px-5"
                                                            loading={
                                                              clubItem.loading
                                                            }
                                                            disabled={actionIsLoading}
                                                            onClick={(e) =>
                                                              onHandleDeclineClubInvitation(
                                                                e,
                                                                {
                                                                  type: "decline",
                                                                  clubId:
                                                                    clubItem.memberGroupId,
                                                                  clubName:
                                                                    clubItem.name,
                                                                }
                                                              )
                                                            }
                                                          >
                                                            Decline
                                                          </Button>
                                                        </div>
                                                      )}
                                                      {clubItem.userMembership
                                                        .createdBy ===
                                                        clubItem.userMembership
                                                          .memberId && (
                                                        <div className="d-flex">
                                                          <Button
                                                            style={{
                                                              height: "40px",
                                                            }}
                                                            htmlType="button"
                                                            className="btn-radius px-5"
                                                            loading={
                                                              clubItem.loading
                                                            }
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              // OR
                                                              e.preventDefault();
                                                              onLeaveClub(
                                                                e,
                                                                clubItem
                                                              );
                                                            }}
                                                          >
                                                            Cancel
                                                          </Button>
                                                        </div>
                                                      )}
                                                    </Fragment>
                                                  )}
                                                <Fragment>
                                                  {clubItem &&
                                                    clubItem.status ===
                                                      "Rejected" &&
                                                    clubItem.userMembership
                                                      .createdBy ===
                                                      signedInUserMemberId && (
                                                      <Fragment>
                                                        <Link
                                                          to={`/club-proposal/${clubItem.memberGroupId}`}
                                                        >
                                                          <Button
                                                            htmlType="button"
                                                            className="btn-radius px-4 mr-1"
                                                            type="primary"
                                                            loading={
                                                              clubItem.loading
                                                            }
                                                          >
                                                            Repropose
                                                          </Button>
                                                        </Link>
                                                      </Fragment>
                                                    )}
                                                </Fragment>
                                              </Fragment>
                                            </div>
                                            {clubItem &&
                                              clubItem.userMembership && (
                                                <div
                                                  className="ml-1"
                                                  onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    // OR
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  <Dropdown
                                                    className="item-menu"
                                                    overlay={
                                                      <Menu
                                                        onClick={(e: any) =>
                                                          onLeaveClub(
                                                            e,
                                                            clubItem
                                                          )
                                                        }
                                                        items={[
                                                          {
                                                            key: `leave-club-${clubItem.memberGroupId}`,
                                                            label: <a>Leave</a>,
                                                          },
                                                        ]}
                                                      />
                                                    }
                                                    placement="bottomRight"
                                                    trigger={["click"]}
                                                  >
                                                    <Button
                                                      shape="circle"
                                                      icon={
                                                        <EllipsisOutlined />
                                                      }
                                                    />
                                                  </Dropdown>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                        <div className="d-flex-column">
                                          <div className="d-flex align-items-center mr-3">
                                            <h5 className="club-item-interest d-flex align-items-center">
                                              <StarOutlined className="mr-1" />
                                              {clubItem.interest &&
                                                clubItem.interest.name}
                                            </h5>
                                            <h5 className="club-item-interest d-flex align-items-center">
                                              <UserOutlined className="ml-3 mr-2" />
                                              <span className="mt-1">
                                                {clubItem.memberCount} members
                                              </span>
                                            </h5>
                                          </div>

                                          <p className="club-item-description pt-3">
                                            {clubItem.description &&
                                              clubItem.description.substring(
                                                0,
                                                120
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </Col>
                          );
                        })}
                      </Row>
                    </Fragment>
                  )}
                </Fragment>
              );
            })}
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state: { auth: any; firebase: any }) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  joinClub,
  leaveClub,
})(MyClubsItemList);
