import { Input } from "antd";
import { ReactNode, useState } from "react";

const { Search } = Input;
const MiniPageHeader = (props: {
  onSearch: Function;
  placeholder: string;
  filterToggle: ReactNode;
  children: ReactNode;
  dateRange?: ReactNode | string;
}) => {
  const { onSearch, placeholder, filterToggle, dateRange, children } = props;
  const [searchString, setSearchString] = useState("");

  return (
    <div className="mini-page-header">
      <div className="container">
        <div className="d-flex" id="mini-page-item-1">
          {filterToggle && <div className="list-filters show-desktop-filters">{filterToggle}</div>}
          <div className="search-wrapper">
            <Search
              // onKeyUp={onFilterSearchHandler}
              onSearch={(e: any) => onSearch(e)}
              allowClear
              placeholder={placeholder}
            />
          </div>
          {dateRange && <div>{dateRange}</div>}
        </div>

        <div className="d-flex" id="mini-page-item-2">
          <div className="list-filters">{props.children}</div>
          <div className="list-filters show-medium-filters">{filterToggle}</div>
        </div>
      </div>
    </div>
  );
};

export default MiniPageHeader;
