import { Button, Checkbox, Input, Modal, Select, Space, Spin } from "antd";
import { Fragment } from "react";
import AlertContainer from "../../../layouts/AlertContainer";

export const ModalInviteClubMembers = (props: {
  modalTitle: any;
  handleSubmit: Function;
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: Function;
  onSearch: Function;
  list: any;
  isSearching: boolean;
  onSelectHandler: Function;
  selectedList: any;
  validationMessages: any;
}) => {
  const {
    modalTitle,
    handleSubmit,
    isOpen,
    isLoading,
    setIsOpen,
    onSearch,
    list,
    isSearching,
    onSelectHandler,
    selectedList,
    validationMessages,
  } = props;

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = async () => {
    handleSubmit();
  };

  const handleSearch = (searchString: string) => {
    onSearch(searchString);
  };

  const onSelect = (checked: boolean, memberId: string) => {
    onSelectHandler(checked, memberId);
  };

  return (
    <Modal
      className="modal-invite-club-member"
      title={modalTitle}
      visible={isOpen}
      onCancel={onCancelHandler}
      footer={[
        <Button
          type="primary"
          shape="round"
          key="submit"
          loading={isLoading}
          onClick={() => onSubmitHandler()}
          disabled={selectedList.length===0}
        >
          Send Invitations
        </Button>,
        <Button
          shape="round"
          key="cancel"
          loading={isLoading}
          onClick={() => onCancelHandler()}
        >
          Cancel
        </Button>,
      ]}
    >
      <label className="mb-2">Name</label>
      <Input
        className="w-100 mt-1 pt-2 pb-2"
        allowClear
        onChange={(e: any) => handleSearch(e.target.value)}
        placeholder="Search Name"
      />

      <div className="search-result-container">
        <ul>
          {isSearching && (
            <Fragment>
              <div className="d-flex justify-content-center align-items-center h-100 ">
                <h3 className="mr-2">Loading Data</h3>
                <Spin />
              </div>
            </Fragment>
          )}

          {list.length !== 0 ? (
            <Fragment>
              {list.map((item: any) => {
                return (
                  <li key={item.memberId}>
                    <div className="no-avatar">
                      <span>
                        {item && item.firstName.charAt(0)}
                        {item && item.lastName.charAt(0)}
                      </span>
                    </div>
                    <h5>
                      {item.firstName} {item.lastName}
                    </h5>
                    <Checkbox
                      checked={selectedList.includes(item.memberId)}
                      onChange={(e) =>
                        onSelect(e.target.checked, item.memberId)
                      }
                    />
                  </li>
                );
              })}
            </Fragment>
          ) : (
            <Fragment>
              {!isSearching && (
                <h3 className="d-flex justify-content-center align-items-center h-100">
                  No Result Found
                </h3>
              )}
            </Fragment>
          )}
        </ul>
        <div
          className={`${
            validationMessages && validationMessages.error && "mb-4 has-error"
          }`}
        >
          {validationMessages && validationMessages.error && (
            <AlertContainer data={validationMessages} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalInviteClubMembers;
