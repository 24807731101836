import axios from "axios";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
export const getModeratorList = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Member/list?MemberTypes=1&MemberTypes=2&SearchString=${params.SearchString}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};
