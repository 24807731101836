import { acceptEventInvite, getEventDetails } from "../../../actions/event";
import { Button, Tooltip, Row, Col } from "antd";
import { 
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CompassOutlined,
  CloseCircleFilled,
 } from "@ant-design/icons";
import { connect } from "react-redux";
import { declineEventInvite } from "../../../actions/event";
import { deleteInvite } from "../../../actions/event";
import { EventInvite, Invite } from "./EventInvite";
import { joinEvent } from "../../../actions/event";
import { markAsAttended } from "../../../actions/event";
import { NO_CONTENT, OK } from "../../../helpers/statuscodes";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import headerBannerBg from "../../../assets/images/soccer-img.png";
import clubIcon from "../../../assets/images/icons/club-icon.svg";
import { softSkillColors } from "../../../helpers/softSkillColors";
import { neutralSkillColors } from "../../../helpers/neutralSkillColors";

export type QuickAction = {
  primary?: string;
  secondary?: string;
  status?: string;
};
export type ListViewType = "row" | "card" | "calendar";
export const getQuickAction = (
  endDate: Date,
  userInvite?: Invite
): QuickAction => {
  const now = new Date();
  let result = undefined;

  if (
    userInvite === undefined ||
    userInvite === null ||
    userInvite?.inviteId === undefined
  ) {
    // user not invited nor submitted a request to join event
    if (now < endDate) {
      result = { primary: "Request to Join" };
    }
  } else if (
    userInvite &&
    endDate < now &&
    userInvite?.attendance !== undefined &&
    userInvite?.attendance.isAttended === false
  ) {
    // event is marked as absent and rejected
    result = { status: "Absent" };
  } else if (
    userInvite &&
    endDate < now &&
    userInvite?.attendance === undefined
  ) {
    // event is finished and user is invited
    result = { primary: "Mark as Attended" };
  } else if (
    userInvite &&
    endDate < now &&
    userInvite?.attendance !== undefined
  ) {
    // event is finished and user is invited
    result = { status: "Attended" };
  } else if (
    userInvite &&
    userInvite?.status === 0 &&
    userInvite?.createdBy !== userInvite?.memberId
  ) {
    // user is invited but not yet responded
    result = { status: "Pending", primary: "Accept", secondary: "Decline" };
  } else if (
    userInvite &&
    userInvite?.status === 0 &&
    userInvite?.createdBy === userInvite?.memberId
  ) {
    // user requested to join but not yet approved nor decline
    result = { status: "Waiting for Approval", secondary: "Cancel Request" };
  } else if (
    userInvite &&
    userInvite?.status === 1 &&
    userInvite?.participationType === 0
  ) {
    // user is a moderator for the event
    result = {};
  } else if (
    userInvite &&
    userInvite?.status === 1 &&
    userInvite?.participationType === 1 &&
    userInvite?.createdBy !== userInvite?.memberId
  ) {
    // user accepted the event invite
    result = { status: "Accepted", secondary: "Leave" };
  } else if (
    userInvite &&
    userInvite?.status === 1 &&
    userInvite?.participationType === 1 &&
    userInvite?.createdBy === userInvite?.memberId
  ) {
    // user was accepted in the event
    result = { status: "Accepted", secondary: "Leave" };
  } else if (
    userInvite &&
    userInvite?.status === 2 &&
    userInvite?.createdBy != userInvite?.memberId
  ) {
    // user rejected the invitation
    // result = { status: "Declined", secondary: "" };
    result = { primary: "Request to Join" };
  } else if (
    userInvite &&
    userInvite?.status === 2 &&
    userInvite?.createdBy === userInvite?.memberId
  ) {
    // user was declined to join the event
    result = { primary: "Request to Join", status: "Rejected" };
  } else {
    result = {};
  }

  return result as QuickAction;
};

const EventItem = (props: {
  event: EventInvite;
  view: ListViewType;
  joinEvent: Function;
  deleteInvite: Function;
  markAsAttended: Function;
  acceptEventInvite: Function;
  declineEventInvite: Function;
  onDeclineHandler: Function;
  fetchEarnedPointsLastWeek?: Function;
  getEventDetails: Function;
  source?: string;
}) => {
  const {
    event,
    joinEvent,
    deleteInvite,
    markAsAttended,
    acceptEventInvite,
    declineEventInvite,
    onDeclineHandler,
    fetchEarnedPointsLastWeek,
    getEventDetails,
  } = props;
  const [eventData, setEventData] = useState<EventInvite>();
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [banner, setBanner] = useState(headerBannerBg);
  const [userInvite, setUserInvite] = useState<Invite>();
  const [quickAction, setQuickAction] = useState<QuickAction>();
  const navigate = useNavigate();

  const onActionClick = async (action: string | undefined) => {
    if (action === undefined) return;
    setIsLoading(true);
    if (action === "Request to Join") {
      const result = await joinEvent(eventData?.eventId);
      if (result && result.data) {
        setUserInvite(result.data);
        // await fetchEventDetails(eventData?.eventId);
      }
    } else if (action === "Accept" && userInvite) {
      const result = await acceptEventInvite({
        eventId: eventData?.eventId,
        inviteId: userInvite?.inviteId,
      });
      if (result && result.data) {
        setUserInvite(result.data);
        // await fetchEventDetails(eventData?.eventId);
      }
    } else if (action === "Decline" && userInvite) {
      onDeclineHandler(event, userInvite?.inviteId);
      // const result = await declineEventInvite({
      //   eventId: eventData?.eventId,
      //   inviteId: userInvite?.inviteId,
      // });
      // if (result && result.data) {
      //   setUserInvite(result.data);
      // }
    } else if (
      (action === "Cancel Request" ||
        action === "Cancel" ||
        action === "Leave") &&
      userInvite
    ) {
      const result = await deleteInvite({
        eventId: eventData?.eventId,
        inviteId: userInvite?.inviteId,
      });
      if (result.status === NO_CONTENT) {
        setUserInvite(undefined);
        // await fetchEventDetails(eventData?.eventId);
      }
    } else if (action === "Mark as Attended" && userInvite) {
      const response = await markAsAttended({
        eventId: eventData?.eventId,
        inviteId: userInvite?.inviteId,
      });
      if (response.status === OK) {
        // const _userInvite = { ...userInvite, attendance: response.data };
        // setUserInvite(_userInvite);
        await fetchEventDetails(eventData?.eventId);
        if (fetchEarnedPointsLastWeek) {
          fetchEarnedPointsLastWeek();
        }
      }
    }
    setIsLoading(false);
  };

  const fetchEventDetails = async (eventId: any) => {
    const result = await getEventDetails({ eventId });
    if (result && result.data) {
      setEventData(result.data);
    }
  };

  useEffect(() => {
    if (event) {
      setQuickAction(
        getQuickAction(moment(event.endDate).toDate(), userInvite)
      );
    }
  }, [userInvite]);

  useEffect(() => {
    if (event) {
      setEventData(event);
    }
  }, [event]);

  useEffect(() => {
    if (eventData) {
      const startDate = new Date(eventData.startDate);
      const endDate = new Date(eventData.endDate);


      setEventDate(moment(startDate).format("ll"));
      setEventTime(
        `${moment(startDate).format("LT")} - ${moment(endDate).format("LT")}`
      );
      if (eventData.userInvite && eventData.userInvite?.inviteId) {
        setUserInvite(eventData.userInvite);
      }
      if (eventData.bannerFileUrl) {
        setBanner(eventData.bannerFileUrl);
      }
    }
  }, [eventData]);

  return (
    <Fragment>
      <div className={`event-list-item ${props.view}`}>
        {eventData?.bannerFileUrl &&
          props.view === "card" &&
          eventData?.userInvite?.attendance && (
            <div
              className="header"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${banner})`,
              }}
              onClick={() =>
                navigate(
                  `/event/${eventData?.eventId}${
                    props.source ? "?g=" + props.source : ""
                  }`
                )
              }
            >
              <Fragment>
                <h4>
                  {eventData?.userInvite?.attendance?.earnedPoints &&
                    Math.round(eventData?.userInvite?.attendance?.earnedPoints)}
                  {eventData?.userInvite?.attendance?.earnedPoints === 1
                    ? " pt"
                    : " pts"}
                </h4>
              </Fragment>
            </div>
          )}

        <div
          className="content"
          onClick={() =>
            navigate(
              `/event/${eventData?.eventId}${
                props.source ? "?g=" + props.source : ""
              }`
            )
          }
        >
          {eventData?.userInvite?.attendance && !eventData?.bannerFileUrl && (
            <div className="header-no-img">
              <h4>
                <b>
                  {eventData?.userInvite?.attendance?.earnedPoints &&
                    Math.round(eventData?.userInvite?.attendance?.earnedPoints)}
                  {eventData?.userInvite?.attendance?.earnedPoints === 1
                    ? " pt"
                    : " pts"}
                </b>
              </h4>
            </div>
          )}

          <div className="d-flex">
            <h2 className="event-list-title mr-auto">{eventData?.title}</h2>
          </div>
          <div className="event-list-item-footer">
            <span>
              {/* <CalendarOutlined /> */}
              {eventDate}
              {/* <ClockCircleOutlined /> */}
              &nbsp;{eventTime}
              {eventData?.location && (
                <span>
                  {/* <CompassOutlined /> */}
                  &nbsp;{eventData?.location}
                </span>
              )}
            </span>
          </div>
          {event && (
            <div className="event-club-details">
              {eventData?.memberGroup && (
                <div className="d-flex align-items-center">
                <img height={28} width={28} src={clubIcon} />
                <h5>{eventData?.memberGroup}</h5>
              </div>
              )}

              {!eventData?.memberGroup && (
                <div className="d-flex align-items-center">
                </div>
              )}
              
              {userInvite
                && userInvite?.status === 2
                && userInvite?.createdBy != userInvite?.memberId
                && (
                  <div className="invite-status">
                    <div className="d-flex align-items-center">
                      <CloseCircleFilled
                          style={{fontSize: 28, marginRight: 5}}
                        />
                      <h5>Declined</h5>
                    </div>
                    <div className="tooltip">
                      <h5>Declined Reason:</h5>
                      <span>{userInvite.remarks}</span>
                    </div>
                  </div>
                )}
                
            </div>
          )}
          {event && eventData?.softSkillScores && (
            <div className="event-softskill-content mt-1">
              <h4>Softskills</h4>

              <div className="event-softskill-bar">
                {eventData?.softSkillScores.map((skill: any, index: any) => {
                  return (
                    <div
                      className="bar"
                      style={{
                        width: `${skill?.percentage}%`,
                        backgroundColor: softSkillColors[index] ?? neutralSkillColors[index % neutralSkillColors.length],
                      }}
                    >
                      {skill?.percentage}%
                    </div>
                  );
                })}
              </div>
              <div className="event-softskill-legend mt-3">
                {eventData?.softSkillScores.map((skill: any, index: any) => {
                  return (
                    <div className="softskill-legend">
                      <div
                        className="box"
                        style={{ background: softSkillColors[index] ?? neutralSkillColors[index % neutralSkillColors.length] }}
                      ></div>
                      <label>{skill?.name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="footer">
          {quickAction && quickAction.primary && (
            <Button
              style={{ height: 40 }}
              type="primary"
              shape="round"
              loading={isLoading}
              icon={<CheckOutlined />}
              onClick={() => onActionClick(quickAction.primary)}
            >
              {quickAction.primary}
            </Button>
          )}
          {quickAction && quickAction.secondary && (
            <Button
              shape="round"
              loading={isLoading}
              icon={<CloseOutlined />}
              onClick={() => onActionClick(quickAction.secondary)}
            >
              {quickAction.secondary}
            </Button>
          )}
          {quickAction && quickAction.status && (
            <div className="d-flex-column">
              {quickAction.status === "Rejected" ? (
                // <Tooltip title={userInvite?.remarks}>
                <div className="event-status">
                  <span >{quickAction.status}</span>
                  <div className="tooltip">
                      <h5>Rejected Reason:</h5>
                      <span>{userInvite?.remarks}</span>
                  </div>
                </div>
                // </Tooltip>
              ):(
                <span className="event-status">{quickAction.status}</span>
              )}
              {props.view === "row" && eventData?.userInvite?.attendance && (
                <Fragment>
                  <h2 className="event-list-title text-center mt-4 color-success">
                    <b>
                      {eventData?.userInvite?.attendance?.earnedPoints &&
                        Math.round(
                          eventData?.userInvite?.attendance?.earnedPoints
                        )}{" "}
                      {eventData?.userInvite?.attendance?.earnedPoints === 1
                        ? "pt"
                        : "pts"}
                    </b>
                  </h2>
                </Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default connect(null, {
  joinEvent,
  deleteInvite,
  acceptEventInvite,
  declineEventInvite,
  markAsAttended,
  getEventDetails,
})(EventItem);
