import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMemberList } from "../../../actions/school";

import iconExclamation from "../../../assets/images/icons/icon-exclamation.svg";
import { Link, useNavigate } from "react-router-dom";
import { Teacher } from "../../../helpers/memberTypes";
import { memberStatus } from "../../../helpers/memberStatus";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const TeacherList = (props: any) => {
  let navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
      render: (text: any, record: any) => (
        <a>{record.firstName + " " + record.lastName}</a>
      ),
    },
    {
      title: "School",
      dataIndex: "schoolName",
      key: "school",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => <a>{memberStatus[text]}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a id={record.memberId} onClick={() => editMemberHandler(record)}>
            Edit
          </a>
        </Space>
      ),
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('status');
  const [statusFilter, setStatusFilter] = useState(parseInt(myParam??"-1") >= 0 ? parseInt(myParam??"-1") :-1);
  const { getMemberList } = props;
  const [teacherList, setTeacherList]: any = useState([]);
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination]: any = useState(initialPagination);
  const [searchString, setSearchString]: any = useState("");

  const [isListLoading, setIsListLoading] = useState(false);

  const onFilterSearchHandler = (searchInput: String) => {
    setSearchString(searchInput);
  };

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
  };

  const editMemberHandler = (record: any) => {
    navigate("/admin/teachers/add", {
      state: {
        memberId: record?.memberId,
        gender: record?.gender,
        birthDate: record?.birthDate,
        firstName: record?.firstName,
        lastName: record?.lastName,
        emailAddress: record?.emailAddress,
        jobTitle: record?.jobTitle,
      },
    });
  };

  const fetchList = async (parameters: object) => {
    setIsListLoading(true);
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        memberType: Teacher,
        status: statusFilter,
      };
    } else {
      params = { ...parameters, memberType: Teacher, status: statusFilter };
    }
    const data: any = await getMemberList(params);
    setTeacherList(data.data.items);
    setPagination({
      current: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsListLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString, statusFilter]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };

  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="Teachers"
          extra={[
            <Button key="1" className="btn-radius">
              <Link to={"/admin/teachers/add-bulk"}>Upload Teachers</Link>
            </Button>,
            <Button key="2" className="btn-radius" type="primary">
              <Link to={"/admin/teachers/add"}>Add New Teachers</Link>
            </Button>,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    <div className="my-auto mr-auto">
                      Status:{" "}
                      <select onChange={onfilterStatusHandler} value={statusFilter}>
                        <option value="-1">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                    <div>
                      <Search
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search by Name, School & Job Title"
                        style={{ width: 280 }}
                      />
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="memberId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      scroll={{ y: "calc(100vh - 420px)" }}
                      dataSource={teacherList}
                      loading={isListLoading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getMemberList })(TeacherList);
