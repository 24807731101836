import { Button, Form, FormInstance, Select, SelectProps, Spin } from "antd";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import AlertContainer from "../../../layouts/AlertContainer";
import { getModeratorList } from "../../../actions/moderator";
import { connect } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { copyFile } from "fs";

const { Option } = Select;

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Form.Item label="Select Club Moderator" name="moderators">
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    </Form.Item>
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

const ClubModeratorField = (props: {
  getModeratorList: Function;
  validationMessages: any;
  form: FormInstance<any>;
  resetValidation: Function;
}) => {
  const [moderators, setModerators] = useState<UserValue[]>([]);
  const { validationMessages, getModeratorList, form, resetValidation } = props;
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchUserList(username: string): Promise<UserValue[]> {
    let params = {
      SearchString: username,
    };
    const data = await getModeratorList(params);

    return data.data
      .filter(
        (f: any) =>
          moderators.findIndex((ff) => ff.value === `${f.id}-${f.type}`) < 0
      )
      .map((item: any) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: `${item.memberId}`,
      }));
  }

  useEffect(() => {
    const formData = form.getFieldValue("moderators");
    if (formData) {
      setModerators(formData);
    }
  }, [form]);

  return (
    <Fragment>
      <DebounceSelect
        mode="multiple"
        value={moderators}
        placeholder="Select Club Moderator"
        fetchOptions={fetchUserList}
        onChange={(newValue) => {
          setModerators(newValue as UserValue[]);
          resetValidation();
        }}
        style={{ width: "100%" }}
        allowClear
      />
      {validationMessages && (
        <div className={`${validationMessages.memberGroupId && "has-error"}`}>
          {validationMessages && validationMessages.memberGroupId && (
            <AlertContainer data={validationMessages.memberGroupId} />
          )}
        </div>
      )}

      {moderators.length > 0 && (
        <div className="select-item-selected-container-designed mt-0">
          {moderators.map((p: UserValue) => (
            <div key={p.value} className="selected-item-container">
              <div className="d-flex align-items-center">
                {/* <div className="user-avatar mr-2">
                <img height="auto" width="42px" src={"https://notion-emojis.s3-us-west-2.amazonaws.com/prod/svg-twitter/1f309.svg"} />
              </div> */}
                <div className="no-avatar avatar-sm mr-2">
                  <span>{p.label?.charAt(0)}</span>
                </div>
                <p className="align-items-center mb-0">{p.label}</p>
              </div>
              <Button
                type="link"
                size="small"
                icon={<CloseOutlined />}
                onClick={() => {
                  setModerators(moderators.filter((f) => f.value !== p.value));
                  form.setFieldsValue({
                    moderators: moderators.filter((f) => f.value !== p.value),
                  });
                }}
              ></Button>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default connect(null, {
  getModeratorList,
})(ClubModeratorField);
