import { Form, Input, Button, Select, Col, Row } from "antd";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  addSoftSkill,
  updateSoftSkill,
  deleteSoftskill,
} from "../../../actions/softskill";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation, useNavigate } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";

const { Option } = Select;

const AddSoftSkillForm = (props: {
  addSoftSkill: Function;
  updateSoftSkill: Function;
  deleteSoftskill: Function;
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [addSoftSkillForm] = Form.useForm();
  const { addSoftSkill, updateSoftSkill, deleteSoftskill } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const onFinish = async (formData: any) => {
    setValidationMessages(new Array());
    setIsLoading(true);
    let res;
    if (isEditMode) {
      res = await updateSoftSkill(
        { ...formData, softSkillId: location.state.softSkillId },
        location.state.softSkillId
      );
    } else {
      res = await addSoftSkill(formData);
    }
    if (res.status == OK || res.status == CREATED) {
      if (!isEditMode) {
        onReset();
      }
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  //deactivate teacher
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteSoftskill(location.state.softSkillId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
      navigate("/admin/softskills");
    }
    setIsDeleteLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    addSoftSkillForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    if (location && location.state) {
      setIsEditMode(true);
      addSoftSkillForm.setFieldsValue({
        name: location?.state?.name,
        description: location?.state?.description,
      });
    }
  }, []);

  return (
    <Fragment>
      <ModalDeleteAlert
        modalTitle="Soft Skill"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Row>
        <Col span={24}>
          <Form
            form={addSoftSkillForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert"
          >
            <h5 className="text-head pb-3">Soft Skill Details</h5>
            <Row>
              <Col
                span={8}
                xs={{ span: 24 }}
                lg={{ span: 16 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  className="mb-0"
                  label={
                    <span>
                      Soft Skill Name (<small>50 characters max.</small>)
                    </span>
                  }
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input Soft Skill Name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Soft Skill Name!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Soft Skill Name" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages && validationMessages.Name && "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Name && (
                    <AlertContainer data={validationMessages.Name} />
                  )}
                </div>

                <Form.Item
                  className="mb-0 pt-2"
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input Soft Skill Description!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Soft Skill Description!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Soft Skill Description" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.Description &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Description && (
                    <AlertContainer data={validationMessages.Description} />
                  )}
                </div>

                <div
                  className={`${
                    validationMessages &&
                    validationMessages.error &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && validationMessages.error && (
                    <AlertContainer data={validationMessages} />
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <div className="w-100 d-flex">
                  <Button
                    className="btn-breeze-box mr-2 btn-radius"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save Soft Skill
                  </Button>
                  <Button
                    className="btn-radius"
                    disabled={isLoading}
                    onClick={onReset}
                  >
                    Reset Fields
                  </Button>
                  {isEditMode && (
                    <Button
                      className="ml-auto btn-radius"
                      disabled={isLoading}
                      onClick={() => setIsModalDeleteVisible(true)}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  addSoftSkill,
  updateSoftSkill,
  deleteSoftskill,
})(AddSoftSkillForm);
