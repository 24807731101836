import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Col,
  Row,
  Radio,
  DatePicker
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { deleteMember } from "../../../actions/school";
import { addTeacherUser, updateTeacherUser } from "../../../actions/teacher";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import moment from "moment";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";

const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
const customFormat = (value: any) => `${value.format(dateFormat)}`;

const AddTeacherForm = (props: {
  addTeacherUser: Function;
  updateTeacherUser: Function;
  deleteMember: Function;
}) => {
  const location: any = useLocation();
  const [addTeacherForm] = Form.useForm();
  const { addTeacherUser, updateTeacherUser, deleteMember } = props;
  const [isEditMode, setIsEditMode]: any = useState(false);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const onFinish = async (formData: any) => {
    let values = {
      ...formData,
      birthDate: moment(formData.birthDate).format(dateFormat)
    };
    setValidationMessages(new Array());
    setIsLoading(true);
    let res;
    if (isEditMode) {
      let customFormData = { ...values, memberType: 0 };
      res = await updateTeacherUser(customFormData, location.state.memberId);
    } else {
      res = await addTeacherUser(values);
    }

    if (res.status == OK || res.status == CREATED) {
      setIsLoading(false);
      if (!isEditMode) {
        onReset();
      }
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  //deactivate teacher
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteMember(location.state.memberId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
    }
    setIsDeleteLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    addTeacherForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    const fetchSchoolList = async () => {
      //populate form if editing a student data
      if (location && location.state) {
        setIsEditMode(true);
        addTeacherForm.setFieldsValue({
          gender: location?.state?.gender,
          birthDate: moment(location?.state?.birthDate),
          firstname: location?.state?.firstName,
          lastname: location?.state?.lastName,
          EmailAddress: location?.state?.emailAddress,
          jobTitle: location?.state?.jobTitle
        });
      }
    };

    fetchSchoolList();
  }, []);

  return (
    <Fragment>
      <ModalDeleteAlert
        modalTitle="Teacher"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Row>
        <Col span={24}>
          <Form
            form={addTeacherForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert"
          >
            <Row gutter={24}>
              <Col
                span={8}
                xs={{ span: 24 }}
                lg={{ span: 16 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    { required: true, message: "Please select your gender" }
                  ]}
                >
                  <Radio.Group>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.gender &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.gender && (
                    <AlertContainer data={validationMessages.gender} />
                  )}
                </div>

                <Form.Item
                  name="birthDate"
                  label="Birthdate"
                  rules={[
                    { required: true, message: "Please your Select Birthdate" }
                  ]}
                >
                  <DatePicker
                    placeholder=" Select Birthdate"
                    format={dateFormat}
                  />
                </Form.Item>

                <Row justify="space-between" gutter={24}>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      className="mb-0"
                      label="First Name"
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!"
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              //resolve to use original rule
                              return Promise.resolve();
                            }
                            if (!isEmptyOrSpaces(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error("Please input your First Name!")
                              );
                            }
                          }
                        })
                      ]}
                    >
                      <Input placeholder="Enter Given Name" />
                    </Form.Item>
                    <div
                      className={`mb-4 ${
                        validationMessages &&
                        validationMessages.FirstName &&
                        "has-error"
                      }`}
                    >
                      {validationMessages && validationMessages.FirstName && (
                        <AlertContainer data={validationMessages.FirstName} />
                      )}
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      className="mb-0"
                      label="Last Name"
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!"
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              //resolve to use original rule
                              return Promise.resolve();
                            }
                            if (!isEmptyOrSpaces(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error("Please input your Last Name!")
                              );
                            }
                          }
                        })
                      ]}
                    >
                      <Input placeholder="Enter Family Name" />
                    </Form.Item>
                    <div
                      className={`mb-4 ${
                        validationMessages &&
                        validationMessages.LastName &&
                        "has-error"
                      }`}
                    >
                      {validationMessages && validationMessages.LastName && (
                        <AlertContainer data={validationMessages.LastName} />
                      )}
                    </div>
                  </Col>
                </Row>
                <Form.Item
                  className="mb-0"
                  label="School Email"
                  name="EmailAddress"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The email you entered is invalid!"
                    }
                  ]}
                >
                  <Input placeholder="eg johndoe@xyz.edu.ph " disabled={isEditMode ? true : false} />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.EmailAddress &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.EmailAddress && (
                    <AlertContainer data={validationMessages.EmailAddress} />
                  )}
                </div>

                <Form.Item
                  className="mb-0"
                  label="Job Title"
                  name="jobTitle"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Job Title!"
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input your Job Title!")
                          );
                        }
                      }
                    })
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.jobTitle &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.JobTitle && (
                    <AlertContainer data={validationMessages.JobTitle} />
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <div className="w-100 d-flex">
                  <Button
                    className="btn-breeze-box mr-2"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save User Info
                  </Button>
                  <Button disabled={isLoading} onClick={onReset}>
                    Reset Fields
                  </Button>
                  {isEditMode && (
                    <Button
                      className="ml-auto"
                      disabled={isLoading}
                      onClick={() => setIsModalDeleteVisible(true)}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  addTeacherUser,
  updateTeacherUser,
  deleteMember
})(AddTeacherForm);
