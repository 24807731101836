import { Button } from "antd";
import { Fragment } from "react";
import WelcomeBackArtWork from "../../../assets/images/welcome-back-artwork.png";
import { useNavigate } from "react-router-dom";

export const WelcomeBack = (props: { setShowWelcomeBack: Function }) => {
  let navigate = useNavigate();
  return (
    <div className="empty-state-v2 d-flex justify-content-center h-100">
      <div className="d-flex d-flex-column justify-content-center">
        <h1>Welcome Back</h1>
        <p>
          It’s nice to know that you are active again in joining Club Events
        </p>
        <div className="d-flex justify-content-center mt-3">
          <Button
            style={{ height: 57, borderRadius: 10, fontSize: 21 }}
            type="primary"
            shape="default"
            onClick={() => props.setShowWelcomeBack(false)}
          >
            Previous Page
          </Button>
        </div>
        <div>
          <img width="100%" src={WelcomeBackArtWork} />
        </div>
      </div>
    </div>
  );
};

export default WelcomeBack;
