import axios from "axios";
import { Form, Button, Input, Col, Row } from "antd";

import { Fragment, useEffect, useState } from "react";

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import infoCircleIcon from "../../assets/images/icons/info-circle.svg";
import {
  BAD_REQUEST,
  NOT_FOUND,
  NO_CONTENT,
  OK,
} from "../../helpers/statuscodes";
import AlertContainer from "../../layouts/AlertContainer";

const SetupPassword = (props: any) => {
  const [setupPasswordForm] = Form.useForm();
  const [isLoading, setIsloading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [validationMessages, setValidationMessages]: any = useState([]);

  const { onSetPassword, userData, emailAddress, firstName, isResetPassword } =
    props;

  const { verificationCodeId } = userData;

  const onFinish = (values: any) => {
    console.log("Success:", values);
    setIsloading(true);
    let formData = {
      ...values,
      ConfirmPassword: isResetPassword
        ? values.password
        : values.ConfirmPassword,
      verificationCodeId,
    };
    onSetupPasswordHandler(formData);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onSetupPasswordHandler = async (formData: any) => {
    setValidationMessages([]);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `/api/Account/set-password`,
        formData,
        config
      );

      if (res.status === NO_CONTENT) {
        let body = {
          username: emailAddress,
          password: formData.password,
        };
        if (!isResetPassword) {
          setIsLoggingIn(true);
        }
        onSetPassword(body);
      }
    } catch (err: any) {
      if (err.response.status === BAD_REQUEST) {
        if (err.response.data) {
          handleFormErrors(err.response.data);
        }
      }
    }

    setIsloading(false);
  };

  const handleFormErrors = (data: any) => {
    for (let property in data) {
      setValidationMessages({
        [property]: data[property],
      });
    }
  };

  useEffect(() => {
    setupPasswordForm.setFieldsValue({
      username: emailAddress,
    });
  }, []);

  return (
    <Row>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 10, offset: 7 }}
        xxl={{ span: 8, offset: 7 }}
      >
        <div className="form-container">
          {isResetPassword ? (
            <Fragment>
              <h2 className="form-title">Please enter your new password</h2>
              <h5 className="sub-title">
                We’ll help you reset it and get back on track.
              </h5>
            </Fragment>
          ) : (
            <Fragment>
              <h2 className="form-title">
                Hi {firstName}! You’re just one step away on using Breeze!
              </h2>
              <h5 className="sub-title">Create your Breeze Account</h5>
            </Fragment>
          )}

          <Form
            form={setupPasswordForm}
            name="setup-password-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="custom-alert mt-6"
            layout="vertical"
          >
            {!isResetPassword && (
              <Fragment>
                <label className="mb-2">School Email Address</label>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input valid Email!",
                    },
                  ]}
                >
                  <Input className="mt-1" readOnly disabled />
                </Form.Item>
              </Fragment>
            )}

            <div className="d-flex">
              <label>Password</label>
              <div className="ml-1 custom-tooltip-container">
                <img className="info-icon" src={infoCircleIcon} />
                <div className="custom-tooltip-content">
                  <div className="arrow-left"></div>
                  <div className="d-flex-column">
                    <div className="tip-title">Required Format</div>
                    <ul>
                      <li>Must be 8 Characters long</li>
                      <li> At least 1 Capital Letter</li>
                      <li> At least 1 Special Character</li>
                      <li>
                        No consecutive three letters, numbers or special
                        characters
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <Form.Item
              className="mb-0"
              name="password"
              {...(validationMessages && validationMessages.Password
                ? { validateStatus: "error" }
                : "")}
              rules={[
                {
                  required: true,
                  message: "The password you entered is invalid",
                },
              ]}
            >
              <Input
                type={showPassword ? "text" : "password"}
                addonAfter={
                  !showPassword ? (
                    <EyeOutlined
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )
                }
                placeholder="******"
              />
            </Form.Item>
            <div
              className={`mb-4  force-alert ${
                validationMessages && validationMessages.Password && "has-error"
              }`}
            >
              {validationMessages && validationMessages.Password && (
                <AlertContainer data={validationMessages.Password} />
              )}
            </div>

            <label className="mb-2">Confirm Password </label>
            <Form.Item
              className="mb-3"
              name="ConfirmPassword"
              dependencies={["password"]}
              {...(validationMessages && validationMessages.ConfirmPassword
                ? { validateStatus: "error" }
                : "")}
              {...(validationMessages && validationMessages.ConfirmPassword
                ? { help: validationMessages.ConfirmPassword }
                : "")}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The password you submitted does not match.")
                    );
                  },
                }),
              ]}
            >
              <Input
                type={showConfirmPassword ? "text" : "password"}
                addonAfter={
                  !showConfirmPassword ? (
                    <EyeOutlined
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  )
                }
                placeholder="******"
              />
            </Form.Item>
            {isLoggingIn ? (
              <Form.Item>
                <Button
                  className="btn-breeze-box-radius mt-2"
                  disabled={isLoggingIn}
                  htmlType="submit"
                  loading={isLoggingIn}
                >
                  Logging In..
                </Button>
              </Form.Item>
            ) : (
              <Form.Item>
                <Button
                  className="btn-breeze-box-radius mt-2"
                  disabled={isLoading}
                  htmlType="submit"
                  loading={isLoading}
                >
                  {!isResetPassword ? "Submit" : "Change Password"}
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default SetupPassword;
