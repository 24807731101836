import { Col, PageHeader, Row } from "antd";
import { Fragment } from "react";

const SchoolAdmin = () => {
  return (
    <Fragment>
      <PageHeader
        className="site-page-header content-header"
        onBack={() => null}
        title="School Admin"
      />
      <div className="app-main-content">
        <div className="app-main-content-inner">
          <Row>
            <Col span={24}> school admin content here</Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default SchoolAdmin;
