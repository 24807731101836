import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row, Skeleton } from "antd";
import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { connect } from "react-redux";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { Form } from "antd";
import { Input } from "antd";
import { Layout } from "antd";
import LocationField from "../../super-admin/select-fields/LocationField";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import ClubModeratorField from "../../super-admin/select-fields/ClubModeratorField";
import { getInterestListNonPaged } from "../../../actions/interest";
import academicsIcon from "../../../assets/images/icons/academics-icon.svg";
import checkmarkIcon from "../../../assets/images/icons/checkmark-green.svg";
import { addClub, getClubById, updateClub } from "../../../actions/club";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import AlertContainer from "../../../layouts/AlertContainer";
import { Closed, Private, Public } from "../../../helpers/privacyTypes";
import proposeFrame from "../../../assets/images/illustration/illus-propose.svg";

const ProposeClub = (props: {
  getInterestListNonPaged: Function;
  addClub: Function;
  getClubById: Function;
  updateClub: Function;
}) => {
  const { memberGroupId } = useParams();
  const { getInterestListNonPaged, addClub, getClubById, updateClub } = props;

  const navigate = useNavigate();
  const mainDiv = useRef<HTMLElement | null>(null);
  const carousel = useRef<CarouselRef | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [pageIndex, setPageIndex] = useState(0);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [form] = Form.useForm();
  const [clubModeratorList, setClubModeratorList] = useState<any>([]);
  const [selectedInterest, setSelectedInterest] = useState<string>();
  const [clubType, setClubType]: any = useState(0);
  const [interestList, setInterestList] = useState<any[]>([]);
  const [isFinalSlide, setIsFinalSlide] = useState(false);
  const [isFinish, setIsFinish] = useState(false);

  const onBackClick = () => {
    if (carousel && carousel.current && pageIndex !== 0) {
      carousel.current.prev();
    }
  };
  const onNextClick = () => {
    if (carousel && carousel.current) {
      carousel.current.next();
    }
  };

  const onGoToClick = (position: any) => {
    if (carousel && carousel.current) {
      carousel.current.goTo(position);
    }
  };

  const onCancelClick = () => {
    navigate(`/clubs/`);
  };

  const onSubmit = async (formData: any) => {
    setIsSaving(true);

    let moderatorIds =
      formData.moderators &&
      formData.moderators.map((mod: { value: string }) => {
        return mod.value;
      });
    let data = {
      ...formData,
      privacyType: clubType,
      interestId: selectedInterest,
      moderators: moderatorIds,
      noAlert: true,
    };

    let res;
    // onNextClick();
    if (memberGroupId) {
      res = await updateClub(data, memberGroupId);
    } else {
      res = await addClub(data);
    }
    if (res.status == OK || res.status == CREATED) {
      onGoToClick(3);
      setIsFinish(true);
      setValidationMessages(new Array());
    } else if (res.status === BAD_REQUEST) {
      if (res.data.error === "Club already exists.") {
        handleFormErrors(res.data);
        onGoToClick(0);
      } else if (res.data.error.includes("Clubs under moderator")) {
        handleFormErrors(res.data);
        onGoToClick(1);
      }
    }

    setIsSaving(false);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onPageChange = (currentSlide: number) => {
    if (currentSlide === 2) {
      setIsFinalSlide(true);
    } else if (currentSlide === 3) {
      setIsFinish(true);
    } else {
      setIsFinalSlide(false);
    }
    setPageIndex(currentSlide);
    onGoToClick(3);
    mainDiv && mainDiv.current && mainDiv.current.scrollTo(0, 0);
  };

  const onBeforeChange = (from: number, to: number) => {
    onGoToClick(3);
  };

  let submitText = isFinalSlide
    ? memberGroupId
      ? "Resubmit"
      : "Submit"
    : "Continue";

  const onContinueClick = async () => {
    if (carousel && carousel.current) {
      if (pageIndex === 0) {
        await form.validateFields(["name"]);
        carousel.current.next();
      } else if (pageIndex === 1) {
        console.log(await form.validateFields(["moderators"]));
        let mods = await form.validateFields(["moderators"]);
        if (mods && mods.moderators && mods.moderators.length > 0) {
          carousel.current.next();
        } else {
          setValidationMessages({
            error: "Club Moderator is required",
          });
        }
      } else if (pageIndex === 2) {
        form.submit();
      } else {
        carousel.current.next();
      }
    }
  };

  const getPrivacyTypeValue = (string: String) => {
    if (string === "Public") {
      return Public;
    } else if (string === "Private") {
      return Private;
    } else if (string === "Close") {
      return Closed;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);

      if (memberGroupId) {
        let clubData = await getClubById({ clubId: memberGroupId });
        let arraYMod: any = [];
        clubData.data?.moderators?.forEach((item: any) => {
          arraYMod.push({
            value: item.memberId,
            label: item.firstName + " " + item.lastName,
          });
        });
        form.setFieldsValue({
          name: clubData.data.name,
          description: clubData.data.description && clubData.data.description,
          moderators: arraYMod,
        });

        setSelectedInterest(clubData.data.interest.interestId);
        setClubType(getPrivacyTypeValue(clubData.data.privacyType));
      }

      const data = await getInterestListNonPaged();
      setInterestList(
        data.data.map((interest: any) => {
          return {
            ...interest,
            selected: false,
          };
        })
      );
      setIsLoading(false);
    };
    initialize();
  }, []);

  return (
    <Layout className="club-propose-wizard">
      <Form
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
        form={form}
        name="clubInfo"
        layout="vertical"
        size="large"
      >
        <Fragment>
          <Header style={{ background: "unset" }}>
            {!isFinish && (
              <Fragment>
                <Button
                  type="text"
                  disabled={pageIndex === 0 || isLoading}
                  icon={<ArrowLeftOutlined />}
                  onClick={onBackClick}
                >
                  Back
                </Button>
                <Button
                  type="text"
                  onClick={onCancelClick}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Fragment>
            )}
          </Header>
          <Content ref={mainDiv}>
            <Carousel
              className={`club-carousel ${isFinalSlide ? "expanded" : ""}`}
              dots={false}
              ref={carousel}
              draggable={false}
              swipe={false}
              accessibility={false}
              swipeToSlide={false}
              afterChange={onPageChange}
              beforeChange={onBeforeChange}
            >
              <div className="wizard-page-container">
                <h2 className="text-center">Propose a new club</h2>

                <Form.Item
                  name="name"
                  label="Club Name"
                  required
                  rules={[
                    { required: true, message: "Club Name is required!" },
                  ]}
                >
                  <Input placeholder="Enter club name" />
                </Form.Item>
                <div className={`${"mb-4 has-error"}`}>
                  <AlertContainer data={validationMessages.PrivacyType} />
                </div>
                <Form.Item
                  name="description"
                  label={<span>Description (Optional)</span>}
                >
                  <TextArea
                    placeholder="Enter event description"
                    style={{ height: 120 }}
                  />
                </Form.Item>
                <Form.Item label="Club Type">
                  <Button
                    className={`${clubType === 0 && "btn-active"}`}
                    onClick={() => setClubType(0)}
                  >
                    Public
                  </Button>
                  <Button
                    className={`${clubType === 1 && "btn-active"}`}
                    onClick={() => setClubType(1)}
                  >
                    Private
                  </Button>
                  <Button
                    className={`${clubType === 2 && "btn-active"}`}
                    onClick={() => setClubType(2)}
                  >
                    Closed
                  </Button>
                </Form.Item>
                <div
                  className={`${
                    validationMessages &&
                    validationMessages.error &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && validationMessages.error && (
                    <AlertContainer data={validationMessages} />
                  )}
                </div>
              </div>
              <div className="wizard-page-container">
                <h2 className="text-center">Select Club Moderator</h2>
                {!isLoading && (
                  <ClubModeratorField
                    form={form}
                    validationMessages={undefined}
                    resetValidation={() => setValidationMessages([])}
                  />
                )}
                {validationMessages && validationMessages.error && (
                  <div className={`has-error`}>
                    {validationMessages && validationMessages.error && (
                      <AlertContainer data={validationMessages} />
                    )}
                  </div>
                )}
              </div>
              <div className="wizard-page-container">
                <h2 className="text-center">Select Club Interest</h2>
                <div className="">
                  <Row gutter={16} className="interest-selection">
                    {isLoading &&
                      Array(9)
                        .fill(0)
                        .map((_, i) => {
                          return (
                            <Col span={6} xs={12} md={8} lg={8} xl={8}>
                              <div className={`gutter-row interest-item`}>
                                <Skeleton.Image active />
                                <Skeleton active paragraph={{ rows: 0 }} />
                              </div>
                            </Col>
                          );
                        })}

                    {!isLoading &&
                      interestList.map((interest: any) => {
                        return (
                          <Col
                            span={6}
                            xs={12}
                            md={8}
                            lg={8}
                            xl={8}
                            className="fadein"
                          >
                            <div
                              onClick={() =>
                                setSelectedInterest(interest.interestId)
                              }
                              className={`gutter-row interest-item ${
                                selectedInterest == interest.interestId
                                  ? "interest-active"
                                  : ""
                              }`}
                            >
                              <div className="d-flex align-items-center d-flex-column w-100 justify-content-center">
                                {interest.selected && (
                                  <img
                                    className="checkmark-icon"
                                    src={checkmarkIcon}
                                    height={21}
                                    width={21}
                                  />
                                )}
                                <img
                                  height={64}
                                  width={64}
                                  src={academicsIcon}
                                />
                                <h5>{interest.name}</h5>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </div>
              <div className="wizard-page-container">
                <div className="proposal-success-frame">
                  <img src={proposeFrame} height="358px" width="auto" />
                  <h1>
                    {memberGroupId
                      ? "Club Reproposal Submitted."
                      : "Club Proposal Submitted."}
                  </h1>
                  <h4 className="text-center mb-5">
                    The school admin will review and assess the details of your
                    club proposal. You will be notified once approve or if there
                    are clarifications needed.
                  </h4>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => navigate("/clubs")}
                  >
                    Go to club list
                  </Button>
                </div>
              </div>
            </Carousel>
          </Content>
          <Footer>
            {!isFinish ? (
              <Button
                // htmlType={"submit"}
                type="primary"
                className="btn-breeze-box-radius"
                // {...(!isFinalSlide && { onClick: onContinueClick })}
                onClick={onContinueClick}
                loading={isSaving}
              >
                {submitText}
              </Button>
            ) : (
              <Fragment></Fragment>
              // <Button
              //   type="primary"
              //   className="btn-breeze-box-radius"
              //   onClick={() => navigate("/clubs")}
              // >
              //   Finish
              // </Button>
            )}
          </Footer>
        </Fragment>
      </Form>
    </Layout>
  );
};
export default connect(null, {
  getInterestListNonPaged,
  addClub,
  getClubById,
  updateClub,
})(ProposeClub);
