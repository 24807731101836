import React, { useEffect, useState, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import {
  Button,
} from "antd";
import {
  CloseOutlined,
  ScanOutlined
} from "@ant-design/icons";
import { QrReader } from "react-qr-reader";

const Scanner = (props: {isActive: any, onResult: any, onCancel: any, width: number, height: number}) => {
  const { isActive, onResult, onCancel } = props;
  const [scanValue, setScanValue] = useState<any>();

  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevScanValue = usePrevious(scanValue);

  useEffect(() => {
    if (prevScanValue !== scanValue) {
      onResult(scanValue);
    }
  }, [scanValue]);

  return (
    <div
      className={`scanner-wrapper ${props.isActive ? 'expanded' : ''}`}
      onClick={() => {
        onCancel();
      }}
    >
      <div
        className={`scanner-container ${props.isActive ? 'expanded' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="scanner-button-container"
        >
          <Button
            shape="circle"
            size="large"
            style={{borderColor: "rgba(255,255,255,0.5)"}}
            icon={<CloseOutlined style={{color: "rgba(255,255,255,0.5)"}} />}
            onClick={() => {
              onCancel();
            }}
            ghost
          />
        </div>

        <h1> QR Scanner </h1>

        {
          props.isActive && (
            <QrReader
          onResult={(result: any, error: any) => {
            if (!!result) {
              setScanValue(result);
            }
          }}
          constraints={{ facingMode: 'environment' }}
          className='scanner'
          ViewFinder={(e) => {
            return <svg
              viewBox="0 0 100 100"
              style={{
                top: 0,
                left: 0,
                zIndex: 1,
                boxSizing: "border-box",
                border: "100px solid transparent",
                position: "absolute",
                width: "100%",
                height: "100%"
              }}
              >
              <path fill="none" d="M20,0 L0,0 L0,20" stroke="rgba(255,255,255,0.9)" stroke-width="3"></path>
              <path fill="none" d="M0,80 L0,100 L20,100" stroke="rgba(255,255,255,0.9)" stroke-width="3"></path>
              <path fill="none" d="M80,100 L100,100 L100,80" stroke="rgba(255,255,255,0.9)" stroke-width="3"></path>
              <path fill="none" d="M100,20 L100,0 L80,0" stroke="rgba(255,255,255,0.9)" stroke-width="3"></path>
            </svg>
          }}
        />
          )
        }

        <h4> Place your QR Code at the center of the camera </h4>
      </div>
    </div>
  );
};

export default Scanner;
