import { Button, Col, PageHeader, Row, Space } from "antd";
import { Fragment, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import successImage from "../../../assets/images/event-add-success.svg";

const AddEventSuccess = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const title = location?.state?.memberGroupId ? "Edit Club" : "Add Club";
  useEffect(() => {
    setTimeout(() => {
      navigate("/admin/events/", { replace: true });
      return;
    }, 3000);
  }, []);
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <div className="app-main-content">
          <div className="app-main-content-inner h-100-i">
            <div className="d-flex justify-content-center mt-7">
              <div className="pt-5">
                <img
                  height="auto"
                  width="320px"
                  src={successImage}
                  alt="event success image"
                />
                <h2 className="text-center mt-3">
                  Event successfully created!
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddEventSuccess;
