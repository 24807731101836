import SchoolYearForm from "./SchoolYearForm";
import { Button, Col, PageHeader, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SchoolYearPage = () => {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    const schoolYearId = param.schoolYearId;
    if (schoolYearId) {
      setTitle("Edit School Year details");
    } else {
      setTitle("Add School Year");
    }
  }, []);

  return (
    <div className="app-main-content-wrapper">
      <PageHeader
        className="site-page-header content-header"
        onBack={() => navigate(-1)}
        title={title}
        extra={[
          <Button
            key="back"
            className="btn-radius"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        ]}
      />
      <div className="app-main-content">
        <div className="app-main-content-inner">
          <Row>
            <Col span={24}>
              <div className="content-container pt-1">
                <SchoolYearForm />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default SchoolYearPage;
