import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getClubEventList } from "../../../actions/event";

import { Link, useLocation, useNavigate } from "react-router-dom";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const ClubEventList = (props: { getClubEventList: Function }) => {
  let navigate = useNavigate();
  const location: any = useLocation();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {/* <a id={record.eventId} onClick={() => console.log("approve")}>
            Approve
          </a>
          <a id={record.eventId} onClick={() => console.log("decline")}>
            Decline
          </a> */}
          <a id={record.eventId} onClick={() => editHandler(record)}>
            Edit
          </a>
        </Space>
      ),
    },
  ];

  const { getClubEventList } = props;
  const [list, setList]: any = useState([]);
  const [statusFilter, setStatusFilter] = useState(-1);
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination] = useState<any>(initialPagination);
  const [searchString, setSearchString] = useState<String>("");
  const [isListLoading, setIsListLoading] = useState(false);

  const onFilterSearchHandler = (searchInput: String) => {
    setSearchString(searchInput);
  };

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
    fetchList(pagination);
  };

  const editHandler = (record: any) => {
    navigate(`/admin/clubs/${location?.state?.memberGroupId}/events/edit`, {
      state: {
        eventId: record?.eventId,
        name: record?.name,
        description: record?.description,
        locationId: record?.locationId,
        recurrence: record?.recurrence,
        daysOfWeek: record?.daysOfWeek,
        startDate: record?.startDate,
        endDate: record?.endDate,
        startTime: record?.startTime,
        endTime: record?.endTime,
        eventType: record?.eventType,
        memberGroupId: record?.memberGroupId,
        zoomLink: record?.zoomLink,
        interestId: record?.interestId,
        softSkillScores: record?.softSkillScores,
      },
    });
  };

  const fetchList = async (parameters: object) => {
    setIsListLoading(true);
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        status: statusFilter,
        ClubId: location.state.memberGroupId,
      };
    } else {
      params = {
        ...parameters,
        status: statusFilter,
        ClubId: location.state.memberGroupId,
      };
    }
    const data: any = await getClubEventList(params);
    setList(data.data.items);
    setPagination({
      current: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsListLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString, statusFilter]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };

  const showTotal = () => {
    return `Total ${pagination?.total} items`;
  };

  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate(-1)}
          title={`${location.state.name} Club Events`}
          // extra={[
          //   <Button key="add" type="primary">
          //     <a
          //       id={location.state.memberGroupId}
          //       onClick={() => {
          //         navigate(
          //           `/admin/clubs/${location.state.memberGroupId}/invite-members`,
          //           {
          //             state: {
          //               memberGroupId: location.state?.memberGroupId,
          //             },
          //           }
          //         );
          //       }}
          //     >
          //       Add New Event
          //     </a>
          //   </Button>,
          //   <DropdownMenu key="more" />,
          // ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    <div className="my-auto mr-auto">
                      Status:{" "}
                      <select onChange={onfilterStatusHandler}>
                        <option value="-1">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                    <div>
                      <Search
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search by Event Name"
                        style={{ width: 280 }}
                      />
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="memberGroupId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      dataSource={list}
                      loading={isListLoading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getClubEventList })(ClubEventList);
