import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Dropdown,
  Menu,
  Modal,
  Form,
  Collapse,
  Checkbox,
  DatePicker,
} from "antd";
import { MoreOutlined, FilterOutlined, CloseOutlined } from "@ant-design/icons";
import { deleteEvent } from "../../../actions/event";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getEventList, handleEventProposal } from "../../../actions/event";
import { Link, useNavigate } from "react-router-dom";
import { Teacher } from "../../../helpers/memberTypes";
import moment from "moment";
import { mainEventType } from "../../../helpers/eventTypes";
import { CREATED, NO_CONTENT, OK } from "../../../helpers/statuscodes";
import ModalDeclineReason from "../../member-app/Modals/ModalDeclineReason";
import ModalSuccess from "../../member-app/Modals/ModalSuccess";
import ModalProfilePopup from "../../member-app/Modals/ModalProfilePopup";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import type { RangePickerProps } from "antd/es/date-picker";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const { Search } = Input;

const getEventStatus = (int: Number) => {
  if (int === 1) {
    return "Approved";
  } else if (int === 2) {
    return "Declined";
  } else if (int === 0) {
    return "Pending";
  }
};

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const optionsForEventType = [
  // { label: "All", value: "all" },
  { label: "My Events", value: "myEvents" },
  { label: "Upcoming Events", value: "upcomingEvents" },
  { label: "Past Events", value: "pastEvents" },
];

const optionsForStatus = [
  { label: "Pending", value: 0 },
  { label: "Approved", value: 1 },
  { label: "Declined", value: 2 },
];

const optionsPrivacyClubs = [
  { label: "Public", value: 0 },
  { label: "Private", value: 1 },
  { label: "Closed", value: 2 },
];

const optionsPrivacyForSchoolWide = [
  { label: "Public", value: 0 },
  { label: "Private", value: 1 },
];

const EventList = (props: {
  deleteEvent: Function;
  getEventList: Function;
  handleEventProposal: Function;
}) => {
  const { deleteEvent, getEventList, handleEventProposal } = props;
  const [schoolEventsOnly, setSchoolEventsOnly] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [selectedStatus, setSelectedStatus]: any = useState([0, 1, 2]);
  const [selectedPrivacy, setSelectedPrivacy]: any = useState([0, 1, 2]);
  const [selectedEventType, setSelectedEventType]: any = useState("myEvents");
  const [dateRangeFilter, setDateRangeFilter] = useState<any[]>([]);

  let navigate = useNavigate();
  const columns = [
    {
      title: "Event Name",
      key: "title",
      render: (text: any, record: any) => (
        <a
          id={record.eventId}
          onClick={() => {
            navigate(`/admin/events/${record.eventId}`, {
              state: record,
            });
          }}
        >
          {record.title}
        </a>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   ellipsis: true
    // },
    {
      title: "Date",
      key: "date",
      render: (text: any, record: any) => (
        <Space size="middle">
          {moment(new Date(record.startDate)).format("ll")}
        </Space>
      ),
    },
    {
      title: "Time",
      key: "time",
      render: (text: any, record: any) => (
        <Space size="middle">
          {moment(new Date(record.startDate)).format("LT")}
        </Space>
      ),
    },
    {
      title: "Event Type",
      key: "eventType",
      render: (text: any, record: any) => mainEventType[record.eventType],
    },
    {
      title: "Proposed by",
      key: "createdByName",
      dataIndex: "createdByName",
      hidden: schoolEventsOnly,
      render: (text: any, record: any) => (
        <div>
          <a
            onClick={() => {
              setIsVisibleProfilePopup(true);
              setProposedByBasicInfo(record);
            }}
          >
            {record.createdByName}
          </a>
        </div>
      ),
    },
    {
      title: "Profile",
      key: "createdByProfile",
      dataIndex: "createdByProfile",
      hidden: schoolEventsOnly,
    },
    {
      title: "Status",
      key: "status",
      render: (text: any, record: any) => getEventStatus(record.status),
    },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          {record.status === 0 && (
            <>
              <Button
                type="link"
                size="small"
                onClick={() => onHandleEventProposal(record.eventId, "approve")}
              >
                Approve
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  setIsModalDeclineReasonOpen(true);
                  setSelectedEventId(record.eventId);
                }}
              >
                Decline
              </Button>
            </>
          )}

          {record.isSchoolWideEvent && (
            <Button
              type="link"
              size="small"
              onClick={() => onDeleteEvent(record.eventId, record.title)}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const [formDeclineJoinRequest] = Form.useForm();
  const [selectedEventId, setSelectedEventId] = useState("");
  const [eventList, setEventList]: any = useState([]);
  const [proposedByBasicInfo, setProposedByBasicInfo]: any = useState([]);
  const [eventId, setEventId] = useState();
  const [isVisibleProfilePopup, setIsVisibleProfilePopup] = useState(false);
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination]: any = useState(initialPagination);
  const [searchString, setSearchString]: any = useState("");

  const [isListLoading, setIsListLoading] = useState(false);
  const [isLoadingModalDeclineReason, setIsLoadingModalDeclineReason] =
    useState(false);
  const [isModalDeclineReasonOpen, setIsModalDeclineReasonOpen] =
    useState(false);

  const onChangeStatus = (checkedValues: CheckboxValueType[]) => {
    // if (checkedValues.length > 0) {
    setSelectedStatus(checkedValues);
    // }
  };

  const onChangePrivacy = (checkedValues: CheckboxValueType[]) => {
    setSelectedPrivacy(checkedValues);
  };
  const onChangeEventType = (checkedValues: CheckboxValueType[]) => {
    setSelectedEventType(checkedValues);
  };

  const onChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
    if (dates) {
      setDateRangeFilter(dateStrings);
    } else {
      setDateRangeFilter([]);
    }
  };

  const onFilterSearchHandler = (e: any) => {
    setSearchString(e.target.value);
  };

  const onfilterStatusHandler = (e: any) => {
    setSelectedStatus(e.target.value);
  };

  const onHandleEventProposal = async (eventId: string, type: String) => {
    let params = {
      eventId: eventId,
      type,
    };
    const res = await handleEventProposal(params);
    if (res.status == OK || res.status == CREATED) {
      let tempData = eventList.map((item: any) => {
        if (item.eventId === eventId) {
          return res.data;
        } else {
          return item;
        }
      });
      setSuccessMessage(`You have successfully approved ${res.data.title}.`);
      setIsModalSuccessOpen(true);
      setEventList(tempData);
    }
  };

  const onHandleClubDeclineProposal = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);
    let params = {
      ...formData,
      eventId: selectedEventId,
      type: "decline",
    };

    const res = await handleEventProposal(params);
    if (res.status == OK || res.status == CREATED) {
      let tempData = eventList.map((item: any) => {
        if (item.eventId === selectedEventId) {
          return res.data;
        } else {
          return item;
        }
      });
      setIsLoadingModalDeclineReason(false);
      setIsModalDeclineReasonOpen(false);
      setSuccessMessage(`You have successfully declined ${res.data.title}.`);
      setIsModalSuccessOpen(true);
      setEventList(tempData);
    }
    setIsLoadingModalDeclineReason(false);
  };

  const editHandler = (record: any) => {
    navigate("/admin/events/add", {
      state: {
        eventId: record?.eventId,
        name: record?.name,
        description: record?.description,
        locationId: record?.locationId,
        recurrence: record?.recurrence,
        daysOfWeek: record?.daysOfWeek,
        startDate: record?.startDate,
        endDate: record?.endDate,
        startTime: record?.startTime,
        endTime: record?.endTime,
        eventType: record?.eventType,
        memberGroupId: record?.memberGroupId,
        zoomLink: record?.zoomLink,
        interestId: record?.interestId,
        softSkillScores: record?.softSkillScores,
      },
    });
  };

  const onDeleteEvent = (eventId: string, eventName: string) => {
    Modal.confirm({
      title: "Delete Event",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete "${eventName}" event?`,
      okText: "Confirm",
      onOk: () => submitDeleteEvent(eventId),
      okButtonProps: { shape: "round" },
      cancelText: "Cancel",
      cancelButtonProps: { shape: "round" },
    });
  };

  const submitDeleteEvent = async (eventId: string) => {
    setIsListLoading(true);
    const response = await deleteEvent(eventId);
    setIsListLoading(false);
    if (response.status === NO_CONTENT) {
      fetchList(initialPagination);
    }
  };
  const controller = new AbortController();

  const fetchList = async (parameters: object) => {
    setEventList([]);
    setIsListLoading(true);
    let eventPrivacy = selectedPrivacy;
    if (schoolEventsOnly) {
      eventPrivacy = selectedPrivacy.filter((item: any) => {
        return item != 2;
      });
    }
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        memberType: Teacher,
        status: selectedStatus,
        EventTypes: eventPrivacy,
        schoolEventsOnly,
        from:
          dateRangeFilter.length > 0 &&
          moment(dateRangeFilter[0]).toISOString(),
        to:
          dateRangeFilter.length > 0 &&
          moment(dateRangeFilter[1]).toISOString(),
      };
    } else {
      params = {
        ...parameters,
        searchString,
        memberType: Teacher,
        status: selectedStatus,
        EventTypes: eventPrivacy,
        schoolEventsOnly,
        from:
          dateRangeFilter.length > 0 &&
          moment(dateRangeFilter[0]).toISOString(),
        to:
          dateRangeFilter.length > 0 &&
          moment(dateRangeFilter[1]).toISOString(),
      };
    }
    const data: any = await getEventList(params, controller);
    setEventList(data.data.items);
    setPagination({
      current: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsListLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
    return () => {
      controller.abort(); // stop previous api request
    };
  }, [
    searchString,
    selectedStatus,
    schoolEventsOnly,
    selectedEventType,
    selectedPrivacy,
    dateRangeFilter,
  ]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };
  return (
    <Fragment>
      <ModalProfilePopup
        visible={isVisibleProfilePopup}
        userData={proposedByBasicInfo}
        handleCancel={setIsVisibleProfilePopup}
      />
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p className="text-center">{successMessage}</p>
      </ModalSuccess>
      <ModalDeclineReason
        title={`Decline Event`}
        handleSubmit={onHandleClubDeclineProposal}
        isOpen={isModalDeclineReasonOpen}
        setIsOpen={setIsModalDeclineReasonOpen}
        form={formDeclineJoinRequest}
        isLoading={isLoadingModalDeclineReason}
      />
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="Events"
          extra={[
            <Fragment>
              <Search
                allowClear
                onKeyUp={(e) => onFilterSearchHandler(e)}
                placeholder="Search Event"
                style={{ width: 280 }}
                onSearch={(e) => setSearchString(e)}
              />
              <Button
                className="filter-toggler"
                icon={<FilterOutlined />}
                shape="round"
                onClick={() => setIsFilterShown(!isFilterShown)}
              >
                <span className="filter-text">
                  {!isFilterShown ? "Hide Filters" : "Show Filters"}
                </span>
              </Button>
              <Button className="btn-radius" key="back" type="primary">
                <Link to={"/admin/events/add"}>Add New Event</Link>
              </Button>
            </Fragment>,

            // <Button key="calendar-view" type="primary">
            //   <Link to={"/admin/events/calendar"}>Calendar View</Link>
            // </Button>,
            // <DropdownMenu key="more" />,
          ]}
        />
        {/* <div className="app-main-content with-widget-filters"> */}
        {/* <div className="app-main-content-inner listing-content-view"> */}
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <div className="with-widget-filters">
              <div
                className={`d-flex flex-column ${
                  !isFilterShown ? "content-squeeze" : "content-expand"
                }`}
              >
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    <div className="my-auto mr-auto w-100">
                      <div className="d-flex custom-tab-2">
                        <div
                          onClick={() => setSchoolEventsOnly(true)}
                          className={`tab-item mr-4 ${
                            schoolEventsOnly === true && "tab-active"
                          }`}
                        >
                          School Wide Event
                        </div>
                        <div
                          onClick={() => setSchoolEventsOnly(false)}
                          className={`tab-item mr-4 ${
                            schoolEventsOnly === false && "tab-active"
                          }`}
                        >
                          Club Events
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="eventId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      scroll={{ y: "calc(100vh - 420px)" }}
                      dataSource={eventList}
                      loading={isListLoading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </div>

              <div
                className={`sidebar-placeholder ${
                  !isFilterShown ? "show-sidebar" : "hide-sidebar"
                }`}
              >
                <div
                  className="sidebar-backdrop"
                  onClick={(event) =>
                    event.currentTarget == event.target &&
                    setIsFilterShown(!isFilterShown)
                  }
                >
                  <div className="listing-sidebar">
                    <div className="close-filters-sm d-flex align-items-center">
                      <span>Filter</span>
                      <CloseOutlined
                        style={{
                          verticalAlign: "middle",
                        }}
                        onClick={() => setIsFilterShown(!isFilterShown)}
                      />
                    </div>
                    <Collapse
                      className="collapse-filters"
                      defaultActiveKey={["1"]}
                      bordered={false}
                      expandIconPosition={"end"}
                    >
                      <Panel header="Date & Time" key="1">
                        <RangePicker
                          showTime={{ format: "HH:mm" }}
                          format="YYYY-MM-DD HH:mm"
                          onChange={onChange}
                        />
                      </Panel>
                      <Panel header="Event Type" key="2">
                        {schoolEventsOnly === true ? (
                          <Checkbox.Group
                            options={optionsPrivacyForSchoolWide}
                            value={selectedPrivacy}
                            onChange={onChangePrivacy}
                          />
                        ) : (
                          <Checkbox.Group
                            options={optionsPrivacyClubs}
                            value={selectedPrivacy}
                            onChange={onChangePrivacy}
                          />
                        )}
                      </Panel>
                      <Panel header="Status" key="3">
                        <Checkbox.Group
                          options={optionsForStatus}
                          value={selectedStatus}
                          onChange={onChangeStatus}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, {
  deleteEvent,
  getEventList,
  handleEventProposal,
})(EventList);
