import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EventItem from "./EventItem";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import { EventInvite } from "./EventInvite";
import { Fragment } from "react";
import {
  getMyEvents,
  getProfileSummary,
  getMyClubsGrouped,
} from "../../../actions/profile";
import {
  declineEventInvite,
  searchUpcomingEvents,
  searchSchoolWideEvents,
} from "../../../actions/event";
import {
  Button,
  Divider,
  Skeleton,
  Tooltip,
  Col,
  Row,
  Collapse,
  Checkbox,
  DatePicker,
  Form,
  Modal,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  MenuOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  FilterOutlined,
  CalendarOutlined,
  UpOutlined,
  DownOutlined,
  RightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { ListViewType } from "./EventItem";
import { getInterestListNonPaged } from "../../../actions/interest";
import { getClubListNonPaged } from "../../../actions/club";

import MiniPageHeader from "../layouts/MiniPageHeader";
import EmptyState from "../layouts/EmptyState";
import TotallyNewState from "../layouts/TotallyNewState";
import BreezeCalendar from "../../calendar/BreezeCalendar";
import ModalDeclineReason from "../Modals/ModalDeclineReason";
import successIcon from "../../../assets/images/icons/success-icon.svg";
import { OK } from "../../../helpers/statuscodes";
import axios from "axios";
import { getMouseEventOptions } from "@testing-library/user-event/dist/utils";
import WelcomeBack from "../layouts/WelcomeBack";
const { Panel } = Collapse;
interface SelectItem {
  label: string;
  value: string;
}
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD HH:mm:ss";
const customFormat = (value: any) => `${value.format(dateFormat)}`;
const PAGE_SIZE = 9;
const EventList = (props: {
  searchUpcomingEvents: Function;
  searchSchoolWideEvents: Function;
  getMyEvents: Function;
  getInterestListNonPaged: Function;
  getClubListNonPaged: Function;
  getMyClubsGrouped: Function;
  declineEventInvite: Function;
  interests: any;
  getProfileSummary: any;
  userIsBack: boolean;
  flutterConnect: Boolean;
}) => {
  const { getMyClubsGrouped } = props;
  const [listView, setListView] = useState<ListViewType>("card");
  const [events, setEvents] = useState<EventInvite[]>([]);
  const [eventsToday, setEventsToday] = useState<EventInvite[]>([]);
  const [eventsFuture, setEventsFuture] = useState<EventInvite[]>([]);
  const [eventsSchoolWide, setEventsSchoolWide] = useState<EventInvite[]>([]);
  const [eventsSchoolWideTotal, setEventsSchoolWideTotal] = useState(0);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [eventsTodayHasMorePage, setEventsTodayHasMorePage] = useState(false);
  const [eventsFutureHasMorePage, setEventsFutureHasMorePage] = useState(false);
  const [eventsSchoolWideHasMorePage, setEventsSchoolWideHasMorePage] =
    useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<any[]>([]);
  const [dateRangeComponentValue, setDateRangeComponentValue] = useState<any[]>([]);
  const [dateRangeFilterForMonth, setDateRangeFilterForMonth] = useState<any[]>(
    []
  );
  const [earnedPoints, setEarnedPoints] = useState<Number>(0);
  const [extraItem, setExtraItem] = useState<any[]>([]);
  const [searchString, setSearchString] = useState("");
  const [eventFilter, setEventFilter] = useState("my-events");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEventTodayPage, setCurrentEventTodayPage] = useState(1);
  const [currentFutureEventPage, setCurrentFutureEventPage] = useState(1);
  const [currentSchoolWideEventPage, setCurrentSchoolWideEventPage] =
    useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEventToday, setIsLoadingEventToday] = useState(true);
  const [isLoadingEventThisWeek, setIsLoadingEventThisWeek] = useState(true);
  const [isLoadingEventThisMonth, setIsLoadingEventThisMonth] = useState(true);
  const [isLoadingEventFuture, setIsLoadingEventFuture] = useState(true);
  const [isLoadingEventSchoolWide, setIsLoadingEventSchoolWide] =
    useState(true);
  const [isLoadingPastEvent, setIsLoadingPastEvent] = useState(true);
  const [hasCurrentWeek, setHasCurrentWeek] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedEventType, setSelectedEventType]: any = useState("myEvents");
  const [selectedClubPrivacy, setSelectedClubPrivacy]: any = useState([
    "0",
    "1",
    "2",
  ]);
  const [selectedInterest, setSelectedInterest]: any = useState([]);
  const [isInterestLoaded, setIsInterestLoaded] = useState(false);
  const [isClubLoaded, setIsClubLoaded] = useState(false);
  const [isMyClubsLoaded, setIsMyClubLoaded] = useState(false);

  const [selectedStatus, setSelectedStatus]: any = useState([]);
  const [interestOptions, setInterestOptions] = useState<SelectItem[]>();
  const [showMoreInterests, setShowMoreInterest] = useState(false);

  const [statusOptions, setStatusOptions] = useState<SelectItem[]>();
  const [selectedClub, setSelectedClub]: any = useState([]);
  const [clubOptions, setClubOptions] = useState<SelectItem[]>();
  const [myClubs, setMyClubs] = useState<SelectItem[]>();
  const [showMoreClubs, setShowMoreClubs] = useState(false);

  const [isFilterShown, setIsFilterShown] = useState(false);

  const [isDeclineEventModalOpen, setIsDeclineEventModalOpen] = useState(false);
  const [isLoadingDeclineEventInvite, setIsLoadingDeclineEventInvite] =
    useState(false);
  const [declineEventForm] = Form.useForm();
  const [isHandleJoinModalOpen, setIsHandleJoinModalOpen] = useState(false);
  const [isJoinModalText, setJoinModalText] = useState("");

  const [selectedEventInfo, setSelectedEventInfo] = useState<any>({});
  const [showWelcomeBack, setShowWelcomeBack] = useState(false);

  const optionsForEventType = [
    // { label: "All", value: "all" },
    { label: "My Events", value: "myEvents" },
    { label: "Upcoming Events", value: "upcomingEvents" },
    { label: "Past Events", value: "pastEvents" },
  ];

  const optionsForClubPrivacy = [
    { label: "Public", value: "public" },
    { label: "Private", value: "private" },
    { label: "Closed", value: "closed" },
  ];

  let optionsForStatus = [
    { label: "Pending", value: 0, disabled: false, },
    { label: "Active", value: 1, disabled: false, },
    { label: "Declined", value: 2, disabled: false, },
  ];

  let optionsForStatusPast = [
    { label: "Pending", value: 0, disabled: true, },
    { label: "Active", value: 1, disabled: true, },
    { label: "Declined", value: 2, disabled: true, },
  ];

  const onChangeEventType = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues?.length > 0) {
      setSelectedEventType(checkedValues[0]);
    }
  };

  const onChangeClubPrivacy = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues?.length > 0) {
      setSelectedClubPrivacy(checkedValues);
    }
  };

  const onChangeInterest = (checkedValues: CheckboxValueType[]) => {
    // if (checkedValues?.length > 0) {
    setSelectedInterest(checkedValues);
    // }
  };

  const onChangeStatus = (checkedValues: CheckboxValueType[]) => {
    // if (checkedValues?.length > 0) {
    setSelectedStatus(checkedValues);
    // }
  };

  const onChangeClub = (checkedValues: CheckboxValueType[]) => {
    // if (checkedValues?.length > 0) {
    setSelectedClub(checkedValues);
    // }
  };

  const onChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
    if (dates) {
      setDateRangeFilter(dateStrings);
      setDateRangeComponentValue(dateStrings);
    } else {
      setDateRangeFilter([]);
      setDateRangeComponentValue([]);
    }
  };

  const moreInterestsTrigger = () => {
    setShowMoreInterest(!showMoreInterests);
  };

  const moreClubsTrigger = () => {
    setShowMoreClubs(!showMoreClubs);
  };

  const onDeclineHandler = (event: any, inviteId: string) => {
    setSelectedEventInfo({
      eventName: event.title,
      eventId: event.eventId,
      inviteId,
    });
    setIsDeclineEventModalOpen(true);
  };

  const onDeclineForm = async (formData: any) => {
    setIsLoadingDeclineEventInvite(true);
    let values = {
      ...formData,
      ...selectedEventInfo,
    };
    const result = await props.declineEventInvite(values);
    if (result && result.data) {
      // setUserInvite(result.data);
    }
    setIsLoadingDeclineEventInvite(false);
    if (result.status == OK) {
      fetchEventData(true);
      setIsDeclineEventModalOpen(false);
      setJoinModalText(
        `You have successfully submitted decline reason for ${selectedEventInfo?.eventName} invitation.`
      );
      declineEventForm.resetFields();
    }
    setIsHandleJoinModalOpen(true);
  };

  const removeDuplicates = (arr1: any, arr2: any) => {
    const mergedArr = arr1.concat(arr2);
    return mergedArr.filter(
      (v: any, i: any, s: any) =>
        mergedArr.findIndex((e: any) => e.eventId == v.eventId) === i
    );
  };

  const onFilterAgain = () => {
    setIsFilterShown(true);
    setSearchString("");
    setDateRangeFilter([]);
  };

  const fetchInterestList = async () => {
    const data = await props.getInterestListNonPaged();
    let myInterests = props.interests
      ? props.interests.map((data: any) => {
          return data.interestId;
        })
      : [];
    let firstOnList = data.data.filter((item: any) => {
      if (myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let nextOnList = data.data.filter((item: any) => {
      if (!myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let mergedList = firstOnList.concat(nextOnList).map((item: any) => ({
      label: item.name,
      value: item.interestId,
    }));
    setInterestOptions(mergedList);
    // uncomment to enable auto select
    let selectedInterest = data.data;

    setSelectedInterest(
      selectedInterest
        .filter((item: any) => {
          if (myInterests.includes(item.interestId)) {
            return item;
          }
        })
        .map((item: any) => item.interestId)
    );

    setIsInterestLoaded(true);
  };

  const fetchClubList = async () => {
    const data = await props.getClubListNonPaged();
    const result = data.data.map((item: any) => ({
      label: item.name,
      value: item.memberGroupId,
    }));
    setClubOptions(result);
    setIsClubLoaded(true);
    //uncomment to enable auto select
    // let selectedInterest = data.data.map((item: any) => {
    //   return item.interestId;
    // });
    // setSelectedInterest(selectedInterest);
  };

  const fetchMyClubList = async () => {
    let params = {
      searchString: "",
      privacyTypes: "",
      interests: "",
    };
    let data = await getMyClubsGrouped(params);
    if(data && data.data?.length>0){
      let joinedClubs = data?.data.filter((d:any)=>d.category === ("Joined Clubs"));
      if((joinedClubs!==null || joinedClubs!==undefined) && joinedClubs.length!==0){
        setMyClubs(joinedClubs[0]?.clubs);
      }else{
        setMyClubs([]);
      }
    }else{
      setMyClubs([]);
    }

    setIsMyClubLoaded(true);
  };

  const fetchEarnedPointsLastWeek = async () => {
    let eventsLastWeek = await props.searchUpcomingEvents({
      page: 1,
      pageSize: 50,
      MyEventsOnly: true,
      isDesc: false,
      schoolEventsOnly: false,
      from: moment().subtract(1, "weeks").startOf("week").toISOString(),
      to: moment().subtract(1, "weeks").endOf("week").toISOString(),
    },
    //NOTE: Flutter - controller was previously not declared, added undefined
    undefined,
    props.flutterConnect);
    if (eventsLastWeek && eventsLastWeek.data && eventsLastWeek.data.items) {
      let totalPoints = eventsLastWeek.data.items.reduce(function (
        sum: any,
        current: { userInvite: any }
      ) {
        if (current.userInvite?.attendance?.earnedPoints) {
          return sum + parseFloat(current.userInvite.attendance.earnedPoints);
        } else {
          return sum;
        }
      },
      0);
      setEarnedPoints(Math.round(totalPoints));
    }
  };

  const fetchUpcomingEvents = async (
    from: Moment | Date | String | undefined,
    to: Moment | Date | String,
    page: Number,
    sort?: boolean,
    ignoreCustomDate?: boolean
  ) => {
    // if (isSearchThisWeek) {
    //   params = {
    //     page: currentPage,
    //     pageSize: PAGE_SIZE,
    //     searchString,
    //     from: moment().add(1, "days"),
    //     to: moment().add(6, "days"),
    //   };
    //   console.log(params);
    // } else {
    //   params = {
    //     page: currentPage,
    //     pageSize: PAGE_SIZE,
    //     searchString,
    //   };
    // }

    return await props.searchUpcomingEvents(
      {
        page: page,
        pageSize: listView === "calendar" ? 50 : PAGE_SIZE,
        searchString,
        schoolEventsOnly: false,
        isDesc: sort ? sort : false,
        MyEventsOnly:
          (selectedEventType === "myEvents" ||
            selectedEventType === "pastEvents") &&
          listView === "card"
            ? true
            : false,
        from:
          dateRangeFilter?.length > 0 && ignoreCustomDate === undefined
            ? moment(dateRangeFilter[0]).startOf("day").toISOString()
            : from,
        to:
          dateRangeFilter?.length > 0 && ignoreCustomDate === undefined
            ? moment(dateRangeFilter[1]).endOf("day").toISOString()
            : to,
        InterestIds:
          selectedEventType === "upcomingEvents" && selectedInterest?.length > 0
            ? selectedInterest.toString()
            : null,
        memberGroupIds:
          listView === "card" && selectedClub?.length > 0
            ? selectedClub.toString()
            : null,
        Statuses:
          listView === "card"
            ? selectedStatus?.length > 0
              ? selectedStatus.toString()
              : selectedEventType === "myEvents"
              ? "0,1,2" //myEvents - accepted or declined      [0,1,2] === ["pending", "accepted", "declined"]
              : selectedEventType === "pastEvents"
              ? "0,1"
              : "-1" //pastEvents - accepted only, upcomingEvents - N/A
            : "-1,0,1,2",
      },
      controller,
      //NOTE: Flutter
      props.flutterConnect
    );
  };

  const fetchSchoolWideEvents = async (
    page: Number,
    from?: Moment | Date | String | undefined,
    to?: Moment | Date | String | undefined,
    sort?: Boolean
  ) =>
    await props.searchSchoolWideEvents(
      {
        from: from,
        to: to,
        page: page,
        isDesc: sort ? sort : false,
        pageSize: listView === "calendar" ? 50 : 3,
        MyEventsOnly:
          selectedEventType === "myEvents" || selectedEventType === "pastEvents"
            ? true
            : false,
        schoolEventsOnly: true,
        Statuses:
          selectedStatus?.length > 0
            ? selectedStatus.toString()
            : selectedEventType === "myEvents"
            ? "0,1,2" //myEvents - accepted or declined      [0,1,2] === ["pending", "accepted", "declined"]
            : selectedEventType === "pastEvents"
            ? "0,1"
            : "-1", //pastEvents - accepted only, upcomingEvents - N/A
        // Statuses: selectedEventType === "pastEvents"
        // ? '0,1'
        // : ''
      },
      controller
    );

  const fetchMyEvent = async (
    showPastEvent: boolean = false,
    isDesc = false,
    to: Moment | null,
    from: Moment | null,
    page: any
  ) => {
    if (to && from) {
      return await props.getMyEvents({
        page: page,
        pageSize: PAGE_SIZE,
        showPastEvent,
        searchString,
        isDesc,
        to,
        from,
        InterestIds:
          selectedInterest?.length > 0 ? selectedInterest.toString() : null,
        memberGroupIds:
          selectedClub?.length > 0 ? selectedClub.toString() : null,
      });
    } else {
      return await props.getMyEvents({
        page: page,
        pageSize: PAGE_SIZE,
        showPastEvent,
        searchString,
        isDesc,
        InterestIds: selectedInterest.toString(),
        memberGroupIds:
          selectedClub?.length > 0 ? selectedClub.toString() : null,
      });
    }
  };

  const fetchEventsToday = async (more: boolean) => {
    var result: any;
    var resultToday: any;
    let page = more ? currentEventTodayPage : 1;
    setIsLoadingEventToday(true);
    resultToday = await fetchUpcomingEvents(
      moment().toISOString(),
      moment().endOf("day").toISOString(),
      more ? currentEventTodayPage : 1
    );

    if (resultToday && resultToday.data) {
      setIsLoadingEventToday(false);

      setEventsToday((previous) => [...previous, ...resultToday.data.items]);
      const _hasMorePages =
        Math.ceil(resultToday.data.totalItems / PAGE_SIZE) > page;
      setEventsTodayHasMorePage(_hasMorePages);
      setCurrentEventTodayPage((page: number) => ++page);
    }
  };

  const fetchEventsThisWeek = async (more: boolean) => {
    var result: any;
    let page = more ? currentPage : 1;
    setIsLoadingEventThisWeek(true);
    result = await fetchUpcomingEvents(
      moment().add(1, "days").startOf("day").toISOString(),
      moment().endOf("week").endOf("day").toISOString(),
      page
    );
    if (result && result.data) {
      setIsLoadingEventThisWeek(false);
      setEvents((previous) => [...previous, ...result.data.items]);
      const _hasMorePages =
        Math.ceil(result.data.totalItems / PAGE_SIZE) > page;
      setHasMorePage(_hasMorePages);
      setCurrentPage((page: number) => ++page);
    }
  };

  const fetchEventsByMonth = async (more: boolean) => {
    var result: any;
    let page = more ? currentPage : 1;
    setIsLoadingEventThisMonth(true);
    result = await fetchUpcomingEvents(
      moment(dateRangeFilter[0]).add(1, "day").startOf("day").toISOString(),
      moment(dateRangeFilter[1]).endOf("day").toISOString(),
      page,
      false,
      false
    );
    if (result && result.data) {
      setIsLoadingEventThisMonth(false);
      setEvents((previous) => [...previous, ...result.data.items]);
      const _hasMorePages =
        Math.ceil(result.data.totalItems / PAGE_SIZE) > page;
      setHasMorePage(_hasMorePages);
      setCurrentPage((page: number) => ++page);
    }
  };

  const fetchEventsFuture = async (more: boolean) => {
    var result: any;
    let page = more ? currentFutureEventPage : 1;
    setIsLoadingEventFuture(true);
    if (listView === "calendar") {
      result = await fetchUpcomingEvents(
        moment() > moment(dateRangeFilter[0])
          ? moment().toISOString()
          : moment(dateRangeFilter[0]).startOf("month").toISOString(),
        moment(dateRangeFilter[0]).endOf("month").toISOString(),
        page,
        true,
        false
      );
    } else {
      result = await fetchUpcomingEvents(
        // moment().endOf("week").endOf("day").toISOString(),
        moment().toISOString(),
        moment().add(1, "year").toISOString(),
        page
      );
    }

    if (result && result.data) {
      setIsLoadingEventFuture(false);
      setEventsFuture((previous) => [...previous, ...result.data.items]);
      const _hasMorePages =
        Math.ceil(result.data.totalItems / PAGE_SIZE) > page;
      setEventsFutureHasMorePage(_hasMorePages);
      setCurrentFutureEventPage((page: number) => ++page);
    }
  };

  const fetchEventsLastWeek = async (more: boolean) => {
    var result: any;
    let page = more ? currentPage : 1;
    setIsLoadingEventThisWeek(true);
    result = await fetchUpcomingEvents(
      moment().subtract(1, "weeks").startOf("day").toISOString(),
      moment().endOf("day").toISOString(),
      page,
      false,
      true
    );
    if (result && result.data) {
      setIsLoadingEventThisWeek(false);

      setEvents((previous) => [...previous, ...result.data.items]);
      const _hasMorePages =
        Math.ceil(result.data.totalItems / PAGE_SIZE) > page;
      setHasMorePage(_hasMorePages);
      setCurrentPage((page: number) => ++page);
    }
  };

  const fetchEventsFromTheBeginningOfTime = async (more: boolean) => {
    var result: any;
    let page = more ? currentFutureEventPage : 1;
    setIsLoadingEventFuture(true);
    result = await fetchUpcomingEvents(
      undefined,
      moment().subtract(1, "weeks").subtract(1, "day").endOf("day").toISOString(),
      page,
      true
    );

    if (result && result.data && result.data.items) {
      setIsLoadingEventFuture(false);
      setEventsFuture((previous) => [...previous, ...result.data.items]);
      const _hasMorePages =
        Math.ceil(result.data.totalItems / PAGE_SIZE) > page;
      setEventsFutureHasMorePage(_hasMorePages);
      setCurrentFutureEventPage((page: number) => ++page);
    }
  };

  const fetchEventsSchoolWide = async (more: boolean) => {
    var result: any;
    let page = more ? currentSchoolWideEventPage : 1;
    setIsLoadingEventSchoolWide(true);
    result =
      selectedEventType === optionsForEventType[0].value // My Events
        ? await fetchSchoolWideEvents(page, moment().toISOString())
        : selectedEventType === optionsForEventType[1].value // Upcoming Events
        ? await fetchSchoolWideEvents(page, moment().toISOString())
        : await fetchSchoolWideEvents(
            page,
            undefined,
            moment().toISOString(),
            true
          ); // Past Events

    if (result && result.data && result.data.items) {
      setIsLoadingEventSchoolWide(false);

      if (selectedEventType === "pastEvents") {
        setEventsFuture(result.data.items);
        // setEventsFuture(removeDuplicates([], result.data.items));
      } else {
        setEventsSchoolWide((previous) => [...previous, ...result.data.items]);
      }
      // setEventsSchoolWideTotal(result.data.totalItems);
      const _hasMorePages =
        Math.ceil(result.data.totalItems / PAGE_SIZE) > page;
      setEventsSchoolWideHasMorePage(_hasMorePages);
      setCurrentSchoolWideEventPage((page: number) => ++page);
    }
  };

  const fetchEventData = async (reset = false) => {
    if (reset) {
      setCurrentPage(1);
      setEvents([]);
      setEventsToday([]);
      setEventsFuture([]);
      setEventsSchoolWide([]);
      setHasCurrentWeek(false);
    }
    // setIsLoading(true);
    var result: any;
    var resultToday: any;

    const startOfWeek = moment().startOf("week");
    const previousWeek = moment().startOf("week").add(-7, "d");

    if (listView !== "calendar") {
      if (selectedEventType === "upcomingEvents") {  //has school wide events, student was invited, event has not ended, no filtering interests, student has accepted or not
          fetchEventsToday(false);
          fetchEventsThisWeek(false);
          fetchEventsFuture(false);
          fetchEventsSchoolWide(false);
      } else if (selectedEventType === "myEvents") {  //has school wide events, student was not invited, event has not ended, must filter interests, student has accepted N/A
          // fetchEventsToday(false);
          // fetchEventsThisWeek(false);
          fetchEventsFuture(false);
          fetchEventsSchoolWide(false);
        } else if (selectedEventType === "pastEvents") { //no school wide events, student was invited, event has ended, no filtering interests, student has accepted
          fetchEventsSchoolWide(false);
          fetchEventsLastWeek(false);
          fetchEventsFromTheBeginningOfTime(false);
      }
    } else {
      fetchEventsByMonth(false);
      setIsFilterShown(false);
    }

    if (result && result.data) {
      //commenting this out will call 2 api for setting events today and this week
      // let _hasCurrentWeek =
      //   events.find((f) => f.divider === "Current week") !== undefined;
      // let _hasPreviousWeek =
      //   events.find((f) => f.divider === "Previous week") !== undefined;
      // let _hasOlder = events.find((f) => f.divider === "Older") !== undefined;
      // const _data = (result.data.items as EventInvite[]).flatMap(
      //   (invite, index, array) => {
      //     if (selectedEventType === "pastEvents") {
      //       const startDate = moment(new Date(invite.startDate));
      //       if (!_hasCurrentWeek && startDate.isAfter(startOfWeek)) {
      //         setHasCurrentWeek(true);
      //         _hasCurrentWeek = true;
      //         return [{ divider: "Current week" } as EventInvite, invite];
      //       } else if (
      //         !_hasPreviousWeek &&
      //         startDate.isAfter(previousWeek) &&
      //         startDate.isBefore(startOfWeek)
      //       ) {
      //         _hasPreviousWeek = true;
      //         const currentWeek = array.filter(
      //           (f) =>
      //             moment(new Date(f.startDate)).isAfter(previousWeek) &&
      //             moment(new Date(f.startDate)).isBefore(startOfWeek)
      //         );
      //         const sum = currentWeek.reduce(
      //           (a, b) =>
      //             a +
      //             (b.userInvite.attendance !== undefined
      //               ? b.userInvite.attendance.earnedPoints
      //               : 0),
      //           0
      //         );
      //         return [
      //           { divider: "Previous week", dividerPoints: sum } as EventInvite,
      //           invite,
      //         ];
      //       } else if (!_hasOlder && startDate.isBefore(previousWeek)) {
      //         _hasOlder = true;
      //         return [{ divider: "Older" } as EventInvite, invite];
      //       }
      //     }
      //     return invite;
      //   }
      // );
      // setEvents((previous) =>
      //   reset ? result.data.items : [...previous, ...result.data.items]
      // );
      // const _hasMorePages =
      //   Math.ceil(result.data.totalItems / PAGE_SIZE) > currentPage;
      // setHasMorePage(_hasMorePages);
      // _hasMorePages && document.addEventListener("scroll", listOnScroll);
    }
    // setIsLoading(false);
  };

  // const listOnScroll = () => {
  //   const target = document.getElementsByTagName("html")[0];
  //   if (
  //     target &&
  //     target.scrollHeight - target.scrollTop <= window.innerHeight
  //   ) {
  //     setCurrentPage((page: number) => ++page);
  //     document.removeEventListener("scroll", listOnScroll);
  //   }
  // };

  // const resizeWindow = () => {
  //   const list = document.getElementById("event-list");
  //   if (list) {
  //     const item = list.firstElementChild;
  //     if (item) {
  //       const items = list.querySelectorAll(
  //         ".event-list-item:not(.hidden)"
  //       )?.length;
  //       const noItemsRow = Math.trunc(list.offsetWidth / item.clientWidth);
  //       const noExtraItems = noItemsRow - (items % noItemsRow);
  //       if (noExtraItems > 0) {
  //         const arr = new Array(noExtraItems).fill(0);
  //         setExtraItem(arr);
  //       } else {
  //         setExtraItem([]);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    fetchInterestList();
    fetchClubList();
    fetchMyClubList();
    fetchEarnedPointsLastWeek();
  }, []);

  useEffect(() => {
    //clears any selected statuses on upcomingEvents
    if (selectedEventType === "upcomingEvents") {
      setSelectedStatus([]);
    }

    if (selectedEventType === "pastEvents") {
      setSelectedStatus([0,1]); //pending and accepted invites
    }
  }, [selectedEventType]);

  const controller = new AbortController();

  useEffect(() => {
    if (isInterestLoaded && isClubLoaded) {
      setEventsSchoolWide([]);
      setEventsToday([]);
      setEvents([]);
      setCurrentPage(1);
      setCurrentEventTodayPage(1);
      setCurrentFutureEventPage(1);
      setCurrentSchoolWideEventPage(1);
      fetchEventData(true);
    }
    return () => {
      controller.abort(); // stop previous api request
      setIsLoadingEventToday(true);
      setIsLoadingEventThisWeek(true);
      setIsLoadingEventThisMonth(true);
      setIsLoadingEventFuture(true);
      setIsLoadingEventSchoolWide(true);
    };
  }, [
    selectedEventType,
    selectedInterest,
    selectedClubPrivacy,
    selectedClub,
    selectedStatus,
    searchString,
    dateRangeFilter,
    isInterestLoaded,
    isClubLoaded,
    isMyClubsLoaded,
    listView,
  ]);
  // useEffect(() => {
  //   fetchEventData(currentPage === 1);
  // }, [currentPage]);

  // useEffect(() => {
  //   if (currentPage === 1) {
  //     fetchEventData(true);
  //   } else {
  //     setCurrentPage(1);
  //   }
  // }, [selectedEventType]);

  // useEffect(() => resizeWindow(), [events, listView]);

  // useEffect(() => {
  //   window.addEventListener("resize", resizeWindow);
  //   return () => {
  //     window.scrollTo({ top: 0 });
  //     window.removeEventListener("resize", resizeWindow);
  //   };
  // }, []);

  // useEffect(() => {
  //   return () => document.removeEventListener("scroll", listOnScroll);
  // }, []);

  const viewMoreHandler = (pageType: any) => {
    if (pageType == "eventsToday") {
      fetchEventsToday(true);
    } else if (pageType == "eventsThisWeek") {
      fetchEventsThisWeek(true);
    } else if (pageType === "eventsFromTheBeginning") {
      fetchEventsFromTheBeginningOfTime(true);
    } else if (pageType === "eventsLastWeek") {
      fetchEventsLastWeek(true);
    } else if (pageType === "eventsFuture") {
      fetchEventsFuture(true);
    } else if (pageType === "eventsSchoolWide") {
      fetchEventsSchoolWide(true);
    }
  };

  useEffect(() => {
    if (props.userIsBack) {
      setShowWelcomeBack(true);
    }
  }, [props.userIsBack]);

  if (showWelcomeBack) {
    return <WelcomeBack setShowWelcomeBack={setShowWelcomeBack} />;
  }

  return (
    <Fragment>
      <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={onDeclineForm}
        isOpen={isDeclineEventModalOpen}
        setIsOpen={setIsDeclineEventModalOpen}
        form={declineEventForm}
        isLoading={isLoadingDeclineEventInvite}
      />
      <Modal
        centered
        className="modal-action-success"
        visible={isHandleJoinModalOpen}
        onCancel={() => setIsHandleJoinModalOpen(false)}
        footer={[]}
      >
        <div className="d-flex justify-content-center w-100">
          <img src={successIcon} height="96px" width="96px" />
        </div>
        <div className="d-flex-column w-100 justify-content-center">
          <h4 className="text-center">Success!</h4>
          <p className="text-center">{isJoinModalText}</p>
        </div>
      </Modal>
      <MiniPageHeader
        onSearch={(e: any) => {
          setSearchString(e);
        }}
        placeholder="Search Event"
        filterToggle={
          listView !== "calendar" && (
            <Button
              className="filter-toggler"
              icon={<FilterOutlined />}
              shape="round"
              onClick={() => setIsFilterShown(!isFilterShown)}
            >
              <span className="filter-text">
                {isFilterShown ? "Hide Filters" : "Show Filters"}
              </span>
            </Button>
          )
        }
        dateRange={listView === "card"
        ?
          <div>
            <div className="date-filter-container">
              <label className="ml-4 mr-3 show-desktop-filters">
                Event Date
              </label>
              <RangePicker
                defaultValue={dateRangeComponentValue && dateRangeComponentValue?.length === 2
                  ? [moment(dateRangeComponentValue[0]), moment(dateRangeComponentValue[1])]
                  : null}
                style={{ height: "40px", marginLeft: "5px" }}
                placeholder={["From", "To"]}
                ranges={{
                  Today: [moment(), moment()],
                  "This Week": [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                showTime
                format="YYYY-MM-DD"
                onChange={onChange}
              />
            </div>
          </div>
          : null
        }
      >
        <Tooltip title="Grid View">
          <Button
            className={listView === "card" ? "view-active" : ""}
            shape="circle"
            icon={<AppstoreOutlined />}
            onClick={() => {
              setDateRangeFilter([]);
              setListView("card");
            }}
          />
        </Tooltip>
        {/* <Tooltip title="Table View">
          <Button
            className={listView === "row" ? "view-active" : ""}
            shape="circle"
            icon={<UnorderedListOutlined />}
            onClick={() => {
              setDateRangeFilter([]);
              setListView("row");
            }}
          />
        </Tooltip> */}
        <Tooltip title="Calendar View">
          <Button
            className={listView === "calendar" ? "view-active" : ""}
            shape="circle"
            icon={<CalendarOutlined />}
            onClick={() => {
              listView === "card" && setDateRangeFilter([]);
              // setSelectedEventType("myEvents");

              if (listView === "card") {
                setDateRangeFilter([]);
                setListView("calendar");
              }
            }}
          />
        </Tooltip>
        {listView !== "calendar" && (
          <Tooltip title="Filters" className="filter-sm">
            <Button
              shape="circle"
              icon={<FilterOutlined />}
              onClick={() => setIsFilterShown(!isFilterShown)}
            />
          </Tooltip>
        )}
      </MiniPageHeader>

      <div className="content-wrapper">
        <div className="content-container">
          <div
            className="listing-container"
            style={{ gap: !isFilterShown ? 0 : 24 }}
          >
            <div
              className={`sidebar-placeholder ${
                isFilterShown ? "show-sidebar" : "hide-sidebar"
              }`}
            >
              <div
                className="sidebar-backdrop"
                style={{
                  background: !isFilterShown
                    ? "rgba(0, 0, 0, 0)"
                    : "rgba(0, 0, 0, 0.2)",
                }}
                onClick={(event) =>
                  event.currentTarget == event.target &&
                  setIsFilterShown(!isFilterShown)
                }
              >
                <div
                  className={`listing-sidebar ${!isFilterShown && "d-none"}`}
                >
                  <div className="close-filters-sm d-flex align-items-center">
                    <span>Filter</span>
                    <CloseOutlined
                      style={{
                        verticalAlign: "middle",
                      }}
                      onClick={() => setIsFilterShown(!isFilterShown)}
                    />
                  </div>
                  <Collapse
                    className="collapse-filters"
                    defaultActiveKey={["1"]}
                    bordered={false}
                    expandIconPosition={"end"}
                  >
                    {listView === "card" && (
                      <Panel header="Type" key="1">
                        <Checkbox.Group
                          options={optionsForEventType}
                          // options={
                          //   listView === "calendar"
                          //     ? optionsForEventType.filter((filter: any) => {
                          //         return filter.value === "myEvents";
                          //       })
                          //     : optionsForEventType
                          // }
                          value={selectedEventType}
                          onChange={onChangeEventType}
                        />
                      </Panel>
                    )}
                    {/* <Panel header="Privacy Type" key="2">
                    <Checkbox.Group
                      options={optionsForClubPrivacy}
                      value={selectedClubPrivacy}
                      onChange={onChangeClubPrivacy}
                    />
                  </Panel> */}
                    {selectedEventType !== "upcomingEvents" &&
                      listView === "card" && (
                        <Panel header="Status" key="2">
                          <Checkbox.Group
                            options={ selectedEventType === "pastEvents" ? optionsForStatusPast : optionsForStatus }
                            value={selectedStatus}
                            onChange={onChangeStatus}
                          />
                        </Panel>
                      )}
                    {selectedEventType === "upcomingEvents" &&
                      listView === "card" && (
                        <Panel header="Interest" key="3">
                          <Checkbox.Group
                            options={
                              showMoreInterests
                                ? interestOptions
                                : interestOptions?.slice(0, 5)
                            }
                            value={selectedInterest}
                            onChange={onChangeInterest}
                          />
                          {interestOptions !== undefined &&
                            interestOptions?.length > 5 &&
                            (!showMoreInterests ? (
                              <a
                                className="more-dropdown"
                                onClick={() => moreInterestsTrigger()}
                              >
                                More <DownOutlined />
                              </a>
                            ) : (
                              <a
                                className="more-dropdown"
                                onClick={() => moreInterestsTrigger()}
                              >
                                Less <UpOutlined />
                              </a>
                            ))}
                        </Panel>
                      )}

                    <Panel header="Clubs" key="5">
                      <Checkbox.Group
                        options={
                          showMoreClubs
                            ? clubOptions
                            : clubOptions?.slice(0, 10)
                        }
                        value={selectedClub}
                        onChange={onChangeClub}
                      />
                      {clubOptions !== undefined &&
                        clubOptions?.length > 10 &&
                        (!showMoreClubs ? (
                          <a
                            className="more-dropdown"
                            onClick={() => moreClubsTrigger()}
                          >
                            More <DownOutlined />
                          </a>
                        ) : (
                          <a
                            className="more-dropdown"
                            onClick={() => moreClubsTrigger()}
                          >
                            Less <UpOutlined />
                          </a>
                        ))}
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="listing-content-view">
              {listView !== "calendar" &&
                (eventsFuture?.length !== 0 ||
                  eventsSchoolWide?.length !== 0 ||
                  events?.length !== 0) &&
                !isLoadingEventFuture &&
                !isLoadingEventSchoolWide &&
                !isLoading && (
                  <div className="earnedpoint-container">
                    <div className="d-flex">
                      <h4 className="filter-title">Earned Points</h4>
                      <div className="badge-count">{earnedPoints}</div>
                    </div>
                  </div>
                )}

              {(listView === "row" || listView === "card") && (
                <Fragment>
                  <div id="event-list" className={`event-list ${listView}`}>
                    {selectedEventType === "pastEvents" && (
                      <Fragment>
                        {events?.length > 0 && (
                          <Fragment>
                            <div className="my-club-container mb-4 justify-content-space-between">
                              <h4 className="filter-title">Last Week</h4>
                            </div>
                            <Row gutter={[24, 24]}>
                              {events.map((event: EventInvite) => {
                                return (
                                  <Col
                                    // span={listView === "row" ? 24 : 8}
                                    span={8}
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="gutter-row event-column"
                                  >
                                    <EventItem
                                      view={listView}
                                      key={event.eventId}
                                      event={event}
                                      onDeclineHandler={onDeclineHandler}
                                      fetchEarnedPointsLastWeek={
                                        fetchEarnedPointsLastWeek
                                      }
                                    />
                                  </Col>
                                );
                              })}
                            </Row>

                            <Fragment>
                              {hasMorePage ? (
                                <div
                                  className="view-more-btn"
                                  onClick={() =>
                                    viewMoreHandler("eventsLastWeek")
                                  }
                                >
                                  View More <RightOutlined />
                                </div>
                              ) : (
                                <Divider orientation="center" plain>
                                  No more events to show
                                </Divider>
                              )}
                            </Fragment>
                          </Fragment>
                        )}
                        {eventsFuture?.length > 0 && (
                          <Fragment>
                            <div className="my-club-container mb-4 justify-content-space-between">
                              <h4 className="filter-title">
                                {dateRangeFilter?.length === 0
                                  ? "Past Events"
                                  : "Past Events From " +
                                    moment(dateRangeFilter[0]).format("ll") +
                                    " To " +
                                    moment(dateRangeFilter[1]).format("ll")}
                              </h4>
                            </div>
                            <Row gutter={[24, 24]}>
                              {eventsFuture.map((event: EventInvite) => {
                                return (
                                  <Col
                                    // span={listView === "row" ? 24 : 8}
                                    span={8}
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="gutter-row event-column"
                                  >
                                    {event.divider && (
                                      <div className="list-divider">
                                        {/* <span>{event.divider}</span> */}
                                        {event.dividerPoints !== undefined && (
                                          <span>
                                            Earned points: {event.dividerPoints}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    <EventItem
                                      view={listView}
                                      key={event.eventId}
                                      event={event}
                                      onDeclineHandler={onDeclineHandler}
                                      fetchEarnedPointsLastWeek={
                                        fetchEarnedPointsLastWeek
                                      }
                                    />
                                  </Col>
                                );
                              })}
                            </Row>

                            <Fragment>
                              {eventsFutureHasMorePage ? (
                                <Fragment>
                                  {!isLoadingEventFuture && (
                                    <div
                                      className="view-more-btn"
                                      onClick={() =>
                                        viewMoreHandler(
                                          "eventsFromTheBeginning"
                                        )
                                      }
                                    >
                                      View More <RightOutlined />
                                    </div>
                                  )}
                                </Fragment>
                              ) : (
                                <Divider orientation="center" plain>
                                  No more events to show
                                </Divider>
                              )}
                            </Fragment>
                          </Fragment>
                        )}
                        {eventsFuture?.length === 0 &&
                          !isLoadingEventFuture &&
                          eventsSchoolWide?.length == 0 &&
                          !isLoadingEventSchoolWide &&
                          events?.length === 0 &&
                          !isLoading &&
                          (myClubs===undefined || 
                          myClubs?.length > 0)  && 
                          isMyClubsLoaded &&
                           <EmptyState onFilterAgain={onFilterAgain}/>}
                        {myClubs!==undefined && 
                        myClubs?.length === 0 &&
                        !isLoadingEventFuture &&
                        !isLoadingEventSchoolWide &&
                        !isLoading &&
                         <TotallyNewState/>}
                      </Fragment>
                    )}

                    {(selectedEventType === "myEvents" ||
                      selectedEventType === "upcomingEvents") && (
                      <Fragment>
                        {eventsToday?.length > 0 &&
                          dateRangeFilter?.length === 0 && (
                            <Fragment>
                              <div className="my-club-container mb-4">
                                <h4 className="filter-title">Happening Now</h4>
                                {/* <div className="badge-count">0</div> */}
                              </div>
                              <Row gutter={[24, 24]}>
                                {eventsToday.map((event: EventInvite) => {
                                  return (
                                    <Col
                                      // span={listView === "row" ? 24 : 8}
                                      span={8}
                                      xs={{ span: 24 }}
                                      md={{ span: 12 }}
                                      lg={{ span: 12 }}
                                      xl={{ span: 8 }}
                                      className="gutter-row event-column"
                                    >
                                      {event.divider && (
                                        <div className="list-divider">
                                          {/* <span>{event.divider}</span> */}
                                          {event.dividerPoints !==
                                            undefined && (
                                            <span>
                                              Earned points:{" "}
                                              {event.dividerPoints}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                      <EventItem
                                        view={listView}
                                        key={event.eventId}
                                        event={event}
                                        onDeclineHandler={onDeclineHandler}
                                        fetchEarnedPointsLastWeek={
                                          fetchEarnedPointsLastWeek
                                        }
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>

                              {/* {!isListLoading && ( */}
                              <Fragment>
                                {eventsTodayHasMorePage ? (
                                  <div
                                    className="view-more-btn"
                                    onClick={() =>
                                      viewMoreHandler("eventsToday")
                                    }
                                  >
                                    View More <RightOutlined />
                                  </div>
                                ) : (
                                  <Divider orientation="center" plain>
                                    No more events to show
                                  </Divider>
                                )}
                              </Fragment>
                              {/* )} */}
                            </Fragment>
                          )}

                        {events?.length > 0 && (
                          <Fragment>
                            <div className="my-club-container mb-4">
                              <h4 className="filter-title">
                                {dateRangeFilter?.length === 0
                                  ? "This Week"
                                  : "From " +
                                    moment(dateRangeFilter[0]).format("ll") +
                                    " To " +
                                    moment(dateRangeFilter[1]).format("ll")}
                              </h4>
                              {/* <div className="badge-count">0</div> */}
                            </div>
                            <Row gutter={[24, 24]}>
                              {events.map((event: EventInvite) => {
                                return (
                                  <Col
                                    // span={listView === "row" ? 24 : 8}
                                    span={8}
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    className="gutter-row event-column"
                                  >
                                    {event.divider && (
                                      <div className="list-divider">
                                        {/* <span>{event.divider}</span> */}
                                        {event.dividerPoints !== undefined && (
                                          <span>
                                            Earned points: {event.dividerPoints}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    <EventItem
                                      view={listView}
                                      key={event.eventId}
                                      event={event}
                                      onDeclineHandler={onDeclineHandler}
                                      fetchEarnedPointsLastWeek={
                                        fetchEarnedPointsLastWeek
                                      }
                                    />
                                  </Col>
                                );
                              })}
                            </Row>

                            <Fragment>
                              {hasMorePage ? (
                                <div
                                  className="view-more-btn"
                                  onClick={() =>
                                    viewMoreHandler("eventsThisWeek")
                                  }
                                >
                                  View More <RightOutlined />
                                </div>
                              ) : (
                                <Divider orientation="center" plain>
                                  No more events to show
                                </Divider>
                              )}
                            </Fragment>
                          </Fragment>
                        )}

                        {eventsFuture?.length > 0 &&
                          dateRangeFilter?.length === 0 && (
                            <Fragment>
                              <div className="my-club-container mb-4">
                                <h4 className="filter-title">Future Events</h4>
                                {/* <div className="badge-count">0</div> */}
                              </div>
                              <Row gutter={[24, 24]}>
                                {eventsFuture.map((event: EventInvite) => {
                                  return (
                                    <Col
                                      // span={listView === "row" ? 24 : 8}
                                      span={8}
                                      xs={{ span: 24 }}
                                      md={{ span: 12 }}
                                      lg={{ span: 12 }}
                                      xl={{ span: 8 }}
                                      className="gutter-row event-column"
                                    >
                                      <EventItem
                                        view={listView}
                                        key={event.eventId}
                                        event={event}
                                        onDeclineHandler={onDeclineHandler}
                                        fetchEarnedPointsLastWeek={
                                          fetchEarnedPointsLastWeek
                                        }
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>

                              {/* {!isListLoading && ( */}
                              <Fragment>
                                {eventsFutureHasMorePage ? (
                                  <div
                                    className="view-more-btn"
                                    onClick={() =>
                                      viewMoreHandler("eventsFuture")
                                    }
                                  >
                                    View More <RightOutlined />
                                  </div>
                                ) : (
                                  <Divider orientation="center" plain>
                                    No more events to show
                                  </Divider>
                                )}
                              </Fragment>
                              {/* )} */}
                            </Fragment>
                          )}

                        {events?.length === 0 &&
                          eventsToday?.length === 0 &&
                          eventsFuture?.length === 0 &&
                          eventsSchoolWide?.length == 0 &&
                          // !isLoadingEventToday &&
                          // !isLoadingEventThisWeek &&
                          !isLoadingEventFuture &&
                          !isLoadingEventSchoolWide &&
                          (myClubs===undefined || 
                          myClubs?.length > 0) &&
                          isMyClubsLoaded &&
                           <EmptyState onFilterAgain={onFilterAgain}/>}
                        {myClubs!==undefined && 
                          myClubs?.length === 0  &&
                        !isLoadingEventFuture &&
                        !isLoadingEventSchoolWide && 
                        <TotallyNewState/>}

                      </Fragment>
                    )}

                    {/* School Wide Events */}
                    {
                      <Fragment>
                        {eventsSchoolWide?.length > 0 &&
                          dateRangeFilter?.length === 0 && (
                            <Fragment>
                              <div className="my-club-container mb-4">
                                <h4 className="filter-title">
                                  School Wide Events
                                </h4>
                                {/* <div className="badge-count">{eventsSchoolWideTotal}</div> */}
                              </div>
                              <Row gutter={[24, 24]}>
                                {eventsSchoolWide.map((event: EventInvite) => {
                                  return (
                                    <Col
                                      // span={listView === "row" ? 24 : 8}
                                      span={8}
                                      xs={{ span: 24 }}
                                      md={{ span: 12 }}
                                      lg={{ span: 12 }}
                                      xl={{ span: 8 }}
                                      className="gutter-row event-column"
                                    >
                                      {event.divider && (
                                        <div className="list-divider">
                                          {/* <span>{event.divider}</span> */}
                                          {event.dividerPoints !==
                                            undefined && (
                                            <span>
                                              Earned points:{" "}
                                              {event.dividerPoints}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                      <EventItem
                                        view={listView}
                                        key={event.eventId}
                                        event={event}
                                        onDeclineHandler={onDeclineHandler}
                                        fetchEarnedPointsLastWeek={
                                          fetchEarnedPointsLastWeek
                                        }
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>

                              {/* {!isListLoading && ( */}
                              <Fragment>
                                {eventsSchoolWideHasMorePage ? (
                                  <div
                                    className="view-more-btn"
                                    onClick={() =>
                                      viewMoreHandler("eventsSchoolWide")
                                    }
                                  >
                                    View More <RightOutlined />
                                  </div>
                                ) : (
                                  <Divider orientation="center" plain>
                                    No more events to show
                                  </Divider>
                                )}
                              </Fragment>
                              {/* )} */}
                            </Fragment>
                          )}
                      </Fragment>
                    }

                    {listView === "card" &&
                      extraItem.map((m, i) => (
                        <div
                          key={`extra-${i}`}
                          className="event-list-item card hidden"
                        ></div>
                      ))}

                    {selectedEventType === "pastEvents" ? (
                      <Fragment>
                        {isLoadingEventFuture && (
                          <Fragment>
                            <Skeleton loading={true} active />
                            <Skeleton loading={true} active />
                            <Skeleton loading={true} active />
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {
                          // isLoadingEventToday ||
                          // isLoadingEventThisWeek ||
                          (isLoadingEventFuture ||
                            isLoadingEventSchoolWide) && (
                            <Fragment>
                              <Skeleton loading={true} active />
                              <Skeleton loading={true} active />
                              <Skeleton loading={true} active />
                            </Fragment>
                          )
                        }
                      </Fragment>
                    )}

                    {/* {!isLoading && !hasMorePage && events?.length === 0 && (
                      <Divider orientation="center" plain>
                        No events to show
                      </Divider>
                    )} */}
                  </div>
                </Fragment>
              )}
              {listView === "calendar" && (
                <Fragment>
                  {/* <h2 className="event-calendar-title">My Event Calendar</h2> */}
                  <BreezeCalendar
                    eventsFuture={events}
                    handleCalendarFilterDateChange={async (dateFilter: any) => {
                      setDateRangeFilter([
                        moment(dateFilter).startOf("month").toISOString(),
                        moment(dateFilter).endOf("month").toISOString(),
                      ]);
                    }}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state: { auth: any; userIsBack: boolean; flutterConnect: boolean; }) => ({
  interests: state.auth.member.interests,
  userIsBack: state.userIsBack,
  flutterConnect: state.flutterConnect,
});
export default connect(mapStateToProps, {
  searchUpcomingEvents,
  searchSchoolWideEvents,
  getMyEvents,
  getInterestListNonPaged,
  getClubListNonPaged,
  getMyClubsGrouped,
  declineEventInvite,
  getProfileSummary,
})(EventList);
