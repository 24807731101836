import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Skeleton, Button } from "antd";
import { ReactNode, useEffect, useState } from "react";
import {
    getStudentEventsPointsList,
  } from "../../../actions/dashboard";
import { UserOutlined } from "@ant-design/icons";

const DashboardTopPerformingStudents = (props: {
    getStudentEventsPointsList: Function;
    flutterConnect: Boolean;
}) => {
    const {
        getStudentEventsPointsList,
        flutterConnect,
      } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [month, setMonth] = useState("");
    const [topStudents, setTopStudents] = useState<any[]>([]);

    const fetchList = async () => {
        setIsLoading(true);
        let params = {
          type: 1,
        };
        const res = await getStudentEventsPointsList(params);
        setTopStudents(res.data.items);
        setMonth(res.data.month);
        setIsLoading(false);
      };

    useEffect(()=>{
       fetchList();
    },[])

    const Student = (data: any) => {
        if (flutterConnect)
            return (
                <div>
                    <div className="student mobile">
                        <div className="start-group">
                            <div className="invite-image-wrapper">
                            {
                                data?.data?.profilePicUrl ? 
                                <img src={data?.data?.profilePicUrl} alt={'profile_pic'} className="invite-image"/>
                                :<div className="invite-image">
                                    <UserOutlined />
                                    </div>
                            }
                            </div>
                        </div>
                        <div className="end-group">
                            <p className="student-name">{data?.data?.fullName ?? ""}</p>
                            <p className="student-info">
                                <span>Total Events Points : </span>
                                {data?.data?.totalPoints.toFixed(0) ?? ""}
                            </p>
                            <p className="student-info">
                                <span>Total  Clubs : </span>
                                {data?.data?.totalClubs ?? ""}
                            </p>
                        </div>
                    </div>
                    <div className="student">
                        <div className="start-group">
                            <div className="invite-image">
                            {
                                data?.data?.profilePicUrl ? 
                                <img src={data?.data?.profilePicUrl} alt={'profile_pic'} className="invite-image"/>
                                :<div className="invite-image">
                                    <UserOutlined />
                                    </div>
                            }
                            </div>
                            <div className="text">
                                <h3>{data?.data?.fullName ?? ""}</h3>
                                <p>{data?.data?.level ?? "Senior High School"}</p>
                            </div>
                        </div>
                        <div className="end-group">
                            <h1>{data?.data?.totalPoints.toFixed(0) ?? ""}</h1>
                            <h1>{data?.data?.totalClubs ?? ""}</h1>
                        </div>
                    </div>
                </div>
            );
        return(
            <div className="student">
                <div className="start-group">
                    <div className="invite-image">
                    {
                        data?.data?.profilePicUrl ? 
                        <img src={data?.data?.profilePicUrl} alt={'profile_pic'} className="invite-image"/>
                        :<div className="invite-image">
                            <UserOutlined />
                            </div>
                    }
                    </div>
                    <div className="text">
                        <h3>{data?.data?.fullName ?? ""}</h3>
                        <p>{data?.data?.level ?? "Senior High School"}</p>
                    </div>
                </div>
                <div className="end-group">
                    <h1>{data?.data?.totalPoints.toFixed(0) ?? ""}</h1>
                    <h1>{data?.data?.totalClubs ?? ""}</h1>
                </div>
            </div>
        );
    }
    
    if (flutterConnect)
        return (
            <div className="dashboard-students-boards app">
                <div className="header">
                    <h1>Top Performing Students</h1>
                    <p className="month">Month of {month}</p>
                </div>
                <div className="students">
                    <div className="point-labels">
                        <div className="start-group">
                        </div>
                        <div className="end-group">
                            <p>Total Events Points</p>
                            <p>Total Clubs</p>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="loading-container">
                            <Skeleton loading={true} active />
                            <Skeleton loading={true} active />
                            <Skeleton loading={true} active />
                        </div>
                    ) }
                    {!isLoading && topStudents.length>0 &&
                        <div className="student-data">
                            {
                                topStudents.slice(0,5).map((student)=><Student data={student} key={student.memberId}/>)
                            }
                        </div>
                    }
                    {!isLoading && topStudents.length===0 &&
                        <div className="no-events-container">
                            {/* <img height={120} width={120} src={noEventInvites} /> */}
                            <h4>No Top Performing Students.</h4> 
                        </div>
                    }
                </div>
                {/* <Link to={"/admin/students"}> */}
                    <Button
                    type="default"
                    shape="default"
                    key="submit"
                    className="view-all"
                    disabled
                    >
                    View all top Students
                    </Button>
                {/* </Link> */}
            </div>
          )

    return (
    <div className={`dashboard-students-boards`}>
        <div className="header">
            <h1>Top Performing Students</h1>
            <p className="month">Month of {month}</p>
        </div>
        <div className="students">
            <div className="point-labels">
                <p>Total Events Points</p>
                <p>Total Clubs</p>
            </div>
            {isLoading && (
                <div className="loading-container">
                    <Skeleton loading={true} active />
                    <Skeleton loading={true} active />
                    <Skeleton loading={true} active />
                </div>
            ) }
            {!isLoading && topStudents.length>0 &&
                topStudents.slice(0,5).map((student)=><Student data={student} key={student.memberId}/>)
            }
            {!isLoading && topStudents.length===0 &&
                <div className="no-events-container">
                    {/* <img height={120} width={120} src={noEventInvites} /> */}
                    <h4>No Top Performing Students.</h4> 
                </div>
            }
        </div>
        {/* <Link to={"/admin/students"}> */}
            <Button
            type="default"
            shape="default"
            key="submit"
            className="view-btn"
            >
                View all top Students
            </Button>
        {/* </Link> */}
    </div>
  );
};


const mapStateToProps = (state: { auth: any; userIsBack: boolean; flutterConnect: boolean; }) => ({
    interests: state.auth.member.interests,
    userIsBack: state.userIsBack,
    flutterConnect: state.flutterConnect,
  });
export default connect(mapStateToProps, {
    getStudentEventsPointsList,
  })(DashboardTopPerformingStudents);