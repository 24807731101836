import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import { Fragment } from "react";
import {
  getMyEvents,
  getProfileSummary,
  getMyClubsGrouped,
} from "../../../actions/profile";
import {
  addEvent,
  declineEventInvite,
  searchUpcomingEvents,
  searchSchoolWideEvents,
} from "../../../actions/event";
import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
} from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  FilterOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  CloseOutlined,
  SearchOutlined,
  CalendarOutlined,
  CommentOutlined
} from "@ant-design/icons";
import { getInterestListNonPaged } from "../../../actions/interest";
import { getClubListNonPaged } from "../../../actions/club";

import MiniPageHeader from "../layouts/MiniPageHeader";
import BreezeCalendar from "../../calendar/BreezeCalendar";
import CreateEventModal from "./CreateEventModal";
import CreateReminderModal from "./CreateReminderModal";
import { BAD_REQUEST, CREATED, OK, NO_CONTENT } from "../../../helpers/statuscodes";
import { EventInvite } from "../events/EventInvite";

const { Panel } = Collapse;
interface SelectItem {
  label: string;
  value: string;
}
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD HH:mm:ss";
const customFormat = (value: any) => `${value.format(dateFormat)}`;
const PAGE_SIZE = 9;

const CalendarPage = (props: {
  searchUpcomingEvents: Function;
  searchSchoolWideEvents: Function;
  getMyEvents: Function;
  getInterestListNonPaged: Function;
  getClubListNonPaged: Function;
  getMyClubsGrouped: Function;
  declineEventInvite: Function;
  interests: any;
  getProfileSummary: any;
  userIsBack: boolean;
  member: any;
  addEvent: Function;
}) => {
  const {getMyClubsGrouped, member, addEvent} = props;
  const [events, setEvents] = useState<EventInvite[]>([]);
  const [allEvents, setAllEvents] = useState<EventInvite[]>([]);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [showMoreInterests, setShowMoreInterest] = useState(false);
  const [selectedInterest, setSelectedInterest]: any = useState([]);
  const [interestOptions, setInterestOptions] = useState<SelectItem[]>();
  const [interestList, setInterestList] = useState<any[]>([]);
  const [isInterestLoaded, setIsInterestLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEventThisMonth, setIsLoadingEventThisMonth] = useState(true);
  const [isModalCreateEventOpen, setIsModalCreateEventOpen] = useState(false);
  const [isModalCreateEventLoading, setIsModalCreateEventLoading] = useState(false);
  const [isModalCreateReminderOpen, setIsModalCreateReminderOpen] = useState(false);
  const [isModalCreateReminderLoading, setIsModalCreateReminderLoading] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<any[]>([]);
  const [formCreateEvent] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [clubsUserMods, setClubsUserMods] = useState([]);

  const removeDuplicates = (arr1: any, arr2: any) => {
    const mergedArr = arr1.concat(arr2);
    return mergedArr.filter(
      (v: any, i: any, s: any) =>
        mergedArr.findIndex((e: any) => e.eventId == v.eventId) === i
    );
  };

  const openNotification = () => {
    api.open({
      message: 'Search Results',
      description:
        'No Record Found!',
      duration: 3,
      icon: <SearchOutlined/>,
    });
  };

  const fetchUpcomingEvents = async (
    from: Moment | Date | String | undefined,
    to: Moment | Date | String,
    page: Number,
    sort?: boolean,
    ignoreCustomDate?: boolean
  ) => {
    return await props.searchUpcomingEvents(
      {
        page: page,
        pageSize: 50,
        searchString,
        schoolEventsOnly: false,
        isDesc: sort ? sort : false,
        MyEventsOnly: false,
        from:
          dateRangeFilter?.length > 0 && ignoreCustomDate === undefined
            ? moment(dateRangeFilter[0]).startOf("day").toISOString()
            : from,
        to:
          dateRangeFilter?.length > 0 && ignoreCustomDate === undefined
            ? moment(dateRangeFilter[1]).endOf("day").toISOString()
            : to,
        InterestIds: selectedInterest?.length > 0
        ? selectedInterest.toString()
        : null,
        memberGroupIds: null,
        Statuses: "-1,0,1,2",
      },
      controller
    );
  };

  const fetchEventsByMonth = async (more: boolean) => {
    var result: any;
    let page = more ? currentPage : 1;
    setIsLoadingEventThisMonth(true);
    result = await fetchUpcomingEvents(
      moment(dateRangeFilter[0]).add(1, "day").startOf("day").toISOString(),
      moment(dateRangeFilter[1]).endOf("day").toISOString(),
      page,
      false,
      false,
    );
    if (result && result.data) {
      setIsLoadingEventThisMonth(false);

      setEvents((previous) => removeDuplicates([],[...result.data.items]));
      setAllEvents((previous) => removeDuplicates([],[...result.data.items]));
      const _hasMorePages =
        Math.ceil(result.data.totalItems / PAGE_SIZE) > page;
      setHasMorePage(_hasMorePages);
      setCurrentPage((page: number) => ++page);
    }
  };

  const fetchInterestList = async () => {
    const data = await props.getInterestListNonPaged(); 
    let myInterests = props.interests
      ? props.interests.map((data: any) => {
          return data.interestId;
        })
      : [];
    let firstOnList = data.data.filter((item: any) => {
      if (myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let nextOnList = data.data.filter((item: any) => {
      if (!myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let mergedList = firstOnList.concat(nextOnList).map((item: any) => ({
      label: item.name,
      value: item.interestId,
    }));
    setInterestOptions(mergedList);
    setInterestList(data.data);
    setIsInterestLoaded(true);
  };

  const getClubsUserMods = async () => {
    let params = {
      searchString: "",
      privacyTypes: "",
      interests: "",
    };
    let res = await getMyClubsGrouped(params);
    if(res && res.data){
      let joinedClubs = (res.data).filter((t:any)=>t.category==="Joined Clubs")[0].clubs;
      let modClubList = joinedClubs.filter((c:any)=>c.moderators?.some((m:any)=>m.memberId===member.memberId));
      setClubsUserMods(modClubList);
    }
  }

  const InterestFilter = () => {
    return(
      <div className="interest-filter">
        <div className="interest-filter-header">
            <h4>Interest </h4>
            {/* <CloseOutlined
              style={{
                verticalAlign: "middle",
              }}
              onClick={() => setIsFilterShown(!isFilterShown)}
            /> */}
          </div>
          <div className="interest-filter-options">
            <Checkbox.Group
              options={
                showMoreInterests
                  ? interestOptions
                  : interestOptions?.slice(0, 5)
              }
              value={selectedInterest}
              onChange={onChangeInterest}
            />
            {interestOptions !== undefined &&
              interestOptions?.length > 5 &&
              (!showMoreInterests ? (
                <a
                  className="more-dropdown"
                  onClick={() => moreInterestsTrigger()}
                >
                  More <DownOutlined />
                </a>
              ) : (
                <a
                  className="more-dropdown"
                  onClick={() => moreInterestsTrigger()}
                >
                  Less <UpOutlined />
                </a>
              ))}
          </div>
        </div>
    )
  };
  
  const searchFilter = () => {
    let filteredResult = allEvents.filter(e=>(e.title).toLowerCase().includes(searchString.toLowerCase()));
    if(filteredResult.length === 0 && allEvents.length>0){
      openNotification();
    }
    setEvents(filteredResult);
  };

  const moreInterestsTrigger = () => {
    setShowMoreInterest(!showMoreInterests);
  };

  const onChangeInterest = (checkedValues: CheckboxValueType[]) => {
    setSelectedInterest(checkedValues);
  };

  const submitCreateEvent = async (formData: any) => {
    try {
      setIsModalCreateEventLoading(true);
      setIsModalCreateReminderLoading(true);
      const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let payload={};
      if(formCreateEvent.getFieldValue('memberGroupId')===undefined){
        payload = {
          title: formCreateEvent.getFieldValue('title'),
          description: formCreateEvent.getFieldValue('description'),
          locationId: null,
          onlineLink: null,
          eventSeriesType: 0,
          eventType: null,
          interestId: null,
          startDate: formCreateEvent.getFieldValue('date')[0],
          endDate: formCreateEvent.getFieldValue('date')[1],
          memberGroupId: null,
          timeZoneId,
          isReminder:true,
        };
      }else{
        payload = {
          title: formCreateEvent.getFieldValue('title'),
          description: formCreateEvent.getFieldValue('description'),
          locationId: formCreateEvent.getFieldValue('locationId'),
          onlineLink: formCreateEvent.getFieldValue('onlineLink')??null,
          eventSeriesType: 0,
          eventType: formCreateEvent.getFieldValue('eventType'),
          interestId: formCreateEvent.getFieldValue('interestId'),
          softSkillScores: formCreateEvent.getFieldValue('softSkillScores')!==undefined?Object.values(formCreateEvent.getFieldValue('softSkillScores')):[],
          participants: formCreateEvent.getFieldValue('participants')!==undefined? formCreateEvent.getFieldValue('participants').map((item: any) => ({
            id: item.value.split("-")[0],
            type: parseInt(item.value.split("-")[1]),
          })):[],
          startDate: formCreateEvent.getFieldValue('date')[0],
          endDate: formCreateEvent.getFieldValue('date')[1],
          memberGroupId:formCreateEvent.getFieldValue('memberGroupId'),
          timeZoneId,
          isReminder:false,
        };
      }
      const result = await addEvent(payload);

      if (result.status == OK || result.status == CREATED) {
        setIsModalCreateEventLoading(false);
        setIsModalCreateReminderLoading(false);
        setIsModalCreateEventOpen(false);
        setIsModalCreateReminderOpen(false);
        fetchEventsByMonth(false);
      } else if (result.status === BAD_REQUEST) {
        if (result.data) {
          message.error(result.data);
        }
        setIsModalCreateEventLoading(false);
        setIsModalCreateReminderLoading(false);
      }
    } catch (err) {
      console.log("Error", err);
    }
  }

  useEffect(() => {
    fetchInterestList();
    getClubsUserMods();
  }, []);
 
  useEffect(() => {
    searchFilter();
  }, [searchString]);

  useEffect(() => {
    if(isModalCreateEventOpen===false && isModalCreateReminderOpen===false){
      formCreateEvent.resetFields();
    }
  }, [isModalCreateEventOpen, isModalCreateReminderOpen]);

  useEffect(() => {
    setEvents([]);
    fetchEventsByMonth(false);
  }, [dateRangeFilter, selectedInterest ]);

  const controller = new AbortController();

  return (
    <Fragment>
      {contextHolder}
        <MiniPageHeader
        onSearch={(e: any) => {
          setSearchString(e);
        }}
        placeholder="Search Event"
        filterToggle={
          <Popover placement="bottomLeft" content={<InterestFilter />} trigger="click">
            <Button
              className="filter-toggler mobile-reactive-btn"
              icon={<FilterOutlined />}
              shape="round"
              // onClick={() => setIsFilterShown(!isFilterShown)}
              >
              <span className="filter-text">
                Show Filters
              </span>
            </Button>
          </Popover>
        }
        >
          {clubsUserMods.length>0 && (
            <Button
            className="mr-1 mobile-reactive-btn"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalCreateEventOpen(true)}
            shape="round"
            >
            Create Event
            </Button>
            )}
            <Button
            className="mr-1 mobile-reactive-btn"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalCreateReminderOpen(true)}
            shape="round"
          >
            Create Reminder
          </Button>
          <Popover placement="bottomLeft" content={<InterestFilter />} trigger="click" className="filter-sm">
            <Button
              className="filter-toggler mobile-reactive-btn"
              icon={<FilterOutlined />}
              shape="round"
              />
          </Popover>
      </MiniPageHeader>
      <div className="content-wrapper">
        <div className="content-container">
          <Fragment>
            <Spin spinning={isLoadingEventThisMonth} size="large">
            <BreezeCalendar
              refresh={fetchEventsByMonth}
              eventsFuture={events}
              handleCalendarFilterDateChange={async (dateFilter: any) => {
                setDateRangeFilter([
                  moment(dateFilter).startOf("month").toISOString(),
                  moment(dateFilter).endOf("month").toISOString(),
                ]);
              }}
            />
            </Spin>
          </Fragment>
        </div>
      </div>
      <CreateEventModal
        title={"Create Club Event"}
        handleSubmit={submitCreateEvent}
        isOpen={isModalCreateEventOpen}
        setIsOpen={setIsModalCreateEventOpen}
        form={formCreateEvent}
        isLoading={isModalCreateEventLoading}
        clubs={clubsUserMods}
        interests={interestOptions}
        interestList={interestList}
      />
      <CreateReminderModal
        title={"Create Reminder"}
        handleSubmit={submitCreateEvent}
        isOpen={isModalCreateReminderOpen}
        setIsOpen={setIsModalCreateReminderOpen}
        form={formCreateEvent}
        isLoading={isModalCreateReminderLoading}
      />
    </Fragment>
  );
};
const mapStateToProps = (state: { auth: any; userIsBack: boolean }) => ({
  interests: state.auth.member.interests,
  userIsBack: state.userIsBack,
  member: state.auth?.member,
});
export default connect(mapStateToProps, {
  searchUpcomingEvents,
  searchSchoolWideEvents,
  getMyEvents,
  getInterestListNonPaged,
  getClubListNonPaged,
  getMyClubsGrouped,
  declineEventInvite,
  getProfileSummary,
  addEvent,
})(CalendarPage);
