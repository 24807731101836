import { Content, Header } from "antd/lib/layout/layout";
import { Button, Col, Divider, Dropdown, Form, Layout, Menu, Row } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getClubById,
  handleClubProposal,
  joinClub,
  leaveClub,
} from "../../../actions/club";
import headerBannerBg from "../../../assets/images/club-banner-default.png";
import interestPlaceholder from "../../../assets/images/interest-placeholder.svg";
import avatarPlaceholder from "../../../assets/images/avatar-placeholder.svg";
import {
  GlobalOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

import { Fragment, useEffect, useState } from "react";
import PageHeader from "../layouts/PageHeader";
import { connect } from "react-redux";
import { ClubItem } from "../../../interface/Club";
import ClubEvent from "./ClubEvent";
import ClubMembers from "./ClubMembers";
import {
  MemberString,
  ModeratorString,
  Student,
  StudentString,
  Teacher,
} from "../../../helpers/memberTypes";
import moment from "moment";
import { CREATED, NO_CONTENT, OK } from "../../../helpers/statuscodes";
import _ from "lodash";
import ModalConfirm from "../Modals/ModalDeclineConfirmation";
import ClubModerators from "./ClubModerators";
import ModalSuccess from "../Modals/ModalSuccess";
import ModalDeclineReason from "../Modals/ModalDeclineReason";
import { userPermissions } from "../../../permissions/user-permissions";
import ComingSoonState from "../layouts/ComingSoonState";

const GroupPage = (props: {
  viewingAs: string;
  getClubById: Function;
  auth: any;
  joinClub: Function;
  leaveClub: Function;
  handleClubProposal: Function;
}) => {
  const navigate = useNavigate();
  const {
    viewingAs,
    auth: { member },
    joinClub,
    leaveClub,
    handleClubProposal,
  } = props;
  const { getClubById } = props;
  const { memberGroupId } = useParams();
  const [clubFilter, setClubFilter] = useState(1);
  const [prevClubFilter, setPrevClubFilter] = useState(1);
  const [groupData, setGroupData] = useState<any>([]);
  const [readAble, setReadable] = useState(false);
  const [joinAble, setJoinAble] = useState(false);
  const [leaveable, setLeaveable] = useState(false);
  const [joinPending, setJoinPending] = useState(false);
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [formDeclineJoinRequest] = Form.useForm();
  const [isModalDeclineReasonOpen, setIsModalDeclineReasonOpen] =
    useState(false);
  const [isLoadingModalDeclineReason, setIsLoadingModalDeclineReason] =
    useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isLoadingModalConfirm, setIsLoadingModalConfirm] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [currentDate, setCurrentDate] = useState(Date.now().toString());
  
  const clubFilterLabel = [
    '',
    'About',
    'Discussion',
    'Events',
    'Members',
    'Moderators',
    'Files',
    'FAQ',
  ];

  const onMenuClick = (info: any) => {
    if (info.key === "edit_club") {
      navigate(`/club/${memberGroupId}/edit`);
    } else {
      // delete button click
    }
  };

  const menu = (
    <Menu
      onClick={onMenuClick}
      items={[
        { label: "Edit Club", key: "edit_club" },
        // { label: "Delete Club", key: "delete_club" },
      ]}
    ></Menu>
  );

  const onJoinClub = async (e: any, memberGroupId: any) => {
    setIsJoinLoading(true);
    e.stopPropagation();
    // OR
    e.preventDefault();
    const res = await joinClub(memberGroupId);
    if (res.status == OK) {
      setJoinPending(true);
      setJoinAble(false);
    }
    setIsJoinLoading(false);
  };

  const leaveGroupHandle = async () => {
    setIsLoadingModalConfirm(true);
    const res = await leaveClub({
      memberId: member.memberId,
      clubId: memberGroupId,
    });

    if (res.status == NO_CONTENT) {
      setIsModalConfirmOpen(false);
      setLeaveable(false);
      fetchClubDetails();
    }
    setIsLoadingModalConfirm(false);
  };

  const onHandleClubProposal = async (type: String) => {
    let params = {
      clubId: memberGroupId,
      type,
    };

    const res = await handleClubProposal(params);
    if (res.status == OK || res.status == CREATED) {
      setGroupData({
        ...groupData,
        status: res.data.status,
      });
      setSuccessMessage("You have successfully approve the club proposal.");
      setIsModalSuccessOpen(true);
    }
  };

  const onHandleClubDeclineProposal = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);
    let params = {
      ...formData,
      clubId: memberGroupId,
      type: "decline",
    };

    const res = await handleClubProposal(params);
    if (res.status == OK || res.status == CREATED) {
      setGroupData({
        ...groupData,
        status: res.data.status,
      });
      setIsModalDeclineReasonOpen(false);
      setSuccessMessage("You have successfully declined the club proposal.");
      setIsModalSuccessOpen(true);
    }
    setIsLoadingModalDeclineReason(false);
  };

  const fetchClubDetails = async () => {
    let res = await getClubById({
      clubId: memberGroupId,
    });
    if (res.data) {
      setGroupData(res.data);

      if (
        res.data.userMembership &&
        res.data.userMembership.membershipType &&
        res.data.userMembership.joinStatus === "Approved"
      ) {
        if (res.data.userMembership.membershipType === ModeratorString) {
          setReadable(true);
        } else if (res.data.userMembership.membershipType === MemberString) {
          setLeaveable(true);
        }
      } else if (res.data.userMembership === undefined) {
        if (member && member.memberType === StudentString) {
          setJoinAble(true);
        }
      }
    }
  };

  useEffect(() => {
    fetchClubDetails();
  }, [memberGroupId]);

  let Permissions = {
    canCreateEvent:
      userPermissions[member.memberType] &&
      userPermissions[member.memberType]?.createEvent &&
      userPermissions[member.memberType]?.canCreateIf ===
        groupData?.userMembership?.membershipType,
    canInviteMember:
      userPermissions[member.memberType] &&
      userPermissions[member.memberType]?.inviteMember &&
      userPermissions[member.memberType]?.canCreateIf ===
        groupData?.userMembership?.membershipType,
    isDisabled:
      userPermissions[member.memberType]?.canCreateIfJoinStatus !==
      groupData?.userMembership?.joinStatus,
  };

  const setClubFilterSelection = (filter: number) => {
    setPrevClubFilter(clubFilter);
    setClubFilter(filter);
  }

  const onComingSoon = () => {
    const currentClubFilter = clubFilter;
    setClubFilter(prevClubFilter);
    setPrevClubFilter(currentClubFilter);
  }

  if ([2,6,7].includes(clubFilter))
    return (
      <>
        <ComingSoonState onButtonClick={onComingSoon} featureTitle={`Club/${clubFilterLabel[clubFilter]}`}/>
      </>
    );
  else
    return (
      <Fragment>
        {viewingAs === "Admin" && (
          <Fragment>
            <ModalSuccess
              isOpen={isModalSuccessOpen}
              setIsOpen={setIsModalSuccessOpen}
            >
              <p className="text-center">{successMessage}</p>
            </ModalSuccess>
            <ModalDeclineReason
              title={`Enter reason for declining ${groupData.name} Club`}
              handleSubmit={onHandleClubDeclineProposal}
              isOpen={isModalDeclineReasonOpen}
              setIsOpen={setIsModalDeclineReasonOpen}
              form={formDeclineJoinRequest}
              isLoading={isLoadingModalDeclineReason}
            />
          </Fragment>
        )}
        {viewingAs === "NonAdmin" && (
          <Fragment>
            {" "}
            <ModalConfirm
              handleSubmit={leaveGroupHandle}
              isOpen={isModalConfirmOpen}
              setIsOpen={setIsModalConfirmOpen}
              isLoading={isLoadingModalConfirm}
            >
              <p className="mb-0">
                Are you sure you want to leave the {groupData && groupData.name}
              </p>
            </ModalConfirm>
            <div
              className="banner-wrapper"
              style={{
                backgroundImage: `url(${
                  groupData && groupData.bannerFileUrl
                    ? groupData.bannerFileUrl + "?" + currentDate
                    : headerBannerBg
                })`,
              }}
            >
              <div className="banner-container">
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate("/clubs")}
                  style={{ color: "#fff", padding: "unset" }}
                >
                  Back
                </Button>
              </div>
            </div>
          </Fragment>
        )}

        <PageHeader
          title={groupData && groupData.name}
          header={
            <div className="d-flex">
              {viewingAs === "Admin" && groupData.status === "Pending" && (
                <div className="d-flex">
                  <Button
                    className="mr-2"
                    type="primary"
                    shape="round"
                    style={{ height: 40 }}
                    onClick={() => onHandleClubProposal("approve")}
                  >
                    Approve Club Proposal
                  </Button>
                  <Button
                    shape="round"
                    style={{ height: 40 }}
                    onClick={() => setIsModalDeclineReasonOpen(true)}
                  >
                    Decline Club Proposal
                  </Button>
                </div>
              )}

              {joinAble && (
                <Button
                  loading={isJoinLoading}
                  type="primary"
                  shape="round"
                  style={{ height: 40 }}
                  onClick={(e) => onJoinClub(e, memberGroupId)}
                >
                  Join Club
                </Button>
              )}
              {leaveable && (
                <Button
                  loading={isJoinLoading}
                  type="primary"
                  shape="round"
                  style={{ height: 40 }}
                  onClick={(e) => setIsModalConfirmOpen(true)}
                >
                  Leave Club
                </Button>
              )}
              {joinPending && (
                <Button disabled={true} shape="round" style={{ height: 40 }}>
                  Join Request Pending
                </Button>
              )}
              {readAble && (
                <Dropdown placement="bottomRight" overlay={menu}>
                  <Button
                    shape="circle"
                    icon={<EllipsisOutlined />}
                    style={{ height: 40 }}
                  ></Button>
                </Dropdown>
              )}
            </div>
          }
        >
          {/* add your tab filters here */}
          <div className="filter-info">
            <div className="d-flex mr-3">
              <div className="mr-1">
                <GlobalOutlined />
              </div>
              <div>{groupData && groupData.privacyType}</div>
            </div>
            <div className="d-flex mr-3">
              <div className="mr-1">
                <UserOutlined />
              </div>
              <div>{groupData && groupData.memberCount} Members</div>
            </div>
          </div>
          <div className="d-flex tab-filter mt-4">
            <div
              onClick={() => setClubFilterSelection(1)}
              className={`tab-item mr-4 ${clubFilter === 1 && "tab-active"}`}
            >
              About
            </div>
            {viewingAs !== "Admin" && (
              <div
                onClick={() => setClubFilterSelection(2)}
                className={`tab-item mr-4 ${clubFilter === 2 && "tab-active"}`}
              >
                Discussion
              </div>
            )}

            <div
              onClick={() => setClubFilterSelection(3)}
              className={`tab-item mr-4 ${clubFilter === 3 && "tab-active"}`}
            >
              Events
            </div>
            <div
              onClick={() => setClubFilterSelection(4)}
              className={`tab-item mr-4 ${clubFilter === 4 && "tab-active"}`}
            >
              Members
            </div>
            <div
              onClick={() => setClubFilterSelection(5)}
              className={`tab-item mr-4 ${clubFilter === 5 && "tab-active"}`}
            >
              Moderators
            </div>
            {viewingAs !== "Admin" && (
              <Fragment>
                <div
                  onClick={() => setClubFilterSelection(6)}
                  className={`tab-item mr-4 ${clubFilter === 6 && "tab-active"}`}
                >
                  Files
                </div>
                <div
                  onClick={() => setClubFilterSelection(7)}
                  className={`tab-item mr-4 ${clubFilter === 7 && "tab-active"}`}
                >
                  FAQ
                </div>
              </Fragment>
            )}
          </div>
        </PageHeader>

        <div
          className={`group-page-single-content-wrapper ${
            viewingAs === "Admin" ? "px-0" : ""
          }`}
        >
          <div
            className={`group-page-single-content ${
              viewingAs === "Admin" && "admin-group-page-single-content-override"
            }`}
          >
            {clubFilter === 1 && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col
                  className="gutter-row mb-2"
                  xs={{ span: 24 }}
                  md={{ span: 14 }}
                  lg={{ span: 18 }}
                  xl={{ span: 18 }}
                >
                  <div className="content-left">
                    <div className="about">
                      {groupData && groupData.description}
                      {/* <div>
                        <h4>About the Club</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                          do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                          Et ligula ullamcorper malesuada proin. Cras ornare arcu dui
                          vivamus arcu. Risus quis varius quam quisque id diam vel quam
                          elementum. Rhoncus mattis rhoncus urna neque viverra justo nec
                          ultrices dui. Senectus et netus et malesuada. Est velit
                          egestas dui id ornare arcu. Et malesuada fames ac turpis
                          egestas integer. Sapien nec sagittis aliquam malesuada
                          bibendum arcu vitae elementum. Lobortis feugiat vivamus at
                          augue eget arcu dictum varius.
                        </p>
                      </div>
                      <div>
                        <h4>Mission</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                          do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                          Et ligula ullamcorper malesuada proin. Cras ornare arcu dui
                          vivamus arcu. Risus quis varius quam quisque id diam vel quam
                          elementum. Rhoncus mattis rhoncus urna neque viverra justo nec
                          ultrices dui. Senectus et netus et malesuada. Est velit
                          egestas dui id ornare arcu. Et malesuada fames ac turpis
                          egestas integer. Sapien nec sagittis aliquam malesuada
                          bibendum arcu vitae elementum. Lobortis feugiat vivamus at
                          augue eget arcu dictum varius.
                        </p>
                      </div>
                      <div>
                        <h4>Objectives</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                          do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                          Et ligula ullamcorper malesuada proin. Cras ornare arcu dui
                          vivamus arcu. Risus quis varius quam quisque id diam vel quam
                          elementum. Rhoncus mattis rhoncus urna neque viverra justo nec
                          ultrices dui. Senectus et netus et malesuada. Est velit
                          egestas dui id ornare arcu. Et malesuada fames ac turpis
                          egestas integer. Sapien nec sagittis aliquam malesuada
                          bibendum arcu vitae elementum. Lobortis feugiat vivamus at
                          augue eget arcu dictum varius.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </Col>

                <Col
                  className="gutter-row mb-2"
                  xs={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <div className="content-right">
                    <div className="normal-widget">
                      <h4 className="mb-2">Interest</h4>
                      <div className="d-flex align-items-center">
                        <img src={interestPlaceholder} />
                        <h5>
                          {groupData &&
                            groupData.interest &&
                            groupData.interest.name}
                        </h5>
                      </div>
                    </div>
                    <Divider orientation="left"></Divider>
                    <div className="normal-widget">
                      <h4>Date Established</h4>
                      <div className="d-flex align-items-center">
                        <h5>
                          {groupData.createdOn &&
                            moment(groupData.createdOn).format("LL")}
                        </h5>
                      </div>
                    </div>
                    <Divider orientation="left"></Divider>
                    <div className="normal-widget">
                      <h4 className="mb-2">Moderators</h4>
                      {groupData.moderators ? (
                        groupData.moderators.map(
                          (moderator: {
                            firstName: string;
                            lastName: string;
                          }) => {
                            return (
                              <div
                                key={moderator.firstName}
                                className="d-flex align-items-center mb-2"
                              >
                                <img src={avatarPlaceholder} />
                                <h5>
                                  {moderator.firstName} {moderator.lastName}
                                </h5>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <Fragment>
                          <small>
                            <i>No Moderators</i>
                          </small>
                        </Fragment>
                      )}
                    </div>
                    <Divider orientation="left"></Divider>
                    <div>
                      <h4 className="mb-2">Members</h4>
                      <div className="d-flex align-items-center">
                        <div className="club-members-avatar">
                          {groupData.topMembers &&
                            groupData.topMembers.map(
                              (clubTopMember: { firstName: string }) => {
                                return (
                                  <div
                                    key={clubTopMember.firstName}
                                    className="no-avatar avatar-sm"
                                  >
                                    <span>
                                      {clubTopMember.firstName.charAt(0)}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          {groupData.memberCount > 3 && (
                            <div className="no-avatar avatar-sm avatar-total-member">
                              <span>{groupData.memberCount}+</span>
                            </div>
                          )}
                          {/* <div className="no-avatar avatar-sm">
                          <span>B</span>
                        </div>
                        <div className="no-avatar avatar-sm">
                          <span>J</span>
                        </div>
                        <div className="no-avatar avatar-sm">
                          <span>J</span>
                        </div>
                        <div className="no-avatar avatar-sm avatar-total-member">
                          <span>10+</span>
                        </div> */}
                        </div>
                        <div className="ml-3">
                          {groupData.memberCount} Active Members
                        </div>
                      </div>
                    </div>
                    <Divider orientation="left"></Divider>
                  </div>
                </Col>
              </Row>
            )}
            {clubFilter === 3 && (
              <Fragment>
                <ClubEvent Permissions={Permissions} viewingAs={viewingAs} />
              </Fragment>
            )}
            {clubFilter === 4 && (
              <Fragment>
                <ClubMembers
                  Permissions={Permissions}
                  groupName={groupData && groupData.name}
                  clubUserMemberShipType={
                    groupData &&
                    groupData.userMembership &&
                    groupData.userMembership.membershipType
                  }
                />
              </Fragment>
            )}
            {clubFilter === 5 && (
              <Fragment>
                <ClubModerators
                  Permissions={Permissions}
                  groupName={groupData && groupData.name}
                  clubUserMemberShipType={
                    groupData &&
                    groupData.userMembership &&
                    groupData.userMembership.membershipType
                  }
                  fetchClubDetails={fetchClubDetails}
                />
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getClubById,
  joinClub,
  leaveClub,
  handleClubProposal,
})(GroupPage);
