import { PageHeader } from "antd";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GroupPage from "../../member-app/groups/GroupPage";

const ClubPage = (props: any) => {
  let navigate = useNavigate();
  const location: any = useLocation();
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        {/* <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate(-1)}
          title={`${location.state.name}`}
        /> */}
        <div className="app-main-content">
          <div className="admin-page-header-wrapper-override">
            <GroupPage viewingAs="Admin" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, {})(ClubPage);
