import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
export const getStudentList = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Member/list?MemberTypes=2&SearchString=${params.SearchString}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

//add student
export const addStudentUser = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Member/student/`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Student User Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const addStudentUserBulk = (formData, params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Member/student/upload`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Student Users Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", "Check the CSV file", "warning"));
    }
    return response;
  }
};

export const updateStudentUser = (formData, memberId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };


    const res = await axiosApiInstance.put(
      `/api/Member/student/${memberId}`,
      formData,
      config
    );

    dispatch(
      setAlert("Success", "Student User Successfully updated", "success")
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      console.log('student update');
      console.log(response);
      console.log(err);

      
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};