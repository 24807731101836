import { Form, Input, Button, Select, Col, Row, InputNumber } from "antd";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { addSchool, updateSchool, deleteSchool } from "../../../actions/school";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";
import { timeZones } from "../../../helpers/timeZones";

const { Option } = Select;

const AddSchoolForm = (props: {
  addSchool: Function;
  updateSchool: Function;
  deleteSchool: Function;
}) => {
  const location: any = useLocation();
  const [addSchoolForm] = Form.useForm();
  const { addSchool, updateSchool, deleteSchool } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const onFinish = async (formData: any) => {
    setValidationMessages(new Array());
    setIsLoading(true);
    let res;
    if (isEditMode) {
      res = await updateSchool(
        {
          ...formData,
          contactNumber: formData.contactNumber.toString(),
          schoolId: location.state.schoolId,
        },
        location.state.schoolId
      );
    } else {
      res = await addSchool({
        ...formData,
        contactNumber: formData.contactNumber.toString(),
      });
    }
    if (res.status == OK || res.status == CREATED) {
      if (!isEditMode) {
        onReset();
      }
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  //deactivate
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteSchool(location.state.schoolId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
    } else if (res.status === BAD_REQUEST) {
      handleFormErrors(res.data);
    }
    setIsDeleteLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    addSchoolForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    if (location && location.state) {
      setIsEditMode(true);
      addSchoolForm.setFieldsValue({
        name: location?.state?.name,
        contactPerson: location?.state?.contactPerson,
        contactNumber: location?.state?.contactNumber,
        timeZoneId: location?.state?.timeZoneId,
        cultureInfo: location?.state?.cultureInfo,
      });
    }
  }, []);

  return (
    <Fragment>
      <ModalDeleteAlert
        modalTitle="School"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Row>
        <Col span={24}>
          <Form
            form={addSchoolForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert"
          >
            <h5 className="text-head pb-3">School Details</h5>
            <Row>
              <Col
                span={8}
                xs={{ span: 24 }}
                lg={{ span: 16 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  className="mb-0"
                  label={<span>School Name</span>}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input School Name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input School Name!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter School Name" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages && validationMessages.Name && "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Name && (
                    <AlertContainer data={validationMessages.Name} />
                  )}
                </div>
                <Form.Item
                  className="mb-0"
                  label={<span>Contact Person</span>}
                  name="contactPerson"
                  rules={[
                    {
                      required: true,
                      message: "Please input Contact Person!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Contact Person!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Contact Person" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.contactPerson &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.contactPerson && (
                    <AlertContainer data={validationMessages.contactPerson} />
                  )}
                </div>
                <Form.Item
                  className="mb-0"
                  label={<span>Contact Number</span>}
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input Contact Number!",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    placeholder="Enter Contact Number"
                  />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.contactNumber &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.contactNumber && (
                    <AlertContainer data={validationMessages.contactNumber} />
                  )}
                </div>
                <Form.Item
                  className="mb-0"
                  label={<span>TimeZone Id</span>}
                  name="timeZoneId"
                  rules={[
                    {
                      required: true,
                      message: "Please input Timezone ID!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Timezone ID!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Select placeholder="Enter Timezone ID">
                    {timeZones.map((timeZone: any) => (
                      <Option key={timeZone} value={timeZone}>
                        {timeZone}
                      </Option>
                    ))}
                  </Select>
                  {}
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.timeZoneId &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.timeZoneId && (
                    <AlertContainer data={validationMessages.timeZoneId} />
                  )}
                </div>
                <Form.Item
                  className="mb-0"
                  label={<span>Culture Info</span>}
                  name="cultureInfo"
                  rules={[
                    {
                      required: true,
                      message: "Please input Culture Info!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Culture Info!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input className="w-100" placeholder="Enter Culture Info" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.cultureInfo &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.cultureInfo && (
                    <AlertContainer data={validationMessages.cultureInfo} />
                  )}
                </div>
              </Col>
            </Row>

            <div
              className={`${
                validationMessages &&
                validationMessages.error &&
                "mb-4 has-error"
              }`}
            >
              {validationMessages && validationMessages.error && (
                <AlertContainer data={validationMessages} />
              )}
            </div>

            <Row gutter={24}>
              <Col span={24}>
                <div className="w-100 d-flex">
                  <Button
                    className="btn-breeze-box mr-2 btn-radius"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save School
                  </Button>
                  <Button
                    className="btn-radius"
                    disabled={isLoading}
                    onClick={onReset}
                  >
                    Reset Fields
                  </Button>
                  {isEditMode && (
                    <Button
                      className="ml-auto btn-radius"
                      disabled={isLoading}
                      onClick={() => setIsModalDeleteVisible(true)}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  addSchool,
  updateSchool,
  deleteSchool,
})(AddSchoolForm);
