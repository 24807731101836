import { Button, Col, Dropdown, Menu, PageHeader, Row } from "antd";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import BreezeCalendar from "../../calendar/BreezeCalendar";
import { MoreOutlined } from "@ant-design/icons";
const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const EventCalendar = () => {
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="Event Calendar"
          extra={[
            <Button key="back" type="primary">
              <Link to={"/admin/events/add"}>Add New Event</Link>
            </Button>,
            <Button key="calendar-view" type="primary">
              <Link to={"/admin/events"}>List View</Link>
            </Button>,
            <DropdownMenu key="more" />,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <BreezeCalendar eventsToday={[]} upcomingEvents={[]} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventCalendar;
