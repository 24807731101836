import { Col, Row, Button } from "antd";
import Doughnut from "../../widget/Doughnut";
import { connect } from "react-redux";
import flutterConnect from "../../../reducers/flutterConnect";

export const SoftSkillAttributes = (props: {
  chartData: any;
  myProfilePoints: any;
  metricType?: String;
  lgChartSize?: Boolean;
  legendHeaderOn?: Boolean;
  flutterConnect: Boolean;
}) => {
  const { chartData, myProfilePoints, metricType, lgChartSize, flutterConnect } = props;
  
  // var flutterConnect = true;
  
  return (
    <Row gutter={[40, 24]} className="skill-attributes">
      <Col
        className="gutter-row skill-chart"
        xs={{ span: flutterConnect ? undefined : 24 }}
        md={{ span: flutterConnect ? undefined : 12 }}
        lg={{ span: flutterConnect ? undefined : 12 }}
        xl={{ span: flutterConnect ? undefined : lgChartSize ? 11 : 8 }}
        span={flutterConnect ? 24 : undefined}
      >
        <div className="doughnut-wrapper">
          <div className="doughnut-container">
            {chartData && (
              <>
                <div className="total-percentage">
                  {/* {myProfilePoints &&
                  myProfilePoints.reduce(function (
                    sum: any,
                    current: { earnedPoints: any }
                  ) {
                    return (
                      sum +
                      parseFloat(current.earnedPoints)
                    );
                  },
                  0)} */}
                  {myProfilePoints && "100"}%
                </div>
                <Doughnut data={chartData} legend={false} />
              </>
            )}
          </div>
         
        </div>
      </Col>
      <Col
        className="gutter-row skill-detail"
        xs={{ span: flutterConnect ? undefined : 24 }}
        md={{ span: flutterConnect ? undefined : 12 }}
        lg={{ span: flutterConnect ? undefined : 12 }}
        xl={{ span: flutterConnect ? undefined : lgChartSize ? 13 : 16 }}
        span={flutterConnect ? 24 : undefined}
      >
        <div className="doughnut-wrapper h-100">
          <div className="doughnut-legend">
            <p className="point-label">Points/Hours</p>
            <ul>
              {myProfilePoints &&
                myProfilePoints.map((point: any) => {
                  return (
                    <li key={point.softskillId}>
                      <div
                        className="color-identifier"
                        style={{
                          background: point.color,
                        }}
                      ></div>
                      <h5>{point.softskill}</h5>
                      <div className="legend-percentage">
                        {metricType && metricType === "points"
                          ? point.earnedPointsFloat
                          : point.earnedPoints + "%"}
                      </div>
                    </li>
                  );
                })}
              {/* <li>
              <div className="color-identifier"></div>
              <h5>Professional Development</h5>
              <div className="legend-percentage">
                40%
              </div>
            </li>
            <li>
              <div className="color-identifier"></div>
              <h5>Global Engagement</h5>
              <div className="legend-percentage">
                20%
              </div>
            </li>
            <li>
              <div className="color-identifier"></div>
              <h5>Personal Development</h5>
              <div className="legend-percentage">
                20%
              </div>
            </li>
            <li>
              <div className="color-identifier"></div>
              <h5>People Leadership</h5>
              <div className="legend-percentage">
                20%
              </div>
            </li> */}
            </ul>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  member: state.auth?.member,
  flutterConnect: state.flutterConnect,
});

export default connect(mapStateToProps)(SoftSkillAttributes);