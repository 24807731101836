export const userPermissions: any = {
  Student: {
    createClub: true, //club proposal
    editClub: true, //edit club proposal
    canCreateIf: "Moderator", //connected
    deleteEvent: false, //connected
    canCreateIfJoinStatus: "Approved", //connected
    createEvent: true, //connected
  },
  Teacher: {
    createClub: false,
    editClub: false, //edit club proposal
    canCreateIf: "Moderator", //connected
    deleteEvent: true, //connected
    canCreateIfJoinStatus: "Approved", //connected
    createEvent: true, //connected
    inviteMember: true, //connected
  },
  Moderator: {
    createClub: true,
    editClub: true, //edit club proposal
    createEvent: true, //connected
    inviteMember: true, //connected
  },
  Admin: {
    createEvent: false,
    inviteMember: false,
  },
};
