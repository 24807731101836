import { Button, Carousel } from "antd";
import "../../assets/scss/components/announcement.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState, Fragment } from "react";
import { getPagedAnnouncements } from "../../actions/announcement";
import { OK } from "../../helpers/statuscodes";
import { connect } from "react-redux";
import moment from "moment";
export const Announcement = (props: { getPagedAnnouncements: Function }) => {
  const { getPagedAnnouncements } = props;
  const [announcementItems, setAnnouncementItems] = useState([]);
  const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(
    localStorage.getItem("isAnnouncementHidden") === "true" ? false : true
  );

  const fetchAnnouncements = async () => {
    let res = await getPagedAnnouncements({
      isSiteWide: false,
      isClubFiltered: true,
      isActive: true,
      page: 1,
      pageSize: 8,
    });

    if (res?.status === OK) {
      console.log(res.data.items);
      setAnnouncementItems(res.data.items);
    }

  };

  const ref: any = useRef();

  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <div className="announcement-wrapper">
      {(announcementItems && announcementItems.length > 0) && (
        <div className="position-relative">
          {!isAnnouncementVisible && (
            <Button
              className="mr-2 position-absolute showAnnouncement"
              onClick={() => {
                setIsAnnouncementVisible(true);
                localStorage.setItem("isAnnouncementHidden", "false");
              }}
            >
              Show Announcements
            </Button>
          )}

          <div
            className={`announcement-container anim-slide ${
              isAnnouncementVisible == true ? "open" : "close"
            }`}
          >
            <div className="announcement-header">
              <h3>Announcement</h3>
              <div className="d-flex">
                <Button
                  className="mr-2"
                  icon={
                    <LeftOutlined
                      onClick={() => {
                        ref.current?.prev();
                      }}
                    />
                  }
                  style={{display: announcementItems.length <= 1 ? 'none' :  '' }}
                />
                <Button
                  className="mr-2"
                  icon={<RightOutlined />}
                  style={{display: announcementItems.length <= 1 ? 'none' :  '' }}
                  onClick={() => {
                    ref.current?.next();
                  }}
                />
                <Button
                  className="mr-2"
                  onClick={() => {
                    setIsAnnouncementVisible(false);
                    localStorage.setItem("isAnnouncementHidden", "true");
                  }}
                >
                  Hide
                </Button>
              </div>
            </div>
            <div>
              <Carousel ref={ref} afterChange={onChange}>
                {announcementItems &&
                  announcementItems.map((item: any) => {
                    return (
                      <div className="carousel-item">
                        <div className="d-flex d-flex-column">
                          <h1 className="mt-3">{item.title && item.title}</h1>
                          <p>{item.body && item.body}</p>
                          <div className="mt-2 d-none">
                            <Button>Show More</Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getPagedAnnouncements })(Announcement);
