import { Form, Input, Button, Checkbox, Select, Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  addSchoolUser,
  updateAdminUser,
  getSchoolList,
  deleteMember,
} from "../../../actions/school";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";

const { Option } = Select;

const AddUserForm = (props: {
  addSchoolUser: Function;
  updateAdminUser: Function;
  getSchoolList: Function;
  deleteMember: Function;
}) => {
  const location: any = useLocation();
  const [addUserForm] = Form.useForm();
  const { addSchoolUser, updateAdminUser, getSchoolList, deleteMember } = props;
  const [schoolList, setSchoolList]: any = useState([]);
  const [isEditMode, setIsEditMode]: any = useState(false);

  const [validationMessages, setValidationMessages]: any = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSchoolList, setIsLoadingSchoolList] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const onFinish = async (values: any) => {
    setValidationMessages(new Array());
    setIsLoading(true);
    let res;
    if (isEditMode) {
      let customFormData = { ...values, memberType: 0 };
      res = await updateAdminUser(customFormData, location.state.memberId);
    } else {
      res = await addSchoolUser(values);
    }

    if (res.status == OK || res.status == CREATED) {
      onReset();
    } else if (res.status === BAD_REQUEST) {
      // console.log(res.data);
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  //deactivate school admin
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteMember(location.state.memberId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
    }
    setIsDeleteLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    addUserForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    const fetchSchoolList = async () => {
      setIsLoadingSchoolList(true);
      const data = await getSchoolList();
      setSchoolList(data.data);
      setIsLoadingSchoolList(false);

      if (location && location.state) {
        setIsEditMode(true);
        addUserForm.setFieldsValue({
          firstname: location?.state?.firstName,
          lastname: location?.state?.lastName,
          EmailAddress: location?.state?.emailAddress,
          schoolId: location?.state?.schoolId,
          jobTitle: location?.state?.jobTitle,
        });
      }
    };

    fetchSchoolList();
  }, []);

  return (
    <Fragment>
      <ModalDeleteAlert
        modalTitle="School Admin"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Row>
        <Col span={24}>
          <Form
            form={addUserForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert"
          >
            <Row>
              <Col
                span={8}
                xs={{ span: 24 }}
                lg={{ span: 16 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="schoolId"
                  label="School"
                  rules={[
                    { required: true, message: "Please select your school!" },
                  ]}
                >
                  <Select
                    disabled={isLoadingSchoolList}
                    placeholder={
                      isLoadingSchoolList
                        ? "Loading Schools..."
                        : "Select School"
                    }
                    allowClear
                  >
                    {schoolList &&
                      schoolList.map((data: any) => {
                        return (
                          <Option key={data.schoolId} value={data.schoolId}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Row justify="space-between" gutter={24}>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      className="mb-0"
                      label="First Name"
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              //resolve to use original rule
                              return Promise.resolve();
                            }
                            if (!isEmptyOrSpaces(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error("Please input your First Name!")
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="Enter Given Name" />
                    </Form.Item>
                    <div
                      className={`mb-4 ${
                        validationMessages &&
                        validationMessages.FirstName &&
                        "has-error"
                      }`}
                    >
                      {validationMessages && validationMessages.FirstName && (
                        <AlertContainer data={validationMessages.FirstName} />
                      )}
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      className="mb-0"
                      label="Last Name"
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              //resolve to use original rule
                              return Promise.resolve();
                            }
                            if (!isEmptyOrSpaces(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error("Please input your Last Name!")
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="Enter Family Name" />
                    </Form.Item>
                    <div
                      className={`mb-4 ${
                        validationMessages &&
                        validationMessages.LastName &&
                        "has-error"
                      }`}
                    >
                      {validationMessages && validationMessages.LastName && (
                        <AlertContainer data={validationMessages.LastName} />
                      )}
                    </div>
                  </Col>
                </Row>
                <Form.Item
                  className="mb-0"
                  label="School Email"
                  name="EmailAddress"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The email you entered is invalid!",
                    },
                  ]}
                >
                  <Input placeholder="eg johndoe@xyz.edu.ph " />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.EmailAddress &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.EmailAddress && (
                    <AlertContainer data={validationMessages.EmailAddress} />
                  )}
                </div>
                <Form.Item
                  className="mb-0"
                  label="Job Title"
                  name="jobTitle"
                  // {...(validationMessages && validationMessages.JobTitle
                  //   ? { validateStatus: "error" }
                  //   : "")}
                  // {...(validationMessages && validationMessages.JobTitle
                  //   ? { help: validationMessages.JobTitle }
                  //   : "")}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Job Title!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input your Job Title!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.JobTitle &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.JobTitle && (
                    <AlertContainer data={validationMessages.JobTitle} />
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <div className="w-100 d-flex">
                  <Button
                    className="btn-breeze-box mr-2"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save User Info
                  </Button>
                  <Button disabled={isLoading} onClick={onReset}>
                    Reset Fields
                  </Button>
                  {isEditMode && (
                    <Button
                      className="ml-auto"
                      disabled={isLoading}
                      onClick={() => setIsModalDeleteVisible(true)}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  addSchoolUser,
  updateAdminUser,
  getSchoolList,
  deleteMember,
})(AddUserForm);
