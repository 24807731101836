import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import firebase from "./firebase";
import pushData from "./pushData";
import userIsBack from "./userIsBack";
import flutterConnect from "./flutterConnect";
import recentLogout from "./recentLogout";

export default combineReducers({
  alert,
  auth,
  firebase,
  pushData,
  userIsBack,
  flutterConnect,
  recentLogout
});
