import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment, { Moment } from "moment";
import {
  Skeleton,
  Menu,
  Button,
  MenuProps,
  Dropdown,
  Spin,
  Row,
  Col,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { searchUpcomingEvents, deleteInvite } from "../../../actions/event";
import { NO_CONTENT, OK } from "../../../helpers/statuscodes";

import {
  EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import noEvents from "../../../assets/images/icons/no_events.svg";
import clubIcon from "../../../assets/images/icons/club-icon.svg";
import flutterConnect from "../../../reducers/flutterConnect";

const DashboardUpcomingEvents = (props: {
  searchUpcomingEvents: Function;
  deleteInvite: Function;
  flutterConnect: Boolean;
}) => {
  const {
    deleteInvite,
    searchUpcomingEvents,
    flutterConnect
  } = props;
  const today = new Date().getDay().toString();
  const [selectedDay, setSelectedDay] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [events, setEvents] = useState<any[]>([]);

  // var flutterConnect = true;

  let leftMenuItems = [
    {
      key: "1",
      label: <p>Monday</p>,
      disabled: today > "1",
    },
    {
      key: "2",
      label: <p>Tuesday</p>,
      disabled: today > "2",
    },
    {
      key: "3",
      label: <p>Wednesday</p>,
      disabled: today > "3",
    },
    {
      key: "4",
      label: <p>Thursday</p>,
      disabled: today > "4",
    },
    {
      key: "5",
      label: <p>Friday</p>,
      disabled: today > "5",
    },
    {
      key: "6",
      label: <p>Saturday</p>,
      disabled: today > "6",
    },
    {
      key: "0",
      label: <p>Sunday</p>,
    },
  ];

  const fetchEvents = async (page: Number, sort?: boolean) => {
    return await searchUpcomingEvents(
      {
        page: page,
        pageSize: 9,
        searchString: "",
        schoolEventsOnly: false,
        isDesc: sort ? sort : false,
        MyEventsOnly: true,
        from: moment()
          .startOf("week")
          .add(parseInt(selectedDay), "day")
          .startOf("day")
          .toISOString(),
        to: moment()
          .startOf("week")
          .add(parseInt(selectedDay), "day")
          .endOf("day")
          .toISOString(),
        InterestIds: null,
        memberGroupIds: null,
        Statuses: "0,1,2", //myEvents - accepted or declined      [0,1,2] === ["pending", "accepted", "declined"]
      },
      controller,
      //NOTE: Flutter
      flutterConnect
    );
  };

  const controller = new AbortController();

  const fetchEventsToday = async () => {
    var result: any;
    let page = 1;
    setIsLoading(true);
    result = await fetchEvents(page, false);
    if (result && result?.data) {
      setIsLoading(false);
      setEvents([...result?.data?.items,]);
    }
  };

  const leaveEvent = async (data: any) => {
    setIsLeaving(true);
    const result = await deleteInvite({
      eventId: data?.eventId,
      inviteId: data?.userInvite?.inviteId,
    });

    if (result.status === NO_CONTENT) {
      setIsLeaving(false);
    }
  };

  useEffect(() => {
    if (!isLeaving) {
      fetchEventsToday();
    }
  }, [selectedDay, isLeaving]);

  const Event = (data: any) => {
    let date = moment(data?.data?.startDate).format("ll");
    let time = `${moment(data?.data?.startDate).format("LT")} to ${moment(
      data?.data?.endDate
    ).format("LT")}`;

    return (
      <div className="event">
        <div className="event-header">
          <Dropdown
            className="dropdown"
            key="more"
            overlay={
              <Menu>
                <Menu.Item key="leave">
                  <a onClick={() => leaveEvent(data?.data)}>Leave</a>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <EllipsisOutlined />
          </Dropdown>
        </div>
        {data?.data?.bannerFileUrl ? (
          <img
            src={data?.data?.bannerFileUrl}
            alt={"banner"}
            className="image-banner"
          />
        ) : (
          <div className="image-placeholder" />
        )}
        <div className="event-information">
          <h3 className="event-title">{data?.data?.title ?? "-"}</h3>
          <p className="event-details">
            {date} {time} - {data?.data?.location ?? ""}
          </p>
          <p className="event-club">
            {data?.data?.isSchoolWideEvent ? (
              <span>School Wide Event</span>
            ) : (
              <>
                <img height={28} width={28} src={clubIcon} />
                <span>
                  {"  "}
                  {data?.data?.memberGroup ?? ""}
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    );
  };

  const onClick: MenuProps["onClick"] = (e) => {
    setSelectedDay(e.key);
  };

  const selectedMenu = !!selectedDay
    ? leftMenuItems[
        selectedDay === "0"
          ? leftMenuItems.length - 1
          : parseInt(selectedDay) - 1
      ]
    : null;

  const navigateDay = (step: number) => {
    if (!selectedMenu) return;

    let index = leftMenuItems.indexOf(selectedMenu);
    index += step;
    index =
      index < 0
        ? leftMenuItems.length - 1
        : index >= leftMenuItems.length
        ? 0
        : index;

    setSelectedDay(leftMenuItems[index].key);
  };

  return (
    <div
      className={`dashboard-upcoming-events`}
    >
      <h1>My Upcoming Events</h1>

      {/* TODO: Turn into component */}
      {flutterConnect && (
        <div className="navigation">
          <div className="navigation-unary">
            <Button
              className="arrow"
              icon={<LeftOutlined onClick={() => {}} />}
              onClick={() => {
                navigateDay(-1);
              }}
            />
            <div className="label">
              {selectedMenu ? selectedMenu.label : ""}
            </div>
            <Button
              className="arrow"
              icon={<RightOutlined onClick={() => {}} />}
              onClick={() => {
                navigateDay(1);
              }}
            />
          </div>
          <div className="navigation-menu">
            <Menu
              className="menus"
              mode="horizontal"
              selectedKeys={[selectedDay]}
              items={leftMenuItems}
              onClick={onClick}
            />
          </div>
        </div>
      )}

      {!flutterConnect && (
        <Menu
          className="menus"
          mode="horizontal"
          selectedKeys={[selectedDay]}
          items={leftMenuItems}
          onClick={onClick}
        />
      )}
      <div className="events">
        {isLeaving && <Spin className="spin-overlay" size="large" />}
        {isLoading && <Skeleton loading={true} active />}
        {!isLoading &&
          events.length > 0 &&
          events.map((event) => <Event data={event} />)}
        {!isLoading && events.length === 0 && (
          <div className="no-events-container">
            <img height={120} width={120} src={noEvents} />
            <h4>No events today.</h4>
          </div>
        )}
      </div>
      <Link to="/events">
        <Button type="default" shape="default" key="submit" className="view-all">
          View All Events
        </Button>
      </Link>
    </div>
  );
};

const mapStateToProps = (state: {
  auth: any;
  userIsBack: boolean;
  flutterConnect: boolean;
}) => ({
  interests: state.auth.member.interests,
  userIsBack: state.userIsBack,
  flutterConnect: state.flutterConnect,
});
export default connect(mapStateToProps, {
  deleteInvite,
  searchUpcomingEvents,
})(DashboardUpcomingEvents);
