import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Spin,
  Radio,
  Upload
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { MoreOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import headerBannerDefault from "../../../assets/images/banner-default.jpg";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { getModeratorList } from "../../../actions/moderator";

import {
  GlobalOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  EllipsisOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { getClubById } from "../../../actions/club";
import { connect } from "react-redux";
import PageHeader from "../layouts/PageHeader";
import { updateClub } from "../../../actions/club";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import AlertContainer from "../../../layouts/AlertContainer";
import { Closed, Private, Public } from "../../../helpers/privacyTypes";
const { Option } = Select;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const EditGroup = (props: {
  getClubById: Function;
  updateClub: Function;
  getModeratorList: Function;
}) => {
  const [editClubForm] = Form.useForm();
  const { getClubById, updateClub, getModeratorList } = props;
  const { memberGroupId } = useParams();
  let navigate = useNavigate();
  const [groupData, setGroupData] = useState<any>([]);
  const [moderatorList, setModeratorList] = useState<any[]>([]);
  const [clubModeratorList, setClubModeratorList] = useState<any>([]);
  const [isModeratorsValid, setIsModeratorsValid] = useState(true);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [clubType, setClubType] = useState(0);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [fileRead, setFileRead]: any = useState();
  const [imageBlob, setImageBlob]: any = useState();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const readFile = async (file: any) => {
    var reader = new FileReader();
    reader.onload = await function () {
      setImageBlob(reader.result);
      return reader.result;
    };
    reader.readAsDataURL(file);
  };

  const onFinish = async (formData: any) => {
    setIsLoading(true);
    let moderatorIds = clubModeratorList.map((mod: { memberId: string }) => {
      return mod.memberId;
    });
    // var reader = new FileReader();
    // console.log(reader.readAsText(file));

    const fileData = new FormData();
    // fileList.forEach((file) => {
    //   fileData.append("file", file as RcFile);
    // });
    // fileData.append("moderatorIds", moderatorIds);
    // fileData.append("privacyType", clubType.toString());

    // fileData.append("description", formData.description);
    // fileData.append("name", formData.name);

    setIsModeratorsValid(true);
    let values = {
      ...groupData,
      ...formData,
      moderators: moderatorIds,
      privacyType: clubType,
      file: imageBlob
    };

    setValidationMessages(new Array());
    let res;
    res = await updateClub(values, memberGroupId);
    if (res.status == OK || res.status == CREATED) {
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const handleSelect = (memberId: string, e: any) => {
    editClubForm.setFieldsValue({
      moderator: null
    });
    if (memberId && e) {
      const checkMemberId = (obj: { memberId: string }) =>
        obj.memberId === memberId;
      if (!clubModeratorList.some(checkMemberId)) {
        setClubModeratorList((prevModerator: any) => [
          ...prevModerator,
          { memberId, name: e.children }
        ]);
      }
    }
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const removeClubModerator = (memberId: string) => {
    let tempMod = clubModeratorList.filter((mod: { memberId: string }) => {
      return mod.memberId != memberId;
    });
    setClubModeratorList(tempMod);
  };

  const onReset = () => {
    setValidationMessages([]);
    setClubModeratorList([]);
    editClubForm.resetFields();
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // setFileList(newFileList);
    // console.log(newFileList);
    // setFileRead(newFileList.file);
  };

  const bannerProps: UploadProps = {
    beforeUpload: (file) => {
      setFileList([file]);
      setFileRead(file);
      readFile(file);

      return false;
    }
  };

  const getPrivacyTypeValue = (string: String) => {
    if (string === "Public") {
      return Public;
    } else if (string === "Private") {
      return Private;
    } else if (string === "Close") {
      return Closed;
    }
  };

  useEffect(() => {
    setSearching(true);
    const delaySearch = setTimeout(async () => {
      console.log(searchTerm);
      if (searchTerm && searchTerm != "") {
        let params = {
          memberType: editClubForm.getFieldValue("memberType"),
          SearchString: searchTerm
        };
        const data = await getModeratorList(params);
        let datax = data.data.map(
          (member: {
            memberId: string;
            firstName: string;
            lastName: string;
          }) => ({
            label: `${member.firstName} ${member.lastName}`,
            value: member.memberId
          })
        );
        setModeratorList(datax);
      } else {
        setModeratorList([]);
      }
      setSearching(false);
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  useEffect(() => {
    // get event with event id

    const fetchEventList = async () => {
      let res = await getClubById({
        clubId: memberGroupId
      });
      if (res.data) {
        setGroupData(res.data);
        editClubForm.setFieldsValue({
          description: res.data.description,
          name: res.data.name,
          moderators: res.data.moderators
        });
        let clubTypeTemp: any = getPrivacyTypeValue(res.data.privacyType);
        setClubType(clubTypeTemp);
        let arraYMod: any = [];
        res.data.moderators.forEach((item: any) => {
          arraYMod.push({
            memberId: item.memberId,
            name: item.firstName + " " + item.lastName
          });
        });
        setClubModeratorList(arraYMod);
      }
    };

    fetchEventList();
  }, []);

  const options =
    moderatorList &&
    moderatorList.map((d) => <Option key={d.value}>{d.label}</Option>);
  return (
    <Fragment>
      <PageHeader
        className="pt-3"
        title={groupData && groupData.name}
        header={
          <div className="d-flex">
            <Button
              className="mr-1"
              type="primary"
              shape="round"
              style={{ height: 40 }}
              onClick={() => editClubForm.submit()}
              loading={isLoading}
            >
              Update
            </Button>

            <Button
              loading={isLoading}
              shape="round"
              style={{ height: 40 }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <div className="pb-2"></div>
      </PageHeader>

      <div className="group-page-single-content-wrapper">
        <div className="group-page-edit-content">
          <Col span={12} xs={{ span: 20 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form
              form={editClubForm}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="breeze-form-1 custom-alert"
            >
              <h5 className="text-head pb-3">Edit Club Details</h5>

              <div
                className={`${
                  validationMessages &&
                  validationMessages.error &&
                  "mb-4 has-error"
                }`}
              >
                {validationMessages && validationMessages.error && (
                  <AlertContainer data={validationMessages} />
                )}
              </div>
              <Row justify="space-between" gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-0"
                    label="Club Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Club Name!"
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            //resolve to use original rule
                            return Promise.resolve();
                          }
                          if (!isEmptyOrSpaces(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("Please input Club Name!")
                            );
                          }
                        }
                      })
                    ]}
                  >
                    <Input placeholder="Enter Club Name" />
                  </Form.Item>
                  <div
                    className={`mb-4 ${
                      validationMessages &&
                      validationMessages.Name &&
                      "has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.Name && (
                      <AlertContainer data={validationMessages.Name} />
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <div className="ant-col ant-form-item-label">
                    <label
                      htmlFor="moderator"
                      className="ant-form-item-required"
                      title="Moderator"
                    >
                      Moderator
                    </label>
                  </div>
                  <div className="d-flex w-100">
                    <Form.Item
                      className="mb-1 p-relative w-100"
                      name="moderator"
                      rules={[
                        () => ({
                          validator(_, value) {
                            console.log(clubModeratorList.length);
                            if (clubModeratorList.length != 0) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error("Please select Moderator!")
                              );
                            }
                          }
                        })
                      ]}
                      // {...(clubModeratorList.length == 0 && reset != true
                      //   ? { validateStatus: "error" }
                      //   : "")}
                      // {...(clubModeratorList.length == 0 && reset != true
                      //   ? { help: "Please select Moderator" }
                      //   : "")}
                    >
                      <Select
                        showSearch
                        showArrow={false}
                        filterOption={false}
                        autoClearSearchValue={true}
                        allowClear
                        onSearch={(searchString: string) =>
                          setSearchTerm(searchString)
                        }
                        onSelect={handleSelect}
                        notFoundContent={<div>Not found</div>}
                        placeholder="Select Moderators"
                      >
                        {options}
                      </Select>
                    </Form.Item>
                    {searching ? (
                      <div className="select-search">
                        <Spin />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <ul className="form-ul">
                    {clubModeratorList &&
                      clubModeratorList.map((data: any) => {
                        return (
                          <li key={data.memberId} className="fadein">
                            {data.name}
                            <span
                              onClick={() => removeClubModerator(data.memberId)}
                            >
                              <DeleteOutlined />
                            </span>
                          </li>
                        );
                      })}
                  </ul>

                  <Form.Item name="clubType" label="Club Type">
                    <Button
                      className={`${clubType === 0 && "btn-active"}`}
                      onClick={() => setClubType(0)}
                    >
                      Public
                    </Button>
                    <Button
                      className={`${clubType === 1 && "btn-active"}`}
                      onClick={() => setClubType(1)}
                    >
                      Private
                    </Button>
                    <Button
                      className={`${clubType === 2 && "btn-active"}`}
                      onClick={() => setClubType(2)}
                    >
                      Closed
                    </Button>
                  </Form.Item>

                  <div
                    className={`${
                      validationMessages &&
                      validationMessages.PrivacyType &&
                      "mb-4 has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.PrivacyType && (
                      <AlertContainer data={validationMessages.PrivacyType} />
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Club Description"
                    className="pt-2 mb-2"
                    name="description"
                  >
                    <TextArea rows={4} placeholder="" />
                  </Form.Item>

                  <div
                    className={`${
                      validationMessages &&
                      validationMessages.Description &&
                      "mb-4 has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.Description && (
                      <AlertContainer data={validationMessages.Description} />
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={16} className="mt-3">
                <Col span={24}>
                  <label title="Club Description">Banner Image</label>
                  <div
                    className="banner-img-preview"
                    style={{
                      backgroundImage: `url(${
                        fileRead
                          ? URL.createObjectURL(fileRead)
                          : groupData.bannerFileUrl
                      })`
                    }}
                  >
                    <>
                      <Upload onChange={onChange} {...bannerProps} accept="image/png, image/jpeg">
                        <Button icon={<UploadOutlined />}>Choose File</Button>
                      </Upload>
                    </>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getClubById, updateClub, getModeratorList })(
  EditGroup
);
