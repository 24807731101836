import { Button, Modal } from "antd";
import successIcon from "../../../assets/images/icons/success-icon.svg";
import { CloseCircleOutlined } from "@ant-design/icons";
export const ModalSuccess = (props: {
  children: any;
  isOpen: boolean;
  setIsOpen: Function;
  type?: string;
}) => {
  const { isOpen, setIsOpen, children, type } = props;

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      centered
      className="modal-action-success"
      visible={isOpen}
      onCancel={onCancelHandler}
      footer={[]}
    >
      <div className="d-flex justify-content-center w-100">
        {type === "failed" ? (
          <CloseCircleOutlined style={{ fontSize: 48, color: "red" }} />
        ) : (
          <img src={successIcon} height="96px" width="96px" />
        )}
      </div>
      <div className="d-flex-column w-100 justify-content-center">
        <h4 className="text-center">
          {type === "failed" ? "Failed!" : "Success!"}
        </h4>
        {props.children}
      </div>
    </Modal>
  );
};

export default ModalSuccess;
