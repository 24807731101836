import axios from "axios";
import { Form, Button, Input, Spin, Row, Col } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

import breezelogosm from "./../../assets/images/logos/breeze-logo-blue.svg";
import breezeHorizontalLogo from "../../assets/images/logos/breeze-horizontal.svg";
import { BAD_REQUEST, OK } from "../../helpers/statuscodes";
import AlertContainer from "../../layouts/AlertContainer";

const ResetPassword = (props: any) => {
  const { onRequestResetPassword } = props;
  const [isLoading, setIsloading] = useState(false);
  const [validationStatus, setValidationStatus]: any = useState("");
  const [validationMessages, setValidationMessages]: any = useState([]);
  const onFinish = (values: any) => {
    setValidationMessages(new Array());
    setIsloading(true);
    requestResetPassword(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const requestResetPassword = async (formData: any) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `/api/Account/forgot-password`,
        formData,
        config
      );
      if (res.status === OK) {
        onRequestResetPassword(res.data);
        return;
      }
    } catch (err: any) {
      if (err.response.status === BAD_REQUEST) {
        setValidationMessages({
          message: err.response.data.error,
        });
      }
    }

    setIsloading(false);
  };

  const clearValidation = () => {
    validationMessages(new Array());
    setValidationStatus("");
  };

  return (
    <Row>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 10, offset: 7 }}
        xxl={{ span: 8, offset: 7 }}
      >
        <div className="form-container">
          <h2 className="form-title">Forgot your password?</h2>
          <h5 className="sub-title">
            We’ll help you reset it and get back on track.
          </h5>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            className="custom-alert"
          >
            <div
              className={`mb-3 force-alert ${
                validationMessages != 0 && "has-error"
              }`}
            >
              {validationMessages.length != 0 && (
                <AlertContainer data={validationMessages} />
              )}
            </div>
            <label className="mb-2">School Email Address</label>

            <Form.Item
              name="emailAddress"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The email you entered is invalid",
                },
              ]}
            >
              <Input className="mt-1" placeholder="johndoe@lpu.edu.ph" />
            </Form.Item>
            <Form.Item>
              <Button
                className="btn-breeze-box-radius mt-2"
                disabled={isLoading}
                htmlType="submit"
                loading={isLoading}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
