import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Skeleton, Button, Menu, MenuProps } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { getFacultyMembers } from "../../../actions/dashboard";
import { UserOutlined } from "@ant-design/icons";
import flutterConnect from "../../../reducers/flutterConnect";

const FacultyMembers = (props: { getFacultyMembers: Function; flutterConnect: Boolean; }) => {
  const { getFacultyMembers, flutterConnect } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState("Active");
  const [facultyMemberList, setFacultyMemberList] = useState<any[]>([]);

  let leftMenuItems = [
    {
        key: 'Active',
        label: <p>Active</p>,
    },
    {
        key: 'Inactive',
        label: <p>Inactive</p>,
    },
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    setSelected(e.key);
  };

  const fetchList = async () => {
    setIsLoading(true);
    let params = {
      type: selected==="Active"?3:4,
    };
    const res = await getFacultyMembers(params);
    setFacultyMemberList(res.data.items);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, [selected]);

  const FacultyMember = (data: any) => {
    if (flutterConnect)
      return (
        <div>
          <div className="faculty mobile">
            <div className="start-group">
              <div className="invite-image-wrapper">
                {data?.data?.profilePicUrl ? (
                  <img
                    src={data?.data?.profilePicUrl}
                    alt={"profile_pic"}
                    className="invite-image"
                  />
                ) : (
                  <div className="invite-image">
                    <UserOutlined />
                  </div>
                )}
              </div>
            </div>
            <div className="end-group">
              <p className="faculty-name">{data?.data?.fullName ?? ""}</p>
              <p className="faculty-info">
                  <span>Total Events Hours : </span>
                  {data?.data?.eventHours ?? ""}
              </p>
              {/* <p className="faculty-info">
                  <span>Total Hours : </span>
                  {data?.data?.totalHours ?? ""}
              </p> */}
              <p className="faculty-info">
                  <span>Total  Clubs : </span>
                  {data?.data?.totalClubs ?? ""}
              </p>
            </div>
          </div>
          <div className="faculty">
            <div className="start-group">
              <div className="invite-image-wrapper">
                {data?.data?.profilePicUrl ? (
                  <img
                    src={data?.data?.profilePicUrl}
                    alt={"profile_pic"}
                    className="invite-image"
                  />
                ) : (
                  <div className="invite-image">
                    <UserOutlined />
                  </div>
                )}
              </div>
              
              <div className="text">
                <h3>{data?.data?.fullName ?? ""}</h3>
                <p>{data?.data?.jobTitle ?? ""}</p>
              </div>
            </div>
            <div className="end-group">
              <h1>{data?.data?.eventHours ?? ""}</h1>
              <h1>{data?.data?.totalHours ?? ""}</h1>
              <h1>{data?.data?.totalClubs ?? ""}</h1>
            </div>
          </div>
        </div>
    );

    return (
      <div className="faculty">
        <div className="start-group">
          <div className="invite-image-wrapper">
            {data?.data?.profilePicUrl ? (
              <img
                src={data?.data?.profilePicUrl}
                alt={"profile_pic"}
                className="invite-image"
              />
            ) : (
              <div className="invite-image">
                <UserOutlined />
              </div>
            )}
          </div>
          
          <div className="text">
            <h3>{data?.data?.fullName ?? ""}</h3>
            <p>{data?.data?.jobTitle ?? ""}</p>
          </div>
        </div>
        <div className="end-group">
          <h1>{data?.data?.eventHours ?? ""}</h1>
          <h1>{data?.data?.totalHours ?? ""}</h1>
          <h1>{data?.data?.totalClubs ?? ""}</h1>
        </div>
      </div>
    );
  };

  if (flutterConnect)
    return (
      <div className="dashboard-faculty">
        <div className="header">
          <h1>Faculty Members</h1>
          
          <div className="menu-wrapper app">
                  <Menu
                      className="menus"
                      mode="horizontal"
                      selectedKeys={[selected]}
                      items={leftMenuItems}
                      onClick={onClick}
                      disabledOverflow={true}
                  />
              </div>
              <div className="menu-wrapper">
                  <Menu
                      className="menus"
                      mode="horizontal"
                      selectedKeys={[selected]}
                      items={leftMenuItems}
                      onClick={onClick}
                  />
              </div>
        </div>
        <div className="faculty-members">
        {!isLoading && facultyMemberList.length > 0 &&
          <div className="point-labels">
            <div className="start-group">
            </div>
            <div className="end-group">
              <p>Hours of Events Created</p>
              <p>Total Time Spent</p>
              <p>Total Clubs</p>
            </div>
            
          </div>}
          {isLoading && (
            <div className="loading-container">
              <Skeleton loading={true} active />
              <Skeleton loading={true} active />
              <Skeleton loading={true} active />
            </div>
          )}
          {!isLoading &&
            facultyMemberList.length > 0 &&
            facultyMemberList
              .slice(0, 5)
              .map((facultyMember) => (
                <FacultyMember
                  data={facultyMember}
                  key={facultyMember?.memberId}
                />
              ))}

          {!isLoading && facultyMemberList.length === 0 && (
            <div className="no-events-container">
              {/* <img height={120} width={120} src={noEventInvites} />  */}
              <h4>No {selected} Faculty Members.</h4>
            </div>
          )}
        </div>
        <Link to={`/admin/teachers?status=${selected==="Active"?1:2}`}>
        <Button
          type="default"
          shape="default"
          key="submit"
          className="view-all"
        >
          View all {selected.toLowerCase()} Teachers
        </Button>
        </Link>
      </div>
    );

  return (
    <div className="dashboard-faculty">
      <div className="header">
        <h1>Faculty Members</h1>
        
        <div className="menu-wrapper app">
                <Menu
                    className="menus"
                    mode="horizontal"
                    selectedKeys={[selected]}
                    items={leftMenuItems}
                    onClick={onClick}
                    disabledOverflow={true}
                />
            </div>
            <div className="menu-wrapper">
                <Menu
                    className="menus"
                    mode="horizontal"
                    selectedKeys={[selected]}
                    items={leftMenuItems}
                    onClick={onClick}
                />
            </div>
      </div>
      <div className="faculty-members">
      {!isLoading && facultyMemberList.length > 0 &&
        <div className="point-labels">
          <p>Hours of Events Created</p>
          <p>Total Time Spent</p>
          <p>Total Clubs</p>
        </div>}
        {isLoading && (
          <div className="loading-container">
            <Skeleton loading={true} active />
            <Skeleton loading={true} active />
            <Skeleton loading={true} active />
          </div>
        )}
        {!isLoading &&
          facultyMemberList.length > 0 &&
          facultyMemberList
            .slice(0, 5)
            .map((facultyMember) => (
              <FacultyMember
                data={facultyMember}
                key={facultyMember?.memberId}
              />
            ))}

        {!isLoading && facultyMemberList.length === 0 && (
          <div className="no-events-container">
             {/* <img height={120} width={120} src={noEventInvites} />  */}
            <h4>No {selected} Faculty Members.</h4>
          </div>
        )}
      </div>
      <Link to={`/admin/teachers?status=${selected==="Active"?1:2}`}>
      <Button
        type="default"
        shape="default"
        key="submit"
        className="view-all"
      >
        View all {selected.toLowerCase()} Teachers
      </Button>
      </Link>
    </div>
  );
};

const mapStateToProps = (state: { flutterConnect: boolean; }) => ({
  flutterConnect: state.flutterConnect
});

export default connect(mapStateToProps, {
  getFacultyMembers,
})(FacultyMembers);
