import { Button, Col, Form, Input, Row } from "antd";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import AlertContainer from "../../../layouts/AlertContainer";
import { changeProfilePassword } from "../../../actions/profile";
import { BAD_REQUEST, NO_CONTENT, OK } from "../../../helpers/statuscodes";
import infoCircleIcon from "../../../assets/images/icons/info-circle.svg";
import PasswordRequirements from "../../layout/PasswordRequirement";

const ChangePasswordForm = (props: { changeProfilePassword: Function }) => {
  const { changeProfilePassword } = props;

  const [changePasswordForm] = Form.useForm();
  const onFinish = async (formData: any) => {
    setValidationMessages(new Array());
    setIsLoading(true);
    let res = await changeProfilePassword(formData);

    if (res.status == OK || res.status == NO_CONTENT) {
      onReset();
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [validationMessages, setValidationMessages]: any = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const onReset = () => {
    setValidationMessages([]);
    changePasswordForm.resetFields();
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  return (
    <Fragment>
      <div className="d-flex mb-4">
        <h1 className="section-title">Security</h1>
      </div>
      <Form
        form={changePasswordForm}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="breeze-form-1 custom-alert"
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="mb-0"
              label="Current Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      //resolve to use original rule
                      return Promise.resolve();
                    }
                    if (!isEmptyOrSpaces(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Please input your current password!")
                      );
                    }
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Enter Current Password" />
            </Form.Item>
            <div
              className={`mb-4 ${
                validationMessages && validationMessages.password && "has-error"
              }`}
            >
              {validationMessages && validationMessages.password && (
                <AlertContainer data={validationMessages.password} />
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 12 }}>
            <PasswordRequirements fieldName="Password" />
            <Form.Item
              className="mb-0 pt-2"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      //resolve to use original rule
                      return Promise.resolve();
                    }
                    if (!isEmptyOrSpaces(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Please input your new password!")
                      );
                    }
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Enter New Pasword" />
            </Form.Item>
            <div
              className={`mb-4 ${
                validationMessages &&
                validationMessages.NewPassword &&
                "has-error"
              }`}
            >
              {validationMessages && validationMessages.NewPassword && (
                <AlertContainer data={validationMessages.NewPassword} />
              )}
            </div>
          </Col>
          <Col span={8} xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 12 }}>
            <PasswordRequirements fieldName="Confirm Password" />
            <Form.Item
              className="mb-0 pt-2"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The password you submitted does not match.")
                    );
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Enter New Pasword" />
            </Form.Item>
            <div
              className={`mb-4 ${
                validationMessages &&
                validationMessages.confirmPassword &&
                "has-error"
              }`}
            >
              {validationMessages && validationMessages.confirmPassword && (
                <AlertContainer data={validationMessages.confirmPassword} />
              )}
            </div>
          </Col>
        </Row>

        <div
          className={`mb-3 force-alert ${
            validationMessages?.error && "has-error"
          }`}
        >
          {validationMessages?.error && (
            <AlertContainer data={validationMessages} />
          )}
        </div>

        <div className="button-container justify-content-end">
          <Row gutter={12}>
            <Col>
              <Button
                className="btn-breeze-box btn-radius"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Save Password
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  );
};

export default connect(null, {
  changeProfilePassword,
})(ChangePasswordForm);
