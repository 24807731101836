// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const apiKey = `${process.env.REACT_APP_FIREBASE_MESSAGING_API_KEY}`;
const authDomain = `${process.env.REACT_APP_FIREBASE_MESSAGING_AUTH_DOMAIN}`;
const projectId = `${process.env.REACT_APP_FIREBASE_MESSAGING_PROJECT_ID}`;
const storageBucket = `${process.env.REACT_APP_FIREBASE_MESSAGING_STORAGE_BUCKET}`;
const messagingSenderId = `${process.env.REACT_APP_FIREBASE_MESSAGING_MESSAGING_SENDER_ID}`;
const appId = `${process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID}`;
const measurementId = `${process.env.REACT_APP_FIREBASE_MESSAGING_MEASUREMENT_ID}`;
const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getFbToken = async (
  setTokenFound,
  setFirebaseRegistrationToken
) => {
  return getToken(messaging, {
    vapidKey: `${process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY}`,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        setTokenFound(true);
        setFirebaseRegistrationToken(currentToken);

        //subscribe to topic
        subscribeTokenToTopic(currentToken, "breeze-push");

        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log(payload);
      if (payload.data?.title !== "breeze-push-topic") {
        resolve(payload);
      }
    });
  });

export const requestPermission = async () => {
  console.log("Requesting permission...");
  let permission = await Notification.requestPermission();
  if (permission === "granted") {
    return true;
  }
  console.log("Denied..");
  return false;
};

export const subscribeTokenToTopic = (token, topic) => {
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    method: "POST",
    headers: new Headers({
      Authorization: `key=${process.env.REACT_APP_FIREBASE_MESSAGING_SERVER_KEY}`,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        console.log(response.status, response);
      }
      console.log(`"${topic}" is subscribed`);
    })
    .catch((error) => {
      console.error(error.result);
    });
  return true;
}
