import { Button } from "antd";
import illustration from "../../../assets/images/coming-soon-illustration.svg";
import { tagComingSoon } from "../../../utils/googleTagManager";

const ComingSoonState = (props: {
  title?: string;
  featureTitle?: string;
  description?: string;
  buttonText?: string;
  onButtonClick?: Function;
}) => {

  const {
    title = 'Coming Soon',
    featureTitle = 'Unknown',
    description = 'We are developing new and exciting feature',
    buttonText = 'Previous Page',
    onButtonClick
  } = props;

  tagComingSoon(featureTitle);

  return (
    <div className="coming-soon-state d-flex">
      <div
        className="d-flex d-flex-column align-items-center"
        >
        <h1>{title}</h1>
        <p>
          {description}
        </p>
        <Button
          style={{width: 178, height: 57, borderRadius: 10, fontSize:21 }}
          type="primary"
          shape="default"
          onClick={() => onButtonClick ? onButtonClick() : window.history.go(-1)}
        >
          {buttonText}
        </Button>
      </div>
      <img src={illustration} />
      {/* <p className="text-center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p> */}
    </div>
  );
};

export default ComingSoonState;
