import { Button, Col, Divider, Row, Skeleton } from "antd";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import placeholderImg from "../../../assets/images/club-banner-default.png";
import { StarOutlined, UserOutlined, RightOutlined } from "@ant-design/icons";

const GroupItemList = (props: {
  groupTitle: string;
  hasMorePage: boolean;
  totalClubs: Number;
  clubs: any;
  handleJoinClub: Function;
  viewMoreHandler: Function;
  isListLoading: boolean;
}) => {
  const {
    groupTitle,
    hasMorePage,
    totalClubs,
    clubs,
    handleJoinClub,
    viewMoreHandler,
    isListLoading,
  } = props;

  const onJoinClub = async (e: any, memberGroupId: any) => {
    e.stopPropagation();
    e.preventDefault();
    handleJoinClub(memberGroupId);
  };

  return (
    <Fragment>
      {clubs.length > 0 && (
        <div className="my-club-container mb-4">
          <h4 className="filter-title">{groupTitle}</h4>
          <div className="badge-count">{totalClubs}</div>
        </div>
      )}

      <Row gutter={24}>
        {clubs &&
          clubs.map((clubItem: any) => {
            return (
              <Col span={24} className="mb-5">
                <Link to={`/club/${clubItem.memberGroupId}`}>
                  <div className="club-item-list-view">
                    <div className="club-item-image">
                      <img
                        src={
                          clubItem && clubItem.bannerFileUrl
                            ? clubItem.bannerFileUrl + "?" + performance.now()
                            : placeholderImg
                        }
                        height="170px"
                        width="auto"
                      />
                    </div>
                    <div className="club-item-content">
                      <div className="club-content-wrapper">
                        <div className="d-flex-column mb-4">
                          <div className="d-flex align-items-center justify-content-space-between">
                            <h4 className="club-item-title">{clubItem.name}</h4>
                            <div className="d-flex align-items-center">
                              <div>
                                <Button
                                  htmlType="button"
                                  className="btn-radius px-4"
                                  type="primary"
                                  loading={clubItem.loading}
                                  onClick={(e) =>
                                    onJoinClub(e, clubItem.memberGroupId)
                                  }
                                >
                                  {clubItem.loading ? "Joining" : "Join"}
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex-column">
                            <div className="d-flex align-items-center mr-3">
                              <h5 className="club-item-interest d-flex align-items-center">
                                <StarOutlined className="mr-1" />
                                {clubItem.interest && clubItem.interest.name}
                              </h5>
                              <h5 className="club-item-interest d-flex align-items-center">
                                <UserOutlined className="ml-3 mr-2" />
                                <span className="mt-1">
                                  {clubItem.memberCount} members
                                </span>
                              </h5>
                            </div>

                            <p className="club-item-description pt-3">
                              {clubItem.description &&
                                clubItem.description.substring(0, 120)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}

        {isListLoading && (
          <Fragment>
            {Array(3)
              .fill(0)
              .map((_, i) => {
                return (
                  <Col span={24} className="mb-5">
                    <div className="club-item-list-view">
                      <div className="club-item-image">
                        <Skeleton.Image />
                      </div>
                      <Skeleton
                        className="custom-skeleton"
                        active
                        paragraph={{ rows: 3 }}
                      />
                    </div>
                  </Col>
                );
              })}
          </Fragment>
        )}
      </Row>
      {!isListLoading && clubs.length > 0 && (
        <Fragment>
          {hasMorePage ? (
            <div
              className="view-more-btn"
              onClick={() => viewMoreHandler({ groupTitle })}
            >
              View More <RightOutlined />
            </div>
          ) : (
            <Divider orientation="center" plain>
              No more clubs to show
            </Divider>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default GroupItemList;
