import { Col, Row } from "antd";
import { Fragment } from "react";
import { connect } from "react-redux";
import Announcement from "../../widget/Announcement";
import DashboardUpcomingEvents from "./DashboardUpcomingEvents";
import DashboardPendingInvites from "./DashboardPendingInvites";
import DashboardTopClubs from "./DashboardTopClubs";
import SoftSkillWidget from "./SoftSkillWidget";
import flutterConnect from "../../../reducers/flutterConnect";

export const Dashboard = (props: { member: any, flutterConnect: Boolean; }) => {
  const {
    member,
    flutterConnect
  } = props;

  // var flutterConnect = true;
  const sizeCommon = flutterConnect ? undefined : 24;
  const spanFlutter = flutterConnect ? 24 : undefined;

  return (
    <div className="dashboard-page-single-content-wrapper mt-24px">
      <div className={`dashboard-page-single-content`}>
        <h3 className="hi-user">Hi, {member && member.firstName}</h3>
        <Row>
          <Col
            span={spanFlutter}
          >
            <Announcement />
          </Col>
        </Row>
        <Row>
          <Col
            sm={sizeCommon}
            md={sizeCommon}
            span={spanFlutter}
          >
            <DashboardUpcomingEvents />
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="section-club-event">
          <Col
            sm={sizeCommon}
            md={flutterConnect ? undefined : 12}
            span={spanFlutter}
            className="gutter-row"
          >
            <DashboardPendingInvites />
          </Col>
          <Col
            sm={sizeCommon}
            md={flutterConnect ? undefined : 12}
            span={spanFlutter}
            className="gutter-row"
          >
            <DashboardTopClubs />
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={24}>
            <SoftSkillWidget />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  member: state.auth?.member,
  flutterConnect: state.flutterConnect,
});

export default connect(mapStateToProps)(Dashboard);
