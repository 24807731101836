import { Fragment } from "react";

const AlertContainer2 = ({ data }: any) => {
  var indents = [];
  let counter = 0;
  if (data && data.error) {
    for (let property in data) {
      indents.push(
        <div key={property} className="ant-form-item-explain">
          <div role="alert" className="ant-form-item-explain-error">
            {data[property]}
          </div>
        </div>
      );
    }
  } else {
    for (let property in data) {
      indents.push(
        <div key={property} className="ant-form-item-explain mt-3">
          <h3>File could not be uploaded for the ff reason:</h3>

          <h3>Record {counter}: Issues</h3>
          {data[property].map((data: any) => {
            return (
              <div role="alert" className="ant-form-item-explain-error">
                <div>{data}</div>
              </div>
            );
          })}
        </div>
      );
      counter++;
    }
  }

  return <Fragment>{indents}</Fragment>;
};

export default AlertContainer2;
