import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { Button, Col, Row } from "antd";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../actions/auth";
import PinVerification from "./PinVerification";
import SetupPassword from "./SetupPassword";
import SignUp from "./Signup";
import breezeWhite from "./../../assets/images/logos/breeze-white.svg";
import signupImage from "./../../assets/images/signup-img.svg";
import mobileAuthBg from "./../../assets/images/mobile-auth-bg.svg";
import { OK } from "../../helpers/statuscodes";
import { Student, Teacher } from "../../helpers/userRoles";
import WelcomePage from "../WelcomePage/WelcomePage";

const SignupPage = (props: any) => {
  let navigate = useNavigate();
  const {
    login,
    auth: { member },
  } = props;
  const [emailAddress, setEmailAddress] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [verificationCodeId, setVerificationCodeId] = useState("");
  const [userData, setUserData] = useState([]);
  const [userRole, setUserRole]: any = useState();

  const [step, setStep] = useState(1);
  const [OTPCode, setOTPCode] = useState<string>("");

  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isFading, setIsFading] = useState(false);

  const onSetEmailData = (data: any) => {
    setEmailAddress(data.emailAddress);
    setVerificationCodeId(data.verificationId);
    if (process.env.REACT_APP_ENV_IDENTIFIER === "SIT") {
      setOTPCode(data.verificationCode ?? "");
    }
    setStep(2);
  };

  const onVerifyEmail = (resUserData: any) => {
    setUserData(resUserData);
    setStep(3);
    setFirstName(resUserData.firstName);
  };

  const onSetPassword = (body: any) => {
    const forceLogin = async (body: any) => {
      const res = await login(body); //call redux action
      if (res.status === OK) {
        let decoded: any = jwt_decode(res.data.token);
        let userRole: String =
          decoded[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ];
        setUserRole(userRole);

        setIsLoginSuccess(true);
        //show login success page for 5 seconds before going to admin
        // setTimeout(() => {
        //   setIsFading(true);
        //   setTimeout(() => {
        //     if (userRole === Student) {
        //       navigate("/student", { replace: true });
        //     } else if (userRole === Teacher) {
        //       navigate("/teacher", { replace: true });
        //     } else {
        //       navigate("/admin", { replace: true });
        //     }
        //   }, 500);
        // }, 3000);
      } else {
        navigate("/login", { replace: true });
      }
    };
    forceLogin(body);
    //auto login after password is set
  };

  let content;

  switch (step) {
    case 1:
      content = <SignUp onSetEmailData={onSetEmailData} />;
      break;
    case 2:
      content = (
        <PinVerification
          onVerifyEmail={onVerifyEmail}
          emailAddress={emailAddress}
          verificationCodeId={verificationCodeId}
          OTPCode={OTPCode}
        />
      );
      break;
    case 3:
      content = (
        <SetupPassword
          onSetPassword={onSetPassword}
          userData={userData}
          emailAddress={emailAddress}
          firstName={firstName}
        />
      );
      break;
    default:
      content = <SignUp onSetEmailData={onSetEmailData} />;
      break;
  }

  if (isLoginSuccess) {
    if (member.memberType === Student) {
      if (member && member.interests && member.interests.length > 0) {
        navigate("/events");
      } else {
        navigate("/interests");
      }
    } else {
      return <WelcomePage />;
    }
  }

  return (
    <Fragment>
      {!isLoginSuccess ? (
        <div className="signup-page-wrapper">
          <div className="panel-container">
            <div className="left-content blue-bg">
              <div className="h-100 d-flex-column justify-content-center align-items-center">
                <img
                  className="login-logo"
                  src={breezeWhite}
                  height="37.87px"
                  width="128"
                />
                <img src={signupImage} height="536px" width="100%" />
                <h2 className="text-center">Join Breeze</h2>
                <p className="sub-title text-center">
                  Helps teachers and admin plan events by indicating which rooms
                  are in use.
                </p>
              </div>
            </div>
            <div className="right-content d-flex-column h-100 ">
              <div
                className="mobile-header d-md-none mb-2"
                style={{ backgroundImage: `url(${mobileAuthBg})` }}
              >
                <img
                  className="login-logo"
                  src={breezeWhite}
                  height="32px"
                  width="auto"
                />
              </div>
              <div className="d-flex justify-content-end w-100">
                <div className="nav-controls justify-content-center d-sm-none">
                  <Link className="mr-3" to="/sign-up">
                    Already have an account?
                  </Link>
                  <Button>
                    <Link to="/login">Sign In</Link>
                  </Button>
                </div>
              </div>

              <div className="form-panel d-flex-column align-items-center justify-content-center h-100">
                <div className="w-100">{content}</div>
                <div className="nav-controls d-flex-column w-100 d-md-none">
                  <Link className="text-center" to="/login">
                    Already have an account?
                  </Link>
                  <Button className="mt-3 w-100">
                    <Link to="/login">Sign In</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <WelcomePage fading={isFading} userRole={userRole} />
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: { auth: any; firebase: any }) => ({
  auth: state.auth,
  firebase: state.firebase,
});

export default connect(mapStateToProps, { login })(SignupPage);
