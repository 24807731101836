import { Button } from "antd";
import { Link } from "react-router-dom";
import emptyState from "../../../assets/images/empty-state-illustration-plus.svg";
import { connect } from "react-redux";

const TotallyNewState = (props: {
  flutterConnect: boolean;
}) => {

  const { flutterConnect } = props;

  return (
    <div>
      <div className={`empty-state d-flex justify-content-center h-100 state-no-events`}>
        <div className="d-flex d-flex-column justify-content-center state-content">
          <h1 className="text-center mb-2">No Clubs Joined!</h1>
          <p>
            Hmm... Seems like we don't have any events to show you.
            <br/>
            Check out our clubs to see what you can join!.
          </p>
          <Link to={"/clubs"}>
              <Button
                style={{width: 150, height: 57, borderRadius: 10, fontSize:21 }}
                type="primary"
                shape="default"
                // loading={isLoading}
                // icon={<CheckOutlined />}
                >
                Check Clubs
              </Button>
          </Link>
        </div>
        <img src={emptyState} height="548px" />
        {/* <p className="text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
      </div>

      <div className={`empty-state d-flex justify-content-center h-100 state-no-events app ${flutterConnect ? 'flutter' : ''}`}>
        <div className="d-flex d-flex-column justify-content-center state-content">
          <img src={emptyState} />
          <div className="text-content">
            <h1 className="text-center mb-2">No Clubs Joined!</h1>
            <p>
            Hmm... Seems like we don't have any events to show you.
            Check out our clubs to see what you can join!.
            </p>
            <Link to={"/clubs"}>
                <Button
                  style={{width: 150, height: 57, borderRadius: 10, fontSize:21 }}
                  type="primary"
                  shape="default"
                  // loading={isLoading}
                  // icon={<CheckOutlined />}
                  >
                  Check Clubs
                </Button>
            </Link>
          </div>
        </div>
        
        {/* <p className="text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  flutterConnect: state.flutterConnect,
});
export default connect(mapStateToProps, {
})(TotallyNewState);
