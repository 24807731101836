import { Button, Col, Collapse, Dropdown, Menu, Row, Skeleton } from "antd";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { joinClub, leaveClub } from "../../../actions/club";
import placeholderImg from "../../../assets/images/club-banner-default.png";
import {
  StarOutlined,
  UserOutlined,
  EllipsisOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
const { Panel } = Collapse;

const MyClubItemGrid = (props: {
  clubs: any;
  // myClubs: any;
  joinClub: Function;
  onHandleConfirm: Function;
  onHandleClubInvitation: Function;
  onHandleDeclineClubInvitation: Function;
  leaveClub: Function;
  isListLoading: boolean;
  auth: any;
  actionIsLoading: boolean;
}) => {
  const {
    clubs,
    onHandleConfirm,
    onHandleClubInvitation,
    onHandleDeclineClubInvitation,
    isListLoading,
    auth,
    actionIsLoading,
  } = props;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const [activeGroup, setActiveGroup]: any = useState([]);

  const onLeaveClub = async (e: any, clubItem: any) => {
    onHandleConfirm(clubItem);
  };

  useEffect(() => {
    let group = clubs?.map((item: any) => {
      return item.category;
    });
    setActiveGroup(group);
  }, [clubs]);

  return (
    <Fragment>
      {isListLoading && (
        <Row gutter={24}>
          {Array(8)
            .fill(0)?.map((_, i) => {
              return (
                <Col
                  key={i}
                  span={8}
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 8 }}
                  className="mb-5"
                >
                  <div className="club-item-grid-view">
                    <div className="club-item-image">
                      <Skeleton.Image className="w-100" />
                    </div>
                    <Skeleton
                      className="custom-skeleton"
                      active
                      paragraph={{ rows: 3 }}
                    />
                  </div>
                </Col>
              );
            })}
        </Row>
      )}

      {activeGroup?.length !== 0 && (
        <div className="my-club-collapse">
          {clubs &&
            clubs?.map((group: any, index: string) => {
              return (
                <Fragment key={index}>
                  {group && group.clubs && group.clubs?.length !== 0 && (
                    <Fragment>
                      <div className="my-club-container mb-4">
                        <h4 className="filter-title">{group.category}</h4>
                        <div className="badge-count">{group.clubs?.length}</div>
                      </div>
                      <Row gutter={24} key={group.category}>
                        {group.clubs?.map((clubItem: any) => {
                          return (
                            <Col
                              key={clubItem.memberGroupId}
                              span={8}
                              xs={{ span: 24 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 8 }}
                              className="mb-5"
                            >
                              <Link
                                key={clubItem.memberGroupId}
                                to={`/club/${clubItem.memberGroupId}`}
                              >
                                <div className="club-item-grid-view">
                                  {/* uncomment this if club image is available */}
                                  <div className="club-item-image">
                                    {/* FOR DECLINED PROPOSALS */}
                                    {/* FOR PENDING PROPOSALS */}
                                    {/* FOR APPROVED PROPOSALS OR CLUBS*/}
                                    {clubItem &&
                                    clubItem.status === "Rejected" ? (
                                      <Fragment>
                                        <div className="badge-status">
                                          <span
                                            className={`text-bold color-error`}
                                          >
                                            Declined
                                          </span>
                                        </div>
                                      </Fragment>
                                    ) : clubItem &&
                                      clubItem.status === "Pending" ? (
                                      <Fragment>
                                        <div className="badge-status">
                                          <span
                                            className={`text-bold color-warning`}
                                          >
                                            Pending
                                          </span>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {clubItem.userMembership &&
                                          clubItem.userMembership.joinStatus ===
                                            "Approved" && (
                                            <div className="badge-status">
                                              <span
                                                className={`text-bold color-success`}
                                              >
                                                Joined
                                              </span>
                                            </div>
                                          )}
                                        {clubItem.userMembership &&
                                          clubItem.userMembership.joinStatus ===
                                            "Pending" && (
                                            <div className="badge-status">
                                              <span
                                                className={`text-bold color-warning`}
                                              >
                                                Pending
                                              </span>
                                            </div>
                                          )}
                                      </Fragment>
                                    )}
                                    <img
                                      src={
                                        clubItem && clubItem.bannerFileUrl
                                          ? clubItem.bannerFileUrl +
                                            "?" +
                                            performance.now()
                                          : placeholderImg
                                      }
                                      height="210px"
                                      width="auto"
                                    />
                                  </div>
                                  <div className="club-item-content">
                                    <div className="d-flex-column">
                                      <div className="d-flex justify-content-space-between pb-1">
                                        <h4 className="club-item-title">
                                          {clubItem.name}
                                        </h4>
                                      </div>
                                      <div className="d-flex">
                                        <div className="d-flex align-items-center mr-3">
                                          <h5 className="club-item-interest d-flex align-items-center">
                                            <StarOutlined className="mr-1" />
                                            {clubItem.interest &&
                                              clubItem.interest.name}
                                          </h5>
                                        </div>
                                        <h5 className="club-item-interest d-flex align-items-center">
                                          <UserOutlined className="mr-1" />
                                          {clubItem.memberCount} members
                                        </h5>
                                      </div>
                                    </div>

                                    <p className="club-item-description pt-2 pb-4">
                                      {clubItem.description &&
                                        clubItem.description.substring(0, 120)}
                                    </p>
                                  </div>
                                  <div className="club-item-controls">
                                    <div>
                                      <Fragment>
                                        {clubItem.userMembership &&
                                          clubItem.userMembership.joinStatus ===
                                            "Pending" && (
                                            <Fragment>
                                              {clubItem.userMembership
                                                .createdBy !==
                                                clubItem.userMembership
                                                  .memberId && (
                                                <div className="d-flex">
                                                  <Button
                                                    htmlType="button"
                                                    className="btn-radius px-4 mr-1"
                                                    type="primary"
                                                    loading={clubItem.loading}
                                                    disabled={actionIsLoading}
                                                    onClick={(e) =>
                                                      onHandleClubInvitation(
                                                        e,
                                                        {
                                                          type: "accept",
                                                          clubId:
                                                            clubItem.memberGroupId,
                                                          clubName:
                                                            clubItem.name,
                                                        }
                                                      )
                                                    }
                                                  >
                                                    Accept
                                                  </Button>
                                                  <Button
                                                    htmlType="button"
                                                    className="btn-radius px-5"
                                                    loading={clubItem.loading}
                                                    disabled={actionIsLoading}
                                                    onClick={(e) =>
                                                      onHandleDeclineClubInvitation(
                                                        e,
                                                        {
                                                          type: "decline",
                                                          clubId:
                                                            clubItem.memberGroupId,
                                                          clubName:
                                                            clubItem.name,
                                                        }
                                                      )
                                                    }
                                                  >
                                                    Decline
                                                  </Button>
                                                </div>
                                              )}
                                              {clubItem.userMembership
                                                .createdBy ===
                                                clubItem.userMembership
                                                  .memberId && (
                                                <div className="d-flex">
                                                  <Button
                                                    htmlType="button"
                                                    className="btn-radius px-5"
                                                    loading={clubItem.loading}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      // OR
                                                      e.preventDefault();
                                                      onLeaveClub(e, clubItem);
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                </div>
                                              )}
                                            </Fragment>
                                          )}
                                        <Fragment>
                                          {clubItem &&
                                            clubItem.status === "Rejected" &&
                                            clubItem.userMembership
                                              .createdBy ===
                                              signedInUserMemberId && (
                                              <Fragment>
                                                <Link
                                                  to={`/club-proposal/${clubItem.memberGroupId}`}
                                                >
                                                  <Button
                                                    htmlType="button"
                                                    className="btn-radius px-4 mr-1"
                                                    type="primary"
                                                    loading={clubItem.loading}
                                                  >
                                                    Repropose
                                                  </Button>
                                                </Link>
                                              </Fragment>
                                            )}
                                        </Fragment>
                                      </Fragment>
                                    </div>
                                    <div
                                      className="ml-auto"
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        // OR
                                        e.preventDefault();
                                      }}
                                    >
                                      {clubItem && clubItem.userMembership && (
                                        <Dropdown
                                          className="item-menu"
                                          overlay={
                                            <Menu
                                              onClick={(e: any) =>
                                                onLeaveClub(e, clubItem)
                                              }
                                              items={[
                                                {
                                                  key: `leave-club-${clubItem.memberGroupId}`,
                                                  label: <a>Leave</a>,
                                                },
                                              ]}
                                            />
                                          }
                                          placement="bottomRight"
                                          trigger={["click"]}
                                        >
                                          <Button
                                            style={{ height: "40px" }}
                                            shape="circle"
                                            icon={<EllipsisOutlined />}
                                          />
                                        </Dropdown>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </Col>
                          );
                        })}
                      </Row>
                    </Fragment>
                  )}
                </Fragment>
              );
            })}
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state: { auth: any; firebase: any }) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  joinClub,
  leaveClub,
})(MyClubItemGrid);
