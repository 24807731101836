import { Button, Col, PageHeader, Row, Space } from "antd";
import { useState, Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddAnnouncementForm from "./AnnouncementForm";

const AddAnnouncement = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [id, setId] = useState('');
  const [announcementTitle, setAnnouncementTitle] = useState('');

  const onLoad = (id: any, announcementTitle: string) => {
    console.log(id);
    console.log(announcementTitle);
    setId(id);
    setAnnouncementTitle(announcementTitle);
  };

  const title = `${id ? "Edit" : "Add"} ${announcementTitle ?? ''}`;
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate("/admin/announcements")}
          title={title}
          extra={[
            <Button
              key="back"
              className="btn-radius"
              onClick={() => navigate("/admin/announcements")}
            >
              Cancel
            </Button>,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container pt-1">
                  <AddAnnouncementForm onLoad={onLoad} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddAnnouncement;
