import { Button, Col, PageHeader, Row } from "antd";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../../ContentHeader/ContentHeader";
import AddUserBulkForm from "./AddUserBulkForm";

const AddUserBulk = (props: any) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate(-1)}
          title="Upload School Admins"
          extra={[<Button key="add" onClick={() => navigate(-1)}>Cancel</Button>]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <AddUserBulkForm />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddUserBulk;
