import { Modal } from "antd";
import { CrownOutlined, MailOutlined } from "@ant-design/icons";
import DefaultAvatar from "../../widget/DefaultAvatar";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";

export const ModalProfilePopup = (props: {
  visible: boolean;
  userData?: any;
  handleCancel: Function;
  member: any;
}) => {
  const {
    visible,
    userData,
    handleCancel,
    member: { schoolName },
  } = props;
  return (
    <Modal
      className="modal-profile-popup"
      footer={[]}
      visible={visible}
      centered
      onCancel={() => handleCancel(false)}
    >
      <div className="d-flex">
        <div className="mr-2">
        <div className="user-container">
        {userData &&
          userData.createdByProfilePicture &&
          userData.createdByProfilePicture ? (
            <Fragment>
              <img
                src={userData.createdByProfilePicture + "?" + Date.now()}
                height={64}
                width={64}
              />
            </Fragment>
          ) : (
            <Fragment>
              <DefaultAvatar
                firstName={
                  userData.createdByName && userData.createdByName.split(" ")[0]
                }
                lastName={
                  userData.createdByName && userData.createdByName.split(" ")[1]
                }
                size={64}
              />
            </Fragment>
          )}
          </div>
         
        </div>
        <div className="d-flex-column">
          <h5>{userData?.createdByName}</h5>
          <label>
            <CrownOutlined /> {userData?.createdByProfile} - {schoolName}
          </label>
          <label className="mt-1">
            <MailOutlined /> {userData?.createdByProfileEmailAddress}
          </label>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  member: state.auth.member,
});

export default connect(mapStateToProps)(ModalProfilePopup);
