import { addInvites } from "../../../actions/event";
import { Button, Form, Modal, Tabs, Spin } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getEventParticipantList,
  getParticipantListNonPaged,
} from "../../../actions/participant";
import { OK } from "../../../helpers/statuscodes";
import { UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import AttendanceListWidget from "../../widget/AttendanceListWidget";
import ModalSuccess from "../Modals/ModalSuccess";
import { userPermissions } from "../../../permissions/user-permissions";
import {
  getClubModeratorList,
} from "../../../actions/club";


interface IEventParticipantPageProps {
  auth: any;
  event: any;
  readonly: boolean;
  addInvites: Function;
  getEventParticipantList: Function;
  getParticipantListNonPaged: Function;
  getClubModeratorList: Function;
}

const EventAttendancePane = (props: IEventParticipantPageProps) => {
  const { auth, event, getParticipantListNonPaged, getClubModeratorList} = props;
  const { eventId } = event;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const [validationMessages, setValidationMessages]: any = useState({});
  const [showRequestOnly, setShowRequestOnly] = useState(false);
  const [isClubModerator, setIsClubModerator] = useState(false);
  const [participants, setParticipants] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(-2);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [pendingCount, setPendingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [form] = Form.useForm();
  const [inviteMemberSearchTerm, setInviteMemberSearchTerm] = useState("");
  const [isModalInviteMemberSearching, setIsModalInviteMemberSearching] =
    useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [invitedMemberList, setInvitedMemberList]: any = useState([]);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalInviteClubMemberOpen, setIsModalInviteClubMemberOpen] =
    useState(false);


  const onSelectInviteMember = (checked: boolean, id: string) => {
    console.log(checked, id);
    if (checked) {
      if (
        invitedMemberList.filter((item: string) => item.includes(id)).length ==
        0
      ) {
        setInvitedMemberList(invitedMemberList.concat(id));
      }
    } else {
      setInvitedMemberList(
        invitedMemberList.filter((item: string) => item !== id)
      );
    }
  };

  const getClubModeratorStatus = async () => {
    let params = {
      page: 1,
      pageSize: 10,
      searchString: `${auth.member.emailAddress}`,
      memberGroupId: event.memberGroupId,
    };
    const res = await getClubModeratorList(params);
    setIsClubModerator(res.data.items.some((e: any) => e.memberId == auth.member.memberId));
  }
  
  let permissions = {
    canDeleteEvent:
      userPermissions[auth.member.memberType] &&
      userPermissions[auth.member.memberType]?.deleteEvent,
  };

  useEffect(() => {
    getClubModeratorStatus();
  }, []);
  useEffect(() => {
    if(isClubModerator===false){
      setSelectedStatus(4);
    }else{
      setSelectedStatus(-1);
    }
  }, [isClubModerator]);

  if (selectedStatus < -1)
    return <Spin />

  return (
    isClubModerator ? (
    <div className="participants-pane">
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p
          className="text-center"
          dangerouslySetInnerHTML={{ __html: successMessage }}
        ></p>
      </ModalSuccess>
      <div className="justified-row">
        <h2>Participants</h2>
      </div>
      <div className="participants-tabs">
        <Tabs
          defaultActiveKey="-1"
          tabBarExtraContent={{
            left: (
              <Search
                placeholder="Search Participants"
                style={{ width: 280 }}
                onSearch={(val: string) => setSearchString(val)}
              />
            ),
          }}
          onTabClick={(key) => {
            setShowRequestOnly(key.indexOf("|") > 0);
            setSelectedStatus(parseInt(key.split("|")[0]));
          }}
          tabBarGutter={10}
          // centered
        >
          <Tabs.TabPane
            disabled={isLoading}
            key={"-1"}
            tab="All Record"
            className="ant-tab"
          ></Tabs.TabPane>
          <Tabs.TabPane
            disabled={isLoading}
            key={"1"}
            tab="Attended"
          ></Tabs.TabPane>
          <Tabs.TabPane
            disabled={isLoading}
            key={"2"}
            tab="Absent"
          ></Tabs.TabPane>
          {moment().diff(moment(event.startDate), "days") <= 30
          && (isClubModerator || permissions.canDeleteEvent) && (
            <>
              <Tabs.TabPane
                disabled={isLoading}
                key={"3"}
                tab="Edit Attendance"
              ></Tabs.TabPane>
            </>
          )}
          
        </Tabs>
      </div>
      <div className="attendance-layout">
        <AttendanceListWidget
          onReload={(data: any) =>
            setPendingCount((previous) =>
              data ? data.pendingCount : previous - 1
            )
          }
          participantStatus={selectedStatus.toString()}
          invitedFilter={!showRequestOnly}
          searchString={searchString}
          readonly={props.readonly}
          reload={reload}
          event={event}
        ></AttendanceListWidget>
      </div>

    </div>)
    :(
    <div className="participants-pane">
      <div className="justified-row">
        <h2>Your Attendance</h2>
      </div>
      <div className="attendance-layout">
        <AttendanceListWidget
          onReload={(data: any) =>
            setPendingCount((previous) =>
              data ? data.pendingCount : previous - 1
            )
          }
          participantStatus={selectedStatus.toString()}
          invitedFilter={!showRequestOnly}
          searchString={searchString}
          readonly={props.readonly}
          reload={reload}
          event={event}
          userId={signedInUserMemberId}
        ></AttendanceListWidget>
    </div>
    </div>)
    );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
});
export default connect(mapStateToProps, {
  addInvites,
  getEventParticipantList,
  getParticipantListNonPaged,
  getClubModeratorList,
})(EventAttendancePane);
