import { Alert, Button, Col, Row, Skeleton } from "antd";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getInterestListNonPaged } from "../../../actions/interest";
import { updateProfileInterest } from "../../../actions/profile";
import academicsIcon from "../../../assets/images/icons/academics-icon.svg";
import checkmarkIcon from "../../../assets/images/icons/checkmark-green.svg";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import WelcomePage from "../../../pages/WelcomePage/WelcomePage";
const Onboarding = (props: {
  getInterestListNonPaged: Function;
  updateProfileInterest: Function;
  auth: any;
}) => {
  let navigate = useNavigate();
  const {
    getInterestListNonPaged,
    updateProfileInterest,
    auth: { member },
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);
  const [maxReached, setMaxReached] = useState(false);

  const [interestList, setInterestList] = useState<any[]>([]);
  const [validationMessages, setValidationMessages]: any = useState({});

  // const [limit, setLimit] = useState<boolean>(false);

  const handleSelectInterest = async (interestId: any) => {
    setValidationMessages([]);
    if (
      interestList.filter((obj) => obj.selected === true).length >= 5 &&
      interestList.filter(
        (obj) => obj.interestId === interestId && obj.selected === false
      ).length > 0
    ) {
      setMaxReached(true);
    } else {
      setMaxReached(false);
    }
    let x = interestList.map((interestItem: any) => {
      if (interestId === interestItem.interestId) {
        if (
          interestList.filter((obj) => obj.selected === true).length < 5 &&
          interestItem.selected === false
        ) {
          return {
            ...interestItem,
            selected: !interestItem.selected,
          };
        } else {
          return {
            ...interestItem,
            selected: false,
          };
        }
      } else {
        return interestItem;
      }
    });
    setInterestList(x);
  };

  const updateInterest = async () => {
    setValidationMessages(new Array());

    setIsSaving(true);
    const selectedInterest: any = interestList.filter(
      (item: any) => item.selected === true
    );
    let interestIds = selectedInterest.map((item: { interestId: string }) => {
      return item.interestId;
    });
    let res = await updateProfileInterest({ interests: interestIds });
    handleFormErrors(res.data);
    setMaxReached(false);
    if (res.status == OK || res.status == CREATED) {
      navigate("/");
    } else if (res.status === BAD_REQUEST) {
      console.log(res);
    }
    setIsSaving(false);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  //use for limited # of selected interest
  // useEffect(() => {
  //   if (
  //     interestList.filter((item: any) => item.selected === true).length === 3
  //   ) {
  //     setLimit(true);
  //   } else {
  //     setLimit(false);
  //   }
  // }, [interestList]);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      const data = await getInterestListNonPaged();
      setInterestList(
        data.data.map((interest: any) => {
          const found =
            member.interests &&
            member.interests.some(
              (el: any) => el.interestId === interest.interestId
            );
          if (found) {
            return {
              ...interest,
              selected: true,
            };
          } else {
            return {
              ...interest,
              selected: false,
            };
          }
        })
      );
      setIsLoading(false);
    };
    initialize();
  }, []);

  return (
    <Fragment>
      {!isSkipped ? (
        <div className="onboarding-wrapper d-flex-column h-100-vh align-items-center bg-white fadein">
          <h2 className="title">Tell us about your interest.</h2>
          <p className="sub-title">Select atleast three(3) interest</p>
          <div className="">
            <Row gutter={8} className="interest-selection">
              {isLoading &&
                Array(12)
                  .fill(0)
                  .map((_, i) => {
                    return (
                      <Col span={6} xs={12} md={8} lg={8} xl={6}>
                        <div className={`gutter-row interest-item`}>
                          <div className="d-flex align-items-center d-flex-column w-100 justify-content-center">
                            <Skeleton.Image active />
                            <Skeleton active paragraph={{ rows: 0 }} />
                          </div>
                        </div>
                      </Col>
                    );
                  })}

              {!isLoading &&
                interestList.map((interest: any) => {
                  return (
                    <Col
                      span={6}
                      xs={12}
                      md={8}
                      lg={8}
                      xl={6}
                      className="fadein"
                    >
                      <div
                        onClick={() =>
                          handleSelectInterest(interest.interestId)
                        }
                        className={`gutter-row interest-item ${
                          interest.selected ? "interest-active" : ""
                        }`}
                      >
                        <div className="d-flex align-items-center d-flex-column w-100 justify-content-center">
                          {interest.selected && (
                            <img
                              className="checkmark-icon"
                              src={checkmarkIcon}
                              height={21}
                              width={21}
                            />
                          )}
                          <img height={64} width={64} src={academicsIcon} />
                          <h5>{interest.name}</h5>
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
          <div className="onboarding-footer t-3">
            {maxReached && (
              <Alert
                className="mb-2 text-center"
                message="You can only select up to 5 interests"
                type="error"
              />
            )}

            {validationMessages && validationMessages.error && (
              <Alert
                className="mb-2 text-center"
                message={validationMessages.error}
                type="error"
              />
            )}

            <div className="d-flex">
              <h5 className="text-desc mr-5">Not sure what to choose?</h5>
              {!member.interests ? (
                <h5 className="text-action" onClick={() => setIsSkipped(true)}>
                  Skip for now
                </h5>
              ) : (
                <h5
                  className="text-action"
                  onClick={() => navigate("/profile")}
                >
                  Cancel
                </h5>
              )}
            </div>
            <Button
              className="mt-5"
              loading={isSaving || isLoading}
              disabled={
                isSaving ||
                interestList.filter((item: any) => item.selected === true)
                  .length < 3
              }
              onClick={() => updateInterest()}
              type="primary"
              shape="round"
            >
              Continue
            </Button>
          </div>
        </div>
      ) : (
        <WelcomePage />
      )}
    </Fragment>
  );
};
const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getInterestListNonPaged,
  updateProfileInterest,
})(Onboarding);
