import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Dropdown,
  Menu,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPagedAnnouncements, getAnnouncement } from "../../../actions/announcement";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import moment from "moment";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const AnnouncementList = (props: { getPagedAnnouncements: Function, token: any; }) => {
  let decoded: any = jwt_decode(props.token);
  let userRole: String =
    decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

  let navigate = useNavigate();
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Time Zone",
      dataIndex: "timeZoneId",
      key: "timeZoneId",
    },
    {
      title: "Notification",
      dataIndex: "hasNotified",
      key: "hasNotified",
      render: (text: any, record: any) => (
        <span>{record.hasNotified ? 'Published' : 'Unpublished'}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <span>{record.status}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a id={record.announcementId} onClick={() => editHandler(record)}>
            Edit
          </a>
        </Space>
      ),
    },
  ];

  const { getPagedAnnouncements } = props;
  const [announcementList, setAnnouncementList]: any = useState([]);
  const [statusFilter, setStatusFilter] = useState(-1);
  const initialPagination = {
    page: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination]: any = useState(initialPagination);
  const [searchString, setSearchString]: any = useState("");

  const [isListLoading, setIsListLoading] = useState(false);

  const onFilterSearchHandler = (searchInput: String) => {
    setSearchString(searchInput);
  };

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
  };

  const addHandler = () => {
    navigate("/admin/announcements/add", {
      state: {
        userRole: userRole
      },
    });
  };

  const editHandler = (record: any) => {
    navigate("/admin/announcements/add", {
      state: {
        record: record,
        userRole: userRole
      },
    });
  };

  const fetchList = async (parameters: any) => {
    setIsListLoading(true);
    let params;
    if (searchString) {
      params = {
        page: parameters.current ?? parameters.page,
        pageSize: parameters.pageSize,
        isSiteWide: false,
        searchString: searchString,
      };
    } else {
      params = {
        page: parameters.current ?? parameters.page,
        isSiteWide: false,
        pageSize: parameters.pageSize,
      };
    }
    const data: any = await getPagedAnnouncements(params);

    const currentDate = new Date();
    
    data.data.items.forEach((e: any) => {
      if(new Date(currentDate.toUTCString()) >= new Date(new Date(moment(e.startDate).toDate().toLocaleString('en-US', {timeZone: e.timeZoneId})).toUTCString()) &&
      new Date(currentDate.toUTCString()) <= new Date(new Date(moment(e.endDate).toDate().toLocaleString('en-US', {timeZone: e.timeZoneId})).toUTCString()))
        e.status = 'Active';
      else
        e.status = 'Inactive';

    });

    setAnnouncementList(data.data.items);
    setPagination({
      page: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsListLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };

  const announcementTitle = `${userRole == 'SuperAdmin' ? 'Maintenance ' : ''}Announcement`;
  
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title={`${announcementTitle}s`}
          extra={[
            <Button
              key="add"
              type="primary"
              className="btn-radius"
              onClick={addHandler}
            >
              Add New {`${announcementTitle}`}
            </Button>,
            // <DropdownMenu key="more" />,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    {/* <div className="my-auto mr-auto">
                      Status:{" "}
                      <select onChange={onfilterStatusHandler}>
                        <option value="-1">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="0">Pending</option>
                      </select>
                    </div> */}
                    <div className="my-auto mr-auto"></div>
                    <div>
                       <Search
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search by Announcement"
                        style={{ width: 280 }}
                      /> 
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="softSkillId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      scroll={{ y: "calc(100vh - 420px)" }}
                      dataSource={announcementList}
                      loading={isListLoading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getPagedAnnouncements })(AnnouncementList);
