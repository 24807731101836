import { addInvites } from "../../../actions/event";
import { Button, Form, Modal, Tabs } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getEventParticipantList,
  getParticipantListNonPaged,
} from "../../../actions/participant";
import { OK } from "../../../helpers/statuscodes";
import { UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import ParticipantListWidget from "../../widget/ParticipantListWidget";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import ModalInviteClubMembers from "../Modals/ModalInviteClubMembers";
import ModalInviteEventMembers from "../Modals/ModalInviteEventMembers";
import ModalSuccess from "../Modals/ModalSuccess";
import { userPermissions } from "../../../permissions/user-permissions";
import {
  getClubModeratorList,
} from "../../../actions/club";


interface IEventParticipantPageProps {
  auth: any;
  event: any;
  readonly: boolean;
  addInvites: Function;
  getEventParticipantList: Function;
  getParticipantListNonPaged: Function;
  getClubModeratorList: Function;
}

const EventParticipantPane = (props: IEventParticipantPageProps) => {
  const { auth, event, getParticipantListNonPaged, getClubModeratorList} = props;
  const { eventId } = event;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const [validationMessages, setValidationMessages]: any = useState({});
  const [showRequestOnly, setShowRequestOnly] = useState(false);
  const [isClubModerator, setIsClubModerator] = useState(false);
  const [participants, setParticipants] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [pendingCount, setPendingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [form] = Form.useForm();
  const [inviteMemberSearchTerm, setInviteMemberSearchTerm] = useState("");
  const [isModalInviteMemberSearching, setIsModalInviteMemberSearching] =
    useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [invitedMemberList, setInvitedMemberList]: any = useState([]);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalInviteClubMemberOpen, setIsModalInviteClubMemberOpen] =
    useState(false);


  const onSelectInviteMember = (checked: boolean, id: string) => {
    if (checked) {
      if (
        invitedMemberList.filter((item: string) => item.includes(id)).length ==
        0
      ) {
        setInvitedMemberList(invitedMemberList.concat(id));
      }
    } else {
      setInvitedMemberList(
        invitedMemberList.filter((item: string) => item !== id)
      );
    }
  };

  const getClubModeratorStatus = async () => {
    let params = {
      page: 1,
      pageSize: 10,
      searchString: `${auth.member.emailAddress}`,
      memberGroupId: event.memberGroupId,
    };
    const res = await getClubModeratorList(params);
    setIsClubModerator(res.data.items.some((e: any) => e.memberId == auth.member.memberId));
  }
  
  let permissions = {
    canDeleteEvent:
      userPermissions[auth.member.memberType] &&
      userPermissions[auth.member.memberType]?.deleteEvent,
  };

  const getListToInvite = async () => {
    let params = {
      SearchString: inviteMemberSearchTerm,
    };
    const data = await getParticipantListNonPaged(params);
    const formattedData = data.data
      .filter((item: any) => {
        if (item.id !== signedInUserMemberId) {
          return item;
        }
      })
      .map((item: any) => {
        return {
          id: item.id + "-" + item.type,
          name: item.name,
          type: item.type,
        };
      });
    setParticipantList(formattedData);
    setIsModalInviteMemberSearching(false);
  };

  useEffect(() => {
    getClubModeratorStatus();
  }, []);

  useEffect(() => {
    setIsModalInviteMemberSearching(true);
    const delaySearch = setTimeout(async () => {
      getListToInvite();
    }, 600);

    return () => clearTimeout(delaySearch);
  }, [inviteMemberSearchTerm]);

  const onSubmit = async () => {
    setIsLoading(true);
    const participants = invitedMemberList.map((item: any) => ({
      id: item.split("-")[0],
      type: parseInt(item.split("-")[1]),
    }));
    const res = await props.addInvites({
      eventId,
      participants,
    });
    setIsLoading(false);
    if (res.status === OK) {
      setParticipants([]);
      setShowAddModal(false);
      setReload((old) => !old);

      setSuccessMessage("You have successfully invited event participants.");
      setInvitedMemberList([]);
      setIsModalInviteClubMemberOpen(false);
      setIsModalSuccessOpen(true);
    }
  };

  return (
    <div className="participants-pane">
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p
          className="text-center"
          dangerouslySetInnerHTML={{ __html: successMessage }}
        ></p>
      </ModalSuccess>
      <div className="justified-row">
        <h2>Participants</h2>
        {!props.readonly && (
          <Button
            type="primary"
            shape="round"
            disabled={isLoading}
            icon={<UserAddOutlined />}
            onClick={() => setShowAddModal(true)}
          >
            Invite Participants
          </Button>
        )}
      </div>
      <div className="participants-tabs">
        <Tabs
          defaultActiveKey="-1"
          tabBarExtraContent={{
            left: (
              <Search
                placeholder="Search Participants"
                style={{ width: 280 }}
                onSearch={(val: string) => setSearchString(val)}
              />
            ),
          }}
          onTabClick={(key) => {
            setShowRequestOnly(key.indexOf("|") > 0);
            setSelectedStatus(parseInt(key.split("|")[0]));
          }}
          tabBarGutter={10}
          // centered
        >
          <Tabs.TabPane
            disabled={isLoading}
            key={"-1"}
            tab="All Record"
            className="ant-tab"
          ></Tabs.TabPane>
          <Tabs.TabPane
            disabled={isLoading}
            key={"1"}
            tab="Going"
          ></Tabs.TabPane>
          <Tabs.TabPane
            disabled={isLoading}
            key={"2"}
            tab="Declined"
          ></Tabs.TabPane>
          <Tabs.TabPane
            disabled={isLoading}
            key={"0"}
            tab="No Response"
          ></Tabs.TabPane>
          <Tabs.TabPane
            disabled={isLoading}
            key={"0|"}
            tab={
              pendingCount > 0 ? (
                <span
                  style={{ fontWeight: "bold" }}
                >{`Request (${pendingCount})`}</span>
              ) : (
                "Request"
              )
            }
          ></Tabs.TabPane>
        </Tabs>
      </div>
      <div>
        <ParticipantListWidget
          onReload={(data: any) =>
            setPendingCount((previous) =>
              data ? data.pendingCount : previous - 1
            )
          }
          participantStatus={selectedStatus.toString()}
          invitedFilter={!showRequestOnly}
          searchString={searchString}
          readonly={props.readonly}
          reload={reload}
          eventId={eventId}
        ></ParticipantListWidget>
      </div>

      <ModalInviteEventMembers
        modalTitle="Invite Participants"
        handleSubmit={onSubmit}
        isOpen={showAddModal}
        isLoading={isLoading}
        setIsOpen={setShowAddModal}
        onSearch={(searchTerm: string) => setInviteMemberSearchTerm(searchTerm)}
        list={participantList}
        isSearching={isModalInviteMemberSearching}
        onSelectHandler={onSelectInviteMember}
        selectedList={invitedMemberList}
      />

      {/* <Modal
        title="Invite Participants"
        visible={showAddModal}
        okText="Send Invitations"
        okButtonProps={{ shape: "round" }}
        onOk={onSubmit}
        confirmLoading={isLoading}
        cancelButtonProps={{ shape: "round" }}
        onCancel={() => setShowAddModal(false)}
      >
        <Form form={form} layout="vertical">
          <ParticipantField
            className="pb-1"
            validationMessages={validationMessages}
            extendedView={true}
            form={form}
          />
        </Form>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
});
export default connect(mapStateToProps, {
  addInvites,
  getEventParticipantList,
  getParticipantListNonPaged,
  getClubModeratorList,
})(EventParticipantPane);
