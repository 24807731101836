import { Form, Button, Select, Col, Row, Radio, Spin } from "antd";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getStudentList } from "../../../actions/student";
import { addClubMember } from "../../../actions/club";

import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { useLocation } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddClubMemberForm = (props: {
  getStudentList: Function;
  addClubMember: Function;
}) => {
  const { getStudentList, addClubMember } = props;
  const location: any = useLocation();

  const [addClubMemberForm] = Form.useForm();
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [moderatorList, setModeratorList] = useState<any[]>([]);
  const [clubStudentList, setClubStudentList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const onFinish = async (formData: any) => {
    let memberIds = clubStudentList.map((mod: { memberId: string }) => {
      return mod.memberId;
    });
    let values = {
      ...formData,
      clubId: location.state.memberGroupId,
      memberIds: memberIds,
    };

    setValidationMessages(new Array());
    setIsLoading(true);
    let res = await addClubMember(values, location.state.memberGroupId);
    if (res.status == OK || res.status == CREATED) {
      onReset();
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    setClubStudentList([]);
    addClubMemberForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const handleSelect = (memberId: string, e: any) => {
    addClubMemberForm.setFieldsValue({
      moderator: null,
    });
    if (memberId && e) {
      const checkMemberId = (obj: { memberId: string }) =>
        obj.memberId === memberId;
      if (!clubStudentList.some(checkMemberId)) {
        setClubStudentList((prevModerator: any) => [
          ...prevModerator,
          { memberId, name: e.children },
        ]);
      }
    }
  };

  const removeClubModerator = (memberId: string) => {
    let tempMod = clubStudentList.filter((mod: { memberId: string }) => {
      return mod.memberId != memberId;
    });
    setClubStudentList(tempMod);
  };

  const [searching, setSearching] = useState(false);

  useEffect(() => {
    setSearching(true);
    const delaySearch = setTimeout(async () => {
      if (searchTerm && searchTerm != "") {
        let params = {
          memberType: addClubMemberForm.getFieldValue("memberType"),
          SearchString: searchTerm,
        };
        const data = await getStudentList(params);
        let datax = data.data.map(
          (member: {
            memberId: string;
            firstName: string;
            lastName: string;
          }) => ({
            label: `${member.firstName} ${member.lastName}`,
            value: member.memberId,
          })
        );
        setModeratorList(datax);
      } else {
        setModeratorList([]);
      }
      setSearching(false);
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  const options =
    moderatorList &&
    moderatorList.map((d) => <Option key={d.value}>{d.label}</Option>);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Form
            form={addClubMemberForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert"
          >
            <h5 className="text-head pb-3">Add Club Members</h5>
            <Row gutter={24}>
              <Col
                span={8}
                xs={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
                xl={{ span: 7 }}
              >
                <div className="ant-col ant-form-item-label">
                  <label
                    htmlFor="students"
                    className="ant-form-item-required"
                    title="Students"
                  >
                    Students
                  </label>
                </div>
                <div className="d-flex w-100">
                  <Form.Item
                    className="mb-1 p-relative w-100"
                    name="student"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (clubStudentList.length != 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("Please select Students!")
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <Select
                      showSearch
                      showArrow={false}
                      filterOption={false}
                      autoClearSearchValue={true}
                      allowClear
                      onSearch={(searchString: string) =>
                        setSearchTerm(searchString)
                      }
                      onSelect={handleSelect}
                      notFoundContent={<div>Not found</div>}
                      placeholder="Select Members"
                    >
                      {options}
                    </Select>
                  </Form.Item>
                  {searching ? (
                    <div className="select-search">
                      <Spin />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <ul className="form-ul">
                  {clubStudentList &&
                    clubStudentList.map((data: any) => {
                      return (
                        <li key={data.memberId} className="fadein">
                          {data.name}
                          <span
                            onClick={() => removeClubModerator(data.memberId)}
                          >
                            <DeleteOutlined />
                          </span>
                        </li>
                      );
                    })}
                </ul>
                <div
                  className={`${
                    validationMessages &&
                    validationMessages.error &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && (
                    <AlertContainer data={validationMessages} />
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <div className="w-100 d-flex mt-3">
                  <Button
                    className="btn-breeze-box mr-2"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Invite Selected Members
                  </Button>
                  <Button disabled={isLoading} onClick={onReset}>
                    Reset Fields
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  getStudentList,
  addClubMember,
})(AddClubMemberForm);
