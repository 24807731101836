import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";

export const getTeacherList = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Member/list?MemberTypes=1&SearchString=${params.SearchString}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

//add Teacher
export const addTeacherUser = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Member/teacher/`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Teacher User Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const addTeacherUserBulk = (formData, params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Member/teacher/upload`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Teacher Users Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", "Check the CSV file", "warning"));
    }
    return response;
  }
};

export const updateTeacherUser = (formData, memberId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/Member/teacher/${memberId}`,
      formData,
      config
    );

    dispatch(
      setAlert("Success", "Teacher User Successfully updated", "success")
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};
