import { Button, Col, PageHeader, Row } from "antd";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../../ContentHeader/ContentHeader";
import AddTeacherBulkForm from "./AddTeacherBulkForm";

const AddTeacherBulk = (props: any) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate(-1)}
          title="Upload Teachers"
          extra={[<Button className="btn-radius" key="back" onClick={() => navigate(-1)}>Cancel</Button>]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <AddTeacherBulkForm />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddTeacherBulk;
