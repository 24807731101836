import { Button, Col, Form, message, Row, Select, Space, Upload } from "antd";
import { UploadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSchoolList } from "../../../actions/school";
import { addTeacherUserBulk } from "../../../actions/teacher";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import AlertContainer2 from "../../../layouts/AlertContainer2";

const { Option } = Select;

const AddTeachertBulkForm = (props: any) => {
  const [addTeachertBulkForm] = Form.useForm();
  const { addTeacherUserBulk, getSchoolList } = props;
  const [validationMessages, setValidationMessages]: any = useState({});
  const [fileList, setFileList] = useState([] as any);
  const [uploading, setUploading] = useState(false);
  const [schoolList, setSchoolList]: any = useState([]);
  const [isLoadingSchoolList, setIsLoadingSchoolList] = useState(false);

  const onFinish = async (values: any) => {
    handleUpload(values);
  };

  const handleUpload = async (params: any) => {
    const formData = new FormData();
    fileList.forEach((file: any) => {
      formData.append("csvFile", file);
    });
    setUploading(true);
    const res = await addTeacherUserBulk(formData, params);
    if (res.status == OK || res.status == CREATED) {
      onReset();
      setFileList([]);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setUploading(false);
  };

  const onReset = () => {
    addTeachertBulkForm.resetFields();
    setFileList([]);
  };

  const handleFormErrors = (data: any) => {
    if (data && data.errors) {
      let keyString: any;
      let prevKey;
      let dataTemp: any = {};
      for (let property in data) {
        keyString = property.split(".")[0];
        if (prevKey === keyString) {
          for (let i = 0; i < data[property].length; i++) {
            dataTemp[keyString].push(data[property][i]);
          }
        } else {
          dataTemp[keyString] = data[property];
        }
        prevKey = keyString;
      }

      setValidationMessages(dataTemp);
    } else {
      var obj = {};
      for (const [key, value] of Object.entries(data)) {
        Object.assign(obj, { [key]: value });
      }
      setValidationMessages(obj);
    }
  };

  const uploadProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error(`${file.name} is not a csv file`);
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };

  useEffect(() => {
    const fetchSchoolList = async () => {
      setIsLoadingSchoolList(true);
      const data = await getSchoolList();
      setSchoolList(data.data);
      setIsLoadingSchoolList(false);
    };

    fetchSchoolList();
  }, []);
  return (
    <Row>
      <Col span={6} xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 12 }}>
        <Form
          form={addTeachertBulkForm}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          className="breeze-form-1 custom-alert"
        >
          <div className="upload-label mb-1">
            <label>Teacher List</label>
          </div>
          <div className="upload-container">
            <Form.Item
              name="upload"
              rules={[
                {
                  required: true,
                  message: "Please select a file!",
                },
              ]}
            >
              <Upload {...uploadProps}>
                <Button className="btn-radius" icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </div>

          <div className="note-container">
            <p>
              Note: Upload user’s list in .csv format using the template below.
            </p>
          </div>

          <div className="sample-file-template">
            <div>
              <FileExcelOutlined />
            </div>
            <div className="">
              <a href="https://cdnbreeze001.azureedge.net/files/csv-teacher-template.csv">
                teacher-list-template.csv
              </a>
            </div>
          </div>
          <div
            className={`mb-3 force-alert ${
              validationMessages != 0 && "has-error"
            }`}
          >
            {validationMessages.length != 0 && (
              <AlertContainer2 data={validationMessages} />
            )}
          </div>
          <div className="button-container">
            <Space>
              <Button
                className="btn-radius"
                type="primary"
                htmlType="submit"
                disabled={fileList.length === 0}
                loading={uploading}
              >
                Save Teacher
              </Button>
              <Button className="btn-radius" onClick={onReset}>
                Reset
              </Button>
            </Space>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default connect(null, { addTeacherUserBulk, getSchoolList })(
  AddTeachertBulkForm
);
