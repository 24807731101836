import { Skeleton, Form, Input, Tag, Button, Select, Col, Row, InputNumber, DatePicker, DatePickerProps, Spin } from "antd";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { createAnnouncement, updateAnnouncement, deleteAnnouncement, getAnnouncement } from "../../../actions/announcement";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation, useNavigate } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import {
  DeleteOutlined
} from "@ant-design/icons";
import { getClubList } from "../../../actions/club";
import { Teacher } from "../../../helpers/memberTypes";

const { Option } = Select;
const { RangePicker } = DatePicker;

const AddAnnouncementForm = (props: {
  createAnnouncement: Function;
  updateAnnouncement: Function;
  deleteAnnouncement: Function;
  getAnnouncement: Function;
  getClubList: Function;
  onLoad?: Function;
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [addAnnouncementForm] = Form.useForm();
  const { createAnnouncement, updateAnnouncement, deleteAnnouncement, getAnnouncement, onLoad, getClubList } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPublished, setIsPublished] = useState<boolean|null|undefined>(null);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [id, setId] = useState('');
  const [clubList, setClubList] = useState<any>([]);
  const [selectedClubList, setSelectedClubList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };

  var announcementTitle = `${
    location?.state
    && location?.state?.userRole
    && location?.state?.userRole == 'SuperAdmin'
      ? 'Maintenance '
      : ''}Announcement`;

  const onFinish = async (formData: any) => {
   
    setValidationMessages(new Array());
    setIsLoading(true);
    let res;

    formData.startDate = formData['date'][0];
    formData.endDate = formData['date'][1];

    const clubIds = selectedClubList.map((e: any) => e.memberGroupId);

    formData.clubIds = clubIds;

    if (isEditMode) {
      res = await updateAnnouncement(
        id,
        formData
      );
    } else {
      for (var i = 0; i < 1; i++){
        res = await createAnnouncement({
          ...formData,
        });
      }
    }
    if (res.status == OK || res.status == CREATED) {
      setIsPublished(false);

      if (!isEditMode) {
        setId(res.data.announcementId);
        if (onLoad)
          onLoad(id, announcementTitle);
        setIsEditMode(true);
        navigate("/admin/announcements");       
      }    

    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    

    setIsLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    addAnnouncementForm.resetFields();
  };

  //deactivate
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteAnnouncement(id);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
      onReset();
      setIsEditMode(false);
      setId('');
      if(onLoad)
        onLoad();
    } else if (res.status === BAD_REQUEST) {
      handleFormErrors(res.data);
    }
    setIsDeleteLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const removeClub = (memberGroupId: string) => {

    console.log(selectedClubList);
    let tempMod = selectedClubList.filter((mod: { memberGroupId: string }) => {
      return mod.memberGroupId != memberGroupId;
    });
    setSelectedClubList(tempMod);
  };

  const handleSelect = (memberGroupId: string, e: any) => {
    setSearchTerm("");
    addAnnouncementForm.setFieldsValue({
      club: null
    });
    if (memberGroupId && e) {
      const checkMemberGroupId = (obj: { memberGroupId: string }) =>
        obj.memberGroupId === memberGroupId;
      if (!selectedClubList.some(checkMemberGroupId)) {
        setSelectedClubList((prevClub: any) => [
          ...prevClub,
          { memberGroupId, name: e.children }
        ]);
      }
    }
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value }); 
    }
    setValidationMessages(obj);
  };

  const getAnnouncementData = async () => {
    setIsInitialLoading(true);
    const res = await getAnnouncement(location?.state?.record?.announcementId);
    if (res.data && res.data.isActive) {
      setId(res.data.announcementId);
      if (onLoad)
        onLoad(res.data.announcementId, announcementTitle);
      setIsEditMode(true);

      addAnnouncementForm.setFieldsValue({
        title: res.data.title,
        body: res.data.body,
        date: [
          moment(res.data.startDate),
          moment(res.data.endDate)
        ]
      });

      setIsPublished(res.data.hasNotified);

      setSelectedClubList(res.data.clubs.map((e: any) => {
        return {
          memberGroupId: e.memberGroupId,
          name: e.name,
        };
      }));
    }
    setIsInitialLoading(false);
  };

  useEffect(() => {
    if (location && location.state && location.state.record)
      getAnnouncementData();
  }, []);

  useEffect(() => {
    setSearching(true);

    const delaySearch = setTimeout(async () => {
        let params = {
          ...initialPagination,
          searchString: searchTerm,
          memberType: Teacher,
          status: -1
        };
        const data = await getClubList(params);

        let datax = data.data.items.map(
          (group: {
            memberGroupId: string;
            name: string;
          }) => ({
            label: `${group.name}`,
            value: group.memberGroupId
          })
        );
        setClubList(searchTerm && searchTerm != "" ? datax : datax.slice(0, 5));
      
      setSearching(false);
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  const options =
    clubList &&
    clubList.map((d: any) => <Option key={d.value}>{d.label}</Option>);


  if (isInitialLoading)
    return (
      <Skeleton loading={true} active />
    )
  else
    return (
      <Fragment>
        <ModalDeleteAlert
          modalTitle="Announcement"
          isModalDeleteVisible={isModalDeleteVisible}
          onModalCancelHandler={() => setIsModalDeleteVisible(false)}
          deleteItemHandler={deleteItemHandler}
          isDeleteLoading={isDeleteLoading}
        />
        <Row>
          <Col span={24}>
            <Form
              form={addAnnouncementForm}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="breeze-form-1 custom-alert"
            >
              <div>
                <h5
                  className="text-head pb-3"
                  style={{display: "inline"}}
                  >
                  {announcementTitle} Details
                </h5>
                <Tag
                  color={isPublished ? 'success' : 'default'}
                  style={{margin: "0 8px"}}
                  >
                    {isPublished ? 'Published' : 'Unpublished'}
                </Tag>
              </div>
              
              <Row>
                <Col
                  span={8}
                  xs={{ span: 24 }}
                  lg={{ span: 16 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    className="mb-0"
                    label={<span>Header</span>}
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input Header!",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            //resolve to use original rule
                            return Promise.resolve();
                          }
                          if (!isEmptyOrSpaces(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("Please input Header!")
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Enter Header" />
                  </Form.Item>
                  <div
                    className={`mb-4 ${
                      validationMessages && validationMessages.Name && "has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.Name && (
                      <AlertContainer data={validationMessages.Name} />
                    )}
                  </div>

                  <Form.Item
                    label={<span>{announcementTitle}</span>}
                    className="pt-2 mb-2"
                    name="body"
                    rules={[
                      {
                        required: true,
                        message: `Please input ${announcementTitle}!`,
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            //resolve to use original rule
                            return Promise.resolve();
                          }
                          if (!isEmptyOrSpaces(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error(`Please input ${announcementTitle}!`)
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <TextArea rows={4} placeholder="" />
                  </Form.Item>

                  <div
                    className={`${
                      validationMessages &&
                      validationMessages.Body &&
                      "mb-4 has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.Description && (
                      <AlertContainer data={validationMessages.Description} />
                    )}
                  </div>


                  {location?.state
                  && location?.state?.userRole
                  && location?.state?.userRole == 'Admin' && (
                  <div>
                    <div className="ant-col ant-form-item-label">
                      <label
                        htmlFor="club"
                        title="Club"
                      >
                        Club Filter
                      </label>
                    </div>
                    <div
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        fontStyle: "italic"
                      }}
                    >
                      In case of non-selection, the announcement will be sent to all
                    </div>
                    <div className="d-flex w-100">
                      <Form.Item
                        className="mb-1 p-relative w-100"
                        name="club"
                      >
                        <Select
                          showSearch
                          showArrow={false}
                          filterOption={false}
                          autoClearSearchValue={true}
                          allowClear
                          onSearch={(searchString: string) =>
                            setSearchTerm(searchString)
                          }
                          onSelect={handleSelect}
                          notFoundContent={<div>Not found</div>}
                          placeholder="Search Clubs"
                        >
                          {options}
                        </Select>
                      </Form.Item>
                      {searching ? (
                        <div className="select-search">
                          <Spin />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <ul className="form-ul">
                      {selectedClubList &&
                        selectedClubList.map((data: any) => {
                          return (
                            <li key={data.memberGroupId} className="fadein">
                              {data.name}
                              <span
                                onClick={() => removeClub(data.memberGroupId)}
                              >
                                <DeleteOutlined />
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  )}
                  

                  <Form.Item
                    name="date"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Start Date and End Date",
                      },
                    ]}
                    {...(validationMessages &&
                    validationMessages.error &&
                    validationMessages.error === "There is a conflict event."
                      ? { validateStatus: "error" }
                      : "")}
                    {...(validationMessages &&
                    validationMessages.error &&
                    validationMessages.error === "There is a conflict event."
                      ? {
                          help: validationMessages.error,
                        }
                      : "")}
                  >
                    <RangePicker
                      use12Hours
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div
                className={`${
                  validationMessages &&
                  validationMessages.error &&
                  "mb-4 has-error"
                }`}
              >
                {validationMessages && validationMessages.error && (
                  <AlertContainer data={validationMessages} />
                )}
              </div>

              <Row gutter={24}>
                <Col span={24}>
                  <div className="w-100 d-flex">
                    <Button
                      className="btn-breeze-box mr-2 btn-radius"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Save {announcementTitle}
                    </Button>
                    <Button
                      className="btn-radius"
                      disabled={isLoading}
                      onClick={onReset}
                    >
                      Reset Fields
                    </Button>
                    {isEditMode && (
                      <Button
                        className="ml-auto btn-radius"
                        disabled={isLoading}
                        onClick={() => setIsModalDeleteVisible(true)}
                        type="primary"
                        danger
                      >
                        Deactivate
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Fragment>
    );
};

export default connect(null, {
  createAnnouncement, updateAnnouncement, deleteAnnouncement, getAnnouncement, getClubList
})(AddAnnouncementForm);
