import { addInvites } from "../../../actions/event";
import { Button, Form, Modal, Tabs, Collapse } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getEventParticipantList,
  getParticipantListNonPaged,
} from "../../../actions/participant";
import { OK } from "../../../helpers/statuscodes";
import { UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import ParticipantListWidget from "../../widget/ParticipantListWidget";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import ModalInviteClubMembers from "../Modals/ModalInviteClubMembers";
import ModalInviteEventMembers from "../Modals/ModalInviteEventMembers";
import ModalSuccess from "../Modals/ModalSuccess";
import { userPermissions } from "../../../permissions/user-permissions";
import { getClubModeratorList } from "../../../actions/club";
const { Panel } = Collapse;

interface IEventParticipantPageProps {
  auth: any;
  event: any;
  readonly: boolean;
  addInvites: Function;
  getEventParticipantList: Function;
  getParticipantListNonPaged: Function;
  getClubModeratorList: Function;
}

const EventParticipantPane = (props: IEventParticipantPageProps) => {
  const { auth, event, getParticipantListNonPaged, getClubModeratorList } =
    props;
  const { eventId } = event;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const [validationMessages, setValidationMessages]: any = useState({});
  const [showRequestOnly, setShowRequestOnly] = useState(false);
  const [isClubModerator, setIsClubModerator] = useState(false);
  const [participants, setParticipants] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [pendingCount, setPendingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [form] = Form.useForm();
  const [inviteMemberSearchTerm, setInviteMemberSearchTerm] = useState("");
  const [isModalInviteMemberSearching, setIsModalInviteMemberSearching] =
    useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [invitedMemberList, setInvitedMemberList]: any = useState([]);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalInviteClubMemberOpen, setIsModalInviteClubMemberOpen] =
    useState(false);

  const onSelectInviteMember = (checked: boolean, id: string) => {
    if (checked) {
      if (
        invitedMemberList.filter((item: string) => item.includes(id)).length ==
        0
      ) {
        setInvitedMemberList(invitedMemberList.concat(id));
      }
    } else {
      setInvitedMemberList(
        invitedMemberList.filter((item: string) => item !== id)
      );
    }
  };

  const getClubModeratorStatus = async () => {
    let params = {
      page: 1,
      pageSize: 10,
      searchString: `${auth.member.emailAddress}`,
      memberGroupId: event.memberGroupId,
    };
    const res = await getClubModeratorList(params);
    setIsClubModerator(
      res.data.items.some((e: any) => e.memberId == auth.member.memberId)
    );
  };

  let permissions = {
    canDeleteEvent:
      userPermissions[auth.member.memberType] &&
      userPermissions[auth.member.memberType]?.deleteEvent,
  };

  const getListToInvite = async () => {
    let params = {
      SearchString: inviteMemberSearchTerm,
    };
    const data = await getParticipantListNonPaged(params);
    const formattedData = data.data
      .filter((item: any) => {
        if (item.id !== signedInUserMemberId) {
          return item;
        }
      })
      .map((item: any) => {
        return {
          id: item.id + "-" + item.type,
          name: item.name,
          type: item.type,
        };
      });
    setParticipantList(formattedData);
    setIsModalInviteMemberSearching(false);
  };

  useEffect(() => {
    getClubModeratorStatus();
  }, []);

  useEffect(() => {
    setIsModalInviteMemberSearching(true);
    const delaySearch = setTimeout(async () => {
      getListToInvite();
    }, 600);

    return () => clearTimeout(delaySearch);
  }, [inviteMemberSearchTerm]);

  const onSubmit = async () => {
    setIsLoading(true);
    const participants = invitedMemberList.map((item: any) => ({
      id: item.split("-")[0],
      type: parseInt(item.split("-")[1]),
    }));
    const res = await props.addInvites({
      eventId,
      participants,
    });
    setIsLoading(false);
    if (res.status === OK) {
      setParticipants([]);
      setShowAddModal(false);
      setReload((old) => !old);

      setSuccessMessage("You have successfully invited event participants.");
      setInvitedMemberList([]);
      setIsModalInviteClubMemberOpen(false);
      setIsModalSuccessOpen(true);
    }
  };
  return (
    <div className="participants-pane">
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p
          className="text-center"
          dangerouslySetInnerHTML={{ __html: successMessage }}
        ></p>
      </ModalSuccess>      
      <div>
        <Collapse
          defaultActiveKey="-1"
          onChange={(key) => {
            setShowRequestOnly(key.indexOf("|") > 0);
            setSelectedStatus(parseInt(key[0]?.split("|")[0]));
          }}
        >
          <Panel key="-1" header="All Record" className="admin-participant-panel">
            <ParticipantListWidget
              onReload={(data: any) =>
                setPendingCount((previous) =>
                  data ? data.pendingCount : previous - 1
                )
              }
              participantStatus='-1'
              invitedFilter={!showRequestOnly}
              searchString={searchString}
              readonly={props.readonly}
              reload={reload}
              eventId={eventId}
            ></ParticipantListWidget>
          </Panel>
          <Panel key="1" header="Accepted" className="admin-participant-panel">
            <ParticipantListWidget
              onReload={(data: any) =>
                setPendingCount((previous) =>
                  data ? data.pendingCount : previous - 1
                )
              }
              participantStatus='1'
              invitedFilter={!showRequestOnly}
              searchString={searchString}
              readonly={props.readonly}
              reload={reload}
              eventId={eventId}
            ></ParticipantListWidget>
          </Panel>
          <Panel key="2" header="Declined" className="admin-participant-panel">
            <ParticipantListWidget
              onReload={(data: any) =>
                setPendingCount((previous) =>
                  data ? data.pendingCount : previous - 1
                )
              }
              participantStatus='2'
              invitedFilter={!showRequestOnly}
              searchString={searchString}
              readonly={props.readonly}
              reload={reload}
              eventId={eventId}
            ></ParticipantListWidget>
          </Panel>
          <Panel key="0" header="No Response" className="admin-participant-panel">
            <ParticipantListWidget
              onReload={(data: any) =>
                setPendingCount((previous) =>
                  data ? data.pendingCount : previous - 1
                )
              }
              participantStatus='0'
              invitedFilter={!showRequestOnly}
              searchString={searchString}
              readonly={props.readonly}
              reload={reload}
              eventId={eventId}
            ></ParticipantListWidget>
          </Panel>
          <Panel key="0|" header="Request" className="admin-participant-panel">
            <ParticipantListWidget
              onReload={(data: any) =>
                setPendingCount((previous) =>
                  data ? data.pendingCount : previous - 1
                )
              }
              participantStatus={'0'}
              invitedFilter={false}
              searchString={searchString}
              readonly={props.readonly}
              reload={reload}
              eventId={eventId}
            ></ParticipantListWidget>
          </Panel>
        </Collapse>
      </div>

      <ModalInviteEventMembers
        modalTitle="Invite Participants"
        handleSubmit={onSubmit}
        isOpen={showAddModal}
        isLoading={isLoading}
        setIsOpen={setShowAddModal}
        onSearch={(searchTerm: string) => setInviteMemberSearchTerm(searchTerm)}
        list={participantList}
        isSearching={isModalInviteMemberSearching}
        onSelectHandler={onSelectInviteMember}
        selectedList={invitedMemberList}
      />

      {/* <Modal
        title="Invite Participants"
        visible={showAddModal}
        okText="Send Invitations"
        okButtonProps={{ shape: "round" }}
        onOk={onSubmit}
        confirmLoading={isLoading}
        cancelButtonProps={{ shape: "round" }}
        onCancel={() => setShowAddModal(false)}
      >
        <Form form={form} layout="vertical">
          <ParticipantField
            className="pb-1"
            validationMessages={validationMessages}
            extendedView={true}
            form={form}
          />
        </Form>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
});
export default connect(mapStateToProps, {
  addInvites,
  getEventParticipantList,
  getParticipantListNonPaged,
  getClubModeratorList,
})(EventParticipantPane);
