import { Fragment, useState, useEffect } from "react";
import { Button, Modal, Tooltip, message, Form } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { acceptEventInvite, declineEventInvite } from "../../actions/event";
import ModalDeclineReason from "../member-app/Modals/ModalDeclineReason";
import { OK } from "../../helpers/statuscodes";

const ModalEventView = (props: {
  acceptEventInvite: Function,
  declineEventInvite: Function,
  refresh: Function,
  eventData: any,
  conflicts: any,
  isVisible: boolean,
  onModalCancelHandler: any,
}) => {
  const {
    acceptEventInvite,
    declineEventInvite,
    eventData,
    conflicts,
    isVisible,
    onModalCancelHandler,
    refresh,
  } = props;
  const [hasInvite, setHasInvite] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isDeclineEventModalOpen, setIsDeclineEventModalOpen] = useState(false);
  const [isLoadingDeclineEventInvite, setIsLoadingDeclineEventInvite] = useState(false);
  const [declineEventForm] = Form.useForm();

  const ConflictTooltip = () =>{
    return(
      <div className="custom-tooltip">
        {
            <>
            <h3 className="conflict-h3">Conflict</h3>
            <p>Event has conflict{conflicts?.conflicts?.length>1&&'s'} with {conflicts?.conflicts?.map((d:any, i:number)=>`${d}${conflicts?.conflicts?.length === (i+2)?", and ": conflicts?.conflicts?.length === (i+1)?"":", "} `)}</p>
            </>
        }
      </div>
    )
  }

  const acceptInvite = async () => {
    if(eventData.userInvite!==undefined){
      setIsAcceptLoading(true);
      const result = await acceptEventInvite({
        eventId: eventData.eventId,
        inviteId: eventData.userInvite?.inviteId,
      });

      setIsAcceptLoading(false);
      if (result && result?.data) {
        message.success("Invite Accepted");
        refresh();
        onModalCancelHandler();
      } else {
        message.error("An Error occured!");
      }
    }
  };

  const declineInvite = async (formData: any) => {
    setIsLoadingDeclineEventInvite(true);
    let values = {
      ...formData,
      eventName: eventData.title,
      eventId: eventData.eventId,
      inviteId: eventData.userInvite?.inviteId,
    };

    const result = await props.declineEventInvite(values);
    setIsLoadingDeclineEventInvite(false);

    if (result.status == OK) {
      declineEventForm.resetFields();
      message.success(
        `You have successfully submitted decline reason for ${eventData?.title} invitation.`
      );
      refresh();
      setIsDeclineEventModalOpen(false);
      onModalCancelHandler();
    } else {
      message.error("An Error occured!");
    }
  };

  useEffect(() => {
      if(eventData!==undefined){
          if(eventData.userInvite?.status === 0 && eventData.userInvite?.createdBy !== eventData.userInvite?.memberId && eventData.userInvite?.inviteId !== undefined){
            setHasInvite(true);
          }else{
            setHasInvite(false);
          }
      }
    }, [isVisible]);

  return (
    <>
     <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={declineInvite}
        isOpen={isDeclineEventModalOpen}
        setIsOpen={setIsDeclineEventModalOpen}
        form={declineEventForm}
        isLoading={isLoadingDeclineEventInvite}
      />
    <Modal
      title="Event Details"
      className="modal-event-item"
      centered
      visible={isVisible}
      onCancel={() => onModalCancelHandler()}
      footer={(hasInvite === true) && [
        <Button
        type="primary"
        key="submit"
        style={{borderRadius:5}}
        onClick={acceptInvite}
        loading={isAcceptLoading}
      >
        Accept
      </Button>,
      <Button
        key="submit-2"
        onClick={() => setIsDeclineEventModalOpen(true)}
        loading={isLoadingDeclineEventInvite}
        style={{borderRadius:5}}
        disabled={isAcceptLoading}
      >
        Decline
      </Button>
      ]}
    >
      <div className="d-flex">
        <div className="d-flex-column ml-2 w-100">
          <div className="mt-1">
            <div className="event-data">
              {eventData && (
                <Fragment>
                  <h3>{eventData?.title}</h3>
                  {(conflicts !== null && conflicts!==false) && (
                    <Tooltip title={<ConflictTooltip/>} className="overlap-tooltip" color="white" >
                      <h4 className="conflict-label"><ExclamationCircleOutlined/>{` ${conflicts?.conflicts?.length} conflicts`}</h4>
                    </Tooltip>
                  )}
                  <h4>{eventData?.isSchoolWide ? "Schoolwide event" : eventData?.memberGroup ?? ""}</h4>
                  <p>{moment(eventData.start).format(
                      "dddd, Do MMMM YYYY h:mm a"
                    )} - {moment(eventData.start).isSame(eventData.end, 'day')? moment(eventData.end).format(
                      "h:mm a"
                    ) : moment(eventData.end).format(
                      "dddd, Do MMMM YYYY h:mm a"
                    )}</p>
                  <p>at {eventData?.location ?? "N/A"}</p>
                  <p>{eventData?.description ?? "No Description Available"}</p>
                  <p>Organizer: {eventData?.organizer ?? "N/A"}</p>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
    </>
  );
};

export default connect(null, { acceptEventInvite, declineEventInvite })(ModalEventView);