import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Col, Divider, Form, Input, Modal, Row, Skeleton } from "antd";
import {
  getClubMemberList,
  addClubMember,
  declineClubJoinRequest,
  acceptClubJoinRequest,
  dropClubMember,
  promoteMember,
  getNonClubMember,
} from "../../../actions/club";
import ClubEventItemGrid from "./ClubEventItemGrid";
import { Button, Tooltip } from "antd";
import {
  TableOutlined,
  AppstoreOutlined,
  FilterOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import ClubEventItemList from "./ClubEventItemList";
import { EventModel } from "../../../interface/Event";
import { useParams } from "react-router-dom";
import ClubMemberItemGrid from "./ClubMemberItemGrid";
import { MemberModel } from "../../../interface/Member";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import {
  BAD_REQUEST,
  CREATED,
  NO_CONTENT,
  OK,
} from "../../../helpers/statuscodes";
import ClubParticipantField from "../../super-admin/select-fields/ClubParticipantField";
import ClubMemberItemList from "./ClubMemberItemList";
import ModalDeclineReason from "../Modals/ModalDeclineReason";
import ModalInviteClubMembers from "../Modals/ModalInviteClubMembers";
import ModalSuccess from "../Modals/ModalSuccess";
import { StudentString } from "../../../helpers/memberTypes";
import { ClubMemberModel } from "../../../interface/Club";
import ModalConfirm from "../Modals/ModalDeclineConfirmation";

const { Search } = Input;
interface UserValue {
  label: string;
  value: string;
}
const ClubMembers = (props: {
  Permissions: any;
  getClubMemberList: Function;
  addClubMember: Function;
  acceptClubJoinRequest: Function;
  declineClubJoinRequest: Function;
  dropClubMember: Function;
  groupName: string;
  clubUserMemberShipType: string;
  getNonClubMember: Function;
  promoteMember: Function;
  auth: any;
  pushData: any;
  flutterConnect: boolean;
}) => {
  const { memberGroupId } = useParams();
  const {
    Permissions: { canInviteMember, isDisabled },
    getClubMemberList,
    addClubMember,
    acceptClubJoinRequest,
    declineClubJoinRequest,
    dropClubMember,
    groupName,
    clubUserMemberShipType,
    getNonClubMember,
    promoteMember,
    auth,
    pushData,
    flutterConnect
  } = props;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const initialPagination = {
    current: 1,
  };
  const PAGE_SIZE = 6;
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState(initialPagination);
  const [searchString, setSearchString] = useState("");
  const [clubMembers, setClubMembers] = useState<MemberModel[]>([]);

  const [clubMemberFilter, setClubMemberFilter] = useState(1);
  const [isViewType, setIsViewType] = useState(1);

  const [isListLoading, setIsListLoading] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);
  const [hasMorePage, setHasMorePage] = useState(false);

  const [validationMessages, setValidationMessages]: any = useState({});
  //   const [participants, setParticipants] = useState<any[]>();
  const [participants, setParticipants] = useState<UserValue[]>([]);
  const [form] = Form.useForm();
  const [formDeclineJoinRequest] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalDeclineReasonOpen, setIsModalDeclineReasonOpen] =
    useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState<string>("");
  const [selectedMemberType, setSelectedMemberType] = useState<string>("");

  const [selectedMemberFullName, setSelectedMemberFullName] =
    useState<string>("");

  const [isLoadingModalDeclineReason, setIsLoadingModalDeclineReason] =
    useState(false);
  const [reasonModalType, setReasonModalType] = useState("");
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isLoadingModalConfirm, setIsLoadingModalConfirm] = useState(false);
  const [isModalInviteClubMemberOpen, setIsModalInviteClubMemberOpen] =
    useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);

  const [inviteMemberSearchTerm, setInviteMemberSearchTerm] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [studentListPagination, setStudentListPagination] =
    useState(initialPagination);
  const [isModalInviteMemberLoading, setIsModalInviteMemberLoading] =
    useState(false);
  const [isModalInviteMemberSearching, setIsModalInviteMemberSearching] =
    useState(false);
  const [invitedMemberList, setInvitedMemberList]: any = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const onFinish = async (formData: any) => {
    setValidationMessages(new Array());
    setIsLoading(true);
    let memberIds = formData.participants.map((item: any) => {
      return item.value;
    });

    let res = await addClubMember(
      { memberIds, clubId: memberGroupId, membershipType: 0 },
      memberGroupId
    );
    if (res.status == OK || res.status == CREATED) {
      onReset();
      setPagination(initialPagination);
      fetchClubMembers(initialPagination, false);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const handleInviteMmembers = () => {
    form.submit();
  };

  const onReset = () => {
    form.resetFields();
    setParticipants(Array());
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const handleScroll = (event: any) => {
    // if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
    //   if (!lockLoading) {
    //     console.log("load more data");
    //     fetchClubMembers(pagination, true);
    //   }
    // }
  };

  const onApproveRequestHandler = async (memberId: string) => {
    SetLoadingItem(memberId, true);

    let params = {
      clubId: memberGroupId,
      memberId,
    };
    const res = await acceptClubJoinRequest(params);
    if (res.status == OK) {
      setIsModalDeclineReasonOpen(false);
      formDeclineJoinRequest.resetFields();
      setClubMembers(
        clubMembers.map((member: any) => {
          if (member.memberId === memberId) {
            return {
              ...member,
              ...res.data,
              loading: false,
            };
          } else {
            return member;
          }
        })
      );
    }
    if (!res.data) {
      SetLoadingItem(memberId, false);
    }
  };

  const onDeclineRequestHandler = (memberId: string) => {
    setSelectedMemberId(memberId);
    setReasonModalType("Decline");
    setIsModalDeclineReasonOpen(true);
  };

  const submitFormDeclineJoinRequest = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);

    let params = {
      ...formData,
      clubId: memberGroupId,
      memberId: selectedMemberId,
      dropMember: true,
    };
    const res = await declineClubJoinRequest(params);
    if (res.status == OK) {
      setIsModalDeclineReasonOpen(false);
      formDeclineJoinRequest.resetFields();
      setClubMembers(
        clubMembers.map((member: any) => {
          if (member.memberId === selectedMemberId) {
            return {
              ...res.data,
              loading: false,
            };
          } else {
            return member;
          }
        })
      );
    }
    setIsLoadingModalDeclineReason(false);
  };

  const submitFormDropMember = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);

    let params = {
      ...formData,
      clubId: memberGroupId,
      memberId: selectedMemberId,
    };
    const res = await dropClubMember(params);
    if (res.status == NO_CONTENT || res.status === OK) {
      formDeclineJoinRequest.resetFields();
      setIsModalDeclineReasonOpen(false);
      setIsModalSuccessOpen(true);
      setSuccessMessage(
        `You have successfully dropped <span class="text-error">${selectedMemberFullName}</span> as member of the Club.`
      );
      setClubMembers(
        clubMembers.filter((member: any) => {
          if (member.memberId !== selectedMemberId) {
            return member;
          }
        })
      );
    }
    setIsLoadingModalDeclineReason(false);
  };

  const onDropMemberHandler = async (member: ClubMemberModel) => {
    setSelectedMemberFullName(member.firstName + " " + member.lastName);
    setSelectedMemberId(member.memberId);
    setSelectedMemberType(member.memberType);
    setReasonModalType("Drop");
    setIsModalDeclineReasonOpen(true);
  };

  const onPromoteMemberHandler = async (memberId: string, fullname: string) => {
    setSelectedMemberFullName(fullname);
    setSelectedMemberId(memberId);
    setConfirmMessage(
      `Are you sure you want to promote ${fullname} as Club Moderator ?`
    );
    setIsModalConfirmOpen(true);
  };

  const onSubmitModalConfirmPromotion = async () => {
    setIsLoadingModalConfirm(true);
    let params = {
      clubId: memberGroupId,
      memberId: selectedMemberId,
    };
    const res = await promoteMember(params);
    if (res.status == OK || res.status == NO_CONTENT) {
      setIsModalConfirmOpen(false);
      setIsModalSuccessOpen(true);
      setSuccessMessage(
        `You have successfully invited ${selectedMemberFullName} as Club Moderator.
        An email notification will also be sent.`
      );
    }
  };

  // const onSubmitModalConfirmDrop = async () => {
  //   setIsLoadingModalConfirm(true);

  //   let params = {
  //     clubId: memberGroupId,
  //     memberId: selectedMemberId,
  //   };
  //   const res = await dropClubMember(params);
  //   if (res.status == NO_CONTENT) {
  //     setIsModalConfirmOpen(false);
  //     setClubMembers(
  //       clubMembers.filter((member: any) => {
  //         if (member.memberId !== selectedMemberId) {
  //           return member;
  //         }
  //       })
  //     );
  //   }
  //   setIsLoadingModalConfirm(false);
  // };

  const SetLoadingItem = (memberId: any, loading: boolean) => {
    setClubMembers(
      clubMembers.map((member: any) => {
        if (member.memberId === memberId) {
          return {
            ...member,
            loading,
          };
        } else {
          return member;
        }
      })
    );
  };

  const onSubmitModalInviteClubMember = async () => {
    setIsModalInviteMemberLoading(true);

    let res = await addClubMember(
      {
        memberIds: invitedMemberList,
        clubId: memberGroupId,
        membershipType: 0,
      },
      memberGroupId
    );
    if (res.status == OK || res.status == CREATED) {
      getListToInvite();
      setSuccessMessage(" You have successfully invited a club members.");
      setStudentListPagination(initialPagination);
      setInvitedMemberList([]);
      setIsModalInviteClubMemberOpen(false);
      setIsModalSuccessOpen(true);
      fetchClubMembers(initialPagination, false);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsModalInviteMemberLoading(false);
  };

  const onSelectInviteMember = (checked: boolean, memberId: string) => {
    if (checked) {
      if (
        invitedMemberList.filter((item: string) => item.includes(memberId))
          .length == 0
      ) {
        setInvitedMemberList(invitedMemberList.concat(memberId));
      }
    } else {
      setInvitedMemberList(
        invitedMemberList.filter((item: string) => item !== memberId)
      );
    }
  };

  const getListToInvite = async () => {
    let params = {
      memberGroupId,
      membershipType: 1,
      searchString: inviteMemberSearchTerm,
    };
    const data = await getNonClubMember(params);
    const formattedData = data.data.filter((item: any) => {
      if (item.memberId !== signedInUserMemberId) {
        return {
          item,
        };
      }
    });

    setStudentList(formattedData);
    setIsModalInviteMemberSearching(false);
  };

  useEffect(() => {
    setIsModalInviteMemberSearching(true);
    const delaySearch = setTimeout(async () => {
      getListToInvite();
    }, 600);

    return () => clearTimeout(delaySearch);
  }, [inviteMemberSearchTerm]);

  const fetchClubMembers = async (paginationParam: any, isMore: boolean) => {
    setLockLoading(true);
    setIsListLoading(true);
    if (paginationParam.current === 1) {
      setClubMembers([]);
    }

    let params = {
      page: paginationParam.current,
      pageSize: 6,
      searchString,
      memberGroupId,
    };

    const res = await getClubMemberList(params);

    let items = res.data.items;
    if (items.length !== 0) {
      if (paginationParam.current !== 1) {
        const formattedData = items.map((item: MemberModel) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setClubMembers(clubMembers.concat(formattedData));
      } else {
        const formattedData = items.map((item: MemberModel) => {
          return {
            ...item,
            loading: false,
          };
        });
        setClubMembers(formattedData);
      }
      setPagination({
        current: paginationParam.current + 1,
      });
      const _hasMorePages =
        Math.ceil(res.data.totalItems / PAGE_SIZE) > pagination.current;
      setHasMorePage(_hasMorePages);
    }
    setIsListLoading(false);
    setTimeout(() => {
      setLockLoading(false);
    }, 500);
  };

  useEffect(() => {
    setPagination(initialPagination);
    fetchClubMembers(initialPagination, false);
  }, [searchString]);

  useEffect(() => {
    window.onscroll = async function (ev) {
      console.log(ev);
      if (window.innerHeight + window.scrollY >= (flutterConnect ? document.body.scrollHeight - 1  : document.body.scrollHeight)) {
        if (!lockLoading && hasMorePage) {
          await fetchClubMembers(pagination, true);
        }
      }
    };
  }, [pagination, lockLoading, hasMorePage]);

  useEffect(() => {
    if (pushData.data?.topic === "ClubMemberUpdate") {
      const pushInfo = JSON.parse(pushData.data?.pushData);
      if (
        pushInfo.MemberGroupId === memberGroupId &&
        pushData.data?.memberId !== auth.member.memberId
      ) {
        fetchClubMembers(initialPagination, false);
      }
    }
  }, [pushData]);

  return (
    <Fragment>
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p
          className="text-center"
          dangerouslySetInnerHTML={{ __html: successMessage }}
        ></p>
      </ModalSuccess>
      <ModalConfirm
        handleSubmit={onSubmitModalConfirmPromotion}
        isOpen={isModalConfirmOpen}
        setIsOpen={setIsModalConfirmOpen}
        isLoading={isLoadingModalConfirm}
      >
        <p
          className="mb-0"
          dangerouslySetInnerHTML={{ __html: confirmMessage }}
        ></p>
      </ModalConfirm>
      <ModalDeclineReason
        title={`${
          reasonModalType === "Decline"
            ? "Enter Decline Reason"
            : reasonModalType === "Drop" && "Enter Removal Reason"
        }`}
        handleSubmit={
          reasonModalType === "Drop"
            ? submitFormDropMember
            : reasonModalType === "Decline" && submitFormDeclineJoinRequest
        }
        isOpen={isModalDeclineReasonOpen}
        setIsOpen={setIsModalDeclineReasonOpen}
        form={formDeclineJoinRequest}
        isLoading={isLoadingModalDeclineReason}
      />
      <Modal
        className="modal-club-member-invite"
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Add Participants"
        footer={[
          <Button
            type="primary"
            onClick={() => handleInviteMmembers()}
            loading={isLoading}
          >
            Send Invitations
          </Button>,
        ]}
      >
        <Form
          onFinish={onFinish}
          className="modal-club-member-form"
          form={form}
          layout="vertical"
          size="large"
        >
          <ClubParticipantField
            className="pb-1"
            validationMessages={validationMessages}
            extendedView={true}
            participants={participants}
            setParticipants={setParticipants}
            form={form}
          />
        </Form>
      </Modal>
      <ModalInviteClubMembers
        modalTitle="Invite Club Members"
        handleSubmit={onSubmitModalInviteClubMember}
        isOpen={isModalInviteClubMemberOpen}
        isLoading={isModalInviteMemberLoading}
        setIsOpen={setIsModalInviteClubMemberOpen}
        onSearch={(searchTerm: string) => setInviteMemberSearchTerm(searchTerm)}
        list={studentList}
        isSearching={isModalInviteMemberSearching}
        onSelectHandler={onSelectInviteMember}
        selectedList={invitedMemberList}
        validationMessages={validationMessages}
      />
      <div className="content-container">
        <div className="club-member-list">
          <div className="list-controls">
            <div className="search-container">
              <Search
                //onKeyUp={onFilterSearchHandler}
                onSearch={(val) => setSearchString(val)}
                allowClear
                placeholder="Search member name"
                style={{ width: 280 }}
              />
            </div>
            <div className="list-filters">
              <Tooltip title="Table View">
                <Button
                  shape="circle"
                  icon={<TableOutlined />}
                  onClick={() => setIsViewType(2)}
                />
              </Tooltip>
              <Tooltip title="Grid View">
                <Button
                  shape="circle"
                  icon={<AppstoreOutlined />}
                  onClick={() => setIsViewType(1)}
                />
              </Tooltip>
              {canInviteMember && (
                <Button
                  disabled={isDisabled}
                  type="primary"
                  shape="round"
                  icon={<CheckOutlined size={16} />}
                  onClick={() => setIsModalInviteClubMemberOpen(true)}
                >
                  Invite Members
                </Button>
              )}
            </div>
          </div>
          <Fragment>
            <Row gutter={16} onWheel={handleScroll}>
              {isViewType === 1 ? (
                <Fragment>
                  {clubMembers.map((member: any) => (
                    <ClubMemberItemGrid
                      key={member.memberId}
                      member={member}
                      clubUserMemberShipType={clubUserMemberShipType}
                      onDeclineHandler={onDeclineRequestHandler}
                      onApproveHandler={onApproveRequestHandler}
                      onDropMemberHandler={onDropMemberHandler}
                      onPromoteMemberHandler={onPromoteMemberHandler}
                      onDropModApprovalHandler={() => console.log()}
                    />
                  ))}
                  {isListLoading && (
                    <Fragment>
                      {Array(8)
                        .fill(0)
                        .map((_, i) => {
                          return (
                            <Col
                              key={i}
                              span={6}
                              xs={{ span: 24 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 6 }}
                              className="mb-5"
                            >
                              <div className="member-item-grid-view">
                                <div className="skeleton-loader">
                                  <div className="skeleton-loader-header">
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                  </div>
                                  <Skeleton.Image className="skeleton-img w-100 mb-3" />
                                  <div className="d-flex-column justify-content-center align-items-center">
                                    <Skeleton.Input
                                      className="custom-input-sk"
                                      active
                                    />
                                    <Skeleton.Input
                                      className="custom-input-sk"
                                      active
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {clubMembers.map((member: any) => (
                    <ClubMemberItemList
                      member={member}
                      clubUserMemberShipType={clubUserMemberShipType}
                      onDeclineHandler={onDeclineRequestHandler}
                      onApproveHandler={onApproveRequestHandler}
                      onDropMemberHandler={onDropMemberHandler}
                      onPromoteMemberHandler={onPromoteMemberHandler}
                      onDropModApprovalHandler={() => console.log()}
                    />
                  ))}
                </Fragment>
              )}
            </Row>
          </Fragment>
          {!hasMorePage && clubMembers.length > 0 && (
            <Divider orientation="center" plain>
              No more members to show
            </Divider>
          )}

          {!isListLoading && !hasMorePage && clubMembers.length === 0 && (
            <Divider orientation="center" plain>
              No members to show
            </Divider>
          )}
        </div>
      </div>
    </Fragment>
  );
};

// const mapStateToProps = (state: { auth: any }) => ({
//   auth: state.auth,
// });

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
  flutterConnect: state.flutterConnect
});

export default connect(mapStateToProps, {
  getClubMemberList,
  addClubMember,
  acceptClubJoinRequest,
  declineClubJoinRequest,
  dropClubMember,
  getNonClubMember,
  promoteMember,
})(ClubMembers);
