import { Button } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";
import PinVerification from "./PinVerification";
import ResetPassword from "./ResetPassword";
import SetupPassword from "./SetupPassword";
import breezeWhite from "./../../assets/images/logos/breeze-white.svg";
import forgotImage from "./../../assets/images/forgot-password-image.svg";
import mobileAuthBg from "./../../assets/images/mobile-auth-bg.svg";

const ResetPasswordPage = () => {
  let navigate = useNavigate();
  const [verificationCodeId, setVerificationCodeId] = useState("");
  const [userData, setUserData] = useState([]);

  const [step, setStep] = useState(1);
  const [OTPCode, setOTPCode] = useState<string>("");

  const onRequestResetPassword = (data: any) => {
    setVerificationCodeId(data.verificationCodeId);
    if (process.env.REACT_APP_ENV_IDENTIFIER === "SIT") {
      setOTPCode(data.verificationCode ?? "");
    }
    setStep(2);
  };

  const onVerifyEmail = (resUserData: any) => {
    setUserData(resUserData);
    setStep(3);
  };

  const onSetPassword = (pinCode: Int32Array) => {
    alert("Change Password Successful, You can now login your account");
    navigate("/login", { replace: true });
  };

  let content;

  switch (step) {
    case 1:
      content = (
        <ResetPassword onRequestResetPassword={onRequestResetPassword} />
      );
      break;
    case 2:
      content = (
        <PinVerification
          onVerifyEmail={onVerifyEmail}
          verificationCodeId={verificationCodeId}
          isResetPassword={true}
          OTPCode={OTPCode}
        />
      );
      break;
    case 3:
      content = (
        <SetupPassword
          onSetPassword={onSetPassword}
          userData={userData}
          isResetPassword={true}
        />
      );
      break;
    default:
      <ResetPassword onRequestResetPassword={onRequestResetPassword} />;
      break;
  }

  return (
    <div className="signup-page-wrapper">
      <div className="panel-container">
        <div className="left-content blue-bg">
          <div className="h-100 d-flex-column justify-content-center align-items-center">
            <img
              className="login-logo"
              src={breezeWhite}
              height="37.87px"
              width="128"
            />
            <img src={forgotImage} height="536px" width="100%" />
            <h2 className="text-center">End Event Conflicts</h2>
            <p className="sub-title text-center">
              Helps teachers and admin plan events by indicating which rooms are
              in use.
            </p>
          </div>
        </div>
        <div className="right-content d-flex-column h-100">
          <div
            className="mobile-header d-md-none mb-2"
            style={{ backgroundImage: `url(${mobileAuthBg})` }}
          >
            <img
              className="login-logo"
              src={breezeWhite}
              height="32px"
              width="auto"
            />
          </div>
          <div className="d-flex justify-content-space-between w-100">
            <div className="nav-controls justify-content-center pt-1 d-sm-none">
              <Link className="mr-3" to="/login">
                <ArrowLeftOutlined className="mr-1" />
                Back to sign in
              </Link>
            </div>
            <div className="nav-controls justify-content-center d-sm-none">
              <Link className="mr-3 back-text" to="/sign-up">
                Dont have an account?
              </Link>
              <Button>
                <Link to="/sign-up">Get Started</Link>
              </Button>
            </div>
          </div>

          <div className="form-panel d-flex-column align-items-center justify-content-center h-100">
            <div className="w-100">{content}</div>
            <div className="nav-controls d-flex-column w-100 d-md-none">
              <Link className="text-center" to="/login">
                Already have an account?
              </Link>
              <Button className="mt-3 w-100">
                <Link to="/login">Sign In</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
