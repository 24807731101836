import { Fragment, useEffect, useMemo, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./routing/PrivateRoute";
import SuperAdminApp from "./components/super-admin/App";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignupPage from "./pages/SignupPage/SignupPage";
import PageNotFound from "./routing/PageNotFound";

//redux
import { connect } from "react-redux";
import ResetPasswordPage from "./pages/SignupPage/ResetPasswordPage";
import SessionExpiredPage from "./pages/SessionExpiredPage/SessionExpiredPage";
import {
  getFbToken,
  messaging,
  requestPermission,
  subscribeTokenToTopic
} from "./firebase-cloud-messaging";
import { onMessage } from "firebase/messaging";
import { setAlert } from "./actions/alert";
import { setPushData } from "./actions/pushData";
import { setFlutterConnect } from "./actions/flutterConnect";
import { setRecentLogout } from "./actions/recentLogout";
import { setFirebaseRegistrationToken } from "./actions/firebase";

import { logout } from "./actions/auth";

import AlertPop from "./components/layout/AlertPop";
import MemberApp from "./components/member-app/MemberApp";
import AdminPrivateRoute from "./routing/AdminPrivateRoute";
import TagManager from "react-gtm-module";
import SiteWideAnnouncement from "./components/layout/SiteWideAnnouncement";
import { set } from "lodash";
import {
  getMyClubs,
  getMyClubsGrouped,
} from "./actions/profile";

import {
  FLUTTER_CONNECTION_SUCCESS
} from "./actions/types";
import flutterConnect from "./reducers/flutterConnect";
import { useNavigate } from "react-router-dom";

const AppWrapper = (props: {
  setAlert: Function;
  getMyClubsGrouped: Function;
  setFirebaseRegistrationToken: Function;
  firebaseRegistrationToken: any;
  auth: any;
  logout: Function;
  setPushData: Function;
  setFlutterConnect: Function;
  flutterConnect: Boolean;
  setRecentLogout: Function;
}) => {
  const {
    setAlert,
    getMyClubsGrouped,
    setFirebaseRegistrationToken,
    firebaseRegistrationToken,
    auth,
    logout,
    setPushData,
    setFlutterConnect,
    setRecentLogout
  } = props;
  const { isAuthenticated, member } = auth;
  const [isTokenFound, setTokenFound]: any = useState(false);
  const [isGranted, setIsGranted]: any = useState(false);
  const [showSiteWide, setShowSiteWide]: any = useState(true);
  const [layoutExpanded, setLayoutExpanded] = useState<Boolean>(false);
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    setShowSiteWide(true);
  }, [location]);

  useEffect(() => {
    const get = async () => {
      if (await requestPermission()) {
        // setIsGranted(true);
        if (
          firebaseRegistrationToken === null ||
          firebaseRegistrationToken === ""
        ) {
          await getFbToken(setTokenFound, setFirebaseRegistrationToken);
        }

        if (member && member.schoolId && firebaseRegistrationToken)
          subscribeTokenToTopic(firebaseRegistrationToken, `school-${member.schoolId}`);
      }
    };
    
    //NOTE: Flutter bridge
    window.addEventListener("flutterInAppWebViewPlatformReady", (e) => {
      console.log('Flutter connected');
      setFlutterConnect(true);
    });

    //NOTE: Flutter firebase
    if (props.flutterConnect) {
      (window as any).flutter_inappwebview
      //NOTE: Converted to string for generic handling
      .callHandler('getFirebaseToken')
      .then((e: any) => {
        console.log('firebase got token');
        console.log(e);
        setTokenFound(true);
        setFirebaseRegistrationToken(e);
        subscribeTokenToTopic(e, "breeze-push");

        if (member && member.schoolId && firebaseRegistrationToken)
          subscribeTokenToTopic(firebaseRegistrationToken, `school-${member.schoolId}`);
      });

      const handleAppNotification = (message: any) => {
        handlePayload(JSON.parse(message), true);
      }

      (window as any).handleAppNotification = handleAppNotification;
    }
    else {
      get();
    }

    //NOTE: Flutter Moved to else condition
    // get();
  }, []);
  
  const handlePayload = async (payload: any, isTopicOnly: boolean = false) => {
    if (payload.data?.title === "DeleteMember") {
      const res = await logout();
      if (res.status == 204)
        setRecentLogout(true);
      return;
    }

    if (payload.data?.title === "breeze-push-topic") {
      setPushData(payload.data);
    }

    if (isTopicOnly)
      return;

    if (payload.data?.title !== "breeze-push-topic") {
      setAlert(
        payload.data?.title,
        payload.data?.body,
        "success",
        payload.data
      );
    }
  }

  useEffect(() => {
    {
      firebaseRegistrationToken !== null &&
        console.log("notification enabled, token", firebaseRegistrationToken);
    }
    {
      firebaseRegistrationToken === null ||
        (firebaseRegistrationToken === "" &&
          console.log("notification not enabled"));
    }
  }, [firebaseRegistrationToken]);

  useEffect(() => {
    if (props.flutterConnect) {
      (window as any).flutter_inappwebview
        .callHandler('handleIsAuthenticated', isAuthenticated);
    }
  }, [isAuthenticated])

  const messageListener = (auth: any) => {
    onMessage(messaging, async (payload) => {
      //only fire notif when user is authenticated

      if (isAuthenticated) {


        // if (payload && payload.data && payload.data.topic == 'Announcement') {
        //   const data = payload.data;
        //   let displayNotification = false;

        //   if (data.clubIds) {
        //     let params = {
        //       searchString: "",
        //       privacyTypes: "",
        //       interests: "",
        //     };

        //     let res = await getMyClubsGrouped(params);

        //     if(res && res.data) {
        //       let myClubs = res.data.find((e: any) => e.category == 'Joined Clubs');

        //       if (myClubs && myClubs.clubs) {
        //         let announcementClubs = JSON.parse(data.clubIds);
        //         let myClubIds = myClubs.clubs.map((e: any) => e.memberGroupId);

        //         announcementClubs.forEach((announcementClub: any) => {
        //           if (myClubIds.includes(announcementClub)) {
        //             displayNotification = true;
        //             return;
        //           }
        //         });

        //       }
        //     }
        //   }
        //   else {
        //     displayNotification = true;
        //   }

        //   if (displayNotification) {
        //     setAlert(
        //       payload.notification?.title,
        //       payload.notification?.body,
        //       "success",
        //       payload.data
        //     );
        //   }
        //   return;
        // }

        handlePayload(payload);
        
        
      } else {
        console.log("notification, no user logged in!");
      }
    });
  };

  const firebaseEvent = useMemo(() => messageListener(auth), [auth]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: `${process.env.REACT_APP_GTAG_GTM_ID}`,
      auth: `${process.env.REACT_APP_GTAG_GTM_AUTH}`,
      preview: `${process.env.REACT_APP_GTAG_GTM_PREVIEW}`,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Fragment>
      {firebaseEvent}
      <AlertPop />
      {isAuthenticated && showSiteWide && (
        <SiteWideAnnouncement setLayoutExpanded={setLayoutExpanded} onHideAnnouncement={setShowSiteWide}/>
      )}

      <div className={`App ${layoutExpanded ? "layoutExpanded" : ""}`}>
        <Routes>
          <Route index element={<LoginPage />} />
          <Route path="sign-up" element={<SignupPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ResetPasswordPage />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/*" element={<MemberApp />} />
          </Route>
          <Route path="/" element={<AdminPrivateRoute />}>
            <Route path="/admin/*" element={<SuperAdminApp />} />
          </Route>
          <Route path="session-expired" element={<SessionExpiredPage />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: { auth: any; firebase: any; flutterConnect: boolean; }) => ({
  auth: state.auth,
  firebaseRegistrationToken: state.firebase.firebaseRegistrationToken,
  flutterConnect: state.flutterConnect,
});

export default connect(mapStateToProps, {
  setAlert,
  getMyClubsGrouped,
  setFirebaseRegistrationToken,
  logout,
  setPushData,
  setFlutterConnect,
  setRecentLogout
})(AppWrapper);
