import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment, { Moment } from "moment";
import { Skeleton, Menu, Button, MenuProps, Form, Spin } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { getMyNotifications } from "../../../actions/profile";
import {
    executeAction,
  } from "../../../actions/notifications";
import { NO_CONTENT, CREATED, OK } from "../../../helpers/statuscodes";
import ModalDeclineReason from "../Modals/ModalDeclineReason";
import ModalSuccess from "../Modals/ModalSuccess";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import noEventInvites from "../../../assets/images/icons/no_event_invites.svg";
import noClubInvites from "../../../assets/images/icons/no_club_invites.svg";


const DashboardPendingInvites = (props: {
    getMyNotifications: Function;
    executeAction: Function;
}) => {
    const {
        getMyNotifications,
        executeAction,
      } = props;
    const [selected, setSelected] = useState("Events");
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [invites, setInvites] = useState<any[]>([]);
    const [declineEventForm] = Form.useForm();
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isDeclineEventModalOpen, setIsDeclineEventModalOpen] = useState(false);
    const [isLoadingDeclineEventInvite, setIsLoadingDeclineEventInvite] = useState(false);
    const [inviteData, setInviteData] = useState<any>(null);

    let leftMenuItems = [
        {
            key: 'Events',
            label: <p>Events</p>,
        },
        {
            key: 'Clubs',
            label: <p>Clubs</p>,
        },
    ];

    const fetchList = async () => {
        setIsLoading(true);
        let params = {
          page: 1,
          pageSize: 50,
          searchString: "",
        };
        const res = await getMyNotifications(params);
        let items=[]
        if(selected==="Events"){
            items = res.data.items.filter((i:any)=>i.notificationType === ("EventInvite")&&i.actionTaken===undefined);
        }else{
            items = res.data.items.filter((i:any)=>(
                i.notificationType === ("Club Invite")||
                i.notificationType === ("ClubModeratorInvite")||
                i.notificationType === ("JoinClubInvite")
            )&&i.actionTaken===undefined);
        }
        setInvites(items)
        setIsLoading(false);
      };


    useEffect(()=>{
       fetchList();
    },[selected])

    const Invitation = (data: any) => {
        return(
            <div className="invitation">
                <div className="start-group">
                    <div className="invite-image-wrapper">
                        {
                            data?.data?.bannerFileUrl ? 
                            <img src={data?.data?.bannerFileUrl} alt={'banner'} className="invite-image"/>
                            :<div className="invite-image"/>
                        }
                    </div>
                    <div className="text">
                        <h3>{data?.data?.notificationTitle ?? ""}</h3>
                        <p>Invite sent - {new Date(data?.data?.createdOn).toDateString().slice(3)}</p>
                    </div>
                </div>
                <div className="end-group">
                    <Button className="invite-option"
                     onClick={(e) => {
                        handleAction(data?.data, "Accept");
                      }}><CheckOutlined/></Button>
                    <Button className="invite-option"
                        onClick={()=>onHandleDecline(data?.data)}
                    ><CloseOutlined/></Button>
                </div>
                <ModalDeclineReason
                    title="Enter Decline Reason"
                    handleSubmit={onDeclineForm}
                    isOpen={isDeclineEventModalOpen}
                    setIsOpen={setIsDeclineEventModalOpen}
                    form={declineEventForm}
                    isLoading={isLoadingDeclineEventInvite}
                />
            </div>
        );
    }

    const onClick: MenuProps['onClick'] = (e) => {
        setSelected(e.key);
    };
   
    const onHandleDecline = (data:any) => {
        setInviteData(data);
        setIsDeclineEventModalOpen(true);
    };

    const handleAction = async (notifItem: any, actionType: string) => {
        setIsProcessing(true);
        const parsedActions = JSON.parse(notifItem.notificationActions);
        let itemActions = parsedActions.filter((data: any) => {
          return data.label === actionType;
        });
        const payload = JSON.stringify({
          activityLogId: notifItem.activityLogId,
          status: Number(itemActions[0].status),
        });
        let result = await executeAction(payload);
        if (result.status == OK || result.status == CREATED) {
            fetchList();
            setIsProcessing(false);
        } else {
            setIsProcessing(false);
        }
    };

    const onDeclineForm = async (formData: any) => {
        setIsProcessing(true);
        setIsLoadingDeclineEventInvite(true);
        let values = {
            ...formData,
            ...inviteData,
          };
        const parsedActions = JSON.parse(values?.notificationActions);
        let itemActions = parsedActions.filter((data: any) => {
          return data.label === "Decline";
        });
        const payload = JSON.stringify({
          activityLogId: values.activityLogId,
          status: Number(itemActions[0].status),
          remarks: values.remarks,
        });

        let result = await executeAction(payload);
        if (result.status == OK || result.status == CREATED) {
            setIsModalSuccessOpen(true);
            setIsProcessing(false);
            setIsLoadingDeclineEventInvite(false);
            setIsDeclineEventModalOpen(false);
            declineEventForm.resetFields();
            fetchList();
        } else {
            setIsProcessing(false);
            setIsDeclineEventModalOpen(false);
            setIsLoadingDeclineEventInvite(false);
            declineEventForm.resetFields();
        }
      };

  return (
    <div className="dashboard-pending-invites">
        <div className="header">
            <h1>Pending Invitations</h1>
            <div className="menu-wrapper app">
                <Menu
                    className="menus"
                    mode="horizontal"
                    selectedKeys={[selected]}
                    items={leftMenuItems}
                    onClick={onClick}
                    disabledOverflow={true}
                />
            </div>
            <div className="menu-wrapper">
                <Menu
                    className="menus"
                    mode="horizontal"
                    selectedKeys={[selected]}
                    items={leftMenuItems}
                    onClick={onClick}
                />
            </div>
            
            
        </div>
        <div className="pending">
            {isProcessing && <Spin className="spin-overlay" size="large"/>}
            {isLoading && 
            <>
                <Skeleton loading={true} active />
                <Skeleton loading={true} active />
                <Skeleton loading={true} active />
            </>
             }
            {!isLoading && invites.length>0 &&
                invites.slice(0,5).map((invite)=><Invitation data={invite} key={invite.activityLogId}/>)
            }
            {!isLoading && invites.length===0 &&
                (selected === "Events" ?(
                <div className="no-events-container">
                    <img height={120} width={120} src={noEventInvites} />
                    <h4>No event invites yet.</h4> 
                </div>
                ):(
                    <div className="no-events-container">
                    <img height={120} width={120} src={noClubInvites} />
                    <h4>No club invites yet.</h4> 
                </div>
                ))
            }
        </div>
        {!isLoading && invites.length>0 &&
        <Link to={selected === "Events" ? "/events" : "/clubs"}>
            <Button
            type="default"
            shape="default"
            key="submit"
            className="view-all"
            >
            View All {selected}
            </Button>
        </Link>
        }
          <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p
          className="text-center"
        >
            Decline reason was successfully submitted.
        </p>
      </ModalSuccess>
    </div>
  );
};


const mapStateToProps = (state: { auth: any; userIsBack: boolean }) => ({
    interests: state.auth.member.interests,
    userIsBack: state.userIsBack,
  });
export default connect(mapStateToProps, {
    getMyNotifications,
    executeAction,
  })(DashboardPendingInvites);