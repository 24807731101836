import { Button, Modal } from "antd";
import { HtmlHTMLAttributes, useEffect, useState } from "react";

export const ModalConfirm = (props: {
  children: any;
  handleSubmit: Function;
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: Function;
}) => {
  const { handleSubmit, isOpen, isLoading, setIsOpen, children } = props;

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = async () => {
    handleSubmit();
  };

  return (
    <Modal
      title="Confirmation"
      visible={isOpen}
      onCancel={onCancelHandler}
      footer={[
        <Button
          type="primary"
          shape="round"
          key="submit"
          loading={isLoading}
          onClick={() => onSubmitHandler()}
        >
          Yes
        </Button>,
        <Button
          shape="round"
          key="cancel"
          loading={isLoading}
          onClick={() => onCancelHandler()}
        >
          No
        </Button>,
      ]}
    >
      {props.children}
    </Modal>
  );
};

export default ModalConfirm;
