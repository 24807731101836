import { Button, Col, Dropdown, Menu, Row, Skeleton } from "antd";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { joinClub } from "../../../actions/club";
import placeholderImg from "../../../assets/images/club-placeholder.png";
import { ClubItem, ClubMemberModel } from "../../../interface/Club";
import { EllipsisOutlined } from "@ant-design/icons";
import { EventModel } from "../../../interface/Event";
import moment from "moment";
import { Invite } from "../events/EventInvite";
import { joinEvent } from "../../../actions/event";
import {
  CheckOutlined,
  CloseOutlined,
  CompassOutlined,
} from "@ant-design/icons";
import { MemberModel } from "../../../interface/Member";
import { Admin } from "../../../helpers/userRoles";
import { MemberInformationModel } from "../../../interface/Profile";

const ClubMemberItemGrid = (props: {
  member: ClubMemberModel;
  clubUserMemberShipType: string;
  onDeclineHandler: Function;
  onApproveHandler: Function;
  onDropMemberHandler: Function;
  onPromoteMemberHandler: Function;
  onDropModApprovalHandler: Function;
  authMember: MemberInformationModel;
}) => {
  const {
    member,
    clubUserMemberShipType,
    onDeclineHandler,
    onApproveHandler,
    onDropMemberHandler,
    onPromoteMemberHandler,
    onDropModApprovalHandler,
    authMember,
  } = props;

  const navigate = useNavigate();

  const onDecline = (e: any, memberId: any) => {
    e.stopPropagation();
    // OR
    e.preventDefault();
    onDeclineHandler(memberId);
  };

  const onApprove = (e: any, memberId: any) => {
    e.stopPropagation();
    // OR
    e.preventDefault();
    onApproveHandler(memberId);
  };

  const onDropMember = (member: ClubMemberModel) => {
    onDropMemberHandler(member);
  };

  const onPromoteMember = (memberId: any, fullname: String) => {
    onPromoteMemberHandler(memberId, fullname);
  };

  const onDropModHandler = (memberId: any, type: String) => {
    onDropModApprovalHandler(memberId, type);
  };

  return (
    <Col
      span={6}
      xs={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 12 }}
      xl={{ span: 6 }}
      className="mb-5"
    >
      {/* <Link to={`/member/${member.memberId}`}>  */}
      <div className="member-item-grid-view">
        <div className="member-item-content">
          <div className="member-top-controls">
            {/* only show to admin when requested to be removed as a club mod */}
            {/* add condition to show this if i am the one who dropped it */}
            {(authMember?.memberType === Admin ||
              member.droppedByMemberId === authMember?.memberId) &&
              member.dropStatus === "Pending" &&
              member.membershipType === "Moderator" && (
                <div className="member-status color-warning">
                  Pending For Removal
                </div>
              )}
            {authMember?.memberType === Admin &&
              member.dropStatus !== "Pending" && (
                <div
                  className={`member-status ${
                    member.joinStatus === "Approved"
                      ? "color-success"
                      : "color-warning"
                  }`}
                >
                  {member.joinStatus === "Approved"
                    ? "Active"
                    : member.joinStatus}
                </div>
              )}
            {authMember?.memberType !== Admin &&
              member.droppedByMemberId !== authMember?.memberId && (
                <div
                  className={`member-status ${
                    member.joinStatus === "Approved"
                      ? "color-success"
                      : "color-warning"
                  }`}
                >
                  {member.joinStatus === "Approved"
                    ? "Active"
                    : member.joinStatus}
                </div>
              )}
            {authMember?.memberType !== Admin && (
              <Fragment>
                {member.joinStatus === "Approved" &&
                  authMember?.memberId !== member.memberId &&
                  clubUserMemberShipType === "Moderator" && (
                    <Fragment>
                      {member.membershipType === "Moderator" &&
                        member.dropStatus !== "Pending" && (
                          <Dropdown
                            className="item-menu"
                            overlay={
                              <Fragment>
                                <Menu
                                  items={[
                                    {
                                      key: "leave-club",
                                      label: (
                                        <a onClick={() => onDropMember(member)}>
                                          Drop
                                        </a>
                                      ),
                                    },
                                  ]}
                                />
                              </Fragment>
                            }
                            placement="bottomRight"
                            trigger={["click"]}
                          >
                            <EllipsisOutlined />
                          </Dropdown>
                        )}
                      {member.membershipType !== "Moderator" && (
                        <Dropdown
                          className="item-menu"
                          overlay={
                            <Fragment>
                              <Menu
                                items={[
                                  {
                                    key: "leave-club",
                                    label: (
                                      <a onClick={() => onDropMember(member)}>
                                        Drop
                                      </a>
                                    ),
                                  },
                                  {
                                    key: "promote-member",
                                    label: (
                                      <Fragment>
                                        <a
                                          onClick={() =>
                                            onPromoteMember(
                                              member.memberId,
                                              member.firstName +
                                                " " +
                                                member.lastName
                                            )
                                          }
                                        >
                                          Promote
                                        </a>
                                      </Fragment>
                                    ),
                                  },
                                ]}
                              />
                            </Fragment>
                          }
                          placement="bottomRight"
                          trigger={["click"]}
                        >
                          <EllipsisOutlined />
                        </Dropdown>
                      )}
                    </Fragment>
                  )}
              </Fragment>
            )}
          </div>
          <div className="member-details mt-2">
            {/* <div className="user-avatar">
                <img
                  height="auto"
                  width="42px"
                />
              </div> */}
            <div className="no-avatar avatar-lg">
              <span>
                {member.firstName.charAt(0)}
                {member.lastName.charAt(0)}
              </span>
            </div>
            <h5 className="member-name mt-3 mb-1">
              {member.firstName} {member.lastName}
            </h5>
            <span className="member-type">{member.membershipType}</span>
          </div>
          <div className="member-bot-controls mt-3">
            {member.joinStatus === "Pending" ? (
              <Fragment>
                {member.createdBy && member.createdBy === member.memberId ? (
                  <div className="d-flex">
                    <Button
                      type="primary"
                      className="w-100 mr-1"
                      shape="round"
                      loading={member.loading}
                      onClick={(e) => onApprove(e, member.memberId)}
                    >
                      Approve
                    </Button>
                    <Button
                      className="w-100 ml-1"
                      shape="round"
                      loading={member.loading}
                      onClick={(e) => onDecline(e, member.memberId)}
                    >
                      Decline
                    </Button>
                  </div>
                ) : (
                  <Fragment>
                    {authMember?.memberId === member.memberId ? (
                      <div className="d-flex">
                        <Button
                          type="primary"
                          className="w-100 mr-1"
                          shape="round"
                          loading={member.loading}
                          onClick={(e) => onApprove(e, member.memberId)}
                        >
                          Accept
                        </Button>
                        <Button
                          className="w-100 ml-1"
                          shape="round"
                          loading={member.loading}
                          onClick={(e) => onDecline(e, member.memberId)}
                        >
                          Decline
                        </Button>
                      </div>
                    ) : (
                      <div className="text-center color-warning text-bold">
                        Waiting for approval
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {authMember?.memberType === Admin &&
                member.dropStatus === "Pending" ? (
                  <div className="d-flex">
                    <Button
                      type="primary"
                      className="w-100 mr-1"
                      shape="round"
                      loading={member.loading}
                      onClick={(e) =>
                        onDropModHandler(member.memberId, "Approve")
                      }
                    >
                      Accept
                    </Button>
                    <Button
                      className="w-100 ml-1"
                      shape="round"
                      loading={member.loading}
                      onClick={(e) =>
                        onDropModHandler(member.memberId, "Decline")
                      }
                    >
                      Decline
                    </Button>
                  </div>
                ) : (
                  <Fragment>
                    {/* {" "}
                    <Button className="w-100" shape="round">
                      View Profile
                    </Button> */}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {/* </Link> */}
    </Col>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  authMember: state.auth.member,
});

export default connect(mapStateToProps, {
  joinEvent,
})(ClubMemberItemGrid);
