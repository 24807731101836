import { Doughnut as DChart } from "react-chartjs-2";
import { Chart, ArcElement, Legend, Tooltip, ChartData } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register([ArcElement, Legend, Tooltip, ChartDataLabels]);

interface IProps {
  data: ChartData<"doughnut", number[], unknown>;
}

const Doughnut = (props: { data: any; legend: boolean }) => {
  const { legend } = props;

  function isTooDark(hex: any) {
    const hexCode = hex.charAt(0) === "#" ? hex.substr(1, 6) : hex;

    const hexR = parseInt(hexCode.substr(0, 2), 16);
    const hexG = parseInt(hexCode.substr(2, 2), 16);
    const hexB = parseInt(hexCode.substr(4, 2), 16);
    // Gets the average value of the colors
    const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

    return contrastRatio >= 0.6 ? "black" : "white";
  }

  let textColor = props.data.datasets[0].backgroundColor.map((item: any) => {
    return isTooDark(item);
  });
  return (
    <DChart
      data={props.data}
      options={{
        backgroundColor: "rgba(250, 250, 250, 1)",
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            align: "start",
            display: legend,
            fullSize: true,
            labels: {
              textAlign: "left",
              pointStyle: "circle",
              usePointStyle: true,
            },
          },
          datalabels: {
            display: true,
            color: textColor,
            align: "center",
            labels: {
              title: {
                font: {
                  weight: "normal",
                },
              },
              value: {
                color: textColor,
              },
            },
            formatter: function (value) {
              return "\n" + value + "%";
            },
          },
        },
      }}
    />
  );
};
export default Doughnut;
