import {
  Form,
  Input,
  Button,
  Select,
  Col,
  Row,
  Radio,
  Spin,
  Modal,
} from "antd";
import { useState, useEffect, useMemo, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { getModeratorList } from "../../../actions/moderator";
import { getInterestListNonPaged } from "../../../actions/interest";
import { addClub, deleteClub, updateClub } from "../../../actions/club";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import { DeleteOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";
import { Closed, Private, Public } from "../../../helpers/privacyTypes";
import ModalDeclineReason from "../../member-app/Modals/ModalDeclineReason";
import { declineEventInvite } from "../../../actions/event";
import successIcon from "../../../assets/images/icons/success-icon.svg";

const { Option } = Select;

const getPrivacyTypeValue = (string: String) => {
  if (string === "Public") {
    return Public.toString();
  } else if (string === "Private") {
    return Private.toString();
  } else if (string === "Close") {
    return Closed.toString();
  }
};

const AddClubForm = (props: {
  addClub: Function;
  updateClub: Function;
  getModeratorList: Function;
  getInterestListNonPaged: Function;
  deleteClub: Function;
  declineEventInvite: Function;
}) => {
  const location: any = useLocation();
  const [addClubForm] = Form.useForm();
  const {
    addClub,
    updateClub,
    getModeratorList,
    getInterestListNonPaged,
    deleteClub,
  } = props;
  const [isEditMode, setIsEditMode]: any = useState(false);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [interestList, setInterestList]: any = useState([]);
  const [isLoadingInterestList, setIsLoadingInterestList] = useState(false);
  const [moderatorList, setModeratorList] = useState<any[]>([]);
  const [clubModeratorList, setClubModeratorList] = useState<any>([]);
  const [reset, setReset] = useState<any>(true);
  const [searchTerm, setSearchTerm] = useState("");

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isModeratorsValid, setIsModeratorsValid] = useState(true);

  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isLoadingDecline, setIsLoadingDecline] = useState(false);
  const [declineEventForm] = Form.useForm();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isJoinModalText, setJoinModalText] = useState("");

  const [selectedEventInfo, setSelectedEventInfo] = useState<any>({});

  const onFinish = async (formData: any) => {
    let moderatorIds = clubModeratorList.map((mod: { memberId: string }) => {
      return mod.memberId;
    });

    setIsModeratorsValid(true);
    let values = {
      ...formData,
      moderators: moderatorIds,
      privacyType: parseInt(formData.privacyType),
    };
    console.log(values);

    setValidationMessages(new Array());
    setIsLoading(true);
    let res;
    if (isEditMode) {
      let customFormData = {
        ...values,
        memberType: 0,
      };
      res = await updateClub(customFormData, location?.state?.memberGroupId);
    } else {
      res = await addClub(values);
    }
    if (res.status == OK || res.status == CREATED) {
      if (!isEditMode) {
        onReset();
      }
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const onReset = () => {
    setReset(true);
    setValidationMessages([]);
    setClubModeratorList([]);
    addClubForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    setReset(false);
    console.log("Failed:", errorInfo);
  };

  //deactivate club
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteClub(location.state.memberGroupId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
    }
    setIsDeleteLoading(false);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const handleSelect = (memberId: string, e: any) => {
    addClubForm.setFieldsValue({
      moderator: null,
    });
    if (memberId && e) {
      const checkMemberId = (obj: { memberId: string }) =>
        obj.memberId === memberId;
      if (!clubModeratorList.some(checkMemberId)) {
        setClubModeratorList((prevModerator: any) => [
          ...prevModerator,
          { memberId, name: e.children },
        ]);
      }
    }
  };

  const removeClubModerator = (memberId: string) => {
    let tempMod = clubModeratorList.filter((mod: { memberId: string }) => {
      return mod.memberId != memberId;
    });
    setClubModeratorList(tempMod);
  };

  const onDeclineForm = async (formData: any) => {
    setIsLoadingDecline(true);
    const res = await deleteClub({
      ...formData,
      memberGroupId: location.state.memberGroupId,
    });
    if (res.status === 204) {
      setIsDeclineModalOpen(false);
      setJoinModalText(
        `You have successfully submitted deactivate reason for this club`
      );
      setIsSuccessModalOpen(true);
      declineEventForm.resetFields();
    }
    setIsLoadingDecline(false);
  };

  useEffect(() => {
    const fetchModeratorList = async () => {
      setIsLoadingInterestList(true);
      const data = await getInterestListNonPaged();
      setInterestList(data.data);
      setIsLoadingInterestList(false);

      //populate form if editing a student data
      if (location && location.state) {
        setIsEditMode(true);
        addClubForm.setFieldsValue({
          description: location?.state?.description,
          name: location?.state?.name,
          moderators: location?.state?.moderators,
          interestId: location?.state?.interestId,
          privacyType: getPrivacyTypeValue(
            location?.state?.privacyType.toString()
          ),
          status: location?.state?.status,
        });
        let arraYMod: any = [];
        location?.state?.moderators.forEach((item: any) => {
          arraYMod.push({
            memberId: item.memberId,
            name: item.firstName + " " + item.lastName,
          });
        });

        setClubModeratorList(arraYMod);
      }
    };

    fetchModeratorList();
  }, []);

  const [searching, setSearching] = useState(false);

  useEffect(() => {
    setSearching(true);
    const delaySearch = setTimeout(async () => {
      console.log(searchTerm);
      if (searchTerm && searchTerm != "") {
        let params = {
          memberType: addClubForm.getFieldValue("memberType"),
          SearchString: searchTerm,
        };
        const data = await getModeratorList(params);
        let datax = data.data.map(
          (member: {
            memberId: string;
            firstName: string;
            lastName: string;
          }) => ({
            label: `${member.firstName} ${member.lastName}`,
            value: member.memberId,
          })
        );
        setModeratorList(datax);
      } else {
        setModeratorList([]);
      }
      setSearching(false);
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  const options =
    moderatorList &&
    moderatorList.map((d) => <Option key={d.value}>{d.label}</Option>);

  return (
    <Fragment>
      <ModalDeclineReason
        title="Enter Deactivate Reason"
        handleSubmit={onDeclineForm}
        isOpen={isDeclineModalOpen}
        setIsOpen={setIsDeclineModalOpen}
        form={declineEventForm}
        isLoading={isLoadingDecline}
      />
      <Modal
        centered
        className="modal-action-success"
        visible={isSuccessModalOpen}
        onCancel={() => setIsSuccessModalOpen(false)}
        footer={[]}
      >
        <div className="d-flex justify-content-center w-100">
          <img src={successIcon} height="96px" width="96px" />
        </div>
        <div className="d-flex-column w-100 justify-content-center">
          <h4 className="text-center">Success!</h4>
          <p className="text-center">{isJoinModalText}</p>
        </div>
      </Modal>
      <ModalDeleteAlert
        modalTitle="Club"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Row>
        <Col span={24}>
          <Form
            form={addClubForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert"
          >
            <h5 className="text-head pb-3">Club Details</h5>
            <Row justify="space-between" gutter={24}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
                xl={{ span: 10 }}
              >
                <Form.Item
                  className="mb-0"
                  label="Club Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input Club Name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Club Name!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Club Name" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages && validationMessages.Name && "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Name && (
                    <AlertContainer data={validationMessages.Name} />
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col
                span={8}
                xs={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
                xl={{ span: 7 }}
              >
                <div className="ant-col ant-form-item-label">
                  <label
                    htmlFor="moderator"
                    className="ant-form-item-required"
                    title="Moderator"
                  >
                    Moderator
                  </label>
                </div>
                <div className="d-flex w-100">
                  <Form.Item
                    className="mb-1 p-relative w-100"
                    name="moderator"
                    rules={[
                      () => ({
                        validator(_, value) {
                          console.log(clubModeratorList.length);
                          if (clubModeratorList.length != 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("Please select Moderator!")
                            );
                          }
                        },
                      }),
                    ]}
                    // {...(clubModeratorList.length == 0 && reset != true
                    //   ? { validateStatus: "error" }
                    //   : "")}
                    // {...(clubModeratorList.length == 0 && reset != true
                    //   ? { help: "Please select Moderator" }
                    //   : "")}
                  >
                    <Select
                      showSearch
                      showArrow={false}
                      filterOption={false}
                      autoClearSearchValue={true}
                      allowClear
                      onSearch={(searchString: string) =>
                        setSearchTerm(searchString)
                      }
                      onSelect={handleSelect}
                      notFoundContent={<div>Not found</div>}
                      placeholder="Select Moderators"
                    >
                      {options}
                    </Select>
                  </Form.Item>
                  {searching ? (
                    <div className="select-search">
                      <Spin />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <ul className="form-ul">
                  {clubModeratorList &&
                    clubModeratorList.map((data: any) => {
                      return (
                        <li key={data.memberId} className="fadein">
                          {data.name}
                          <span
                            onClick={() => removeClubModerator(data.memberId)}
                          >
                            <DeleteOutlined />
                          </span>
                        </li>
                      );
                    })}
                </ul>

                <Form.Item
                  label="Club Type"
                  className="pt-2 mb-2"
                  name="interestId"
                  rules={[
                    {
                      required: true,
                      message: "Please select Club Type!",
                    },
                  ]}
                >
                  <Select
                    disabled={isLoadingInterestList}
                    placeholder={
                      isLoadingInterestList
                        ? "Loading Club Types..."
                        : "Select Club Type"
                    }
                    allowClear
                  >
                    {interestList &&
                      interestList.map((data: any) => {
                        return (
                          <Option key={data.interestId} value={data.interestId}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.Interest &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Interest && (
                    <AlertContainer data={validationMessages.Interest} />
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                span={8}
                xs={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
                xl={{ span: 10 }}
              >
                <Form.Item
                  label="Club Description"
                  className="pt-2 mb-2"
                  name="description"
                >
                  <TextArea rows={4} placeholder="" />
                </Form.Item>

                <div
                  className={`${
                    validationMessages &&
                    validationMessages.Description &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Description && (
                    <AlertContainer data={validationMessages.Description} />
                  )}
                </div>

                <Form.Item
                  className="mb-2 mt-4"
                  name="privacyType"
                  label="Club Status"
                  rules={[
                    { required: true, message: "Please select Club Status" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="0">Public</Radio>
                    <Radio value="1">Private</Radio>
                    <Radio value="2">Closed</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className={`${
                    validationMessages &&
                    validationMessages.PrivacyType &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && validationMessages.PrivacyType && (
                    <AlertContainer data={validationMessages.PrivacyType} />
                  )}
                </div>

                <div
                  className={`${
                    validationMessages &&
                    validationMessages.error &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && validationMessages.error && (
                    <AlertContainer data={validationMessages} />
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <div className="w-100 d-flex mt-3">
                  <Button
                    className="btn-breeze-box mr-2 btn-radius"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save Club Details
                  </Button>
                  <Button
                    disabled={isLoading}
                    className="btn-radius"
                    onClick={onReset}
                  >
                    Reset Fields
                  </Button>
                  {isEditMode && (
                    <Button
                      className="ml-auto btn-radius"
                      disabled={isLoading}
                      onClick={() => setIsDeclineModalOpen(true)}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  addClub,
  getModeratorList,
  getInterestListNonPaged,
  deleteClub,
  updateClub,
  declineEventInvite,
})(AddClubForm);
