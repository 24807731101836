import { Form, Select } from "antd";
import { Fragment, useEffect, useState } from "react";
import AlertContainer from "../../../layouts/AlertContainer";
import { getInterestListNonPaged } from "../../../actions/interest";
import { connect } from "react-redux";

const { Option } = Select;

const InterestField = (props: {
  getInterestListNonPaged: Function;
  validationMessages: any;
  onChange: Function;
}) => {
  const { validationMessages, getInterestListNonPaged, onChange } = props;
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (id: any) => {
    let selectedInterest = list.filter((interest: any) => {
      return id === interest.interestId;
    });
    onChange(selectedInterest[0]);
  };
  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      const data = await getInterestListNonPaged();
      setList(data.data);
      setIsLoading(false);
    };
    initialize();
  }, []);
  return (
    <Fragment>
      <Form.Item label="Interest" className="pt-2 mb-2" name="interestId">
        <Select
          disabled={isLoading}
          placeholder={isLoading ? "Loading Interests..." : "Select Interest"}
          allowClear
          onChange={(e: any) => onChangeHandler(e)}
        >
          {list &&
            list.map((data: any) => {
              return (
                <Option key={data.interestId} value={data.interestId}>
                  {data.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <div
        className={`mb-4 ${
          validationMessages && validationMessages.Location && "has-error"
        }`}
      >
        {validationMessages && validationMessages.Location && (
          <AlertContainer data={validationMessages.Location} />
        )}
      </div>
    </Fragment>
  );
};

export default connect(null, {
  getInterestListNonPaged,
})(InterestField);
