import {
  Form,
  Input,
  Button,
  Select,
  Col,
  Row,
  InputNumber,
  Slider,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import iconRemoveField from "../../../assets/images/icons/icon-remove-field.svg";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  addInterest,
  updateInterest,
  deleteInterest,
} from "../../../actions/interest";
import { getSoftSkillsNonPaged } from "../../../actions/softskill";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation, useNavigate } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";
import PointDistributionInput from "./PointDistribution";

const { Option } = Select;

const AddInterestForm = (props: {
  addInterest: Function;
  updateInterest: Function;
  getSoftSkillsNonPaged: Function;
  deleteInterest: Function;
}) => {
  const navigate = useNavigate();
  const { addInterest, updateInterest, getSoftSkillsNonPaged, deleteInterest } =
    props;
  const location: any = useLocation();
  const [addInterestForm] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [softSkillList, setSoftSkillList] = useState([]);
  const [tempSoftSkillList, setTempSoftSkillList] = useState([]);

  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSoftSkillList, setIsLoadingSoftSkillList] = useState(false);
  const [
    isModalInterestDescriptionVisible,
    setIsModalInterestDescriptionVisible,
  ] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSoftSkillValid, setIsSoftSkillValid] = useState(true);

  const [selectedSoftSkill, setSelectedSoftSkill]: any = useState([]);

  const onFinish = async (formData: any) => {
    setValidationMessages(new Array());
    setIsLoading(true);
    if (formData.softSkillScores) {
      setIsSoftSkillValid(true);
      let res;
      if (isEditMode) {
        res = await updateInterest(
          { ...formData, interestId: location.state.interestId },
          location.state.interestId
        );
      } else {
        res = await addInterest(formData);
      }
      if (res.status == OK || res.status == CREATED) {
        if (!isEditMode) {
          onReset();
        }
      } else if (res.status === BAD_REQUEST) {
        if (res.data) {
          handleFormErrors(res.data);
        }
      }
    } else {
      setIsSoftSkillValid(false);
    }

    setIsLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    addInterestForm.resetFields();
    setSelectedSoftSkill([]);
    setIsSoftSkillValid(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const changeSoftSkillHandler = (key: number, e: any) => {
    // let obj: any = [];
    // Object.assign(obj, {
    //   [key]: { key: key, id: e },
    // });

    setSelectedSoftSkill({
      ...selectedSoftSkill,
      [key]: { key: key, id: e },
    });

    // setSelectedSoftSkill((state: any) => [
    //   {
    //     ...state,
    //     [key]: {
    //       key: key,
    //       id: e,
    //     },
    //   },
    // ][0]);
  };

  //deactivate interest
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteInterest(location.state.interestId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
      navigate('/admin/interests');
    }
    setIsDeleteLoading(false);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    const fetchSoftSkillNonPage = async () => {
      setIsLoadingSoftSkillList(true);
      const data = await getSoftSkillsNonPaged();
      setSoftSkillList(data.data);
      setTempSoftSkillList(data.data);
      setIsLoadingSoftSkillList(false);
      if (location && location.state) {
        setIsEditMode(true);

        addInterestForm.setFieldsValue({
          name: location?.state?.name,
          description: location?.state?.description,
          softSkillScores: location?.state?.softSkillScores,
        });

        const softskillScores = location?.state?.softSkillScores;
        let obj: any = [];
        let index = 0;
        for (const [key, value] of Object.entries(softskillScores)) {
          let tempObj: any = value;
          Object.assign(obj, {
            [key]: { key: index, id: tempObj.softSkillId },
          });
          index++;
        }
        setSelectedSoftSkill(obj);
      }
    };

    fetchSoftSkillNonPage();
  }, []);

  return (
    <Fragment>
      <ModalDeleteAlert
        modalTitle="Interest"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Row>
        <Col span={24}>
          <Form
            form={addInterestForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert interest-form"
          >
            <h5 className="text-head pb-3">Interest Details</h5>
            <Row>
              <Col
                span={8}
                xs={{ span: 24 }}
                lg={{ span: 16 }}
                xl={{ span: 7 }}
              >
                <Form.Item
                  className="mb-0"
                  label="Interest Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input Interest Name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Interest Name!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Interest Name" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages && validationMessages.Name && "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Name && (
                    <AlertContainer data={validationMessages.Name} />
                  )}
                </div>

                <Form.Item
                  className="mb-0"
                  label="Interest Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input Interest Description!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          //resolve to use original rule
                          return Promise.resolve();
                        }
                        if (!isEmptyOrSpaces(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Please input Interest Description!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Interest Description" />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.Description &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.Description && (
                    <AlertContainer data={validationMessages.Description} />
                  )}
                </div>
              </Col>
            </Row>

            

            <Row>
              <Col
              span={8}
              xs={{ span: 24 }}
              lg={{ span: 16 }}
              xl={{ span: 7 }}
              >
                <h5 className="text-head pb-3">Points Distribution</h5>

                <Form.List name="softSkillScores">
                  {(fields, { add, remove }) => (
                    <>
                      <div className="point-list">
                        {fields.map(({ key, name, ...restField }) => (
                            <PointDistributionInput
                              key={key}
                              name={name}
                              restField={restField}
                              remove={remove}
                              softSkillList={softSkillList}
                              isLoadingSoftSkillList={isLoadingSoftSkillList}
                              changeSoftSkillHandler={changeSoftSkillHandler}
                              selectedSoftSkill={selectedSoftSkill}
                            />
                          ))}
                      </div>
                      
                      <Form.Item>
                        <Button
                          key="submit"
                          className="btn-breeze-box w-auto btn-radius"
                          type="primary"
                          onClick={() => {
                            add();
                            setIsSoftSkillValid(true);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Soft Skill
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div className={`${!isSoftSkillValid && "mb-4 has-error"}`}>
                  {!isSoftSkillValid && (
                    <div className="ant-form-item-explain">
                      <div role="alert" className="ant-form-item-explain-error">
                        Please add at least one soft skill!
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.SoftSkillScores &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.SoftSkillScores && (
                    <AlertContainer data={validationMessages.SoftSkillScores} />
                  )}
                </div>

                <div
                  className={`${
                    validationMessages &&
                    validationMessages.error &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && validationMessages.error && (
                    <AlertContainer data={validationMessages} />
                  )}
                </div>
              </Col>
            </Row>

            <div className="button-container justify-content-end">
              <Row gutter={12}>
                <Col>
                  <Button
                    className="btn-breeze-box btn-radius"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save Interest Details
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="btn-radius"
                    disabled={isLoading}
                    onClick={onReset}
                  >
                    Reset Fields
                  </Button>
                </Col>
                {isEditMode && (
                  <Col>
                    <Button
                      className="btn-radius"
                      disabled={isLoading}
                      onClick={() => setIsModalDeleteVisible(true)}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  addInterest,
  updateInterest,
  getSoftSkillsNonPaged,
  deleteInterest,
})(AddInterestForm);
