import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  CLEAR_FIREBASE_REG_TOKEN,
  UPDATE_TOKEN,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { UNAUTHORIZED } from "../helpers/statuscodes";
import { axiosApiInstance } from "../utils/axiosApiInstance";
//LOAD USER
export const loadUser = () => async (dispatch) => {
  //load user info here
  // if (localStorage.token) {
  //   setAuthToken(localStorage.token);
  // }

  try {
    //going to use this endpoint for now to just check if token is still valid.
    const res = await axiosApiInstance.get(`/api/Member/list?MemberTypes=0`);
    //upon success lets dispatch the member data to the global state, no data for now
    dispatch({
      type: USER_LOADED,
      payload: "member data here",
    });
  } catch (err) {
    if (err.response.status === UNAUTHORIZED) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  }
};

//regsiter user
export const register =
  ({ name, email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      name,
      email,
      password,
    });

    try {
      const res = await axiosApiInstance.post("/api/users/", body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      // dispatch(loadUser()); //retrieve user info
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => {
          // dispatch(setAlert(error.msg, 'danger'));
        });
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

//login user
export const login = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axiosApiInstance.post(`/api/auth/login`, body, config);
    setAuthToken(res.data.token);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    // dispatch(loadUser()); //retrieve user info

    return res;
  } catch (err) {
    const errors = err.response.data.errors;
    // if (errors) {
    //   errors.forEach((error) => {
    //     // dispatch(setAlert(error.msg, 'danger'));
    //   });
    // }
    return err;
  }
};

//LOGOUT // CLEAR PROFILE
export const logout = () => async (dispatch) => {
  setAuthToken(null);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axiosApiInstance.post(`/api/Auth/logout`, null, config);
    // dispatch(loadUser()); //retrieve user info
    dispatch({
      type: LOGOUT,
    });
    dispatch({
      type: CLEAR_PROFILE,
    });
    return res;
  } catch (err) {
    // const errors = err.response.data.errors;
    // if (errors) {
    //   errors.forEach((error) => {
    //     // dispatch(setAlert(error.msg, 'danger'));
    //   });
    // }
    return err;
  }
  // dispatch({
  //   type: CLEAR_FIREBASE_REG_TOKEN,
  // });
};

export const clearAuth = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: CLEAR_PROFILE,
  });
};

export const updateTokens = (tokenData) => async (dispatch) => {
  dispatch({
    type: UPDATE_TOKEN,
    payload: tokenData,
  });
};
