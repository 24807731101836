import { connect } from 'react-redux';
import { Col, Row, DatePicker, Menu, MenuProps, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { getClubSummary } from '../../../actions/club';
import ColumnChart from './ColumnChart';

const { RangePicker } = DatePicker;

const DashboardDistribution = (props: {
    getClubSummary: Function;
}) => {
  const { getClubSummary } = props;
  const [graphFilter, setGraphFilter] = useState('event');
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const graphFilters = [
    {
      key: 'event',
      label: <p>Events per Club</p>,
    },
    {
      key: 'student',
      label: <p>Student per Club</p>,
    },
  ];

  const graphColumn = {
    event: {
        x: 'index',
        aliasX: 'Legend',
        y: 'totalEvent',
        aliasY: 'Total Events'
    },
    student: {
        x: 'index',
        aliasX: 'Legend',
        y: 'totalStudent',
        aliasY: 'Total Members'
    }
  }

  const tableColumn = {
    event: [
      {
        title: 'Legend',
        key: 'index',
        span: 6
      },
      {
        title: 'Club Name',
        key: 'name',
        span: 12
      },
      {
        title: 'Total Events',
        key: 'totalEvent',
        span: 6
      },
    ],
    student: [
      {
        title: 'Legend',
        key: 'index',
        span: 6
      },
      {
        title: 'Club Name',
        key: 'name',
        span: 12
      },
      {
        title: 'Total Members',
        key: 'totalStudent',
        span: 4
      },
    ],
  };

  const getSampleData = (multiplier: any = 1): any => {
    let sampleData = [];

    for (var i = 1; i < multiplier; i++)
    {
        sampleData.push({
            name: `Club${i}`,
            totalStudents: i * 100,
            totalEvents: i * 10,
        });
    }

    return sampleData.map((e: any, i: any) => {
        return {
            ...e,
            index: String(i+1).padStart(
                sampleData.length.toString().length,
                '0'
            ),
        };
    });
  };

  const onSelectGraphFilter: MenuProps['onClick'] = (e) =>
    setGraphFilter(e.key);

  const fetchList = async () => {

    setIsLoading(true);

    var data = await getClubSummary();

    var sortedData = data.data
    .sort((e: any, f: any) => {
        return (e as any)[(graphColumn as any)[graphFilter].y] - (f as any)[(graphColumn as any)[graphFilter].y]
    })
    .reverse()
    .map((e: any, i: any) => {
        return {
            ...e,
            name: e.memberGroup.name,
            index: String(i+1).padStart(
                data.data.length.toString().length,
                '0'
            ),
        };
    });

    setIsLoading(false);

    setGraphData(sortedData);
  };

  const sortData = () => {
    const tmpData: any = graphData
    .sort((e: any, f: any) => {
        console.log((e as any)[(graphColumn as any)[graphFilter].y]);
        return (e as any)[(graphColumn as any)[graphFilter].y] - (f as any)[(graphColumn as any)[graphFilter].y]
    })
    .reverse()
    .map((e: any, i: any) => {
        return {
            ...e,
            name: e.memberGroup.name,
            index: String(i+1).padStart(
                graphData.length.toString().length,
                '0'
            ),
        };
    });

    setGraphData(tmpData);
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    sortData();
  }, [graphFilter]);

    return (
        <div className='dashboard-distribution'>
            <Row className='header'>
                <div className='d-flex'>
                <div
                >
                    Distribution
                </div>
                </div>
                <div className='d-flex ml-auto menu-header'>
                {
                    isLoading
                        ? <div></div>
                        : <Menu
                        className='menus'
                        mode='horizontal'
                        selectedKeys={[graphFilter]}
                        items={graphFilters}
                        onClick={onSelectGraphFilter}
                />
                }
                
                </div>
            </Row>
            <Row>
                <Col span={24} className='gutter-row'>
                    {isLoading
                        ? <Skeleton loading={true} active />
                        : <ColumnChart
                            data={graphData}
                            x={(graphColumn as any)[graphFilter].x}
                            y={(graphColumn as any)[graphFilter].y}
                            aliasX={(graphColumn as any)[graphFilter].aliasX}
                            aliasY={(graphColumn as any)[graphFilter].aliasY}
                    />}
                </Col>
            </Row>
            <Row className='gutter-row'>
                <Col span={24}>

                {isLoading
                ? <Skeleton loading={true} active />
                : <div className='table'>
                    <div className='header'>
                        <Row>
                            {(tableColumn as any)[graphFilter].map((e: any) => (
                                <Col span={e.span}><span>{e.title}</span></Col>
                            ))}
                        </Row>
                    </div>
                    <div className='body'>
                        
                        {graphData.map((e: any, i: any) => {
                            return (
                                <Row className={`row ${i % 2 ? 'row-even' : 'row-odd'}`}>
                                    {(tableColumn as any)[graphFilter].map((f: any) => (
                                        <Col className='col' span={f.span}>
                                            <span className={`${f.key === 'index' ? 'legend' : ''}`}>{e[f.key] ?? ''}</span>
                                        </Col>
                                    ))}
                                </Row>
                            );
                        })}
                    </div>
                </div>}

                    
                    
                    


                    {/* <Table
                    dataSource={getSampleData(20).map((e, i) => {
                        return {
                            ...e,
                            index: String(i+1).padStart(
                                getSampleData(20).length.toString().length,
                                '0'
                            ),
                        };
                    })}
                    scroll={{ y: 240 }}
                    columns={(tableColumn as any)[graphFilter]}
                    pagination={false}
                    ></Table> */}
                </Col>
                
                
            </Row>
        </div>
    );
};

const mapStateToProps = (state: { auth: any; userIsBack: boolean }) => ({
  interests: state.auth.member.interests,
  userIsBack: state.userIsBack,
});
export default connect(mapStateToProps, {getClubSummary})(DashboardDistribution);
