import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment, { Moment } from "moment";
import { Skeleton, Menu, Button, MenuProps, Form, Spin } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { getStudentTopClubs } from '../../../actions/dashboard';

import noClubInvites from "../../../assets/images/icons/no_club_invites.svg";


const DashboardTopClubs = (props: {
    getStudentTopClubs: Function;
    auth: any;
}) => {
    const {
        getStudentTopClubs,
        auth,
      } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [clubs, setClubs] = useState<any[]>([]);

    const fetchList = async () => {
        setIsLoading(true);
        let res = await getStudentTopClubs();
        setClubs(res?.data?.items ?? []);
        setIsLoading(false);
    };


    useEffect(()=>{
       fetchList();
    },[])

    const Club = (data: any) => {
        return(
            <div className="club">
                <div className="start-group">
                    <div className="club-image-wrapper">
                        {
                            data?.data?.bannerFileUrl ? 
                            <img src={data?.data?.bannerFileUrl} alt={'banner'} className="club-image"/>
                            :<div className="club-image"/>
                        }
                    </div>
                    <div className="text">
                        <h3>{data?.data?.name ?? ""}</h3>
                    </div>
                </div>
                <div className="end-group">
                    <h3>{data?.data?.totalHours ?? ""}</h3> 
                </div>
            </div>
        );
    }


  return (
    <div className="dashboard-top-clubs">
        <div className="header">
            <h1>My Top Clubs</h1>
        </div>
        <div className="clubs">
            {!isLoading && clubs.length>0 &&
                <p className="point-label">Overall Time Spent</p>
            }
            {isLoading && (
                <>
                    <Skeleton loading={true} active />
                    <Skeleton loading={true} active />
                    <Skeleton loading={true} active />
                </>
            ) }
            <div className="club-top-content">
                {!isLoading && clubs.length>0 &&
                    clubs.map((clubData)=><Club data={clubData} key={clubData.id}/>)
                }
                {!isLoading && clubs.length===0 &&
                    <div className="no-clubs-container">
                        <img height={120} width={120} src={noClubInvites} />
                        <h4>No clubs yet.</h4> 
                    </div>
                }
            </div>
            
        </div>
        <Link to={"/clubs"}>
            <Button
            type="default"
            shape="default"
            key="submit"
            className="view-all"
            >
            View All Clubs
            </Button>
        </Link>
    </div>
  );
};


const mapStateToProps = (state: any) => ({
    auth: state.auth,
  });
export default connect(mapStateToProps, {
    getStudentTopClubs,
  })(DashboardTopClubs);