import { async } from "@firebase/util";
import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { SET_USER_IS_BACK } from "./types";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
import moment from "moment";

export const getEventDetails = (params) => async (dispatch) => {
  try {
    return await axiosApiInstance.get(`/api/Event/${params.eventId}`);
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

const parseParams = (params) => {
  let searchParams = new URLSearchParams();
  params
    .filter((e) => e.value || e.isRequired)
    .forEach((e) => {
      const value = e.value ?? e.defaultValue ?? "";

      if (Array.isArray(value))
        value.forEach((f) => searchParams.append(e.name, f));
      else searchParams.append(e.name, value);
    });
  return searchParams;
};

export const getEventList = (params, controller) => async (dispatch) => {
  var queryParameters = [
    {
      name: "searchString",
      // Repush
      value: params.searchString ?? "",
    },
    { name: "status", value: params.status },
    { name: "EventTypes", value: params.EventTypes },
    { name: "schoolEventsOnly", value: params.schoolEventsOnly },
    { name: "from", value: params.from },
    { name: "to", value: params.to },
    { name: "page", value: params.current },
    { name: "pageSize", value: params.pageSize },
  ];

  try {
    const res = await axiosApiInstance.get(`/api/Event`, {
      params: parseParams(queryParameters),
      signal: controller ? controller.signal : null,
    });

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getClubEventList = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  let searchString = params.searchString
    ? `&searchString=${encodeURIComponent(params.searchString)}`
    : "";
  try {
    const res = await axiosApiInstance.get(
      `/api/Event` +
        `?page=${page}&ClubId=${params.ClubId}&pageSize=${params.pageSize}` +
        searchString +
        `&status=${params.status}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getLocationEventList = (params) => async (dispatch) => {
  try {
    const from = params.from ? `&from=${params.from}` : "";
    const to = params.to ? `&to=${params.to}` : "";
    return await axiosApiInstance.get(
      `/api/event/list?locationId=${params.locationId}${from}${to}`
    );
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getEventsListByDate = (params) => async (dispatch) => {
  try {
    const from = params.from ? `${params.from}` : "";
    const to = params.to ? `&to=${params.to}` : "";
    return await axiosApiInstance.get(
      `/api/event/list?from=${from}${to}`
    );
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const searchUpcomingEvents =
  (params, controller, flutterConnect) => async (dispatch) => {
    console.log('test now');
    try {
      const searchString = params.searchString
        ? encodeURIComponent(params.searchString)
        : "";
      const Interests = params.InterestIds
        ? `&interestIds=${params.InterestIds}`
        : "";
      const memberGroupIds = params.memberGroupIds
        ? `&MemberGroupIds=${params.memberGroupIds}`
        : "";
      const Statuses = params.Statuses ? `&Statuses=${params.Statuses}` : "";
      const from = params.from ? `&from=${params.from}` : "";
      const to = params.to ? `&to=${params.to}` : "";

      const response = await axiosApiInstance.get(
        `/api/event/search?searchString=${searchString}&page=${params.page}&pageSize=${params.pageSize}&isDesc=${params.isDesc}&MyEventsOnly=${params.MyEventsOnly}&schoolEventsOnly=${params.schoolEventsOnly}${from}${to}${Interests}${memberGroupIds}${Statuses}`,
        { signal: controller ? controller.signal : null } //pass this param to cancel multiple duplicate requests
      );
      
      //NOTE: Flutter events
      if (flutterConnect) {
        window.flutter_inappwebview
        //NOTE: Converted to string for generic handling
        .callHandler('handleEventData', JSON.stringify(response))
        .then(e => {
          console.log(e);
        });
      }

      return response;
    } catch (err) {
      const response = err.response;
      // dispatch(setAlert(response.status, response.statusText, "error"));
      return response;
    }
  };

export const searchClubUpcomingEvents = (params) => async (dispatch) => {
  try {
    let searchString = params.searchString
      ? encodeURIComponent(params.searchString)
      : "";
    const from = `&from=${new Date(2000, 0, 1).toISOString()}`;
    const to = `&to=${moment()
      .add(365, "day")
      .endOf("day")
      .toDate()
      .toISOString()}`;
    return await axiosApiInstance.get(
      `/api/event/search?searchString=${searchString}&page=${params.page}&pageSize=${params.pageSize}` +
        `&memberGroupIds=${params.groupId}${from}${to}`
    );
  } catch (err) {
    console.log("EVENT err: ", err);
    const response = err.response;
    // dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const addEvent = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(`/api/Event/`, formData, config);

    dispatch(setAlert("Success", "Event Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const addInvites = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/event/${formData.eventId}/invite`,
      formData,
      config
    );

    //dispatch(setAlert("Success", "Event Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const updateEvent = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/Event/${formData.eventId}`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Event Successfully updated", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.data.error === "There is a conflict event.") {
      dispatch(
        setAlert(
          "Error in saving",
          `Cannot save changes. ${response.data.error}`,
          "error"
        )
      );
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`/api/Event/${id}`);

    //dispatch(setAlert("Success", "Event Deactivated", "success"));
    return res;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const deleteInvite = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(
      `/api/Event/${formData.eventId}/invite/${formData.inviteId}`
    );

    dispatch(setAlert("Success", "Event invite removed", "success"));
    return res;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const handleEventProposal = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(
      `/api/Event/${params.eventId}/${params.type}`,
      params,
      config
    );

    dispatch(
      setAlert(
        "Success",
        `Event Proposal has been ${
          params.type === "approve" ? "Approved" : "Declined"
        }`,
        "success"
      )
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const joinEvent = (eventId) => async (dispatch) => {
  try {
    return await axiosApiInstance.post(`/api/event/${eventId}/join`);
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const acceptEventInvite = (params) => async (dispatch) => {
  try {
    return await axiosApiInstance.put(
      `/api/event/${params.eventId}/invite/${params.inviteId}/approve`
    );
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const declineEventInvite = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axiosApiInstance.put(
      `/api/event/${params.eventId}/invite/${params.inviteId}/decline`,
      params,
      config
    );
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const markAsAttended = (params) => async (dispatch) => {
  try {
    let response = await axiosApiInstance.post(
      `/api/attendance`,
      {
        eventId: params.eventId,
        inviteId: params.inviteId,
        authTypes: params.authTypes ?? []
      }
    );
    if (response?.data?.reactivated === true) {
      dispatch({
        type: SET_USER_IS_BACK,
        payload: true,
      });
      setTimeout(() => {
        dispatch({
          type: SET_USER_IS_BACK,
          payload: false,
        });
      }, 100);
    }
    return response;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const updateMultipleAttendance = (params) => async (dispatch) => {
  try {
    let response = await axiosApiInstance.put(
      `/api/attendance/update/multiple`,
      params
    );
    return response;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const searchSchoolWideEvents =
  (params, controller) => async (dispatch) => {
    var queryParameters = [
      {
        name: "searchString",
        value: encodeURIComponent(params.searchString ?? ""),
      },
      { name: "interestIds", value: params.InterestIds },
      { name: "MemberGroupIds", value: params.memberGroupIds },
      { name: "Statuses", value: params.Statuses },
      { name: "from", value: params.from },
      { name: "to", value: params.to },
      { name: "page", value: params.page },
      { name: "pageSize", value: params.pageSize },
      { name: "isDesc", value: params.isDesc },
      { name: "MyEventsOnly", value: params.MyEventsOnly },
      { name: "schoolEventsOnly", value: params.schoolEventsOnly },
    ];

    const payload = queryParameters
      .filter((e) => e.value || e.isRequired)
      .reduce((a, e) => {
        a[e.name] = e.value ?? e.defaultValue ?? "";
        return a;
      }, {});

    try {
      const response = await axiosApiInstance.get("/api/event/search", {
        params: payload,
        signal: controller ? controller.signal : null,
      });

      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const authQrCode =
  (params, controller) => async (dispatch) => {
    var queryParameters = [
      { name: "ConnectionId", value: params.connectionId },
      { name: "EventId", value: params.eventId },
      { name: "Code", value: params.code },
    ];

    const payload = queryParameters
      .filter((e) => e.value || e.isRequired)
      .reduce((a, e) => {
        a[e.name] = e.value ?? e.defaultValue ?? "";
        return a;
      }, {});


    try {
      const response = await axiosApiInstance.post(
        "/api/attendance/auth/qr",
        payload);

      return response;
    } catch (err) {
      return err.response;
    }
  };
