import infoCircleIcon from "../../assets/images/icons/info-circle.svg";

const PasswordRequirements = (props: { fieldName: string }) => {
  return (
    <div className="d-flex">
      <label className="required-label">{props.fieldName}</label>
      <div className="ml-1 custom-tooltip-container">
        <img className="info-icon" src={infoCircleIcon} />
        <div className="custom-tooltip-content">
          <div className="arrow-left"></div>
          <div className="d-flex-column">
            <div className="tip-title">Required Format</div>
            <ul>
              <li>Must be 8 Characters long</li>
              <li> At least 1 Capital Letter</li>
              <li> At least 1 Special Character</li>
              <li>
                No consecutive three letters, numbers or special characters
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRequirements;
