import { Button, Col, PageHeader, Row, Space } from "antd";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddSchoolForm from "./AddSchoolForm";

const AddSchool = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const title = location?.state?.schoolId ? "Edit School" : "Add School";
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate(-1)}
          title={title}
          extra={[
            <Button
              key="back"
              className="btn-radius"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container pt-1">
                  <AddSchoolForm />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddSchool;
