import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { connect } from "react-redux";
import { getPagedAnnouncements } from "../../actions/announcement";
import { Fragment, useEffect, useState } from "react";
import { OK } from "../../helpers/statuscodes";

const SiteWideAnnouncement = (props: {
  setLayoutExpanded: Function;
  getPagedAnnouncements: Function;
  onHideAnnouncement: Function;
}) => {
  const { setLayoutExpanded, getPagedAnnouncements, onHideAnnouncement } = props;
  const [announcement, setAnnouncement] = useState<any>("");
  const fetchAnnouncements = async () => {
    let res = await getPagedAnnouncements({
      isSiteWide: true,
      isActive: true,
      page: 1,
      pageSize: 1,
    });

    if (res.status === OK) {
      setAnnouncement(res.data.items);
      if (res.data.items.length > 0) {
        setLayoutExpanded(true);
      }
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const formatDuration = (data:any) =>{
    const start = new Date(data.startDate);
    const end = new Date(data.endDate);
    const tz = data.timeZoneId;

    if(start.toDateString() === end.toDateString()){  //ends same day
      return `on ${start.toLocaleDateString('en-US')} from ${start.toLocaleString('en-US', {hour: 'numeric', minute: '2-digit'})} to ${end.toLocaleString('en-US', {hour: 'numeric', minute: '2-digit'})} ${tz}`;
    }else{
      return `from ${start.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit'})} to ${end.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit'})} ${tz}`;
    }
  }
  
  return (
    <Fragment>
      {announcement && announcement.length > 0 ? (
        announcement.map((a:any)=>
        <div style={{ paddingBottom: "72px" }} key={a.announcementId}>
          <div className="site-wide-announcement">
            <div className="announcement-container">
              <div className="d-flex align-items-center">
                <ExclamationCircleOutlined
                  className="mr-2"
                  style={{ marginTop: "-3px", fontSize: "21px" }}
                />
                <p>
                  {a.body} {formatDuration(a)} . We apologize for the inconvenience.
                </p>
              </div>
                <Button className="announcement-close" onClick={()=>onHideAnnouncement(false)}>
                  <CloseOutlined />
                </Button>
            </div>
          </div>
        </div>
        )

      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default connect(null, { getPagedAnnouncements })(SiteWideAnnouncement);
