import { Button, Col, PageHeader, Row, Space } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContentHeader from "../../ContentHeader/ContentHeader";
import AddUserForm from "./AddUserForm";

const AddUser = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const title = location?.state?.memberId
    ? "Edit School Admin"
    : "Add School Admin";
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate(-1)}
          title={title}
          extra={[<Button key="back" onClick={() => navigate(-1)}>Cancel</Button>]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <AddUserForm />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddUser;
