import { Button, Typography } from "antd";
import { connect } from "react-redux";
import { Form, FormInstance } from "antd";
import { getSoftSkillsNonPaged } from "../../actions/softskill";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PointDistributionInput from "../super-admin/interest/PointDistribution";

type SoftSkill = {
  name: string;
  description: string;
};

type SofSkillDistribution = {
  softSkillId: string;
  name: string;
  percentage: number;
};

type IProps = {
  form: FormInstance<any>;
  softSkillScores: SofSkillDistribution[];
  getSoftSkillsNonPaged: Function;
  onChange(value: SofSkillDistribution[]): void;
};

const SSDistributionWidget = (props: IProps) => {
  const { form, getSoftSkillsNonPaged } = props;
  const [remainder, setRemainder] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [softSkillList, setSoftSkillList] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState<SoftSkill>();
  const [selectedSoftSkill, setSelectedSoftSkill]: any = useState([]);

  const validateSoftSkillScore = () => {
    let sum = 0;
    const scores = form.getFieldValue("softSkillScores");
    if (scores) {
      sum = scores.reduce((p: any, c: any) => p + c.percentage, 0);

      setTotalScore(sum);
      props.onChange(
        scores.map(
          (score: any) =>
            ({
              softSkillId: score.softSkillId,
              percentage: score.percentage
            } as SofSkillDistribution)
        )
      );
    }
    if (sum <= 0) {
      setRemainder(100);
    } else if (sum >= 100) {
      setRemainder(0);
    } else {
      setRemainder(100 - sum);
    }
    setIsInvalid(sum !== 100);
  };

  const changeSoftSkillHandler = (key: number, e: any) => {
    setSelectedSoftSkill({
      ...selectedSoftSkill,
      [key]: { key: key, id: e }
    });
    validateSoftSkillScore();
  };

  const fetchSoftSkillList = async () => {
    setIsLoading(true);
    const data = await getSoftSkillsNonPaged();
    setSoftSkillList(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (props.softSkillScores && props.softSkillScores.length > 0) {
      const _selSS = props.softSkillScores.reduce(
        (val, curr, index) => ({
          ...val,
          [index]: { key: index, id: curr.softSkillId }
        }),
        {}
      );
      setSelectedSoftSkill(_selSS);
    }
  }, [props.softSkillScores]);

  useEffect(() => {
    fetchSoftSkillList();
  }, []);

  return (
    <Form.List name="softSkillScores">
      {(fields, { add, remove }) => (
        <>
          <div style={{ background: "#FAFAFA", padding: 16 }}>
            <div className="point-list">
              {fields.map(({ key, name, ...restField }) => (
                <PointDistributionInput
                  key={key}
                  name={name}
                  remove={remove}
                  restField={restField}
                  softSkillList={softSkillList}
                  isLoadingSoftSkillList={isLoading}
                  changeSoftSkillHandler={changeSoftSkillHandler}
                  selectedSoftSkill={selectedSoftSkill}
                  setSelectedSkill={setSelectedSkill}
                />
              ))}
            </div>
            
            <div className="justified-row">
              <div>
                <Button
                  key="submit"
                  shape="round"
                  icon={<PlusOutlined />}
                  onClick={() => add({ percentage: remainder })}
                >
                  Add Soft Skill
                </Button>
              </div>
              <span>Total {totalScore}%</span>
            </div>
          </div>
          {isInvalid && (
            <Typography.Text type="danger">
              Softskill points distribution must be 100%.
            </Typography.Text>
          )}
        </>
      )}
    </Form.List>
  );
};
export default connect(null, { getSoftSkillsNonPaged })(SSDistributionWidget);
