import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Col, Divider, Form, Input, Modal, Row, Skeleton } from "antd";
import { searchClubUpcomingEvents } from "../../../actions/event";
import { Button, Tooltip } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { AppstoreOutlined } from "@ant-design/icons";
import { CheckOutlined } from "@ant-design/icons";
import { EventModel } from "../../../interface/Event";
import { Link, useParams } from "react-router-dom";
import { ListViewType } from "../events/EventItem";
import { EventInvite } from "../events/EventInvite";
import { declineEventInvite } from "../../../actions/event";
import { OK } from "../../../helpers/statuscodes";
import EventItem from "../events/EventItem";
import ModalDeclineReason from "../Modals/ModalDeclineReason";
import successIcon from "../../../assets/images/icons/success-icon.svg";

const { Search } = Input;

const ClubEvent = (props: {
  Permissions: any;
  searchClubUpcomingEvents: Function;
  declineEventInvite: Function;
  viewingAs: string;
  auth: any;
  pushData: any;
  flutterConnect: boolean;
}) => {
  const { memberGroupId } = useParams();
  const {
    Permissions: { canCreateEvent, isDisabled },
    searchClubUpcomingEvents,
    viewingAs,
    auth,
    pushData,
    flutterConnect
  } = props;
  const initialPagination = {
    current: 1,
  };
  const PAGE_SIZE = 9;
  const [listView, setListView] = useState<ListViewType>("card");
  const [pagination, setPagination] = useState(initialPagination);
  const [searchString, setSearchString] = useState("");
  const [events, setEvents] = useState<EventInvite[]>([]);

  const [isListLoading, setIsListLoading] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);
  const [hasMorePage, setHasMorePage] = useState(false);

  const [joinModalText, setJoinModalText] = useState("");
  const [selectedEventInfo, setSelectedEventInfo] = useState<any>({});
  const [isHandleJoinModalOpen, setIsHandleJoinModalOpen] = useState(false);
  const [isDeclineEventModalOpen, setIsDeclineEventModalOpen] = useState(false);
  const [isLoadingDeclineEventInvite, setIsLoadingDeclineEventInvite] =
    useState(false);
  const [declineEventForm] = Form.useForm();

  const onDeclineHandler = (event: any, inviteId: string) => {
    setSelectedEventInfo({
      eventName: event.title,
      eventId: event.eventId,
      inviteId,
    });
    setIsDeclineEventModalOpen(true);
  };

  const onDeclineForm = async (formData: any) => {
    setIsLoadingDeclineEventInvite(true);
    let values = {
      ...formData,
      ...selectedEventInfo,
    };
    const result = await props.declineEventInvite(values);
    if (result && result.data) {
      // setUserInvite(result.data);
    }
    setIsLoadingDeclineEventInvite(false);
    if (result.status == OK) {
      setEvents(
        events.filter((item: any) => {
          if (item.eventId !== selectedEventInfo.eventId) {
            return item;
          }
        })
      );
      setIsDeclineEventModalOpen(false);
      setJoinModalText(
        `You have successfully submitted decline reason for ${selectedEventInfo?.eventName} invitation.`
      );
      declineEventForm.resetFields();
    }
    setIsHandleJoinModalOpen(true);
  };

  const fetchUpcomingEvents = async (paginationParam: any, isMore: boolean) => {
    setLockLoading(true);
    setIsListLoading(true);
    if (paginationParam.current === 1) {
      setEvents([]);
    }

    let params = {
      page: paginationParam.current,
      pageSize: PAGE_SIZE,
      searchString,
      groupId: memberGroupId,
    };
    const res = await searchClubUpcomingEvents(params);

    let items = res.data.items;
    if (items.length !== 0) {
      if (paginationParam.current !== 1) {
        const formattedData = items.map((item: EventModel) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setEvents(events.concat(formattedData));
      } else {
        const formattedData = items.map((item: EventModel) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setEvents(formattedData);
      }
      setPagination({
        current: paginationParam.current + 1,
      });
      const _hasMorePages =
        Math.ceil(res.data.totalItems / PAGE_SIZE) > pagination.current;
      setHasMorePage(_hasMorePages);
    }
    setIsListLoading(false);
    setTimeout(() => {
      setLockLoading(false);
    }, 500);
  };

  const viewMoreHandler = (pageType: any) => {
    setPagination({ ...pagination, current: pagination.current++ });
  };

  useEffect(() => {
    setPagination(initialPagination);
    fetchUpcomingEvents(initialPagination, false);
  }, [searchString]);

  useEffect(() => {
    window.onscroll = async function (ev) {
      if (window.innerHeight + window.scrollY >= (flutterConnect ? document.body.scrollHeight - 1  : document.body.scrollHeight)) {
        if (!lockLoading && hasMorePage) {
          await fetchUpcomingEvents(pagination, true);
        }
      }
    };
  }, [pagination, lockLoading, hasMorePage]);

  useEffect(() => {
    if (pushData.data?.topic === "EventUpdate") {
      const pushInfo = JSON.parse(pushData.data?.pushData);
      if (
        pushInfo.MemberGroupId === memberGroupId &&
        pushData.data?.memberId !== auth.member.memberId
      ) {
        fetchUpcomingEvents(initialPagination, false);
      }
    }
  }, [pushData]);

  return (
    <Fragment>
      <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={onDeclineForm}
        isOpen={isDeclineEventModalOpen}
        setIsOpen={setIsDeclineEventModalOpen}
        form={declineEventForm}
        isLoading={isLoadingDeclineEventInvite}
      />
      <Modal
        centered
        className="modal-action-success"
        visible={isHandleJoinModalOpen}
        onCancel={() => setIsHandleJoinModalOpen(false)}
        footer={[]}
      >
        <div className="d-flex justify-content-center w-100">
          <img src={successIcon} height="96px" width="96px" />
        </div>
        <div className="d-flex-column w-100 justify-content-center">
          <h4 className="text-center">Success!</h4>
          <p className="text-center">{joinModalText}</p>
        </div>
      </Modal>
      <div className="content-container">
        <div className="club-event-list">
          <div className="list-controls">
            <div className="search-container">
              <Search
                //onKeyUp={onFilterSearchHandler}
                onSearch={(val) => setSearchString(val)}
                allowClear
                placeholder="Search event name"
                style={{ width: 280 }}
              />
            </div>
            <div className="list-filters">
              {/* <Tooltip title="Table View">
                <Button
                  shape="circle"
                  icon={<MenuOutlined />}
                  onClick={() => setListView("row")}
                  className={listView === "row" ? "selected" : ""}
                />
              </Tooltip> */}
              {/* <Tooltip title="Grid View">
                <Button
                  shape="circle"
                  icon={<AppstoreOutlined />}
                  onClick={() => setListView("card")}
                  className={listView === "card" ? "selected" : ""}
                />
              </Tooltip> */}

              {canCreateEvent && (
                <Link
                  to={
                    viewingAs === "Admin"
                      ? `/admin/events/add`
                      : `/club/${memberGroupId}/events/add`
                  }
                >
                  <Button
                    disabled={isDisabled}
                    type="primary"
                    shape="round"
                    icon={<CheckOutlined size={16} />}
                  >
                    Add Event
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="listing-content-view">
            <div id="event-list" className={`event-list ${listView}`}>
              {events.length > 0 && (
                <Fragment>
                  <Row gutter={[24, 24]}>
                    {events.map((event: EventInvite) => (
                      <Col
                        key={event.eventId}
                        xs={24}
                        sm={12}
                        lg={8}
                        // span={listView === "row" ? 24 : 8}
                        className="gutter-row"
                      >
                        {event.divider && (
                          <div className="list-divider">
                            {/* <span>{event.divider}</span> */}
                            {event.dividerPoints !== undefined && (
                              <span>Earned points: {event.dividerPoints}</span>
                            )}
                          </div>
                        )}
                        <EventItem
                          view={listView}
                          key={event.eventId}
                          event={event}
                          source={memberGroupId}
                          onDeclineHandler={onDeclineHandler}
                        />
                      </Col>
                    ))}
                  </Row>

                  {/* <Fragment>
                    {hasMorePage ? (
                      <div
                        className="view-more-btn"
                        onClick={() => viewMoreHandler("eventsThisWeek")}
                      >
                        View More
                      </div>
                    ) : (
                      <Divider orientation="center" plain>
                        No more events to show
                      </Divider>
                    )}
                  </Fragment> */}
                </Fragment>
              )}

              {isListLoading && listView === "card" && (
                <Row gutter={24}>
                  {Array(8)
                    .fill(0)
                    .map((_, i) => (
                      <Col
                        key={`card-loading-${i}`}
                        span={8}
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}
                        className="mb-5"
                      >
                        <div className="event-item-grid-view">
                          <div className="event-item-image">
                            <Skeleton.Image className="w-100" />
                          </div>
                          <Skeleton
                            className="custom-skeleton"
                            active
                            paragraph={{ rows: 3 }}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              )}
              {isListLoading && listView === "row" && (
                <Row gutter={24}>
                  {Array(8)
                    .fill(0)
                    .map((_, i) => (
                      <Col key={`loading-${i}`} span={24} className="mb-5">
                        <div className="event-item-list-view">
                          <div className="event-item-image">
                            <Skeleton.Image />
                          </div>
                          <Skeleton
                            className="custom-skeleton"
                            active
                            paragraph={{ rows: 3 }}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
  flutterConnect: state.flutterConnect
});
export default connect(mapStateToProps, {
  searchClubUpcomingEvents,
  declineEventInvite,
})(ClubEvent);
