import axios from "axios";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";

export const getStudentEventsPointsList = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Member/dashboarditems/${params.type}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    // dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getStudentTopClubs = () => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Club/topclubs`
    );
    return res;
  } catch (err) {
    const response = err.response;
    // dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getFacultyMembers = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Member/dashboarditems/${params.type}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};
