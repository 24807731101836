import {
  Button,
  Calendar,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Slider,
  message,
  DatePicker,
} from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import AvatarEditor from "react-avatar-editor";
import { Fragment, useEffect, useRef, useState } from "react";
import type { CalendarMode } from "antd/es/calendar/generateCalendar";
import type { Moment } from "moment";
import {
  NotificationOutlined,
  ClockCircleOutlined,
  FlagOutlined,
  RiseOutlined,
  CalendarOutlined,
  CompassOutlined,
  EditOutlined,
  PictureOutlined,
  MinusOutlined,
  EllipsisOutlined,
  PlusOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import PageHeader from "../layouts/PageHeader";
import sampleProfileImg from "../../../assets/images/sample-profile-img.png";
import userBadgeIcon from "../../../assets/images/icons/user-badge.svg";
import pencilIcon from "../../../assets/images/icons/icon-pencil.svg";
import successFrame from "../../../assets/images/illustration/illus-profile.svg";
import illusPwd from "../../../assets/images/illustration/illus-pwd.svg";

import iconCheck from "../../../assets/images/icons/icon-check.svg";
import iconSports from "../../../assets/images/icons/interest/icon-sports.svg";
import placeholderImg from "../../../assets/images/club-banner-default.png";
import sampleClubLogo from "../../../assets/images/logos/sample-club-logo.png";
import editIcon from "../../../assets/images/icons/edit-icon.svg";
import changeProfilePlaceholder from "../../../assets/images/change-profile-placeholder.png";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import ModalFullView from "../Modals/ModalFullView";
import {
  changePassword,
  getMyClubs,
  getMyEvents,
  getMyClubsGrouped,
  getProfileSummary,
  updateProfile,
  uploadProfilePicture,
} from "../../../actions/profile";
import {
  BAD_REQUEST,
  CREATED,
  NO_CONTENT,
  OK,
} from "../../../helpers/statuscodes";
import AlertContainer from "../../../layouts/AlertContainer";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import moment from "moment";
import Doughnut from "../../widget/Doughnut";
import Dragger from "antd/lib/upload/Dragger";

import {
  SchoolAdmin,
  Teacher,
  Student,
  TeacherString,
  StudentString,
} from "../../../helpers/memberTypes";
import { MyProfileSummary } from "../../../interface/Profile";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import MiniPageHeader from "../layouts/MiniPageHeader";
import DefaultAvatar from "../../widget/DefaultAvatar";
import { softSkillColors } from "../../../helpers/softSkillColors";
import { neutralSkillColors } from "../../../helpers/neutralSkillColors";
import SoftSkillAttributes from "../dashboard/SoftSkillAttributes";

const Profile = (props: {
  auth: any;
  updateProfile: Function;
  changePassword: Function;
  uploadProfilePicture: Function;
  getProfileSummary: Function;
  getMyClubs: Function;
  getMyEvents: Function;
  getMyClubsGrouped: Function;
  flutterConnect: Boolean;
}) => {
  const {
    auth,
    updateProfile,
    changePassword,
    uploadProfilePicture,
    getProfileSummary,
    getMyClubs,
    getMyEvents,
    getMyClubsGrouped,
    flutterConnect
  } = props;
  const softSkillMaximumPoint = 240;
  const { member } = auth;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const cropImageRef = useRef(null);

  const [filter, setFilter] = useState(0);
  const onPanelChange = (value: Moment, mode: CalendarMode) => {};
  const [myProfileSummary, setMyProfileSummary] = useState<MyProfileSummary>();
  const [myProfilePoints, setMyProfilePoints] = useState<[]>();
  const [myClubs, setMyClubs] = useState<any>();
  const [myClubsCount, setMyClubsCount] = useState<any>(0);

  const [myUpcomingEvents, setMyUpcomingEvents] = useState<any>();

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isFileSizeValid, setIsFileSizeValid] = useState(true);
  const [isFileTypeValid, setIsFileTypeValid] = useState(true);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isChangeProfilePictureModalOpen, setIsChangeProfilePictureModalOpen] =
    useState(false);
  const [isChangeProfilePictureComplete, setIsChangeProfilePictureComplete] =
    useState(false);

  // const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(true);

  // const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(true);
  const [isUpdatePasswordComplete, setIsUpdatePasswordComplete] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isUpdateComplete, setIsUpdateComplete] = useState(false);

  // const [isLoadingEditProfile, setIsLoadingEditProfile] = useState(true);
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [summaryText, setSummaryText] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState({});

  const [gender, setGender] = useState(1);
  const [chartData, setChartData] = useState<any>();

  const dateFormat = "YYYY-MM-DD";
  const customFormat = (value: any) => `${value.format(dateFormat)}`;
  const location = useLocation();

  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };

  const onFinish = async (formData: any) => {
    setIsLoading(true);
    let val = {
      ...formData,
      birthDate: moment(formData.birthDate).format(dateFormat),
      memberType:
        formData.memberType === TeacherString
          ? Teacher
          : formData.memberType === StudentString && Student,
      gender: gender,
    };
    let res = await updateProfile(val);

    if (res.status == OK) {
      setIsUpdateComplete(true);
      setTimeout(() => {
        setIsUpdateComplete(false);

        setIsEditProfileModalOpen(false);
      }, 3000);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const onSubmitPasswordForm = async (FormData: any) => {
    setIsLoading(true);
    let res = await changePassword(FormData);

    if (res.status == OK || res.status == NO_CONTENT) {
      setIsUpdatePasswordComplete(true);
      passwordForm.resetFields();
      setTimeout(() => {
        setIsChangePasswordModalOpen(false);
      }, 3000);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const onMenuClick = (info: any) => {
    if (info.key === "edit_club") {
      // navigate(`/club/${memberGroupId}/edit`);
    } else {
      // delete button click
    }
  };

  const menu = (
    <Menu
      onClick={onMenuClick}
      items={
        [
          // { label: "Edit Club", key: "edit_club" },
          // { label: "Delete Club", key: "delete_club" },
        ]
      }
    ></Menu>
  );

  const showModal = () => {
    setIsEditProfileModalOpen(true);
  };

  const handleOk = () => {
    setIsEditProfileModalOpen(false);
  };

  const handleCancel = () => {
    setIsEditProfileModalOpen(false);
  };

  const handleChangeSummary = (e: any) => {
    setIsEditingSummary(false);
    setSummaryText(e.target.value);
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pictureUrl, setPictureUrl]: any = useState();

  const MAX_FILE_SIZE = 5120;
  const uploadProps: UploadProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      // const newFileList = fileList.slice();
      // newFileList.splice(index, 1);
      setFileList([]);
    },
    beforeUpload: async (file: any) => {
      setIsFileSizeValid(true);
      setIsFileTypeValid(true);
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        setIsFileTypeValid(false);
        // message.error("You can only upload JPG/PNG file!", 15);
      }
      if (file.size / 1024 > MAX_FILE_SIZE) {
        setIsFileSizeValid(false);
        // message.error("You can only upload up to 5MB file size!", 15);
      }
      if (file.size / 1024 < MAX_FILE_SIZE && isJpgOrPng) {
        setIsFileSizeValid(true);
        setIsFileTypeValid(true);
        setFileList([file]);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPictureUrl(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }

      return false;
    },
    fileList,
  };

  const [scaleLevel, setScaleLevel] = useState(1);

  const onChangeScaleLevel = (value: number) => {
    if (isNaN(value)) {
      return;
    }

    setScaleLevel(value);
  };

  const getImageUrl = async () => {
    let x: any = cropImageRef;
    return x.current.getImageScaledToCanvas().toDataURL();
  };

  const handleSave = async () => {
    setIsLoading(true);
    const imageURL = await getImageUrl();
    const response = await fetch(imageURL);
    // here image is url/location of image
    const blob = await response.blob();
    const imageFile = new File([blob], "imageProfile", { type: blob.type });

    const formData = new FormData();
    formData.append("imageFile", imageFile);
    const res = await uploadProfilePicture(formData);
    if (res.status == OK || res.status == CREATED) {
      setIsChangeProfilePictureModalOpen(false);
      setFileList([]);
      setPictureUrl("");
    }
    setIsLoading(false);
  };

  const getMyProfileSummary = async () => {
    const data = await getProfileSummary();
    let totalPoints = data.data.pointsBreakdown.reduce(function (
      sum: any,
      current: { earnedPoints: any }
    ) {
      return sum + parseFloat(current.earnedPoints);
    },
    0);
    let count = 0;
    let pointsPercentage = data.data.pointsBreakdown.map(
      (point: any, index: any) => {
        return {
          earnedPoints: ((point.earnedPoints / totalPoints) * 100).toFixed(2),
          earnedPointsFloat: point.earnedPoints.toFixed(2),
          softskill: point.softskill,
          softskillId: point.softskillId,
          color:
            softSkillColors[index] ??
            neutralSkillColors[index % neutralSkillColors.length],
        };
      }
    );

    setMyProfilePoints(pointsPercentage);

    setChartData({
      labels: pointsPercentage.map((item: any) => {
        return item.softskill;
      }),
      datasets: [
        {
          label: "Soft Skills Distribution",
          data: pointsPercentage.map((item: any) => {
            return item.earnedPoints;
          }),
          backgroundColor: pointsPercentage.map((item: any) => {
            return item.color;
          }),
          hoverOffset: 4,
        },
      ],
    });
    setMyProfileSummary(data.data);
  };

  const fetchMyClubs = async () => {
    let params = {
      searchString: "",
      privacyTypes: "",
      interests: "",
    };
    let res = await getMyClubsGrouped(params);
    setMyClubsCount(res.data[0]?.count);
    setMyClubs(res.data[0]?.clubs.slice(0, 7));
  };

  const fetchMyUpcomingEvents = async () => {
    let result = await getMyEvents({
      page: 1,
      pageSize: 2,
    });
    setMyUpcomingEvents(result.data.items);
  };

  useEffect(() => {
    getMyProfileSummary();
    fetchMyClubs();
    fetchMyUpcomingEvents();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      firstName: member.firstName,
      lastName: member.lastName,
      memberType: member.memberType,
      jobTitle: member.jobTitle,
      schoolName: member.schoolName,
      emailAddress: member.emailAddress,
      birthDate: moment(member.birthDate),
    });
    if (member.gender === "Male") {
      setGender(0);
    } else {
      setGender(1);
    }
  }, [member]);

  useEffect(() => {
    if (location.pathname.includes("/settings")) {
      setFilter(3);
    } else {
      setFilter(0);
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <ModalFullView
        handleSubmit={handleOk}
        isOpen={isEditProfileModalOpen}
        setIsOpen={setIsEditProfileModalOpen}
        isLoading={isLoading}
      >
        <div className="profile-wrapper">
          {!isUpdateComplete && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
                xl={{ span: 8 }}
              >
                <h3 className="text-head">Edit Account Profile</h3>

                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  className="custom-alert"
                >
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name",
                      },
                      {
                        max: 50,
                        message: "First Name maximum length is 50 characters",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            //resolve to use original rule
                            return Promise.resolve();
                          }
                          if (!isEmptyOrSpaces(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("Please input your First Name!")
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Enter first name" />
                  </Form.Item>

                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name",
                      },
                      {
                        max: 50,
                        message: "Last Name maximum length is 50 characters",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            //resolve to use original rule
                            return Promise.resolve();
                          }
                          if (!isEmptyOrSpaces(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("Please input your Last Name!")
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Enter last name" />
                  </Form.Item>
                  <Form.Item name="memberType" label="Role">
                    <Input placeholder="Enter role" disabled />
                  </Form.Item>
                  <Form.Item name="schoolName" label="School">
                    <Input placeholder="Enter school" disabled />
                  </Form.Item>
                  <Form.Item name="emailAddress" label="Email">
                    <Input placeholder="Enter email" disabled />
                  </Form.Item>
                  <Form.Item
                    name="birthDate"
                    label="Birth Date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Birth Date",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Enter birth date"
                      format={customFormat}
                    />
                  </Form.Item>
                  {member.memberType === TeacherString && (
                    <Fragment>
                      <Form.Item
                        name="jobTitle"
                        label="Job Title"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your job title",
                          },
                          {
                            max: 50,
                            message:
                              "Job Title maximum length is 50 characters",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                //resolve to use original rule
                                return Promise.resolve();
                              }
                              if (!isEmptyOrSpaces(value)) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  new Error("Please input your job title Name!")
                                );
                              }
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Enter Job Title" />
                      </Form.Item>
                    </Fragment>
                  )}
                  <Form.Item>
                    <Button
                      className={`${gender === 0 && "btn-active"}`}
                      onClick={() => setGender(0)}
                    >
                      Male
                    </Button>
                    <Button
                      className={`${gender === 1 && "btn-active"}`}
                      onClick={() => setGender(1)}
                    >
                      Female
                    </Button>
                  </Form.Item>

                  <Button
                    loading={isLoading}
                    htmlType="submit"
                    className="mt-4 btn-radius"
                    type="primary"
                  >
                    Update
                  </Button>
                </Form>
              </Col>
            </Row>
          )}
          {isUpdateComplete && (
            <div className="profile-success-frame">
              <img src={successFrame} height="358px" width="auto" />
              <h1>Update Success!</h1>
              <h4>You have successfully updated your profile details</h4>
            </div>
          )}
        </div>
      </ModalFullView>
      <ModalFullView
        handleSubmit={handleOk}
        isOpen={isChangePasswordModalOpen}
        setIsOpen={setIsChangePasswordModalOpen}
        isLoading={isLoading}
      >
        <div className="profile-wrapper">
          {!isUpdatePasswordComplete && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
              >
                <h3 className="text-head">Change Password</h3>
                <div
                  className={`custom-alert ${
                    validationMessages &&
                    validationMessages.error &&
                    "mb-4 has-error"
                  }`}
                >
                  {validationMessages && validationMessages.error && (
                    <AlertContainer data={validationMessages} />
                  )}
                </div>
                <Form
                  className="form-modal-fullview"
                  layout="vertical"
                  form={passwordForm}
                  onFinish={onSubmitPasswordForm}
                >
                  <Form.Item
                    className="custom-password"
                    name="password"
                    label="Old Password"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter your old password",
                      },
                    ]}
                  >
                    <Input
                      type={showPassword ? "text" : "password"}
                      addonAfter={
                        !showPassword ? (
                          <EyeOutlined
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )
                      }
                    />
                  </Form.Item>
                  <div
                    className={`mb-4 ${
                      validationMessages &&
                      validationMessages.NewPassword &&
                      "has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.Password && (
                      <AlertContainer data={validationMessages.Password} />
                    )}
                  </div>
                  <Form.Item
                    className="custom-password"
                    name="newPassword"
                    label="New Password"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password",
                      },
                    ]}
                  >
                    <Input
                      type={showNewPassword ? "text" : "password"}
                      addonAfter={
                        !showNewPassword ? (
                          <EyeOutlined
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          />
                        )
                      }
                    />
                  </Form.Item>
                  <div
                    className={`mb-4 ${
                      validationMessages &&
                      validationMessages.NewPassword &&
                      "has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.NewPassword && (
                      <AlertContainer data={validationMessages.NewPassword} />
                    )}
                  </div>
                  <Form.Item
                    className="custom-password"
                    name="confirmPassword"
                    label="Confirm Password"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please confirm password",
                      },
                    ]}
                  >
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      addonAfter={
                        !showConfirmPassword ? (
                          <EyeOutlined
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                        )
                      }
                    />
                  </Form.Item>
                  <div
                    className={`mb-4 ${
                      validationMessages &&
                      validationMessages.ConfirmPassword &&
                      "has-error"
                    }`}
                  >
                    {validationMessages &&
                      validationMessages.ConfirmPassword && (
                        <AlertContainer
                          data={validationMessages.ConfirmPassword}
                        />
                      )}
                  </div>
                  <div className="password-requirement">
                    <h5>Password must contain:</h5>
                    <ul>
                      <li>
                        <div>
                          <img src={iconCheck} height="11.5px" />
                          At least 8 characters
                        </div>
                      </li>
                      <li>
                        <div>
                          <img src={iconCheck} height="11.5px" />
                          Atleast 1 upper case letter (A-Z)
                        </div>
                      </li>
                      <li>
                        <div>
                          <img src={iconCheck} height="11.5px" />
                          Atleast 1 number (0-9)
                        </div>
                      </li>
                    </ul>
                  </div>
                  <Button
                    loading={isLoading}
                    htmlType="submit"
                    className="mt-4 btn-radius"
                    type="primary"
                  >
                    Update
                  </Button>
                </Form>
              </Col>
            </Row>
          )}
          {isUpdatePasswordComplete && (
            <div className="profile-success-frame">
              <img src={illusPwd} height="358px" width="auto" />
              <h1>Update Success!</h1>
              <h4>You have successfully updated password.</h4>
            </div>
          )}
        </div>
      </ModalFullView>
      <ModalFullView
        handleSubmit={handleOk}
        isOpen={isChangeProfilePictureModalOpen}
        setIsOpen={setIsChangeProfilePictureModalOpen}
        isLoading={isLoading}
      >
        <div className="profile-wrapper">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              className="gutter-row "
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 14 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
            >
              {fileList.length === 0 && (
                <div className="change-profile-picture-wrapper">
                  <h3 className="text-head">Edit your profile picture</h3>

                  <Dragger {...uploadProps}>
                    <div className="change-profile-picture-container">
                      {!isFileSizeValid || !isFileTypeValid ? (
                        <Fragment>
                          <ExclamationCircleOutlined
                            style={{ fontSize: "42px" }}
                            className="text-error"
                          />
                          {!isFileSizeValid && (
                            <h3 className="text-error text-validation">
                              Please provide a file smaller than 2MB
                            </h3>
                          )}

                          {!isFileTypeValid && (
                            <h3 className="text-error text-validation">
                              You can only upload JPG/PNG file!
                            </h3>
                          )}
                          <h3 className="text-validation">
                            Click this area or drop your photo.
                          </h3>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <img src={changeProfilePlaceholder} />
                          <h3>Click or Drop your photo here to upload it</h3>
                        </Fragment>
                      )}

                      {/* <Upload {...uploadProps}> */}
                      {/* <Button shape="round" icon={<PictureOutlined />}>
                            Select File
                          </Button> */}
                      {/* </Upload> */}
                      {/* <Button shape="round" icon={<PictureOutlined />}>
                          Upload
                        </Button> */}
                    </div>
                  </Dragger>
                  <h5>
                    It should be smaller than 2MB, and it should show your face.
                    That way, your friends and family will know it’s you.
                  </h5>
                  {/* <div className="d-flex justify-content-center mt-5">
                        <Button shape="round">Save Photo</Button>
                      </div> */}
                </div>
              )}
              {fileList.length > 0 && (
                <div className="crop-profile-picture-wrapper">
                  <Fragment>
                    <div className="avatar-editor-wrapper">
                      <AvatarEditor
                        ref={cropImageRef}
                        image={pictureUrl}
                        // width={365}
                        // height={365}
                        border={40}
                        borderRadius={100}
                        color={[0, 0, 0, 0.3]} // RGBA
                        scale={scaleLevel}
                        rotate={0}
                      />
                      {isLoading && (
                        <div className="loading-overlay">
                          <div className="loading-container">
                            <div>
                              <LoadingOutlined />
                            </div>
                            <div>Uploading...</div>
                          </div>
                          <h5>
                            It should be smaller than 2MB, and it should show
                            your face. That way, your friends and family will
                            know it’s you.
                          </h5>
                        </div>
                      )}
                    </div>

                    {!isLoading && (
                      <Fragment>
                        <div className="zoom-slider">
                          <MinusOutlined
                            onClick={() => setScaleLevel(scaleLevel - 0.1)}
                          />
                          <Slider
                            value={scaleLevel}
                            onChange={onChangeScaleLevel}
                            defaultValue={scaleLevel}
                            min={0.5}
                            step={0.1}
                            max={2}
                          />
                          <PlusOutlined
                            onClick={() => setScaleLevel(scaleLevel + 0.1)}
                          />
                        </div>
                        <h4 className="text-center">
                          Make sure your face is visible.
                        </h4>
                        <div className="d-flex justify-content-center">
                          <Button
                            loading={isLoading}
                            htmlType="submit"
                            className="btn-regular mt-3"
                            shape="round"
                            type="primary"
                            onClick={() => handleSave()}
                          >
                            Save Photo
                          </Button>
                        </div>
                        <a
                          className="text-center mt-5"
                          onClick={() => setFileList([])}
                        >
                          Choose Another Photo
                        </a>
                      </Fragment>
                    )}
                  </Fragment>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </ModalFullView>
      <PageHeader
        className="pb-4"
        title={
          <div className="d-flex profile-header-container">
            {member.profilePicture ? (
              <div className="profile-picture-div">
                <div className="profile-avatar">
                  <img
                    src={member.profilePicture + "?" + Date.now()}
                    height="auto"
                    width="auto"
                  />
                </div>
                <div
                  className="btn-change-profile"
                  onClick={() => {
                    setIsChangeProfilePictureComplete(false);
                    setIsChangeProfilePictureModalOpen(true);
                  }}
                >
                  <img src={editIcon} height="16px" />
                </div>
              </div>
            ) : (
              <div className="profile-picture-div">
                <DefaultAvatar
                  firstName={member.firstName}
                  lastName={member.lastName}
                  size={79}
                />
                {/* <div className="no-avatar avatar-xl">
  
                    <span>
                      {member.firstName.charAt(0)}
                      {member.lastName.charAt(0)}
                    </span>
                  </div> */}
                <div
                  className="btn-change-profile"
                  onClick={() => {
                    setIsChangeProfilePictureComplete(false);
                    setIsChangeProfilePictureModalOpen(true);
                  }}
                >
                  <img src={editIcon} height="16px" />
                </div>
              </div>
            )}

            <div className="profile-header-details">
              <h3>
                {member.firstName} {member.lastName}
              </h3>
              <div className="more-detail">
                <div>
                  <img src={userBadgeIcon} />
                </div>
                <h5>{member.memberType}</h5>
              </div>
            </div>
          </div>
        }
        header={
          <div className="event-list-controls">
            <Dropdown
              placement="bottomRight"
              overlay={
                <Fragment>
                  <Menu
                    items={[
                      {
                        key: "edit-profile",
                        label: (
                          <a onClick={() => setIsEditProfileModalOpen(true)}>
                            Edit Profile
                          </a>
                        ),
                      },
                    ]}
                  />
                </Fragment>
              }
            >
              <Button
                shape="circle"
                icon={<EllipsisOutlined />}
                style={{ height: 40 }}
              ></Button>
            </Dropdown>
          </div>
        }
      >
        {/* add your tab filters here */}
        {/* <div className="d-flex tab-filter mt-4">
            <div
              onClick={() => setFilter(0)}
              className={`tab-item mr-4 ${filter === 0 && "tab-active"}`}
            >
              Overview
            </div>
  
            <div
              onClick={() => setFilter(1)}
              className={`tab-item mr-4 ${filter === 1 && "tab-active"}`}
            >
              My Events
            </div>
            <div
              onClick={() => setFilter(2)}
              className={`tab-item mr-4 ${filter === 2 && "tab-active"}`}
            >
              My Clubs
            </div>
            <div
              onClick={() => setFilter(3)}
              className={`tab-item mr-4 ${filter === 3 && "tab-active"}`}
            >
              Settings
            </div>
          </div> */}
      </PageHeader>
      <div className="profile-page-single-content-wrapper">
        <div className="profile-page-single-content">
          {filter === 0 && (
            <Fragment>
              <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: flutterConnect ? undefined : 24 }}
                  sm={{ span: flutterConnect ? undefined : 24 }}
                  md={{ span: flutterConnect ? undefined : 24 }}
                  lg={{ span: flutterConnect ? undefined : 16 }}
                  xl={{ span: flutterConnect ? undefined : 17 }}
                  span={ flutterConnect && 24 }
                >
                  <div className="content-left-main">
                    <div className="outer-box profile-summary">
                      <h3 className="mb-5">Summary</h3>
                      <div className="profile-ratings">
                        {/* <h4 className="text-head">Ratings</h4> */}
                        <Row gutter={[24, 24]} className="summary-details">
                          <Col
                            className="gutter-row summary-details-item"
                            xs={{ span: flutterConnect ? undefined : 12 }}
                            md={{ span: flutterConnect ? undefined :  12 }}
                            lg={{ span: flutterConnect ? undefined :  6 }}
                            xl={{ span: flutterConnect ? undefined :  6 }}
                            span={flutterConnect && 24}
                          >
                            <div className="rating-container">
                              <RiseOutlined className="icon-green" />
                              <h3 className="rating-points">
                                {myProfileSummary &&
                                  Math.round(
                                    myProfileSummary.earnedPoints
                                  )}{" "}
                                / 240
                              </h3>
                              <h4 className="rating-name">
                                Total Semestral Points
                              </h4>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row summary-details-item"
                            xs={{ span: flutterConnect ? undefined : 12 }}
                            md={{ span: flutterConnect ? undefined : 12 }}
                            lg={{ span: flutterConnect ? undefined : 6 }}
                            xl={{ span: flutterConnect ? undefined : 6 }}
                            span={flutterConnect && 24}
                          >
                            <div className="rating-container">
                              <ClockCircleOutlined className="icon-blue" />
                              <h3 className="rating-points">
                                {myProfileSummary?.totalHours} Hours
                              </h3>
                              <h4 className="rating-name">
                                Overall Event Time
                              </h4>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row  summary-details-item"
                            xs={{ span: flutterConnect ? undefined : 12 }}
                            md={{ span: flutterConnect ? undefined : 12 }}
                            lg={{ span: flutterConnect ? undefined : 6 }}
                            xl={{ span: flutterConnect ? undefined : 6 }}
                            span={flutterConnect && 24}
                          >
                            <Link to={"/events"}>
                              <div className="rating-container">
                                <NotificationOutlined className="icon-red" />
                                <h3 className="rating-points">
                                  {myProfileSummary?.upcomingEvents} Events
                                </h3>
                                <h4 className="rating-name">
                                  Events This Week
                                </h4>
                              </div>
                            </Link>
                          </Col>
                          <Col
                            className="gutter-row summary-details-item"
                            xs={{ span: flutterConnect ? undefined : 12 }}
                            md={{ span: flutterConnect ? undefined : 12 }}
                            lg={{ span: flutterConnect ? undefined : 6 }}
                            xl={{ span: flutterConnect ? undefined : 6 }}
                            span={flutterConnect && 24}
                          >
                            <Link to={"/clubs"}>
                              <div className="rating-container">
                                <FlagOutlined className="icon-orange" />
                                <h3 className="rating-points">
                                  {myClubsCount} Clubs
                                </h3>
                                <h4 className="rating-name">Active Clubs</h4>
                              </div>
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="profile-softskill">
                      {/* <h4 className="text-head">Ratings</h4> */}
                      <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                        >
                          <div className="softskill-container">
                            <h4 className="text-head">Softskills Attributes</h4>
                            <SoftSkillAttributes
                              chartData={chartData}
                              myProfilePoints={myProfilePoints}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col
                  className="gutter-row col-calendar"
                  xs={{ span: flutterConnect ? undefined : 24 }}
                  sm={{ span: flutterConnect ? undefined : 12 }}
                  md={{ span: flutterConnect ? undefined : 12 }}
                  lg={{ span: flutterConnect ? undefined : 8 }}
                  xl={{ span: flutterConnect ? undefined : 7 }}
                  span= { flutterConnect && 24 }
                >
                  <div className="content-right-main profile-calendar">
                    <div className="profile-calendar-container">
                      <h4 className="text-head">Calendar</h4>
                      <div className="profile-calendar">
                        <Calendar
                          dateFullCellRender={(current: any) => {
                            const style: React.CSSProperties = {};
                            if (
                              current.format("YYYY-MM-DD") ===
                              moment().add(1, "days").format("YYYY-MM-DD")
                            ) {
                              style.borderRadius = "2px;";
                              style.color = "white";
                              style.background = "#02B8F6";
                              style.marginLeft = "2px";
                              style.marginRight = "2px";
                              return (
                                <div
                                  className="ant-picker-calendar-date-value"
                                  style={style}
                                >
                                  {current.date()}
                                </div>
                              );
                            } else {
                              return (
                                <div className="ant-picker-calendar-date-value">
                                  {current.date()}
                                </div>
                              );
                            }
                          }}
                          fullscreen={false}
                          onPanelChange={onPanelChange}
                        />
                      </div>
                    </div>

                    {/* <hr />
                    <div className="profile-information">
                      <h4 className="text-head pb-1">Account Profile</h4>
                      <Row className="mb-3">
                        <Col
                          className="gutter-row"
                          xs={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <label>Name</label>
                        </Col>
                        <Col className="gutter-row">
                          <h5>
                            {member.firstName} {member.lastName}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col
                          className="gutter-row"
                          xs={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <label>Title</label>
                        </Col>
                        <Col className="gutter-row">
                          <h5>{member.memberType}</h5>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col
                          className="gutter-row"
                          xs={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <label>School</label>
                        </Col>
                        <Col className="gutter-row">
                          <h5>{member.schoolName}</h5>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col
                          className="gutter-row"
                          xs={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <label>Email</label>
                        </Col>
                        <Col className="gutter-row">
                          <h5>{member.emailAddress}</h5>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col
                          className="gutter-row"
                          xs={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <label>Birth Date</label>
                        </Col>
                        <Col className="gutter-row">
                          <h5>{member.birthDate}</h5>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col
                          className="gutter-row"
                          xs={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <label>Gender</label>
                        </Col>
                        <Col className="gutter-row">
                          <h5>{member.gender}</h5>
                        </Col>
                      </Row>
                      <img
                        className="button-edit"
                        src={pencilIcon}
                        onClick={() => {
                          setIsUpdateComplete(false);
                          setIsEditProfileModalOpen(true);
                        }}
                      />
                    </div>
                    <hr /> */}
                    {/* <div className="profile-summary">
                      <h4 className="text-head">Summary</h4>
                      {isEditingSummary && (
                        <textarea
                          onBlur={(e) => handleChangeSummary(e)}
                          rows={3}
                          className={`summary-textarea ${
                            isEditingSummary && "textarea-active"
                          } `}
                          disabled={!isEditingSummary}
                        >
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum dolore eu fugiat nulla pariatur.
                          Excepteur sint occaecat cupidatat non proident, sunt in
                          culpa qui officia deserunt mollit anim id est laborum.
                          Ut enim ad minim veniam, quis nostrud exercitation
                          ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </textarea>
                      )}
  
                      {!isEditingSummary && <p>{summaryText}</p>}
                      <img
                        className="button-edit"
                        src={pencilIcon}
                        onClick={() => setIsEditingSummary(!isEditingSummary)}
                      />
                    </div> */}
                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: flutterConnect ? undefined : 24 }}
                  sm={{ span: flutterConnect ? undefined : 24 }}
                  md={{ span: flutterConnect ? undefined : 24 }}
                  lg={{ span: flutterConnect ? undefined : 16 }}
                  xl={{ span: flutterConnect ? undefined : 17 }}
                  span= { flutterConnect && 24 }
                >
                  <div className="content-left-main">
                    <div className="profile-club-interest">
                      {/* <h4 className="text-head">Ratings</h4> */}
                      <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col
                          className="gutter-row col-club-interest app"
                          xs={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                        >
                          <div className="profile-interest-container">
                            <h4 className="text-head">Club Interest</h4>
                            <div className="interest-container">
                              {member &&
                                member.interests &&
                                member.interests.map((item: any) => {
                                  return (
                                    <div
                                      className="interest-item"
                                      key={item.interestId}
                                    >
                                      <img src={iconSports} />
                                      <h4>{item.name}</h4>
                                    </div>
                                  );
                                })}
                                <div className="interest-item" onClick={ () => {window.location.href = '/interests'}}>
                                  <PlusOutlined />
                                  <h4 className="text-gray">Add More</h4>
                                </div>
                            </div>

                            <Link to="/interests">
                              <img className="button-edit" src={pencilIcon} />
                            </Link>
                          </div>
                        </Col>
                        {
                          flutterConnect && (
                            <Col
                              className="gutter-row col-calendar app"
                              xs={{ span: flutterConnect ? undefined : 24 }}
                              sm={{ span: flutterConnect ? undefined : 12 }}
                              md={{ span: flutterConnect ? undefined : 12 }}
                              lg={{ span: flutterConnect ? undefined : 8 }}
                              xl={{ span: flutterConnect ? undefined : 7 }}
                              span= { flutterConnect && 24 }
                            >
                              <div className="content-right-main profile-calendar">
                                <div className="profile-calendar-container">
                                  <h4 className="text-head">Calendar</h4>
                                  <div className="profile-calendar">
                                    <Calendar
                                      dateFullCellRender={(current: any) => {
                                        const style: React.CSSProperties = {};
                                        if (
                                          current.format("YYYY-MM-DD") ===
                                          moment().add(1, "days").format("YYYY-MM-DD")
                                        ) {
                                          style.borderRadius = "2px;";
                                          style.color = "white";
                                          style.background = "#02B8F6";
                                          style.marginLeft = "2px";
                                          style.marginRight = "2px";
                                          return (
                                            <div
                                              className="ant-picker-calendar-date-value"
                                              style={style}
                                            >
                                              {current.date()}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div className="ant-picker-calendar-date-value">
                                              {current.date()}
                                            </div>
                                          );
                                        }
                                      }}
                                      fullscreen={false}
                                      onPanelChange={onPanelChange}
                                    />
                                  </div>
                                </div>

                                {/* <hr />
                                <div className="profile-information">
                                  <h4 className="text-head pb-1">Account Profile</h4>
                                  <Row className="mb-3">
                                    <Col
                                      className="gutter-row"
                                      xs={{ span: 4 }}
                                      md={{ span: 4 }}
                                      lg={{ span: 4 }}
                                      xl={{ span: 4 }}
                                    >
                                      <label>Name</label>
                                    </Col>
                                    <Col className="gutter-row">
                                      <h5>
                                        {member.firstName} {member.lastName}
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col
                                      className="gutter-row"
                                      xs={{ span: 4 }}
                                      md={{ span: 4 }}
                                      lg={{ span: 4 }}
                                      xl={{ span: 4 }}
                                    >
                                      <label>Title</label>
                                    </Col>
                                    <Col className="gutter-row">
                                      <h5>{member.memberType}</h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col
                                      className="gutter-row"
                                      xs={{ span: 4 }}
                                      md={{ span: 4 }}
                                      lg={{ span: 4 }}
                                      xl={{ span: 4 }}
                                    >
                                      <label>School</label>
                                    </Col>
                                    <Col className="gutter-row">
                                      <h5>{member.schoolName}</h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col
                                      className="gutter-row"
                                      xs={{ span: 4 }}
                                      md={{ span: 4 }}
                                      lg={{ span: 4 }}
                                      xl={{ span: 4 }}
                                    >
                                      <label>Email</label>
                                    </Col>
                                    <Col className="gutter-row">
                                      <h5>{member.emailAddress}</h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col
                                      className="gutter-row"
                                      xs={{ span: 4 }}
                                      md={{ span: 4 }}
                                      lg={{ span: 4 }}
                                      xl={{ span: 4 }}
                                    >
                                      <label>Birth Date</label>
                                    </Col>
                                    <Col className="gutter-row">
                                      <h5>{member.birthDate}</h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col
                                      className="gutter-row"
                                      xs={{ span: 4 }}
                                      md={{ span: 4 }}
                                      lg={{ span: 4 }}
                                      xl={{ span: 4 }}
                                    >
                                      <label>Gender</label>
                                    </Col>
                                    <Col className="gutter-row">
                                      <h5>{member.gender}</h5>
                                    </Col>
                                  </Row>
                                  <img
                                    className="button-edit"
                                    src={pencilIcon}
                                    onClick={() => {
                                      setIsUpdateComplete(false);
                                      setIsEditProfileModalOpen(true);
                                    }}
                                  />
                                </div>
                                <hr /> */}
                                {/* <div className="profile-summary">
                                  <h4 className="text-head">Summary</h4>
                                  {isEditingSummary && (
                                    <textarea
                                      onBlur={(e) => handleChangeSummary(e)}
                                      rows={3}
                                      className={`summary-textarea ${
                                        isEditingSummary && "textarea-active"
                                      } `}
                                      disabled={!isEditingSummary}
                                    >
                                      Duis aute irure dolor in reprehenderit in voluptate
                                      velit esse cillum dolore eu fugiat nulla pariatur.
                                      Excepteur sint occaecat cupidatat non proident, sunt in
                                      culpa qui officia deserunt mollit anim id est laborum.
                                      Ut enim ad minim veniam, quis nostrud exercitation
                                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </textarea>
                                  )}
              
                                  {!isEditingSummary && <p>{summaryText}</p>}
                                  <img
                                    className="button-edit"
                                    src={pencilIcon}
                                    onClick={() => setIsEditingSummary(!isEditingSummary)}
                                  />
                                </div> */}
                              </div>
                            </Col>
                          )
                        }
                      </Row>
                    </div>
                    <div className="profile-upcoming-event">
                      {/* <h4 className="text-head">Ratings</h4> */}
                      <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                        >
                          <div className="profile-upcoming-event-container">
                            <div className="d-flex justify-content-space-between">
                              <h4 className="text-head">Upcoming Events</h4>
                              <Link className="all-link mt-1" to="/events">
                                See All
                              </Link>
                            </div>
                            <Row gutter={[24, 24]}>
                              {myUpcomingEvents &&
                                myUpcomingEvents.map((event: any) => {
                                  return (
                                    <Col
                                      className="gutter-row"
                                      xs={{ span: 24 }}
                                      md={{ span: 12 }}
                                      lg={{ span: 12 }}
                                      xl={{ span: 12 }}
                                    >
                                      <Link to={`/event/${event.eventId}`}>
                                        <div className="upcoming-event-item">
                                          <img
                                            className="event-image"
                                            src={placeholderImg}
                                            height="224px"
                                          />
                                          <div className="upcoming-event-body">
                                            <h5 className="event-interest">
                                              {event.interest}
                                            </h5>
                                            <h3 className="event-name">
                                              {event.title}
                                            </h3>
                                            <div className="event-details">
                                              <div>
                                                <CalendarOutlined className="mr-1" />{" "}
                                                {moment(event.startDate).format(
                                                  "ll"
                                                )}{" "}
                                                {`${moment(
                                                  event.startDate
                                                ).format("LT")} - ${moment(
                                                  event.endDate
                                                ).format("LT")}`}
                                              </div>
                                              <div>
                                                <CompassOutlined className="mr-1" />{" "}
                                                {event.location}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: flutterConnect ? undefined : 24 }}
                  sm={{ span: flutterConnect ? undefined : 24 }}
                  md={{ span: flutterConnect ? undefined : 24 }}
                  lg={{ span: flutterConnect ? undefined : 8 }}
                  xl={{ span: flutterConnect ? undefined : 7 }}
                  span={flutterConnect && 24}
                >
                  <div className="content-right-main">
                    <div className="profile-club-joined">
                      <div className="profile-club-joined-container">
                        <div className="d-flex justify-content-space-between">
                          <h4 className="text-head">Clubs you’ve joined</h4>
                          <Link className="all-link mt-1" to="/clubs">
                            See All
                          </Link>
                        </div>
                        <ul>
                          {myClubs &&
                            myClubs.map((club: any) => {
                              return (
                                <li>
                                  <Link
                                    className="all-link mt-1"
                                    to={"/club/" + club.memberGroupId}
                                  >
                                    <div className="d-flex">
                                      <img
                                        width="72px"
                                        height="72px"
                                        src={sampleClubLogo}
                                      />
                                      <div className="club-info">
                                        <h4>{club.name} Club</h4>
                                        <h5>{club?.interest?.name}</h5>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Fragment>
          )}
          {filter === 3 && (
            <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: flutterConnect ? undefined : 24 }}
                sm={{ span: flutterConnect ? undefined : 12 }}
                md={{ span: flutterConnect ? undefined : 12 }}
                lg={{ span: flutterConnect ? undefined : 17 }}
                xl={{ span: flutterConnect ? undefined : 17 }}
                span={flutterConnect && 24}
              >
                <div className="content-left">
                  <h4 className="text-head">Account Security</h4>
                  <hr />
                  <div className="account-setting-container">
                    <div className="account-setting">
                      <h4 className="text-head"> Account Password</h4>
                      <span>
                        Make sure you change your password once in a while to
                        secure your account
                      </span>
                      {/* <p>
                          Password updated last <b>January 21, 2022</b>
                        </p> */}
                    </div>
                    <Button
                      shape="round"
                      onClick={() => {
                        setIsUpdatePasswordComplete(false);
                        setIsChangePasswordModalOpen(true);
                      }}
                    >
                      Change
                    </Button>
                  </div>
                  {/* <hr />
                    <div className="account-setting-container">
                      <div className="account-setting">
                        <h4 className="text-head">
                          Use SMS for two factor authentication
                        </h4>
                        <span>
                          Breeze can use an SMS message to provide two-factor
                          authentication.
                        </span>
                      </div>
                      <Button shape="round">Enable</Button>
                    </div>
                    <hr />
                    <div className="account-setting-container">
                      <div className="account-setting">
                        <h4 className="text-head">Account Verification</h4>
                        <span>
                          If we see patterns that seem unusual for your account,
                          we'll need to verify your identity.
                        </span>
                      </div>
                      <Button shape="round">Enable</Button>
                    </div> */}

                  <div className="account-setting"></div>
                  <div className="account-setting"></div>
                </div>
              </Col>
              <Col
                className="gutter-row mb-2"
                xs={{ span: flutterConnect ? undefined : 24 }}
                sm={{ span: flutterConnect ? undefined : 12 }}
                md={{ span: flutterConnect ? undefined : 12 }}
                lg={{ span: flutterConnect ? undefined : 7 }}
                xl={{ span: flutterConnect ? undefined : 7 }}
                span={flutterConnect && 24}
              >
                <div className="content-right">
                  <h4 className="text-head">Calendar</h4>
                  <div className="site-calendar-demo-card">
                    <Calendar
                      fullscreen={false}
                      onPanelChange={onPanelChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  flutterConnect: state.flutterConnect
});

export default connect(mapStateToProps, {
  updateProfile,
  changePassword,
  uploadProfilePicture,
  getProfileSummary,
  getMyClubs,
  getMyEvents,
  getMyClubsGrouped,
})(Profile);
