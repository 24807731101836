import { Fragment, useState } from "react";
import PageHeader from "../layouts/PageHeader";
import NotificationList from "./NotificationList";

const NotificationPage = () => {
  const [filter, setFilter] = useState(1);
  const handleHideNotification = () => {};

  return (
    <Fragment>
      <PageHeader title="Notifications">
        {/* add your tab filters here */}
        <div className="d-flex tab-filter mt-4">
          <div
            onClick={() => setFilter(1)}
            className={`tab-item mr-4 ${filter === 1 && "tab-active"}`}
          >
            Alls
          </div>
          <div
            onClick={() => setFilter(2)}
            className={`tab-item mr-4 ${filter === 2 && "tab-active"}`}
          >
            Unread
          </div>
        </div>
      </PageHeader>
      <div className="content-wrapper" style={{ marginTop: 25 }}>
        <div className="content-container">
          <NotificationList
            fullWidth={true}
            handleHideNotification={handleHideNotification}
            dropdownVisible={false}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default NotificationPage;
