import { acceptEventInvite } from "../../../actions/event";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { CheckOutlined } from "@ant-design/icons";
import { ClockCircleOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { CompassOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { deleteEvent } from "../../../actions/event";
import { declineEventInvite } from "../../../actions/event";
import { deleteInvite } from "../../../actions/event";
import { Divider } from "antd";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getEventDetails } from "../../../actions/event";
import { getQuickAction } from "./EventItem";
import { Invite } from "./EventInvite";
import { joinEvent } from "../../../actions/event";
import { markAsAttended } from "../../../actions/event";
import { Menu } from "antd";
import { NO_CONTENT, OK } from "../../../helpers/statuscodes";
import { QuickAction } from "./EventItem";
import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Doughnut from "../../widget/Doughnut";
import EventParticipantPane from "./EventParticipantPane";
import EventAttendancePane from "./EventAttendancePane";
import EventQRPane from "../../widget/EventQRPane";
import headerBannerBg from "../../../assets/images/soccer-bg.png";
import moment from "moment";
import PageHeader from "../layouts/PageHeader";
import { userPermissions } from "../../../permissions/user-permissions";
import { softSkillColors } from "../../../helpers/softSkillColors";
import ComingSoonState from "../layouts/ComingSoonState";
import {
  getClubModeratorList,
} from "../../../actions/club";

const EventPage = (props: {
  member: any;
  deleteEvent: Function;
  getEventDetails: Function;
  joinEvent: Function;
  deleteInvite: Function;
  markAsAttended: Function;
  acceptEventInvite: Function;
  declineEventInvite: Function;
  getClubModeratorList: Function;
  flutterConnect: boolean;
}) => {
  const { eventId } = useParams();
  const {
    member,
    deleteEvent,
    getEventDetails,
    joinEvent,
    deleteInvite,
    markAsAttended,
    acceptEventInvite,
    declineEventInvite,
    getClubModeratorList,
    flutterConnect
  } = props;
  const [event, setEvent] = useState<any>();
  const [readOnly, setReadOnly] = useState(true);
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [chartData, setChartData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [userInvite, setUserInvite] = useState<Invite>();
  const [source, setSource] = useState<string | null>(null);
  const [quickAction, setQuickAction] = useState<QuickAction>({ primary: "" });
  const [selectedTab, setSelectedTab] = useState("about");
  const [PrevSelectedTab, setPrevSelectedTab] = useState("about");
  const [banner, setBanner] = useState<string>();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [isModerator, setIsModerator] = useState(false);
  const [currentDate, setCurrentDate] = useState(Date.now().toString());

  //permissions
  let Permissions = {
    canDeleteEvent:
      userPermissions[member.memberType] &&
      userPermissions[member.memberType]?.deleteEvent,
  };

  const onMenuClick = (info: any) => {
    if (info.key === "edit") {
      navigate(`/event/${eventId}/edit${source ? "?g=" + source : ""}`);
    } else {
      Modal.confirm({
        title: "Delete Event",
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to delete this event?",
        okText: "Confirm",
        onOk: submitDeleteEvent,
        okButtonProps: { shape: "round" },
        cancelText: "Cancel",
        cancelButtonProps: { shape: "round" },
      });
    }
  };

  const getModeratorStatus = async () => {

    if (event) {
      if (event.memberGroupId) {
        let params = {
          page: 1,
          pageSize: 10,
          searchString: `${member.emailAddress}`,
          memberGroupId: event.memberGroupId,
        };
        const res = await getClubModeratorList(params);
        setIsModerator(res.data.items.some((e: any) => e.memberId == member.memberId));
  
      }
      else {
        setIsModerator(event.eventAdmins && event.eventAdmins.map((e:any) => e.id).includes(member.memberId))
      }
    }
  }

  const onActionClick = async (action: string | undefined) => {
    if (action === undefined) return;
    setIsLoading(true);
    if (action === "Request to Join") {
      const result = await joinEvent(event.eventId);
      if (result && result.data) {
        setUserInvite(result.data);
      }
    } else if (action === "Accept" && userInvite) {
      const result = await acceptEventInvite({
        eventId: event.eventId,
        inviteId: userInvite.inviteId,
      });
      if (result && result.data) {
        setUserInvite(result.data);
      }
    } else if (action === "Decline" && userInvite) {
      const result = await declineEventInvite({
        eventId: event.eventId,
        inviteId: userInvite.inviteId,
      });
      if (result && result.data) {
        setUserInvite(result.data);
      }
    } else if (
      (action === "Cancel Request" || action === "Leave") &&
      userInvite
    ) {
      const result = await deleteInvite({
        eventId: event.eventId,
        inviteId: userInvite?.inviteId,
      });
      if (result.status === NO_CONTENT) {
        setUserInvite(undefined);
      }
    } else if (action === "Mark as Attended" && userInvite) {
      const response = await markAsAttended({
        eventId: event.eventId,
        inviteId: userInvite.inviteId,
      });
      if (response.status === OK) {
        const _userInvite = { ...userInvite, attendance: response.data };
        setUserInvite(_userInvite);
      }
    }
    setIsLoading(false);
  };

  const menu = () => {
    let items;
    if (Permissions.canDeleteEvent) {
      items = [
        { label: "Edit Event", key: "edit" },
        { label: "Delete Event", key: "delete" },
      ];
    } else {
      items = [{ label: "Edit Event", key: "edit" }];
    }
    return <Menu onClick={onMenuClick} items={items}></Menu>;
  };

  const submitDeleteEvent = async () => {
    setIsLoading(true);
    const response = await deleteEvent(eventId);
    setIsLoading(false);
    if (response.status === NO_CONTENT) {
      Modal.info({
        title: "Event successfully deleted!",
        okButtonProps: { shape: "round" },
        onOk: () => navigate("/events"),
      });
    }
  };

  const fetchEventDetails = async () => {
    setIsLoading(true);
    const result = await getEventDetails({ eventId });
    setIsLoading(false);
    if (result && result.data) {
      setEvent(result.data);

      if (result.data.userInvite === undefined) {
        setQuickAction(
          getQuickAction(moment(result.data.endDate).toDate(), undefined)
        );
      }
    }
  };

  const onBackClick = () => {
    if (source) {
      navigate(`/club/${source}`);
    } else {
      navigate("/events");
    }
  };

  useEffect(() => {
    if (event) {
      setQuickAction(
        getQuickAction(moment(event.endDate).toDate(), userInvite)
      );
    }
  }, [userInvite]);

  useEffect(() => {
    if (event) {
      const startDate = new Date(event.startDate);
      const endDate = new Date(event.endDate);

      setEventDate(moment(startDate).format("ll"));
      setEventTime(
        `${moment(startDate).format("LT")} - ${moment(endDate).format("LT")}`
      );
      if (event.userInvite && event.userInvite.inviteId) {
        setUserInvite(event.userInvite);
        setReadOnly(event.userInvite.participationType !== 0);
      }

      if (event.bannerFileUrl) {
        setBanner(event.bannerFileUrl);
      }

      if (event.softSkillScores && event.softSkillScores.length > 0) {
        const labels = event.softSkillScores.map((i: any) => i.name);
        const data = event.softSkillScores.map((i: any) => i.percentage);
        setChartData({
          labels,
          datasets: [
            {
              label: "Soft Skills Distribution",
              data,
              backgroundColor: softSkillColors,
              hoverOffset: 4,
            },
          ],
        });
      }
    }
  }, [event]);

  useEffect(() => {
    if (eventId) {
      setSource(params.get("g"));

      fetchEventDetails();
    }
  }, [eventId]);

  useEffect(() => {
    getModeratorStatus();
  }, [event]);

  useEffect(() => {
    if (isModerator) {
      let params = new URLSearchParams(window.location.search)
      if (params.get("qr") === '1' || params.get("qrtab") === 'true') {
        setSelectedTab('qr')
      }
    }
  }, [isModerator]);

  const setTabSelection = (tab: string) => {
    setPrevSelectedTab(selectedTab);
    setSelectedTab(tab);
  }

  const onComingSoon = () => {
    const currentTab = selectedTab;
    setSelectedTab(PrevSelectedTab);
    setPrevSelectedTab(currentTab);
  }

  console.log('membergroupid test');
  console.log(JSON.stringify(event));

  if (event && ['reviews', 'faq'].includes(selectedTab))
    return (
      <>
        <ComingSoonState onButtonClick={onComingSoon} featureTitle={`Event/${selectedTab}`}/>
      </>
    );
  else
    return (
      <>
        <div
          className="banner-wrapper"
          style={{ backgroundImage: `url(${banner + "?" + currentDate})` }}
        >
          <div className="banner-container">
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={onBackClick}
              style={{ color: "#fff", padding: "unset" }}
            >
              Back
            </Button>
          </div>
        </div>
        <PageHeader
          className="event-page"
          title={event?.title}
          header={
            <>
              {quickAction && quickAction.primary && (
                <Button
                  type="primary"
                  shape="round"
                  loading={isLoading}
                  icon={<CheckOutlined />}
                  onClick={() => onActionClick(quickAction.primary)}
                >
                  {quickAction.primary}
                </Button>
              )}
              {quickAction && quickAction.secondary && (
                <Button
                  shape="round"
                  loading={isLoading}
                  icon={<CloseOutlined />}
                  onClick={() => onActionClick(quickAction.secondary)}
                >
                  {quickAction.secondary}
                </Button>
              )}
              {!readOnly && (
                <Dropdown placement="bottomRight" overlay={menu}>
                  <Button
                    shape="circle"
                    icon={<EllipsisOutlined />}
                    style={{ height: 40 }}
                  ></Button>
                </Dropdown>
              )}
            </>
          }
        >
          {isLoading && <Skeleton paragraph={{ rows: 1 }} active />}
          {event && (
            <div className="info-row">
              <span>
                <CalendarOutlined />
                &nbsp;{eventDate} from {eventTime}
              </span>
              {event.location && (
                <span>
                  <CompassOutlined />
                  &nbsp;{event.location}
                </span>
              )}
              {event.memberGroup && (
                <span
                onClick={
                  flutterConnect && event && event.memberGroupId
                  ? () => navigate(
                    `/club/${event.memberGroupId}`
                  )
                  : undefined
                }
                >
                  <ClockCircleOutlined />
                  &nbsp;{event.memberGroup}
                </span>
              )}
            </div>
          )}
          <div className="d-flex tab-filter mt-4">
            <div
              onClick={() => setTabSelection("about")}
              className={`tab-item mr-4 ${
                selectedTab === "about" && "tab-active"
              }`}
            >
              About
            </div>
            {
              isModerator && <div
                onClick={() => setTabSelection("qr")}
                className={`tab-item mr-4 ${
                  selectedTab === "qr" && "tab-active"
                }`}
              >
                QR Code
              </div>
            }
            
            <div
              onClick={() => setTabSelection("attendance")}
              className={`tab-item mr-4 ${
                selectedTab === "attendance" && "tab-active"
              }`}
            >
              Attendance
            </div>
            <div
              onClick={() => setTabSelection("invites")}
              className={`tab-item mr-4 ${
                selectedTab === "invites" && "tab-active"
              }`}
            >
              Invites
            </div>
            <div
              onClick={() => setTabSelection("reviews")}
              className={`tab-item mr-4 ${
                selectedTab === "reviews" && "tab-active"
              }`}
            >
              Reviews
            </div>
            <div
              onClick={() => setTabSelection("faq")}
              className={`tab-item mr-4 ${selectedTab === "faq" && "tab-active"}`}
            >
              FAQ
            </div>
          </div>
        </PageHeader>
        <div className="content-wrapper" style={{ marginTop: 25 }}>
          <div
            className="content-container event-page"
            style={{ minHeight: 500 }}
          >
            {event && selectedTab === "about" && (
              <Row gutter={[16, 16]}>
                <Col
                  lg={{ span: 8, order: 2 }}
                  md={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  xs={{ span: 24, order: 1 }}
                >
                  <div className="content-box">
                    <div className="justified-row">
                      <h2>Participants</h2>
                      <Button
                        type="link"
                        onClick={() => setTabSelection("invites")}
                      >
                        See All
                      </Button>
                    </div>

                    <div className="data-box-container">
                      <span>
                        <dt>{event.counts.accepted}</dt>
                        <dd>Going</dd>
                      </span>
                      <span>
                        <dt>{event.counts.pending}</dt>
                        <dd>Pending</dd>
                      </span>
                      <span>
                        <dt>{event.counts.declined}</dt>
                        <dd>Decline</dd>
                      </span>
                    </div>
                    {event.interest && (
                      <>
                        <Divider />
                        <h2>Interest</h2>
                        <span className="text-highlight">{event.interest}</span>
                      </>
                    )}

                    {chartData && (
                      <>
                        <Divider />
                        <h2>Softskills Distribution</h2>
                        <Doughnut data={chartData} legend={true} />
                      </>
                    )}
                  </div>
                </Col>
                <Col
                  lg={{ span: 16, order: 1 }}
                  md={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 1 }}
                  xs={{ span: 24, order: 1 }}
                >
                  <div className="content-box">
                    <h2>Event Information</h2>
                    <p>{event.description}</p>

                    {event.onlineLink && (
                      <>
                        <Divider />
                        <h2>Meeting Link</h2>
                        <p>
                          <a target={"_blank"} href={event.onlineLink}>
                            {event.onlineLink}
                          </a>
                        </p>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            )}

            {event && selectedTab === "qr" && (
              <div className="content-box" style={{ minHeight: 506 }}>
                <EventQRPane
                  event={event}
                  readonly={readOnly}
                ></EventQRPane>
              </div>
            )}
            {event && selectedTab === "invites" && (
              <div className="content-box" style={{ minHeight: 506 }}>
                <EventParticipantPane
                  event={event}
                  readonly={readOnly}
                ></EventParticipantPane>
              </div>
            )}
            {event && selectedTab === "attendance" && (
              <div className="content-box" style={{ minHeight: 506 }}>
                <EventAttendancePane
                  event={event}
                  readonly={readOnly}
                ></EventAttendancePane>
              </div>
            )}
          </div>
        </div>
      </>
    );
};
const mapStateToProps = (state: { auth: any, flutterConnect: boolean }) => ({
  member: state.auth.member,
  flutterConnect: state.flutterConnect
});
export default connect(mapStateToProps, {
  deleteEvent,
  getEventDetails,
  joinEvent,
  deleteInvite,
  markAsAttended,
  acceptEventInvite,
  declineEventInvite,
  getClubModeratorList
})(EventPage);
