import { Avatar, Button, Form, List, message, Spin, Typography, Checkbox } from "antd";
import { Fragment, useEffect, useState } from "react";
import VirtualList from "rc-virtual-list";
import {
  getEventParticipantList,
  handleParticipantJoinRequest
} from "../../actions/participant";
import { connect } from "react-redux";
import { CREATED, OK, NO_CONTENT } from "../../helpers/statuscodes";
import { Invite, Attendance } from "../member-app/events/EventInvite";
import { deleteInvite, updateMultipleAttendance } from "../../actions/event";
import { Modal } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ModalDeclineReason from "../member-app/Modals/ModalDeclineReason";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Text } = Typography;

const fakeDataUrl =
  "https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo";
const ContainerHeight = 400;

type IProps = {
  participantStatus: String;
  getEventParticipantList: Function;
  handleParticipantJoinRequest: Function;
  invitedFilter: boolean;
  deleteInvite: Function;
  updateMultipleAttendance: Function;
  onReload?: Function;
  searchString?: string;
  readonly: boolean;
  eventId: string;
  reload?: boolean;
};


const ParticipantListWidget = (props: IProps) => {
  const {
    handleParticipantJoinRequest,
    getEventParticipantList,
    participantStatus,
    invitedFilter,
    deleteInvite,
    updateMultipleAttendance,
    searchString,
    readonly,
    eventId,
    reload
  } = props;
  const [isListLoading, setIsListLoading] = useState(false);
  const initialPagination = {
    current: 1
  };
  const [pagination, setPagination] = useState(initialPagination);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selInvite, setSelInvite] = useState<Invite>();
  const [form] = Form.useForm();
  const [data, setData] = useState<Invite[]>([]);
  const [attendance, setAttendance] = useState<{ [key: string]: any }>({});

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      fetchList(pagination);
    }
  };

  const saveChanges = async () => {
    setIsLoading(true);
    const request = Object.keys(attendance)
      .filter(e => attendance[e].isModified)
      .map((e) => {
        return {
          inviteId: e,
          eventId: eventId,
          isAttended: attendance[e].isAttended
        }
      });
    var response = await updateMultipleAttendance(request);
    setIsLoading(false);

    if (response.status == 200)
    {
      var tmpAttendance: { [key: string]: any } = { ...attendance };
      
      Object.keys(tmpAttendance).forEach((e: any) => {
        tmpAttendance[e].isModified = false;
      });
      
      setAttendance(tmpAttendance);
      message.success(`Saved changes.`);
    }
  }


  const checkAttended = (invite: Invite) : boolean => {
    var result = (
      attendance[invite.inviteId]
      && attendance[invite.inviteId].isAttended != null
      && attendance[invite.inviteId].isAttended == true
    )
    || (invite.attendance
    && (invite.attendance.isAttended == null || invite.attendance.isAttended == true))
    ? true
    : false;
    return result;
  };

  const onEditAttendance = (item: Invite, isAttended: boolean) => {
    var editedAttendance: { [key: string]: any } = {
      ...attendance
    };
    if (editedAttendance[item.inviteId].isAttended !== isAttended) {
      editedAttendance[item.inviteId] = {
        isAttended: isAttended,
        isModified: !editedAttendance[item.inviteId].isModified
      };
      setAttendance(editedAttendance);
    }
    
  }

  const fetchList = async (paginationParam: any) => {
    if (paginationParam.current === 1) {
      setData([]);
    }

    let params = {
      eventId,
      page: paginationParam.current,
      searchString,
      pageSize: 10,
      status: participantStatus === '3'
      ? '1'
      : participantStatus,
      joinRequestOnly: !invitedFilter
    };
    const res = await getEventParticipantList(params);
    let items = res.data.items;
    if (items.length !== 0) {

      if (participantStatus === '3') {
        var att: { [key: string]: any } = {
          ...attendance
        }

        items.forEach((e: Invite) => {
          if (!att[e.inviteId])
            att[e.inviteId] = {
              isModified: false,
              isAttended: checkAttended(e)
            };
        });
        setAttendance(att);
      }
     
      if (participantStatus === '0' && invitedFilter) {
        items = (items.filter((i:any)=>i.memberId!==i.createdBy));
      }

      if (paginationParam.current !== 1) {
        setData(data.concat(items));
      } else {
        setData(items);
      }
      setPagination({
        current: pagination.current + 1
      });
    }
    props.onReload && props.onReload({ pendingCount: res.data.pendingCount });
    setIsListLoading(false);

    message.success(
      `${
        items.length !== 0
          ? `${items.length} more participants loaded!`
          : "all participants are loaded"
      }`
    );
  };

  const onClickParticipantJoinRequest = async (
    inviteId: string,
    type: string,
    remarks?: string
  ) => {
    let params = {
      eventId,
      inviteId,
      type,
      remarks
    };

    setIsListLoading(true);
    setData([]);
    setPagination(initialPagination);

    const res = await handleParticipantJoinRequest(params);
    if (res.status == OK || res.status == CREATED) {
      let tempData = data.filter((item: any) => {
        return item.inviteId !== inviteId;
      });
      setData(tempData);
    }
    
    fetchList(initialPagination);
    form.resetFields();
    props.onReload && props.onReload();
    setShowModal(false);
  };

  const onRemoveInvite = (invite: Invite, remarks?: string) => {
    Modal.confirm({
      title: "Delete Event",
      icon: <ExclamationCircleOutlined />,
      content: `Would you like to drop ${invite.memberName} from the event?`,
      okText: "Yes",
      onOk: async () => {
        const res = await deleteInvite({
          eventId,
          inviteId: invite.inviteId,
          remarks
        });
        if (res.status === NO_CONTENT) {
          setIsListLoading(true);
          setPagination(initialPagination);
          fetchList(initialPagination);
        }
      },
      okButtonProps: { shape: "round" },
      cancelText: "No",
      cancelButtonProps: { shape: "round" }
    });
  };

  const onModalSubmit = (formData: any) => {
    const remarks = formData.remarks;
    if (selInvite) {
      onClickParticipantJoinRequest(selInvite?.inviteId, "decline", remarks);
    }
  };

  useEffect(() => {
    if (selInvite) {
      setShowModal(true);
    }
  }, [selInvite]);

  useEffect(() => {
    if (!showModal && selInvite !== undefined) {
      setSelInvite(undefined);
    }
  }, [showModal]);

  useEffect(() => {
    setIsListLoading(true);
    setPagination(initialPagination);
    fetchList(initialPagination);
  }, [participantStatus, invitedFilter, searchString, reload]);

  useEffect(() => {
    if ((Object.values(attendance)).some((e: any) => e.isModified))
      setIsSaveDisabled(false);
    else
      setIsSaveDisabled(true);
  }, [attendance]);
  
  return (
    <Fragment>
      <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={onModalSubmit}
        isOpen={showModal}
        setIsOpen={setShowModal}
        form={form}
        isLoading={isLoading}
      />
      {data.length !== 0 ? (
        <List
          header={() => (
            <div>
              asdads
              asdasd
            </div>
          )}
        >
          <VirtualList
            data={data}
            height={ContainerHeight}
            itemHeight={47}
            itemKey="memberId"
            onScroll={onScroll}
          >
            {(item: Invite) => (
              <List.Item key={item.memberName} className="table-striped-rows">
                <List.Item.Meta
                  key={item.memberId}
                  avatar={item.profilePicture?<img src={`${item.profilePicture}?${(Date.now()).toString()}`} height={30} width={30} style={{borderRadius:'50%'}}/>:<Avatar src={""} />}
                  title={<a>{item.memberName}</a>}
                  description={
                    item.participationType === 0 ? "Moderator" : "Participants"
                  }
                />

                {item.participationType === 1 && (
                  <>
                    {!invitedFilter && participantStatus === '0' && (
                      <div
                        className="d-flex"
                        style={{ display: "flex", gap: 4, margin: "0 12px", justifyContent: "space-between" }}
                      >
                        <Button
                          shape="circle"
                          icon={<CheckOutlined style={{ color: "#43BA7F" }} />}
                          style={{
                            height: 36,
                            width: 36,
                            border: "1px dashed #D9D9D9",
                            visibility: isLoading ? 'hidden' : 'visible',
                            transition: "none"
                          }}
                          onClick={async () => {
                            await onClickParticipantJoinRequest(
                              item.inviteId,
                              "approve"
                            );
                          }}
                        ></Button>
                        <Spin
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            visibility: !isLoading ? 'hidden' : 'visible'
                          }}
                        ></Spin>
                        <Button
                          shape="circle"
                          icon={<CloseOutlined style={{ color: "#FA3E24" }} />}
                          style={{
                            height: 36,
                            width: 36,
                            border: "1px dashed #D9D9D9",
                            visibility: isLoading ? 'hidden' : 'visible',
                            transition: "none"
                          }}
                          onClick={() => setSelInvite(item)}
                        ></Button>
                      </div>
                    )}

                    {/* {!readonly && invitedFilter && participantStatus !== '3' && (
                      <Button
                        size="small"
                        shape="circle"
                        icon={<CloseOutlined />}
                        title="Remove invite"
                        onClick={() => onRemoveInvite(item)}
                      ></Button>
                    )} */}
                  </>
                )}
              </List.Item>
            )}
          </VirtualList>

          {participantStatus === '3' && (
            <List.Item>
              <List.Item.Meta
              />
              <div
                className="d-flex px-4"
                style={{width: 100, justifyContent: "center"}}
              >
                <Button
                  htmlType="button"
                  className="mr-1"
                  style={{borderRadius: 5, height: 40}}
                  key="save"
                  disabled={isSaveDisabled}
                  type="primary"
                  loading={isLoading}
                  onClick={saveChanges}
                >
                  Save changes
                </Button>
              </div>
            </List.Item>
          )}
        </List>
      ) : isListLoading ? (
        <div className="d-flex justify-content-center">
          <Spin tip="Loading Participants..."></Spin>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <h4>No Data</h4>
        </div>
      )}
    </Fragment>
  );
};

export default connect(null, {
  deleteInvite,
  updateMultipleAttendance,
  getEventParticipantList,
  handleParticipantJoinRequest
})(ParticipantListWidget);
