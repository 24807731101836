import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Form, Button, Input, Spin, Row, Col } from "antd";

import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { BAD_REQUEST, OK } from "../../helpers/statuscodes";
import moment from "moment";

const PinVerification = (props: any) => {
  const [pinCodeForm] = Form.useForm();
  const [isLoading, setIsloading] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState([]);
  const {
    onVerifyEmail,
    emailAddress,
    verificationCodeId,
    isResetPassword,
    OTPCode,
  } = props;
  const [storedVerificationCodeId, setStoredVerificationCodeId] =
    useState(verificationCodeId);
  const [timeLeft, setTimeLeft]: any = useState("5:00");
  const [isTimerInProgress, setIsTimerInProgress]: any = useState(false);

  useEffect(() => {
    calculateTimeLeft();
    if (OTPCode) {
      pinCodeForm.setFieldsValue({
        pin1: OTPCode[0],
        pin2: OTPCode[1],
        pin3: OTPCode[2],
        pin4: OTPCode[3],
        pin5: OTPCode[4],
        pin6: OTPCode[5],
      });
      pinCodeForm.submit();
    }
  }, []);

  const sendVerificationCode = async () => {
    if (!isTimerInProgress) {
      calculateTimeLeft();
      let requestCodeUrl = "/api/Account/resend-code";
      setValidationMessages([]);
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const body = JSON.stringify({
          verificationCodeId: storedVerificationCodeId,
        });

        const res = await axios.post(`${requestCodeUrl}`, body, config);
        if (res.status === OK) {
          setStoredVerificationCodeId(res.data.verificationCodeId);
        }
      } catch (err: any) {
        if (err.response.status === BAD_REQUEST) {
          handleBadRequest(err.response.data);
        }
      }
    }
  };

  const verifyPinCode = async (pinCode: any) => {
    setIsloading(true);
    setValidationMessages([]);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let body = {
        emailAddress: emailAddress,
        code: pinCode,
        verificationCodeId: storedVerificationCodeId,
      };

      const res = await axios.post(`/api/Account/verify-email`, body, config);
      if (res.status === OK) {
        onVerifyEmail(res.data);
        return;
      }
    } catch (err: any) {
      pinCodeForm.resetFields();
      if (err.response.status === BAD_REQUEST) {
        handleBadRequest(err.response.data);
      }
    }

    setIsloading(false);
  };

  const onSubmitHandler = (values: any) => {
    let pinCode = "";
    for (const property in values) {
      if (values[property]) {
        pinCode += values[property];
      }
    }
    if (!pinCode) {
      setValidationMessages([
        {
          id: uuidv4(),
          message: "Code is Required",
        },
      ]);
    } else if (pinCode.length != 6) {
      setValidationMessages([
        {
          id: uuidv4(),
          message: "Invalid Code",
        },
      ]);
    } else {
      verifyPinCode(pinCode);
    }
  };

  const handleBadRequest = (data: any) => {
    for (const property in data) {
      setValidationMessages((currentvalidationMessages: any) => [
        ...currentvalidationMessages,
        {
          id: uuidv4(),
          message: data[property],
        },
      ]);
    }
  };

  const handleKeyPress = (e: any) => {
    setValidationMessages([]);
    const form = e.target.form;
    if (e.key === "Backspace") {
      const index = [...form].indexOf(e.target);
      form.elements[index - 1].focus();
    } else {
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      if (index !== 5) {
        form.elements[index + 1].focus();
      }
    }
    if (
      form.elements[0].value &&
      form.elements[1].value &&
      form.elements[2].value &&
      form.elements[3].value &&
      form.elements[4].value &&
      form.elements[5].value
    ) {
      pinCodeForm.submit();
    }
  };

  const calculateTimeLeft = () => {
    setIsTimerInProgress(true);
    let max = 300; //seconds

    const timer = setInterval(() => {
      max = max - 1;
      let duration = moment.duration(max, "seconds");
      let format = Math.floor(duration.asMinutes()) + ":" + duration.seconds();
      setTimeLeft(format);
      if (max <= 0) {
        setTimeLeft("5:00");
        setIsTimerInProgress(false);
        clearInterval(timer);
      }
    }, 1000);
  };

  return (
    <Row>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 10, offset: 7 }}
        xxl={{ span: 10, offset: 7 }}
      >
        <div className="form-container">
          <h2 className="form-title">Verify Your Email Address</h2>
          <h5 className="sub-title">
            We just emailed a six digit verification code to{" "}
            <b>{emailAddress}</b> Keep this window open while checking your
            inbox, then enter the code below
          </h5>
          <Form
            form={pinCodeForm}
            name="pinform"
            initialValues={{ remember: true }}
            onFinish={onSubmitHandler}
            autoComplete="off"
            className="mt-3 pin-form"
          >
            <div className="d-flex">
              <Form.Item name="pin1">
                <Input maxLength={1} onKeyUp={handleKeyPress} placeholder="0" />
              </Form.Item>
              <Form.Item name="pin2">
                <Input maxLength={1} onKeyUp={handleKeyPress} placeholder="0" />
              </Form.Item>
              <Form.Item name="pin3">
                <Input maxLength={1} onKeyUp={handleKeyPress} placeholder="0" />
              </Form.Item>
              <Form.Item name="pin4">
                <Input maxLength={1} onKeyUp={handleKeyPress} placeholder="0" />
              </Form.Item>
              <Form.Item name="pin5">
                <Input maxLength={1} onKeyUp={handleKeyPress} placeholder="0" />
              </Form.Item>
              <Form.Item name="pin6">
                <Input
                  maxLength={1}
                  onKeyUp={handleKeyPress}
                  placeholder="0"
                  className="mr-0"
                />
              </Form.Item>
            </div>
            {validationMessages && validationMessages[0] && (
              <div className="ant-form-item-explain mb-3">
                <div role="alert" className={`ant-form-item-explain-error`}>
                  {validationMessages[0].message === "Invalid code"
                    ? "The code you entered is incorrect. Please try again."
                    : validationMessages[0].message}
                </div>
              </div>
            )}

            <div className="d-flex">
              {isLoading ? (
                <div className="d-flex justify-content-center text-success">
                  <Spin
                    className="anticon-loading-sm"
                    size="small"
                    indicator={<LoadingOutlined />}
                  />
                  Verifying Code...
                </div>
              ) : (
                <div className="resend-code-container">
                  Didn’t receive a code?{" "}
                  <a
                    onClick={sendVerificationCode}
                    className={isTimerInProgress ? "anchor-disabled" : ""}
                  >
                    <span>Resend code </span>{" "}
                    <span>{isTimerInProgress && "in (" + timeLeft + ")"}</span>
                  </a>
                </div>
              )}
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default PinVerification;
