import {
    OK,
    CREATED,
    BAD_REQUEST,
    METHOD_NOT_ALLOWED,
    NOT_FOUND,
  } from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";

const parseParams = (params, values, type) => {
    let searchParams = new URLSearchParams();
    let objectParams = {};
  
    const paramNames = params.map(e => e.name);
    
    Object.keys(values)
      .filter(e => paramNames.includes(e))
      .forEach(e => {
        const value = values[e]
            ?? params.find(f => f.name == e).defaultValue
            ?? '';
  
            switch(type) {
              case 'post':
              case 'put':
              case 'delete':
                objectParams[e] = value;
                break;
              default:
                if (Array.isArray(value)) {
                  value.forEach((f) => searchParams.append(e, f));
                }
                else {
                  searchParams.append(e, value);
                }
            }
        
        
      });
      switch(type) {
        case 'post':
        case 'put':
        case 'delete':
          return objectParams;
        default:
          return searchParams;
      }
  };

export const callApi = async ({api, params = [], values = {}, controller, type = 'get', dispatch, statusAlert, baseUrl}) => {
    const encodedApi = api ? `/${encodeURIComponent(api)}` : '';
  
    try {
    let response = {};
  
      switch(type) {
        case 'post':
          response = await axiosApiInstance.post(`${baseUrl}${encodedApi}`,
            parseParams(params, values, type)
          );
          break;
        case 'put':
          response = await axiosApiInstance.put(`${baseUrl}${encodedApi}`,
            parseParams(params, values, type)
          );
          break;
        case 'delete':
          response = await axiosApiInstance.delete(`${baseUrl}${encodedApi}`,
            parseParams(params, values, type)
          );
          break;
        default:
          response = await axiosApiInstance.get(`${baseUrl}${encodedApi}`, {
            params: parseParams(params, values, type),
            signal: controller ? controller.signal : null,
          });
      }
  
      if(statusAlert && statusAlert[`${response.status}`]) {
        dispatch(setAlert(
          statusAlert[`${response.status}`].title ?? response.status,
          statusAlert[`${response.status}`].message ?? response.statusText,
          statusAlert[`${response.status}`].type ??
            [OK, CREATED].includes(response.status)
              ? 'success'
              : 'error'
        ));
      }
  
      return response;
      
    } catch (err) {
      console.log(err);
      
      if(statusAlert) {
        if(statusAlert[`${err.response.status}`] ){
          dispatch(setAlert(
            statusAlert[`${err.response.status}`].title ?? err.response.status,
            statusAlert[`${err.response.status}`].message ?? err.response.statusText,
            statusAlert[`${err.response.status}`].type ??
              [OK, CREATED].includes(err.response.status)
                ? 'success'
                : 'error'
          ));
        }       
      }
      else {
        dispatch(setAlert(
          err.response.status,
          err.response.statusText,
          [OK, CREATED].includes(err.response.status)
            ? 'success'
            : 'error'
        ));
      }
  
      return err.response;
    }
  }
