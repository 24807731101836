import { Fragment, useState } from "react";
import PageHeader from "../../member-app/layouts/PageHeader";
import NotificationList from "../../member-app/notifications/NotificationList";

const AdminNotifications = () => {
  const [filter, setFilter] = useState(1);
  const handleHideNotification = () => {};
  return (
    <div className="admin-notification">
      <PageHeader title="Notifications">
        {/* add your tab filters here */}
        <div className="d-flex tab-filter mt-4">
          <div
            onClick={() => setFilter(1)}
            className={`tab-item mr-4 ${filter === 1 && "tab-active"}`}
          >
            Alls
          </div>
          <div
            onClick={() => setFilter(2)}
            className={`tab-item mr-4 ${filter === 2 && "tab-active"}`}
          >
            Unread
          </div>
        </div>
      </PageHeader>
      <div className="content-wrapper">
        <div className="content-container">
          <NotificationList
            fullWidth={true}
            handleHideNotification={handleHideNotification}
            dropdownVisible={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminNotifications;
