import { Route, Routes } from "react-router-dom";
import EditClub from "../groups/EditGroup";
import Dashboard from "../dashboard/Dashboard";
import EventForm from "../events/EventForm";
import EventList from "../events/EventList";
import EventPage from "../events/EventPage";
import GroupList from "../groups/GroupList";
import GroupPage from "../groups/GroupPage";
import NotificationPage from "../notifications/NotificationPage";
import PrivacyPage from "../privacy/PrivacyPage";
import Profile from "../profile/Profile";
import Calendar from "../calendar/CalendarPage";
import CalendarPage from "../calendar/CalendarPage";
const MemberRoute = () => {
  return (
    <Routes>
      {/* <Route index={true} element={<Dashboard />} /> */}
      <Route index={true} element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/events" element={<EventList />} />
      <Route path="/event/:eventId" element={<EventPage />} />
      <Route path="/event/:eventId/edit" element={<EventForm />} />
      <Route path="/clubs" element={<GroupList />} />
      <Route path="/settings" element={<Profile />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/calendar" element={<CalendarPage />} />

      <Route
        path="/club/:memberGroupId"
        element={<GroupPage viewingAs="NonAdmin" />}
      ></Route>
      <Route path="/club/:memberGroupId/edit" element={<EditClub />}></Route>
      <Route path="/notifications" element={<NotificationPage />} />
    </Routes>
  );
};

export default MemberRoute;
