import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Input,
  Spin,
  Checkbox,
  Modal,
  Row,
  Col,
  Alert,
  Tabs,
  InputRef
} from "antd";
import {
  requestPermission,
  subscribeTokenToTopic
} from "../../firebase-cloud-messaging";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { BAD_REQUEST, OK } from "../../helpers/statuscodes";
import { login } from "../../actions/auth";
import { hideBlurEffect, showBlurEffect } from "../../layouts/BlurEffect";
import { Student, Teacher } from "../../helpers/userRoles";
import loginImage from "./../../assets/images/login-image.svg";
import iconClose from "../../assets/images/icons/icon-close.svg";
import breezeWhite from "./../../assets/images/logos/breeze-white.svg";
import { isEmptyOrSpaces } from "../../helpers/whitespaces";
import mobileAuthBg from "./../../assets/images/mobile-auth-bg.svg";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import WelcomePage from "../WelcomePage/WelcomePage";
import auth from "../../reducers/auth";
import { tagLoginDetails } from "../../utils/googleTagManager";
import breezelogo from "./../../assets/images/logos/breeze.svg";
import { setRecentLogout } from "../../actions/recentLogout";
const { TabPane } = Tabs;

const LoginPage = (props: any) => {
  let navigate = useNavigate();
  const [validationMessages, setValidationMessages]: any = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userRole, setUserRole]: any = useState();
  const [formActiveKey, setFormActiveKey]: any = useState('1');
  const [username, setUsername]: any = useState('');
  const [redirect, setRedirect] = useState<string>('');
  const [isAdminRedirect, setIsAdminRedirect] = useState<boolean | null>(null);
  const inputPassword = useRef<InputRef | null>(null);

  const {
    login,
    firebase,
    auth: { isAuthenticated, member },
    flutterConnect,
    recentLogout,
    setRecentLogout
  } = props;
  const { firebaseRegistrationToken } = firebase;

  // var flutterConnect = true;

  console.log('recent logout', recentLogout);
  if (recentLogout) {
    window.history.replaceState({}, '', window.location.pathname);
    setRecentLogout(false);
  }

  const onFinish = async (values: any) => {
    if (flutterConnect && formActiveKey == '1') {
      setFormActiveKey('2');
      return;
    }

    console.log("Success:", values);
    setValidationMessages(new Array()); //clear validation errors
    setIsloading(true);
    values = {
      ...values,
      registrationToken: firebaseRegistrationToken,
    };
    const res = await login(values); //call redux action
    if (res.status === OK) {
      let decoded: any = jwt_decode(res.data.token);
      let userRole: String =
        decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      
        setUserRole(userRole);
        
      if (res.data.member)
        tagLoginDetails(res.data.member.memberId);
        
      setIsLoginSuccess(true);
      
      //show login success page for 5 seconds before going to admin
      // setTimeout(() => {
      //   setIsFading(true);
      //   setTimeout(() => {
      //     if (userRole === Student || userRole === Teacher) {
      //       navigate("/", { replace: true });
      //     } else {
      //       navigate("/admin", { replace: true });
      //     }
      //     // if (userRole === Student) {
      //     //   navigate("/student", { replace: true });
      //     // } else if (userRole === Teacher) {
      //     //   navigate("/teacher", { replace: true });
      //     // } else {
      //     //   navigate("/admin", { replace: true });
      //     // }
      //   }, 500);
      // }, 3000);
    } else if (res.response.status === BAD_REQUEST) {
      if (
        res.response.data.error ===
        "Your account has been locked due to 5 consecutive unsuccessful login attempts. Try again in 5 minutes."
      ) {
        setModal2Visible(true);
      }
      setValidationMessages({
        message: res.response.data.error,
      });
    }
    setIsloading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    if (flutterConnect && formActiveKey == '1') {
      setFormActiveKey('2');
      return;
    }
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    showBlurEffect();
    setModal2Visible(true);
  };

  const hideModal = () => {
    hideBlurEffect();
    setModal2Visible(false);
  };

  const hasError = validationMessages != 0 && "has-error";

  useEffect(() => {
    // NOTE: first admin login force redirects to welcome page
    setRedirect(new URLSearchParams(window.location.search).get('r') ?? '');
    setIsAdminRedirect(redirect?.startsWith('/admin'));

    if (isAuthenticated) {
      if (member && "memberType" in member) {
        if (member.memberType === Student || member.memberType === Teacher) {
          navigate(redirect && !isAdminRedirect ? redirect : "/dashboard");
        } else {
          navigate(redirect && isAdminRedirect ? redirect : "/admin");
        }
      } else {
        navigate("/admin");
      }
    }
  }, []);

  useEffect(() => {
    inputPassword.current?.focus();
  }, [formActiveKey]);

  if (isLoginSuccess) {
    if (member && "memberType" in member) {
      if (member.memberType === Student) {
        if (member && member.interests && member.interests.length > 0) {
          navigate(redirect && !isAdminRedirect ? redirect : "/dashboard");
        } else {
          navigate(redirect && !isAdminRedirect ? redirect : "/interests");
        }
      } else {
        return <WelcomePage />;
      }
    } else {
      return <WelcomePage />;
    }
  }
  
  if (flutterConnect) {
    return (
      <Fragment>
        <Modal
          className="modal-notify"
          centered
          visible={modal2Visible}
          onCancel={hideModal}
          footer={[
            <Button key="submit" className="btn-breeze" onClick={hideModal}>
              Confirm
            </Button>,
          ]}
        >
          <div className="d-flex">
            <div>
              <img src={iconClose} />
            </div>
            <div className="d-flex-column ml-2">
              <div className="mod-title">Failed Login</div>
              <div className="mt-1">
                <p className="modal-message">
                  Your account has been locked because of 5 consecutive
                  unsuccessful login attempts. Try again in 5 minutes.
                </p>
              </div>
            </div>
          </div>
        </Modal>
        {!isLoginSuccess ? (
          <div className="login-page-wrapper flutter">
            <div className="panel-container">
              <div className="d-flex-column h-100 content">
                <div className="form-panel d-flex-column align-items-center justify-content-center h-100">
                  <div className="header">
                    <img src={breezelogo} />
                    <h2 className="tagline">Grow your strengths</h2>
                  </div>
                  <div className="form-content">
                    <div className="form-container">
                      {
                        formActiveKey === '2'
                        ? (
                          <h2 className="form-title" onClick={() => setFormActiveKey('1')}>Sign as {`${username}`}</h2>
                        )
                        : (
                          <h2 className="form-title">Sign in to Breeze</h2>
                        )
                      }
                      <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                        className="custom-alert-2 mt-4"
                      >
                        <div
                          className={`mb-3 force-alert ${
                            validationMessages != 0 && "has-error"
                          }`}
                        >
                          {validationMessages.length != 0 && (
                            <Alert
                              message={
                                <p className="mb-0">
                                  You entered an incorrect email, password, or
                                  both. Need an account?{" "}
                                  <Link to="/sign-up">Sign Up</Link>
                                </p>
                              }
                              type="error"
                              showIcon
                            />
                          )}
                        </div>

                        <Tabs
                          activeKey={formActiveKey}
                          renderTabBar={() => (<div></div>)}
                        >
                          <TabPane tab="Tab 1" key="1">
                            <label className="mb-2">School Email Address</label>
                            <Form.Item
                              name="username"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Please input valid Email!",
                                },
                              ]}
                            >
                              <Input
                                className="mt-1"
                                placeholder="john.smith@yale.edu"
                              />
                            </Form.Item>
                            <Form.Item>
                              <Button
                                  className="btn-breeze-box-radius mt-2 next"
                                  disabled={isLoading}
                                  loading={isLoading}
                                  onClick={() => {
                                    setFormActiveKey('2');
                                    setUsername((document.getElementById('basic_username') as HTMLInputElement).value);
                                  }}
                                >
                                  Next
                                </Button>
                                <div>
                                <Form.Item
                                  name="remember"
                                  valuePropName="checked"
                                  className="my-auto"
                                >
                                  <Checkbox className="remember-me">Remember this device</Checkbox>
                                </Form.Item>
                                </div>
                            </Form.Item>
                          </TabPane>
                          <TabPane tab="Tab 2" key="2">
                            <label className="mb-2">Password</label>
                            <Form.Item
                              name="password"
                              className="mt-1 custom-addon"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your password!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      //resolve to use original rule
                                      return Promise.resolve();
                                    }
                                    if (!isEmptyOrSpaces(value)) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        new Error("Please input your password!")
                                      );
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Input
                                ref={inputPassword}
                                type={showConfirmPassword ? "text" : "password"}
                                addonAfter={
                                  !showConfirmPassword ? (
                                    <EyeOutlined
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                    />
                                  ) : (
                                    <EyeInvisibleOutlined
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                    />
                                  )
                                }
                                placeholder="******"
                              />
                            </Form.Item>
                            <Form.Item>
                              <Button
                                className="btn-breeze-box-radius mt-2 next"
                                disabled={isLoading}
                                htmlType="submit"
                                loading={isLoading}
                              >
                                Next
                              </Button>
                              <div>
                                <div className="ml-auto my-auto forgot-password">
                                  <Link
                                    to="/forgot-password"
                                  >
                                    Forgot password?
                                  </Link>
                                </div>
                              </div>
                            </Form.Item>
                          </TabPane>
                        </Tabs>
                      </Form>
                    </div>
                  </div>
                  <div className="nav-controls d-flex-column w-100">
                    <Button className="mt-3 w-100 create-account">
                      <Link to="/sign-up">Create account</Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // <WelcomePage fading={isFading} userRole={userRole} />
          <Fragment></Fragment>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Modal
        className="modal-notify"
        centered
        visible={modal2Visible}
        onCancel={hideModal}
        footer={[
          <Button key="submit" className="btn-breeze" onClick={hideModal}>
            Confirm
          </Button>,
        ]}
      >
        <div className="d-flex">
          <div>
            <img src={iconClose} />
          </div>
          <div className="d-flex-column ml-2">
            <div className="mod-title">Failed Login</div>
            <div className="mt-1">
              <p className="modal-message">
                Your account has been locked because of 5 consecutive
                unsuccessful login attempts. Try again in 5 minutes.
              </p>
            </div>
          </div>
        </div>
      </Modal>
      {!isLoginSuccess ? (
        <div className="login-page-wrapper">
          <div className="panel-container">
            <div className="left-content">
              <div className="h-100 d-flex-column justify-content-center align-items-center">
                <img
                  className="login-logo"
                  src={breezeWhite}
                  height="37.87px"
                  width="128"
                />
                <img src={loginImage} height="536px" width="100%" />
                <h2 className="text-center">Grow your strengths</h2>
                <p className="sub-title text-center">
                  Attend school events to increase your score skill score.
                </p>
              </div>
            </div>
            <div className="right-content d-flex-column h-100">
              <div
                className="mobile-header d-md-none mb-2"
                style={{ backgroundImage: `url(${mobileAuthBg})` }}
              >
                <img
                  className="login-logo"
                  src={breezeWhite}
                  height="32px"
                  width="auto"
                />
              </div>
              <div className="d-flex justify-content-end w-100">
                <div className="nav-controls justify-content-center d-sm-none">
                  <Link className="mr-3" to="/sign-up">
                    Dont have an account?
                  </Link>
                  <Button>
                    <Link to="/sign-up">Get Started</Link>
                  </Button>
                </div>
              </div>


              <div className="form-panel d-flex-column align-items-center justify-content-center h-100">
                <div className="w-100">
                  <Row>
                    <Col
                      xs={{ span: 22, offset: 1 }}
                      md={{ span: 18, offset: 3 }}
                      lg={{ span: 16, offset: 4 }}
                      xl={{ span: 10, offset: 7 }}
                      xxl={{ span: 10, offset: 7 }}
                    >
                      <div className="form-container">
                        <h2 className="form-title">Sign in to Breeze</h2>
                        <h5 className="sub-title">Enter your details below</h5>
                        <Form
                          name="basic"
                          initialValues={{ remember: true }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          layout="vertical"
                          className="custom-alert-2 mt-4"
                        >
                          <div
                            className={`mb-3 force-alert ${
                              validationMessages != 0 && "has-error"
                            }`}
                          >
                            {validationMessages.length != 0 && (
                              <Alert
                                message={
                                  <p className="mb-0">
                                    You entered an incorrect email, password, or
                                    both. Need an account?{" "}
                                    <Link to="/sign-up">Sign Up</Link>
                                  </p>
                                }
                                type="error"
                                showIcon
                              />
                            )}
                          </div>
                          <label className="mb-2">School Email Address</label>
                          <Form.Item
                            name="username"
                            rules={[
                              {
                                required: true,
                                type: "email",
                                message: "Please input valid Email!",
                              },
                            ]}
                          >
                            <Input
                              className="mt-1"
                              placeholder="john.smith@yale.edu"
                            />
                          </Form.Item>
                          <label className="mb-2">Password</label>
                          <Form.Item
                            name="password"
                            className="mb-1-i custom-addon"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    //resolve to use original rule
                                    return Promise.resolve();
                                  }
                                  if (!isEmptyOrSpaces(value)) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      new Error("Please input your password!")
                                    );
                                  }
                                },
                              }),
                            ]}
                          >
                            <Input
                              type={showConfirmPassword ? "text" : "password"}
                              addonAfter={
                                !showConfirmPassword ? (
                                  <EyeOutlined
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                  />
                                ) : (
                                  <EyeInvisibleOutlined
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                  />
                                )
                              }
                              placeholder="******"
                            />
                          </Form.Item>
                          <div className="d-flex pt-5">
                            <Form.Item
                              name="remember"
                              valuePropName="checked"
                              className="my-auto"
                            >
                              <Checkbox>Remember this device</Checkbox>
                            </Form.Item>
                            <div className="ml-auto my-auto">
                              <Link
                                className="breeze-color-blue-1"
                                to="/forgot-password"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          <Form.Item>
                            <Button
                              className="btn-breeze-box-radius mt-2"
                              disabled={isLoading}
                              htmlType="submit"
                              loading={isLoading}
                            >
                              Sign In
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="nav-controls d-flex-column w-100 d-md-none">
                  <Link className="text-center" to="/sign-up">
                    Don't have an account?
                  </Link>
                  <Button className="mt-3 w-100">
                    <Link to="/sign-up">Sign Up</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <WelcomePage fading={isFading} userRole={userRole} />
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: { auth: any; firebase: any; flutterConnect: any; recentLogout: boolean }) => ({
  auth: state.auth,
  firebase: state.firebase,
  flutterConnect: state.flutterConnect,
  recentLogout: state.recentLogout
});

export default connect(mapStateToProps, { login, setRecentLogout })(LoginPage);
