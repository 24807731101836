import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInterests } from "../../../actions/interest";

import iconExclamation from "../../../assets/images/icons/icon-exclamation.svg";
import iconList from "../../../assets/images/icons/icon-list.svg";
import { Link, useNavigate } from "react-router-dom";
import { Teacher } from "../../../helpers/memberTypes";
import { hideBlurEffect2, showBlurEffect2 } from "../../../layouts/BlurEffect";
import ModalList from "../../layout/ModalList";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const InterestList = (props: { getInterests: Function }) => {
  let navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "SoftSkills",
      key: "soft-skills",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsModalPhilosophyVisible(true);
              showBlurEffect2();
              setSelectedInterestName(record.name);
              setSelectedInterest(record.softSkillScores);
            }}
          >
            {record.softSkillScores.length}
          </a>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a id={record.interestId} onClick={() => editHandler(record)}>
            Edit
          </a>
        </Space>
      ),
    },
  ];

  const { getInterests } = props;
  const [interestList, setInterestList]: any = useState([]);
  const [selectedInterest, setSelectedInterest] = useState();
  const [selectedInterestName, setSelectedInterestName] = useState();

  const [isModalPhilosophyVisible, setIsModalPhilosophyVisible] =
    useState(false);

  const [statusFilter, setStatusFilter] = useState(-1);
  const [interestId, setInterestId] = useState();
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination]: any = useState(initialPagination);
  const [searchString, setSearchString]: any = useState("");

  const [isListLoading, setIsListLoading] = useState(false);

  const onFilterSearchHandler = (searchInput: String) => {
    setSearchString(searchInput);
  };

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
  };

  const editHandler = (record: any) => {
    navigate("/admin/interests/add", {
      state: {
        interestId: record?.interestId,
        name: record?.name,
        description: record?.description,
        softSkillScores: record?.softSkillScores,
      },
    });
  };

  const fetchList = async (parameters: object) => {
    setIsListLoading(true);
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        memberType: Teacher,
        status: statusFilter,
      };
    } else {
      params = { ...parameters, memberType: Teacher, status: statusFilter };
    }
    const data: any = await getInterests(params);
    setInterestList(data.data.items);
    setPagination({
      current: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsListLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString, statusFilter]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };
  return (
    <Fragment>
      <Modal
        className="modal-notify"
        centered
        visible={isModalPhilosophyVisible}
        onCancel={() => {
          hideBlurEffect2();
          setIsModalPhilosophyVisible(false);
        }}
        footer={[]}
      >
        <div className="d-flex">
          <div>
            <img height={24} width={24} src={iconList} />
          </div>
          <div className="d-flex-column ml-2 w-100">
            <div className="mod-title">{selectedInterestName}</div>
            <div className="mt-1">
              <ModalList item={selectedInterest} />
              <div className="d-flex justify-content-end">
                <Button
                  key="submit"
                  className="btn-breeze px-7"
                  onClick={() => {
                    setIsModalPhilosophyVisible(false);
                    hideBlurEffect2();
                  }}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="Interests"
          extra={[
            <Button key="add" type="primary" className="btn-radius">
              <Link to={"/admin/interests/add"}>Add New Interests</Link>
            </Button>,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    <div className="my-auto mr-auto">
                      Status:{" "}
                      <select onChange={onfilterStatusHandler}>
                        <option value="-1">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                    <div>
                      <Search
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search by Interest Name"
                        style={{ width: 280 }}
                      />
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="interestId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      scroll={{ y: "calc(100vh - 420px)" }}
                      dataSource={interestList}
                      loading={isListLoading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getInterests })(InterestList);
