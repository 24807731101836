import {
  Button,
  Col,
  DatePicker,
  TimePicker,
  DatePickerProps,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Tabs,
  InputNumber,
  Skeleton,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { MoreOutlined, DotChartOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { RangePickerProps } from "antd/lib/date-picker";
import penIcon from "../../../assets/images/icons/icon-pen.svg";

import { eventTypes } from "../../../helpers/eventTypes";
import ParticipantField from "../select-fields/ParticipantField";
import LocationField from "../select-fields/LocationField";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import ParticipantWidget from "../../widget/ParticipantWidget";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { getEventDetails, updateEvent } from "../../../actions/event";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import AlertContainer from "../../../layouts/AlertContainer";
import InterestField from "../select-fields/InterestField";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import EventQRPane from "../../widget/EventQRPane";
import EventAttendancePane from "./EventAttendancePane";
import EventAdminField from "../select-fields/EventAdminField";
import EventParticipantPane from "./EventParticipantPane";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <div className="d-flex-column links-dropdown-list">
        <div>
          <a>Cancel Event</a>
        </div>
        <div>
          <a>Publish Event</a>
        </div>
      </div>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const dateFormat = "YYYY-MM-DD";
const EventView = (props: any) => {
  const { updateEvent, getEventDetails, setAlert } = props;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [updateEventForm] = Form.useForm();
  const [validationMessages, setValidationMessages]: any = useState({});
  const [eventType, setEventType] = useState(0);
  const [softSkillScores, setSoftskillScores] = useState<any>([]);
  const [participants, setParticipants]: any = useState([]);
  const [eventAdmins, setEventAdmins]: any = useState([]);
  const [totalSoftSkillScore, setTotalSoftSkillScore] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isPastEvent, setIsPastEvent] = useState(false);
  const [event, setEvent] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<any>();
  const [selectedTab, setSelectedTab] = useState("about");

  const timeChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);

    setStartTime(dateString[0]);
    setEndTime(dateString[1]);
  };

  const onStartTimeChange = (value: any) => {
    // console.log(moment.utc(value).format("h:mm a"));
    setStartTime(moment(value).format("h:mm a"));
  };

  const onEndTimeChange = (value: any) => {
    // setEndTime(value);
    setEndTime(moment(value).format("h:mm a"));
  };

  const dateChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Date: ", dateString);
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const onFinish = async (formData: any) => {
    setIsLoading(true);
    let participants = [];
    if (formData.participants) {
      participants = formData.participants.map((item: { value: string }) => {
        let splitValue = item.value.split("-");
        return {
          id: splitValue[0],
          type: parseInt(splitValue[1]),
        };
      });
    }

    let eventAdmins = [];

    if (formData.eventAdmins) {
      eventAdmins = formData.eventAdmins.map((item: { value: string }) => {
        let splitValue = item.value.split("-");
        return {
          id: splitValue[0],
          type: parseInt(splitValue[1]),
        };
      });
    }
    //console.log(moment.utc(startDate + " " + startTime));
    formData = {
      ...formData,
      eventId: eventId,
      startDate: new Date(startDate + " " + startTime).toISOString(),
      endDate: new Date(endDate + " " + endTime).toISOString(),
      eventType: eventType,
      participants: participants,
      eventAdmins: eventAdmins,
      softSkillScores: formData?.softSkillScores
        ? formData.softSkillScores
        : softSkillScores,
    };

    let res = await updateEvent(formData);
    if (res.status == OK || res.status == CREATED) {
      fetchEventList();
    } else if (res.status === BAD_REQUEST) {
      if (res.data.errors) {
        handleFormErrors(res.data);
      }
    }
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const computeTotalSoftSkillScore = () => {
    const sum = updateEventForm
      .getFieldValue("softSkillScores")
      .reduce((accumulator: any, object: any) => {
        return accumulator + object.percentage;
      }, 0);

    setTotalSoftSkillScore(sum);
  };

  const handleInterestChange = (interest: any) => {
    if (interest && interest.softSkillScores) {
      setSoftskillScores(interest.softSkillScores);
      updateEventForm.setFieldsValue({
        softSkillScores: interest.softSkillScores,
      });
    } else {
      setSoftskillScores([]);
      updateEventForm.setFieldsValue({
        softSkillScores: undefined,
      });
    }
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    console.log("ERRORS", data);
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return moment().add(-1, "days") >= current;
  };

  // get event with event id
  const fetchEventList = async () => {
    let res = await getEventDetails({
      eventId,
    });
    if (res.data) {
      setEvent(res.data);
      let participantsValues = res.data.participants.map(
        (participant: any) => {
          return {
            value: `${participant.id}-${participant.type}`,
            label: participant.name,
            type: participant.type,
          };
        }
      );

      let eventAdminsValues = res.data.eventAdmins.map(
        (eventAdmin: any) => {
          return {
            value: `${eventAdmin.id}`,
            label: eventAdmin.name,
          };
        }
      );
      if (moment(res.data.startDate).isBefore()) {
        setIsPastEvent(true);
      }

      const _startDate = moment(new Date(res.data.startDate));
      const _endDate = moment(new Date(res.data.endDate));
      setStartDate(_startDate.format(dateFormat));
      setEndDate(_endDate.format(dateFormat));
      setStartTime(_startDate.format("h:mm a"));
      setEndTime(_endDate.format("h:mm a"));
      updateEventForm.setFieldsValue({
        title: res.data.title,
        dateRange: [moment(res.data.startDate), moment(res.data.endDate)],
        startTime: moment(res.data.startDate),
        endTime: moment(res.data.endDate),
        eventSeriesType: res.data.eventSeriesType,
        locationId: res.data.locationId,
        onlineLink: res.data.onlineLink,
        description: res.data.description,
        participants: participantsValues,
        eventAdmins: eventAdminsValues,
        softSkillScores: res.data.softSkillScores,
        interestId: res.data.interestId,
      });
      setSoftskillScores(res.data.softSkillScores);
      setParticipants(res.data.participants);
      setEventAdmins(res.data.eventAdmins);
      computeTotalSoftSkillScore();
      setEventType(res.data.eventType);
      setIsInitialLoad(false);
    }
  };

  useEffect(() => {
    fetchEventList();
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.get("qr") === '1' && event != null) {
      setActiveTab('2');
    }
  }, [event]);

  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <div className="app-main-content">
          <div>
            <Form
              layout="vertical"
              autoComplete="off"
              className="breeze-form-1 event-view-form"
              form={updateEventForm}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="app-main-content-inner mb-4">
                <div className="page-header-2">
                  <div className="d-flex justify-content-space-between align-items-center w-100">
                    <Form.Item
                      className="event-title-input mb-0"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter event Title!",
                        },
                      ]}
                    >
                      {!isInitialLoad ? (
                        <Input
                          // value="Soccer Tryout"
                          addonAfter={
                            <img src={penIcon} height="22px" width="22px" />
                          }
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>
                    <div>
                      {!isInitialLoad ? (
                        <Fragment>
                          {!isPastEvent ? (
                            <Fragment>
                              <Button
                                htmlType="submit"
                                className="mr-1 btn-radius"
                                key="back"
                                type="primary"
                                loading={isLoading}
                              >
                                Save
                              </Button>

                              <Button
                                className="mr-1 btn-radius"
                                onClick={() => navigate(-1)}
                              >
                                Close
                              </Button>
                            </Fragment>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center">
                              <p className="color-warning mb-0 mr-2">
                                Past Event cannot be rescheduled
                              </p>

                              <Button
                                className="mr-1 btn-radius"
                                onClick={() => navigate(-1)}
                              >
                                Close
                              </Button>
                            </div>
                          )}

                          {/* <DropdownMenu key="more" /> */}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Skeleton.Button className="mr-1" active={true} />
                          <Skeleton.Button className="mr-1" active={true} />
                          <Skeleton.Button active={true} />
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div className="d-flex mt-5">
                    <Form.Item
                      className="mr-3 mb-0"
                      name="dateRange"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Start Date",
                        },
                      ]}
                    >
                      {!isInitialLoad ? (
                        <RangePicker
                          disabled={isPastEvent}
                          disabledDate={disabledDate}
                          format="YYYY-MM-DD"
                          onChange={dateChange}
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>
                    <Form.Item
                      className="mr-3 mb-0"
                      name="startTime"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Start Time!",
                        },
                      ]}
                    >
                      {!isInitialLoad ? (
                        <TimePicker
                          use12Hours
                          disabled={isPastEvent}
                          format="h:mm a"
                          onChange={onStartTimeChange}
                          placeholder="Start Time"
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>
                    <Form.Item
                      className="mr-3 mb-0"
                      name="endTime"
                      rules={[
                        {
                          required: true,
                          message: "Please enter End Time!",
                        },
                      ]}
                    >
                      {!isInitialLoad ? (
                        <TimePicker
                          disabled={isPastEvent}
                          use12Hours
                          format="h:mm a"
                          onChange={onEndTimeChange}
                          placeholder="End Time"
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>

                    {/* <Form.Item className="mr-3 mb-0" name="timeRange">
                      {!isInitialLoad ? (
                        <TimePicker.RangePicker
                          use12Hours
                          format="h:mm a"
                          onChange={timeChange}
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item> */}
                    <Form.Item
                      className="event-series-selection mb-0"
                      name="eventSeriesType"
                      rules={[
                        {
                          required: true,
                          message: "Please select Occurrence!",
                        },
                      ]}
                    >
                      {!isInitialLoad ? (
                        <Select allowClear defaultValue={0}>
                          {eventTypes &&
                            eventTypes.map((data: any) => {
                              return (
                                <Option key={data.value} value={data.value}>
                                  {data.name}
                                </Option>
                              );
                            })}
                        </Select>
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Row gutter={24}>
                <Col span={24}>
                  <div className="app-main-content-inner h-100-i">
                    <div className="content-container">
                      <Row>
                        <Col span={24}>
                          {/* <Tabs defaultActiveKey="1" activeKey={activeTab} type="card">
                            <TabPane tab="About" key="1">
                              <Row gutter={24}>
                                <Col
                                  span={8}
                                  xs={{ span: 24 }}
                                  md={{ span: 16 }}
                                  lg={{ span: 12 }}
                                  xl={{ span: 12 }}
                                >
                                  {!isInitialLoad ? (
                                    <LocationField
                                      className="pb-1"
                                      validationMessages={validationMessages}
                                    />
                                  ) : (
                                    <Skeleton.Input
                                      className="mb-3"
                                      active={true}
                                    />
                                  )}

                                  {!isInitialLoad ? (
                                    <ParticipantField
                                      className="pb-1"
                                      validationMessages={validationMessages}
                                      extendedView={false}
                                      form={updateEventForm}
                                    />
                                  ) : (
                                    <Skeleton.Input
                                      className="mb-3"
                                      active={true}
                                    />
                                  )}

                                  {!isInitialLoad ? (
                                    <EventAdminField
                                      className="pb-1"
                                      validationMessages={validationMessages}
                                      form={updateEventForm}
                                      max={5}
                                    />
                                  ) : (
                                    <Skeleton.Input
                                      className="mb-3"
                                      active={true}
                                    />
                                  )}

                                  {!isInitialLoad ? (
                                    <Form.Item
                                      label="Meeting Link"
                                      name="onlineLink"
                                    >
                                      <Input placeholder="Enter Meeting Link" />
                                    </Form.Item>
                                  ) : (
                                    <Skeleton.Input
                                      className="mb-3"
                                      active={true}
                                    />
                                  )}
                                  {!isInitialLoad ? (
                                    <Form.Item
                                      label="Details"
                                      className="pt-2 mb-2"
                                      name="description"
                                    >
                                      <TextArea
                                        showCount
                                        maxLength={100}
                                        style={{ height: 90 }}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <Skeleton.Input
                                      className="mb-3"
                                      active={true}
                                    />
                                  )}
                                  {!isInitialLoad ? (
                                    <Form.Item
                                      name="eventType"
                                      label="Event Type"
                                    >
                                      <Button
                                        className={`${
                                          eventType === 0 && "btn-active"
                                        }`}
                                        onClick={() => setEventType(0)}
                                      >
                                        Public
                                      </Button>
                                      <Button
                                        className={`${
                                          eventType === 1 && "btn-active"
                                        }`}
                                        onClick={() => setEventType(1)}
                                      >
                                        Private
                                      </Button>
                                    </Form.Item>
                                  ) : (
                                    <Skeleton.Input
                                      className="mb-3"
                                      active={true}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tab="QR Code" key="2" disabled={event == null || event == undefined}>
                              <EventQRPane
                                event={event}
                                readonly={false}
                              ></EventQRPane>
                            </TabPane>
                            {/* <TabPane tab="Interest" key="3">
                              <div className="softskill-distribution-widget">
                                <Row gutter={24}>
                                  <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 16 }}
                                    lg={{ span: 14 }}
                                    xl={{ span: 14 }}
                                  >
                                    <InterestField
                                      validationMessages={[]}
                                      onChange={(e: any) =>
                                        handleInterestChange(e)
                                      }
                                    />

                                    {softSkillScores.length > 0 && (
                                      <Fragment>
                                        <div className="softskill-distribution-container">
                                          <p className="pt-2">
                                            Adjust softskills distribution
                                          </p>
                                          <Form.List name="softSkillScores">
                                            {(fields, { add, remove }) => (
                                              <>
                                                {fields.map(
                                                  ({
                                                    key,
                                                    name,
                                                    ...restField
                                                  }) => (
                                                    <div className="softskill-item">
                                                      <label>
                                                        {softSkillScores &&
                                                          softSkillScores[key]
                                                            ?.name}
                                                      </label>
                                                      <Form.Item
                                                        className="mb-0"
                                                        name={[
                                                          name,
                                                          "percentage",
                                                        ]}
                                                      >
                                                        <InputNumber
                                                          onChange={(
                                                            val: any
                                                          ) => {
                                                            computeTotalSoftSkillScore();
                                                          }}
                                                          value={
                                                            softSkillScores &&
                                                            softSkillScores[key]
                                                              ?.percentage
                                                          }
                                                          min={1}
                                                          max={100}
                                                          placeholder="0%"
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  )
                                                )}
                                              </>
                                            )}
                                          </Form.List>
                                          <div className="softskill-distribution-footer pt-2">
                                            <h3
                                              className={`total-text ${
                                                totalSoftSkillScore > 100
                                                  ? "text-error"
                                                  : ""
                                              } `}
                                            >
                                              Total :{" "}
                                            </h3>
                                            <h3
                                              className={`total-text mr-7 ml-4 ${
                                                totalSoftSkillScore > 100
                                                  ? "text-error"
                                                  : ""
                                              } `}
                                            >
                                              {totalSoftSkillScore}%
                                            </h3>
                                          </div>
                                        </div>
                                      </Fragment>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </TabPane> 
                            <TabPane tab="Attendance" key="3">
                            <EventAttendancePane
                                event={event}
                                readonly={false}
                              ></EventAttendancePane>
                            </TabPane>
                            <TabPane tab="Invites" key="4">
                                {eventId && !isInitialLoad ? (
                                <ParticipantWidget eventId={eventId} />
                              ) : (
                                <Skeleton.Node className="w-100-i" active={true}>
                                  <DotChartOutlined
                                    style={{
                                      fontSize: 40,
                                      color: "#bfbfbf",
                                      width: "100%",
                                    }}
                                  />
                                </Skeleton.Node>
                              )} 
                          <EventParticipantPane
                            event={event}
                            readonly={false} 
                          ></EventParticipantPane>
                              
                            </TabPane>
                            <TabPane tab="Reviews" key="5" disabled></TabPane>
                            <TabPane tab="FAQ" key="6" disabled></TabPane>
                          </Tabs> */}
                          <div className="d-flex tab-filter mt-4">
                            <div
                              onClick={() => setSelectedTab("about")}
                              className={`tab-item mr-4 ${
                                selectedTab === "about" && "tab-active"
                              }`}
                            >
                              About
                            </div>
                            <div
                                onClick={() => setSelectedTab("qr")}
                                className={`${event===null||event===undefined && "disabled"} tab-item mr-4 ${
                                  selectedTab === "qr" && "tab-active"
                                }`}
                              >
                                QR Code
                              </div>
                            <div
                              onClick={() => setSelectedTab("attendance")}
                              className={`tab-item mr-4 ${
                                selectedTab === "attendance" && "tab-active"
                              }`}
                            >
                              Attendance
                            </div>
                            <div
                              onClick={() => setSelectedTab("invites")}
                              className={`tab-item mr-4 ${
                                selectedTab === "invites" && "tab-active"
                              }`}
                            >
                              Invites
                            </div>
                            <div
                              onClick={() => setSelectedTab("reviews")}
                              className={`disabled tab-item mr-4 ${
                                selectedTab === "reviews" && "tab-active"
                              }`}
                            >
                              Reviews
                            </div>
                            <div
                              onClick={() => setSelectedTab("faq")}
                              className={`disabled tab-item mr-4 ${selectedTab === "faq" && "tab-active"}`}
                            >
                              FAQ
                            </div>
                          </div>
                          <div style={{ marginTop: 25 }}>
                            <div
                              className="content-container event-page"
                              style={{ minHeight: 500 }}
                            >
                                {event && selectedTab === "about" && (
                                  <Row gutter={24}>
                                  <Col
                                    span={8}
                                    xs={{ span: 24 }}
                                    md={{ span: 16 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 12 }}
                                  >
                                    {!isInitialLoad ? (
                                      <LocationField
                                        className="pb-1"
                                        validationMessages={validationMessages}
                                      />
                                    ) : (
                                      <Skeleton.Input
                                        className="mb-3"
                                        active={true}
                                      />
                                    )}
  
                                    {!isInitialLoad ? (
                                      <ParticipantField
                                        className="pb-1"
                                        validationMessages={validationMessages}
                                        extendedView={false}
                                        form={updateEventForm}
                                      />
                                    ) : (
                                      <Skeleton.Input
                                        className="mb-3"
                                        active={true}
                                      />
                                    )}
  
                                    {!isInitialLoad ? (
                                      <EventAdminField
                                        className="pb-1"
                                        validationMessages={validationMessages}
                                        form={updateEventForm}
                                        max={5}
                                      />
                                    ) : (
                                      <Skeleton.Input
                                        className="mb-3"
                                        active={true}
                                      />
                                    )}
  
                                    {!isInitialLoad ? (
                                      <Form.Item
                                        label="Meeting Link"
                                        name="onlineLink"
                                      >
                                        <Input placeholder="Enter Meeting Link" />
                                      </Form.Item>
                                    ) : (
                                      <Skeleton.Input
                                        className="mb-3"
                                        active={true}
                                      />
                                    )}
                                    {!isInitialLoad ? (
                                      <Form.Item
                                        label="Details"
                                        className="pt-2 mb-2"
                                        name="description"
                                      >
                                        <TextArea
                                          showCount
                                          maxLength={100}
                                          style={{ height: 90 }}
                                        />
                                      </Form.Item>
                                    ) : (
                                      <Skeleton.Input
                                        className="mb-3"
                                        active={true}
                                      />
                                    )}
                                    {!isInitialLoad ? (
                                      <Form.Item
                                        name="eventType"
                                        label="Event Type"
                                      >
                                        <Button
                                          className={`${
                                            eventType === 0 && "btn-active"
                                          }`}
                                          onClick={() => setEventType(0)}
                                        >
                                          Public
                                        </Button>
                                        <Button
                                          className={`${
                                            eventType === 1 && "btn-active"
                                          }`}
                                          onClick={() => setEventType(1)}
                                        >
                                          Private
                                        </Button>
                                      </Form.Item>
                                    ) : (
                                      <Skeleton.Input
                                        className="mb-3"
                                        active={true}
                                      />
                                    )}
                                  </Col>
                                </Row>
                                )}
                                {event && selectedTab === "qr" && (
                                  <EventQRPane
                                    event={event}
                                    readonly={false}
                                  ></EventQRPane>
                                )}
                                {event && selectedTab === "attendance" && (
                                  <EventAttendancePane
                                    event={event}
                                    readonly={false}
                                  ></EventAttendancePane>
                                )}
                                {event && selectedTab === "invites" && (
                                  <>
                                    <EventParticipantPane
                                      event={event}
                                      readonly={false} 
                                    ></EventParticipantPane>
                                  </>
                                )}
                            </div>
                          </div>

                            <div
                              className={`${
                                validationMessages &&
                                validationMessages.error &&
                                "mb-4 has-error"
                              }`}
                            >

                            {validationMessages && validationMessages.error && (
                              <AlertContainer data={validationMessages} />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                {/* <Col span={8}>
                  <div className="app-main-content-inner">
                    <div className="content-container">
                      {eventId && !isInitialLoad ? (
                        <ParticipantWidget eventId={eventId} />
                      ) : (
                        <Skeleton.Node className="w-100-i" active={true}>
                          <DotChartOutlined
                            style={{
                              fontSize: 40,
                              color: "#bfbfbf",
                              width: "100%",
                            }}
                          />
                        </Skeleton.Node>
                      )}
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, {
  setAlert,
  updateEvent,
  getEventDetails,
})(EventView);
