import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./App.scss";
//redux
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import AppWrapper from "./AppWrapper";

store.subscribe(() => console.log("store.getstate()", store.getState()));

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <AppWrapper />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
