import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import "../../assets/scss/member_pages/modal/modal-expanding.scss";
import { ExpandOutlined } from "@ant-design/icons";
import NewTabIcon from '../../assets/images/icons/new-tab-icon.svg'

export const ModalExpanding = (props: {
  children: any;
  title: any;
  footer: any;
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: Function;
  expanded: boolean;
}) => {
  const { isOpen, isLoading, setIsOpen, children, title, footer, expanded } = props;
  const [isExpanded, setIsExpanded] = useState(false)

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  function swipe() {
    onCloseHandler();
    const url = new URL(window.location.href);
    url.searchParams.append('qr', '1');
    window.open(url);
 }

  useEffect(() => {
    if(expanded!==isExpanded){
      setIsExpanded(expanded)
    }
  }, [expanded]);
  return (
    <Modal
      className={isExpanded?"modal-fullview":"modal-smallview"}
      closable={false}
      transitionName=""
      maskTransitionName=""
      title={
        <div className={isExpanded?"modal-header-expanded":"modal-header"}>
            <h2>
                {title} {isExpanded?` - ${footer}`:''}
            </h2>
            {expanded===false &&(
              <div className={isExpanded?"btnRow-expanded":'btnRow'}>
              <Button className="icon-btn" onClick={swipe} icon={<img src={NewTabIcon}/>}/>
              <Button className="icon-btn" onClick={()=>setIsExpanded(!isExpanded)} icon={<ExpandOutlined />} style={{color:"#000"}}/>
              </div>
            )}
        </div>
      }
      visible={isOpen}
      onCancel={onCloseHandler}
      footer={footer}
    >
      {props.children}
    </Modal>
  );
};

export default ModalExpanding;
