import { Button, Col, PageHeader, Row, Space } from "antd";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddEventForm from "./AddEventForm";

const AddEvent = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const title = location?.state?.eventId ? "Edit Event" : "Add Event";
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <div className="app-main-content">
          <div className="app-main-content-inner px-0">
            <div className="d-flex-column justify-content-center align-items-center">
              <h3 className="page-title mb-6">Create School Wide Event</h3>
              <div className="w-100">
                
                  <AddEventForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddEvent;
