import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSoftSkills } from "../../../actions/softskill";

import iconExclamation from "../../../assets/images/icons/icon-exclamation.svg";
import { Link, useNavigate } from "react-router-dom";
import { Teacher } from "../../../helpers/memberTypes";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const SoftskillList = (props: { getSoftSkills: Function }) => {
  let navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a id={record.softSkillId} onClick={() => editHandler(record)}>
            Edit
          </a>
        </Space>
      ),
    },
  ];

  const { getSoftSkills } = props;
  const [softskillList, setSoftskillList]: any = useState([]);
  const [statusFilter, setStatusFilter] = useState(-1);
  const [softSkillId, setSoftSkillId] = useState();
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination]: any = useState(initialPagination);
  const [searchString, setSearchString]: any = useState("");

  const [isListLoading, setIsListLoading] = useState(false);

  const onFilterSearchHandler = (searchInput: String) => {
    setSearchString(searchInput);
  };

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
  };

  const editHandler = (record: any) => {
    navigate("/admin/softskills/add", {
      state: {
        softSkillId: record?.softSkillId,
        name: record?.name,
        description: record?.description,
      },
    });
  };

  const fetchList = async (parameters: object) => {
    setIsListLoading(true);
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        memberType: Teacher,
        status: statusFilter,
      };
    } else {
      params = { ...parameters, memberType: Teacher, status: statusFilter };
    }
    const data: any = await getSoftSkills(params);
    setSoftskillList(data.data.items);
    setPagination({
      current: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsListLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString, statusFilter]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };
  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="Softskills"
          extra={[
            <Button className="btn-radius" key="add" type="primary">
              <Link to={"/admin/softskills/add"}>Add New Softskill</Link>
            </Button>,
            // <DropdownMenu key="more" />,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    <div className="my-auto mr-auto">
                      Status:{" "}
                      <select onChange={onfilterStatusHandler}>
                        <option value="-1">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                    <div>
                      <Search
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search by Soft Skill Name"
                        style={{ width: 280 }}
                      />
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="softSkillId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      scroll={{ y: "calc(100vh - 420px)" }}
                      dataSource={softskillList}
                      loading={isListLoading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getSoftSkills })(SoftskillList);
