import { Fragment } from "react";

const ModalList = (props: any) => {
  return (
    <ul className="modal-ul">
      {props.item.map((data: any) => {
        return (
          <Fragment>
            <li>{data.name}</li>
          </Fragment>
        );
      })}
    </ul>
  );
};

export default ModalList;
