import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";
import moment from "moment";
import { Button } from "antd";
import { Col } from "antd";
import { DatePicker } from "antd";
import { DatePickerProps } from "antd";
import { Form } from "antd";
import { Input } from "antd";
import { Row } from "antd";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { getGroupCategoryListNonPaged } from "../../../actions/groupCategory";
import { getSchoolYearDetails } from "../../../actions/schoolyear";
import { addSchoolYear } from "../../../actions/schoolyear";
import { updateSchoolYear } from "../../../actions/schoolyear";
import { deleteSchoolYear } from "../../../actions/schoolyear";
import { useNavigate, useParams } from "react-router-dom";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import { RangePickerProps } from "antd/lib/date-picker";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SchoolYearForm = (props: {
  getGroupCategoryListNonPaged: Function;
  getSchoolYearDetails: Function;
  addSchoolYear: Function;
  updateSchoolYear: Function;
  deleteSchoolYear: Function;
}) => {
  const param = useParams();
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditView, setIsEditView] = useState(false);
  const [schoolYear, setSchoolYear] = useState<any>();
  const [schoolYearId, setSchoolYearId] = useState<string>();
  const [groupCategoryList, setGroupCategoryList] = useState([]);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onDateChange = (value: any, dateString: [string, string] | string) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const onFinish = async (formData: any) => {
    setIsLoading(true);
    setValidationMessages({});
    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    formData = {
      ...formData,
      schoolYearId,
      timeZoneId,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString()
    };
    let response = isEditView
      ? await props.updateSchoolYear(formData)
      : await props.addSchoolYear(formData);
    if (response.status === OK || response.status === CREATED) {
    } else if (response.status === BAD_REQUEST) {
      if (response.data) {
        handleFormErrors(response.data);
      }
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    const response = await props.deleteSchoolYear(schoolYearId);
    setIsModalDeleteVisible(false);
    setIsLoading(false);
    navigate(-1);
  };

  const onReset = () => {
    setValidationMessages({});
    if (isEditView) {
      form.setFieldsValue({
        name: schoolYear.name,
        date: [moment(schoolYear.startDate), moment(schoolYear.endDate)],
        memberGroupCategoryId: schoolYear.memberGroupCategoryId
      });
    } else {
      form.resetFields();
      setStartDate("");
      setEndDate("");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const loadSchoolYearDetails = async (_schoolYearId: string) => {
    setIsLoading(true);
    var result = await props.getSchoolYearDetails(_schoolYearId);
    setStartDate(result.data.startDate);
    setEndDate(result.data.endDate);
    setSchoolYear(result.data);
    form.setFieldsValue({
      name: result.data.name,
      date: [moment(result.data.startDate), moment(result.data.endDate)],
      memberGroupCategoryId: result.data.memberGroupCategoryId
    });
    setIsLoading(false);
  };

  const loadGroupCategory = async () => {
    const data = await props.getGroupCategoryListNonPaged();
    setGroupCategoryList(data.data);
  };

  const handleFormErrors = (data: any) => {
    var obj = {};
    for (const [key, value] of Object.entries(data)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    const initialize = async () => {
      await loadGroupCategory();
      if (param.schoolYearId) {
        setIsEditView(true);
        setSchoolYearId(param.schoolYearId);
        await loadSchoolYearDetails(param.schoolYearId);
      }
    };
    initialize();
  }, []);

  return (
    <Row>
      <Col span={24}>
        <ModalDeleteAlert
          modalTitle="School Year"
          isModalDeleteVisible={isModalDeleteVisible}
          onModalCancelHandler={() => setIsModalDeleteVisible(false)}
          deleteItemHandler={onDelete}
          isDeleteLoading={isLoading}
        />

        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="breeze-form-1 custom-alert"
        >
          <h5 className="text-head pb-3">School Year Details</h5>
          <Row>
            <Col span={8} xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 12 }}>
              <Form.Item
                className="mb-0"
                label="Label"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input School Year label!"
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        //resolve to use original rule
                        return Promise.resolve();
                      }
                      if (!isEmptyOrSpaces(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("Please input School Year label!")
                        );
                      }
                    }
                  })
                ]}
              >
                <Input placeholder="Enter School Year label" />
              </Form.Item>
              <div
                className={`mb-4 ${
                  validationMessages && validationMessages.Name && "has-error"
                }`}
              >
                {validationMessages && validationMessages.Name && (
                  <AlertContainer data={validationMessages.Name} />
                )}
              </div>
              <Form.Item
                name="date"
                label="Duration"
                rules={[
                  {
                    required: true,
                    message: "Please Select Start Date and End Date"
                  }
                ]}
              >
                <RangePicker
                  use12Hours
                  format="YYYY-MM-DD"
                  disabled={isEditView}
                  onChange={onDateChange}
                />
              </Form.Item>
              <div
                className={`mb-4 ${
                  validationMessages &&
                  validationMessages.StartDate &&
                  "has-error"
                }`}
              >
                {validationMessages && validationMessages.StartDate && (
                  <AlertContainer data={validationMessages.StartDate} />
                )}
              </div>
              <div
                className={`mb-4 ${
                  validationMessages &&
                  validationMessages.EndDate &&
                  "has-error"
                }`}
              >
                {validationMessages && validationMessages.EndDate && (
                  <AlertContainer data={validationMessages.EndDate} />
                )}
              </div>
              <Form.Item
                name="memberGroupCategoryId"
                label="For"
                rules={[
                  {
                    required: true,
                    message: "Please select Group Category"
                  }
                ]}
              >
                <Select placeholder="Select Group Category">
                  {groupCategoryList.map((item: any) => (
                    <Option
                      key={item.groupCategoryId}
                      value={item.groupCategoryId}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <div className="w-100 d-flex">
                <Button
                  className="btn-breeze-box mr-2 btn-radius"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Save School Year
                </Button>
                <Button
                  className="btn-radius"
                  disabled={isLoading}
                  onClick={onReset}
                >
                  Reset Fields
                </Button>
                {isEditView && (
                  <Button
                    className="ml-auto btn-radius"
                    disabled={isLoading}
                    onClick={() => setIsModalDeleteVisible(true)}
                    type="primary"
                    danger
                  >
                    Deactivate
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
export default connect(null, {
  getGroupCategoryListNonPaged,
  getSchoolYearDetails,
  addSchoolYear,
  updateSchoolYear,
  deleteSchoolYear
})(SchoolYearForm);
