import { Button, Col, message, Modal, PageHeader, Row, Upload } from "antd";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadProfilePicture } from "../../../actions/profile";
import {
  UserOutlined,
  MailOutlined,
  CameraOutlined,
  UploadOutlined
} from "@ant-design/icons";
import caretUpIcon from "../../../assets/images/icons/caretup.svg";
import caretDownIcon from "../../../assets/images/icons/caretdown.svg";
import gpsIcon from "../../../assets/images/icons/gps-icon.svg";
import EditProfileform from "./EditProfileForm";
import ChangePasswordForm from "./ChangePasswordForm";
import { CREATED, OK } from "../../../helpers/statuscodes";
import DefaultAvatar from "../../widget/DefaultAvatar";

const Profile = (props: { uploadProfilePicture: Function; auth: any }) => {
  const { uploadProfilePicture } = props;
  const navigate = useNavigate();
  const location: any = useLocation();
  const [tabActive, setTabActive] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [modalChangeUserPhotoIsVisible, setModalChangeUserPhotoIsVisible] =
    useState(false);
  const {
    auth: { displayName, member }
  } = props;
  const title = "My Profile";
  const handleChangePhoto = () => {
    handleUpload();
  };

  const [fileList, setFileList] = useState<any>([]);

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file: any) => {
      formData.append("imageFile", file);
    });
    setUploading(true);
    const res = await uploadProfilePicture(formData);
    if (res.status == OK || res.status == CREATED) {
      setFileList([]);
      setModalChangeUserPhotoIsVisible(false);
    }

    setUploading(false);
  };

  const uploadProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList
  };
  console.log("Member", member);

  return (
    <Fragment>
      <Modal
        className="modal-change-profile-pic"
        title="Upload Jpg or Png file (5mb maximum file size)*"
        centered
        visible={modalChangeUserPhotoIsVisible}
        onCancel={() => setModalChangeUserPhotoIsVisible(false)}
        footer={[]}
      >
        <div className="d-flex-column pb-4">
          <div className="d-flex">
            <div className="profile-pic-wrapper">
              <div className="profile-picture-container">
                <div className="profile-picture">
                  {member && member.profilePicture ? (
                    <img
                      height={79}
                      width={79}
                      src={member.profilePicture + "?" + Date.now()}
                    />
                  ) : (
                    <DefaultAvatar
                      firstName={displayName}
                      lastName={""}
                      size={79}
                    />
                  )}
                </div>
                <div className="change-photo-container">
                  <div className="change-photo">
                    <CameraOutlined />
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-5">
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Replace Profile Photo</Button>
              </Upload>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button
              key="submit-2"
              className="btn-breeze-2"
              onClick={handleChangePhoto}
              loading={uploading}
              disabled={fileList.length == 0}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => navigate(-1)}
          title={title}
          extra={[
            <Button
              key="back"
              className="btn-radius"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner pb-0">
            <Row>
              <Col span={24}>
                <div className="content-container pt-1">
                  <div className="profile-wrapper d-flex">
                    <div className="profile-pic-wrapper">
                      <div className="profile-picture-container">
                        <div className="profile-picture">
                          {member && member.profilePicture ? (
                            <img
                              height={79}
                              width={79}
                              src={member.profilePicture + "?" + Date.now()}
                            />
                          ) : (
                            <DefaultAvatar
                              firstName={displayName}
                              lastName={""}
                              size={79}
                            />
                          )}
                        </div>
                        <div className="change-photo-container">
                          <div
                            className="change-photo"
                            onClick={() =>
                              setModalChangeUserPhotoIsVisible(true)
                            }
                          >
                            <CameraOutlined />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-details">
                      <h1 className="section-title">
                        {member
                          ? member.firstName + " " + member.lastName
                          : displayName}
                      </h1>
                      <div className="d-flex profile-infos">
                        <div>
                          <UserOutlined />{" "}
                          <p>{member ? member.jobTitle : "-"}</p>
                        </div>
                        <div>
                          <img className="icon-custom" src={gpsIcon} />{" "}
                          <p> {member ? member.schoolName : "-"}</p>
                        </div>
                        <div>
                          <MailOutlined />{" "}
                          <p>{member ? member.emailAddress : "-"}</p>
                        </div>
                      </div>
                      <div className="stats-container">
                        <div className="stat">
                          <h3>
                            1000 <img src={caretUpIcon} />
                          </h3>
                          <p>Stats place holder</p>
                        </div>
                        <div className="stat">
                          <h3>
                            1000 <img src={caretUpIcon} />
                          </h3>
                          <p>Stats place holder</p>
                        </div>
                        <div className="stat">
                          <h3>
                            1000 <img src={caretDownIcon} />
                          </h3>
                          <p>Stats place holder</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex custom-tab-2">
                    <div
                      onClick={() => setTabActive(1)}
                      className={`tab-item  ${tabActive === 1 && "tab-active"}`}
                    >
                      Overview
                    </div>
                    {/* <div
                      onClick={() => setTabActive(2)}
                      className={`tab-item  ${tabActive === 2 && "tab-active"}`}
                    >
                      Option 1
                    </div>
                    <div
                      onClick={() => setTabActive(3)}
                      className={`tab-item  ${tabActive === 3 && "tab-active"}`}
                    >
                      Option 2
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="app-main-content-inner mt-20px profile-edit">
            <EditProfileform />
          </div>
          <div className="app-main-content-inner mt-20px">
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { uploadProfilePicture })(Profile);
