import axios from "axios";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";

export const getGroupCategoryListNonPaged = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`/api/GroupCategory/list`);
    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};
