export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_PUSH_DATA = "SET_PUSH_DATA";
export const REMOVE_PUSH_DATA = "REMOVE_PUSH_DATA";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_PROFILE = "PROFILE_ERROR";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const FIREBASE_REG_TOKEN = "FIREBASE_REG_TOKEN";
export const CLEAR_FIREBASE_REG_TOKEN = "CLEAR_FIREBASE_REG_TOKEN";
export const SET_USER_IS_BACK = "SET_USER_IS_BACK";

export const FLUTTER_CONNECTION_SUCCESS = "FLUTTER_CONNECTION_SUCCESS";
export const FLUTTER_CONNECTION_ERROR = "FLUTTER_CONNECTION_ERROR";

export const RECENT_LOGOUT = "RECENT_LOGOUT";
