import {
  Form,
  Input,
  Button,
  Select,
  Col,
  Row,
  Radio,
  Modal,
  DatePicker,
  TimePicker,
  Space,
  DatePickerProps,
  InputNumber,
} from "antd";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getSoftSkillsNonPaged } from "../../../actions/softskill";
import { addEvent, updateEvent, deleteEvent } from "../../../actions/event";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation, useNavigate } from "react-router-dom";
import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";
import { PlusOutlined } from "@ant-design/icons";
import PointDistributionInput from "../interest/PointDistribution";
import moment from "moment";
import LocationField from "../select-fields/LocationField";
import InterestField from "../select-fields/InterestField";
import ClubField from "../select-fields/ClubField";
import GroupField from "../select-fields/GroupField";
import { RangePickerProps } from "antd/lib/date-picker";
import {
  Daily,
  DoNotRepeat,
  eventTypes,
  Monthly,
  Weekly,
  Yearly,
} from "../../../helpers/eventTypes";
import ParticipantField from "../select-fields/ParticipantField";
import EventAdminField from "../select-fields/EventAdminField";
import TextArea from "antd/lib/input/TextArea";
import PointDistributionInputV2 from "../interest/PointDistributionV2";
import { daysTypes } from "../../../helpers/daysTypes";
import {
  Friday,
  Monday,
  Saturday,
  Sunday,
  Thursday,
  Tuesday,
  Wednesday,
} from "../../../helpers/daysofWeek";
import { monthTypes } from "../../../helpers/monthTypes";

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";
const customFormat = (value: any) => `${value.format(dateFormat)}`;

const AddEventForm = (props: {
  getSoftSkillsNonPaged: Function;
  addEvent: Function;
  updateEvent: Function;
  deleteEvent: Function;
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [addEventForm] = Form.useForm();
  const { getSoftSkillsNonPaged, addEvent, updateEvent, deleteEvent } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventSeriesType, setEventSeriesType] = useState(0);
  const [eventType, setEventType] = useState(0);
  const [monDay, setMonDay] = useState(false);
  const [tuesDay, setTuesDay] = useState(false);
  const [wednesDay, setWednesDay] = useState(false);
  const [thursDay, setThursday] = useState(false);
  const [friDay, setFriday] = useState(false);
  const [saturDay, setSaturday] = useState(false);
  const [sunDay, setSunday] = useState(false);
  const [monthRepeatType, setMonthRepeatType] = useState(1);
  const [monthYearRepeatType, setYearRepeatType] = useState(1);

  const [softSkillList, setSoftSkillList] = useState([]);
  const [isLoadingSoftSkillList, setIsLoadingSoftSkillList] = useState(false);
  const [isSoftSkillValid, setIsSoftSkillValid] = useState(true);
  const [selectedSoftSkill, setSelectedSoftSkill]: any = useState([]);
  const [selectedSkill, setSelectedSkill] = useState<{
    name: string;
    description: string;
  }>();

  const [
    isModalInterestDescriptionVisible,
    setIsModalInterestDescriptionVisible,
  ] = useState(false);

  const onFinish = async (formData: any) => {
    setIsLoading(true);

    let occurrence;
    if (eventSeriesType === Daily) {
      occurrence = {
        repeatEvery: formData.repeatEvery,
        seriesEndDate: formData.seriesEndDate,
      };
    } else if (eventSeriesType === Weekly) {
      let daysOfWeek = 0;
      monDay && (daysOfWeek += Monday);
      tuesDay && (daysOfWeek += Tuesday);
      wednesDay && (daysOfWeek += Wednesday);
      thursDay && (daysOfWeek += Thursday);
      friDay && (daysOfWeek += Friday);
      saturDay && (daysOfWeek += Saturday);
      sunDay && (daysOfWeek += Sunday);

      occurrence = {
        repeatEvery: formData.repeatEvery,
        seriesEndDate: formData.seriesEndDate,
        daysOfWeek,
      };
    } else if (eventSeriesType === Monthly) {
      if (monthRepeatType === 1) {
        occurrence = {
          repeatEvery: formData.repeatEvery,
          seriesEndDate: formData.seriesEndDate,
          specificDay: {
            onDay: formData.onDay,
          },
        };
      } else {
        occurrence = {
          repeatEvery: formData.repeatEvery,
          seriesEndDate: formData.seriesEndDate,
          customDay: {
            every: formData.every,
            dayOfWeek: formData.dayOfWeek,
          },
        };
      }
    } else if (eventSeriesType === Yearly) {
      if (monthYearRepeatType === 1) {
        occurrence = {
          repeatEvery: formData.repeatEvery,
          seriesEndDate: formData.seriesEndDate,
          specificDay: {
            onDay: formData.onDay,
            ofMonth: formData.ofMonth,
          },
        };
      } else {
        occurrence = {
          repeatEvery: formData.repeatEvery,
          seriesEndDate: formData.seriesEndDate,
          customDay: {
            every: formData.every,
            dayOfWeek: formData.dayOfWeek,
            ofMonth: formData.ofMonth,
          },
        };
      }
    }

    // return;
    setValidationMessages(new Array());
    let participants = [];
    if (formData.participants) {
      participants = formData.participants.map((item: { value: string }) => {
        let splitValue = item.value.split("-");
        return {
          id: splitValue[0],
          type: parseInt(splitValue[1]),
        };
      });
    }

    let eventAdmins = [];
    if (formData.eventAdmins) {
      eventAdmins = formData.eventAdmins.map((item: { value: string }) => {
        let splitValue = item.value.split("-");
        return {
          id: splitValue[0],
          type: parseInt(splitValue[1]),
        };
      });
    }
    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    formData = {
      ...formData,
      occurrence,
      timeZoneId,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      eventType: eventType,
      participants: participants,
      eventAdmins: eventAdmins
    };

    let res;
    if (isEditMode) {
      res = await updateEvent(
        { ...formData, eventId: location.state.eventId },
        location.state.eventId
      );
    } else {
      res = await addEvent(formData);
    }
    if (res.status == OK || res.status == CREATED) {
      if (!isEditMode) {
        // onReset();
        navigate("/admin/events/add-success", { replace: true });
      }
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteEvent(location.state.eventId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
    }
    setIsDeleteLoading(false);
  };

  const onReset = () => {
    addEventForm.resetFields();

    setEventSeriesType(0);
    addEventForm.setFieldsValue({
      eventSeriesType: 0,
    });
    setEventType(0);
    setMonDay(false);
    setTuesDay(false);
    setWednesDay(false);
    setThursday(false);
    setFriday(false);
    setSaturday(false);
    setSunday(false);

    setValidationMessages([]);
    setSelectedSoftSkill([]);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const changeSoftSkillHandler = (key: number, e: any) => {
    setSelectedSoftSkill({
      ...selectedSoftSkill,
      [key]: { key: key, id: e },
    });
  };

  const dateTimeChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const handleInterestChange = (interest: any) => {
    let count = 0;

    let softskillScoreTemp = interest?.softSkillScores.map((score: any) => {
      count++;
      return {
        key: count - 1,
        id: score.softSkillId,
      };
    });
    setSelectedSoftSkill(softskillScoreTemp);

    addEventForm.setFieldsValue({
      softSkillScores: interest?.softSkillScores,
    });
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return moment().add(-1, "days") >= current;
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = (current: any) => ({
    // disabledHours: () => range(0, 24).splice(4, 20),
    // disabledMinutes: () => range(30, 60),
    // disabledSeconds: () => [55, 56],
  });

  useEffect(() => {
    const initialize = async () => {
      setIsLoadingSoftSkillList(true);
      const softSkillData = await getSoftSkillsNonPaged();
      setSoftSkillList(softSkillData.data);
      setIsLoadingSoftSkillList(false);
      addEventForm.setFieldsValue({
        eventSeriesType: 0,
      });
    };
    initialize();
  }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Fragment>
      <ModalDeleteAlert
        modalTitle="Event"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Modal
        title={selectedSkill?.name}
        visible={isModalInterestDescriptionVisible}
        onCancel={() => setIsModalInterestDescriptionVisible(false)}
        footer={[
          <Button
            className="btn-radius"
            type="primary"
            key="back"
            onClick={() => setIsModalInterestDescriptionVisible(false)}
          >
            OK
          </Button>,
        ]}
      >
        <p>{selectedSkill?.description}</p>
      </Modal>
      <Form
        form={addEventForm}
        name="basic"
        {...layout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="breeze-form-horizontal"
      >
        <Col className="form-horizontal-container">
          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 22, offset: 0 }}
            lg={{ span: 18, offset: 1 }}
            xl={{ span: 12, offset: 4 }}
          >
            <Form.Item
              className="pb-1"
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter event Title!",
                },
              ]}
              {...(validationMessages && validationMessages.Title
                ? { validateStatus: "error" }
                : "")}
              {...(validationMessages && validationMessages.Title
                ? {
                    help: validationMessages.error
                      ? validationMessages.error
                      : validationMessages.Title,
                  }
                : "")}
            >
              <Input placeholder="Enter event title" />
            </Form.Item>
            {/* <div
              className={`mb-4 text-center ${
                validationMessages && validationMessages.Title && "has-error"
              }`}
            >
              {validationMessages && validationMessages.Title && (
                <AlertContainer data={validationMessages.Title} />
              )}
            </div> */}
            <ParticipantField
              className="pb-1"
              validationMessages={validationMessages}
              extendedView={false}
              form={addEventForm}
            />
            <EventAdminField
              className="pb-1"
              validationMessages={validationMessages}
              form={addEventForm}
              max={5}
            />
            <LocationField
              className="pb-1"
              validationMessages={validationMessages}
            />
            <Form.Item
              className="pb-1"
              label={
                <span>
                  <span>Meeting Link</span> <small>(optional)</small>
                </span>
              }
              name="onlineLink"
            >
              <Input placeholder="Enter Meeting Link" />
            </Form.Item>
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please Select Start Date and End Date",
                },
              ]}
              {...(validationMessages &&
              validationMessages.error &&
              validationMessages.error === "There is a conflict event."
                ? { validateStatus: "error" }
                : "")}
              {...(validationMessages &&
              validationMessages.error &&
              validationMessages.error === "There is a conflict event."
                ? {
                    help: validationMessages.error,
                  }
                : "")}
            >
              <RangePicker
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                use12Hours
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                onChange={dateTimeChange}
              />
            </Form.Item>
            <Form.Item
              label="Occurrence"
              name="eventSeriesType"
              rules={[
                {
                  required: true,
                  message: "Please select Occurrence!",
                },
              ]}
            >
              <Select
                disabled={isLoading}
                allowClear
                defaultValue={0}
                onChange={(val) => {
                  if (!isEditMode && val === 1) {
                    addEventForm.setFieldsValue({
                      repeatEvery: 1,
                    });
                  }
                  setEventSeriesType(val);
                }}
              >
                {eventTypes &&
                  eventTypes.map((data: any) => {
                    return <Option value={data.value}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
            {/* Daily */}
            {eventSeriesType === 1 && (
              <Fragment>
                <Form.Item
                  label="Repeat Every"
                  name="repeatEvery"
                  rules={[
                    {
                      required: true,
                      message: "Please input Occurrence!",
                    },
                  ]}
                >
                  <Select disabled={isLoading} defaultValue={1}>
                    {Array(8)
                      .fill(0)
                      .map((_, i) => {
                        if (i !== 0) {
                          return (
                            <Option key={i} value={i}>
                              {i} Day
                            </Option>
                          );
                        }
                      })}
                    );
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Series End Date"
                  name="seriesEndDate"
                  rules={[
                    {
                      required: true,
                      message: "Please input Series End Date!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=" Select Series End Date"
                    format={customFormat}
                  />
                </Form.Item>
              </Fragment>
            )}
            {/* Weekly */}
            {eventSeriesType === 2 && (
              <Fragment>
                <Form.Item
                  className="w-100"
                  label="Repeat Every Week"
                  name="repeatEvery"
                  rules={[
                    {
                      required: true,
                      message: "Please input Weekly Occurrence!",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    min={1}
                    placeholder="e.g 1,2,3 weeks"
                  />
                </Form.Item>
                <Form.Item label="Days Of Week">
                  <Button
                    className={`focus-none ${monDay && "btn-active"} mr-1 mb-1`}
                    onClick={() => setMonDay(!monDay)}
                  >
                    Monday
                  </Button>
                  <Button
                    className={`focus-none ${
                      tuesDay && "btn-active"
                    } mr-1 mb-1`}
                    onClick={() => setTuesDay(!tuesDay)}
                  >
                    Tuesday
                  </Button>
                  <Button
                    className={`focus-none ${
                      wednesDay && "btn-active"
                    } mr-1 mb-1`}
                    onClick={() => setWednesDay(!wednesDay)}
                  >
                    Wednesday
                  </Button>
                  <Button
                    className={`focus-none ${
                      thursDay && "btn-active"
                    } mr-1 mb-1`}
                    onClick={() => setThursday(!thursDay)}
                  >
                    Thursday
                  </Button>
                  <Button
                    className={`focus-none ${friDay && "btn-active"} mr-1 mb-1`}
                    onClick={() => setFriday(!friDay)}
                  >
                    Friday
                  </Button>
                  <Button
                    className={`focus-none ${
                      saturDay && "btn-active"
                    } mr-1 mb-1`}
                    onClick={() => setSaturday(!saturDay)}
                  >
                    Saturday
                  </Button>
                  <Button
                    className={`focus-none ${sunDay && "btn-active"}`}
                    onClick={() => setSunday(!sunDay)}
                  >
                    Sunday
                  </Button>
                </Form.Item>
                <Form.Item
                  label="Series End Date"
                  name="seriesEndDate"
                  rules={[
                    {
                      required: true,
                      message: "Please input Series End Date!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=" Select Series End Date"
                    format={customFormat}
                  />
                </Form.Item>
              </Fragment>
            )}
            {/* Monthly */}
            {eventSeriesType === 3 && (
              <Fragment>
                <Form.Item
                  className="w-100"
                  label="Repeat Every Month"
                  name="repeatEvery"
                  rules={[
                    {
                      required: true,
                      message: "Please input Monthly Occurrence!",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    min={1}
                    placeholder="e.g 1,2,3 Month"
                  />
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  label={
                    <Radio
                      value={1}
                      checked={monthRepeatType === 1 && true}
                      onChange={(e) => setMonthRepeatType(e.target.value)}
                    >
                      On day
                    </Radio>
                  }
                  rules={[
                    {
                      required: monthRepeatType === 1 && true,
                      message: "Please input Monthly Occurrence!",
                    },
                  ]}
                >
                  <Form.Item
                    className="w-100"
                    name="onDay"
                    rules={[
                      {
                        required: monthRepeatType === 1 && true,
                        message: "Please input Monthly Occurrence!",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={monthRepeatType === 2 && true}
                      className="w-100"
                      min={1}
                      placeholder="Date of month"
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  label={
                    <Radio
                      value={2}
                      checked={monthRepeatType === 2 && true}
                      onChange={(e) => {
                        if (!isEditMode) {
                          addEventForm.setFieldsValue({
                            every: 1,
                            dayOfWeek: 0,
                          });
                        }
                        setMonthRepeatType(e.target.value);
                      }}
                    >
                      On the
                    </Radio>
                  }
                >
                  <Input.Group>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item
                          name="every"
                          rules={[
                            {
                              required: monthRepeatType === 2 && true,
                            },
                          ]}
                        >
                          <Select
                            defaultValue={1}
                            disabled={monthRepeatType === 1 && true}
                          >
                            <Option key={1} value={1}>
                              First
                            </Option>
                            <Option key={2} value={2}>
                              Second
                            </Option>
                            <Option key={3} value={3}>
                              Third
                            </Option>
                            <Option key={4} value={4}>
                              Fourth
                            </Option>
                            <Option key={0} value={0}>
                              Last
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="dayOfWeek"
                          rules={[
                            {
                              required: monthRepeatType === 2 && true,
                            },
                          ]}
                        >
                          <Select
                            defaultValue={0}
                            disabled={monthRepeatType === 1 && true}
                          >
                            {daysTypes &&
                              daysTypes.map((data: any) => {
                                return (
                                  <Option key={data.value} value={data.value}>
                                    {data.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  label="Series End Date"
                  name="seriesEndDate"
                  rules={[
                    {
                      required: true,
                      message: "Please input Series End Date!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=" Select Series End Date"
                    format={customFormat}
                  />
                </Form.Item>
              </Fragment>
            )}
            {/* Yearly */}
            {eventSeriesType === 4 && (
              <Fragment>
                <Form.Item
                  className="mb-0"
                  label={
                    <Radio
                      value={1}
                      checked={monthYearRepeatType === 1 && true}
                      onChange={(e) => setYearRepeatType(e.target.value)}
                    >
                      On
                    </Radio>
                  }
                  rules={[
                    {
                      required: monthYearRepeatType === 1 && true,
                      message: "Please input Monthly Occurrence!",
                    },
                  ]}
                >
                  <Input.Group>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item
                          name="ofMonth"
                          rules={[
                            {
                              required: monthYearRepeatType === 2 && true,
                            },
                          ]}
                        >
                          <Select
                            defaultValue={1}
                            disabled={monthYearRepeatType === 2 && true}
                          >
                            {monthTypes &&
                              monthTypes.map((data: any) => {
                                return (
                                  <Option value={data.value}>
                                    {data.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="w-100"
                          name="onDay"
                          rules={[
                            {
                              required: monthYearRepeatType === 1 && true,
                              message: "Please input Monthly Occurrence!",
                            },
                          ]}
                        >
                          <InputNumber
                            disabled={monthYearRepeatType === 2 && true}
                            className="w-100"
                            min={1}
                            max={31}
                            placeholder="Date of month"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  label={
                    <Radio
                      value={2}
                      checked={monthYearRepeatType === 2 && true}
                      onChange={(e) => {
                        if (!isEditMode) {
                          addEventForm.setFieldsValue({
                            every: 1,
                            dayOfWeek: 0,
                          });
                        }
                        setYearRepeatType(e.target.value);
                      }}
                    >
                      On the
                    </Radio>
                  }
                >
                  <Input.Group>
                    <Row gutter={12}>
                      <Col span={8}>
                        <Form.Item
                          name="every"
                          rules={[
                            {
                              required: monthYearRepeatType === 2 && true,
                            },
                          ]}
                        >
                          <Select
                            defaultValue={1}
                            disabled={monthYearRepeatType === 1 && true}
                          >
                            <Option key={1} value={1}>
                              First
                            </Option>
                            <Option key={2} value={2}>
                              Second
                            </Option>
                            <Option key={3} value={3}>
                              Third
                            </Option>
                            <Option key={4} value={4}>
                              Fourth
                            </Option>
                            <Option key={0} value={0}>
                              Last
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="dayOfWeek"
                          rules={[
                            {
                              required: monthYearRepeatType === 2 && true,
                            },
                          ]}
                        >
                          <Select
                            defaultValue={0}
                            disabled={monthYearRepeatType === 1 && true}
                          >
                            {daysTypes &&
                              daysTypes.map((data: any) => {
                                return (
                                  <Option key={data.value} value={data.value}>
                                    {data.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="of"
                          name="ofMonth"
                          rules={[
                            {
                              required: monthYearRepeatType === 1 && true,
                            },
                          ]}
                        >
                          <Select
                            defaultValue={1}
                            disabled={monthYearRepeatType === 1 && true}
                          >
                            {monthTypes &&
                              monthTypes.map((data: any) => {
                                return (
                                  <Option value={data.value}>
                                    {data.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  label="Series End Date"
                  name="seriesEndDate"
                  rules={[
                    {
                      required: true,
                      message: "Please input Series End Date!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=" Select Series End Date"
                    format={customFormat}
                  />
                </Form.Item>
              </Fragment>
            )}
            <Form.Item label="Details" className="pt-2 mb-2" name="description">
              <TextArea showCount maxLength={1000} style={{ height: 90 }} />
            </Form.Item>

            <Form.Item name="eventType" label="Event Type">
              <Button
                className={`${eventType === 0 && "btn-active"}`}
                onClick={() => setEventType(0)}
              >
                Public
              </Button>
              <Button
                className={`${eventType === 1 && "btn-active"}`}
                onClick={() => setEventType(1)}
              >
                Private
              </Button>
            </Form.Item>
            <InterestField
              validationMessages={[]}
              onChange={(e: any) => handleInterestChange(e)}
            />
            <Row>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 22, offset: 2 }}
                lg={{ span: 18, offset: 6 }}
                xl={{ span: 18, offset: 6 }}
              >
                <h5 className="text-head pb-3">Points Distribution</h5>

                <Form.List name="softSkillScores">
                  {(fields, { add, remove }) => (
                    <>
                      <div className="point-list">
                        {fields.map(({ key, name, ...restField }) => (
                          <PointDistributionInput
                            key={key}
                            name={name}
                            restField={restField}
                            remove={remove}
                            softSkillList={softSkillList}
                            isLoadingSoftSkillList={isLoadingSoftSkillList}
                            changeSoftSkillHandler={changeSoftSkillHandler}
                            selectedSoftSkill={selectedSoftSkill}
                            setSelectedSkill={setSelectedSkill}
                          />
                        ))}
                      </div>
                      
                      <Form.Item>
                        <Button
                          key="submit"
                          className="btn-breeze-box-radius w-auto btn-radius"
                          type="primary"
                          onClick={() => {
                            add();
                            setIsSoftSkillValid(true);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Soft Skill
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>

            {/* <Form.List name="softSkillScores">
              {(fields, { add, remove }) => (
                <Fragment>
                  {fields.map(({ key, name, ...restField }) => (
                    <Form.Item
                      label={`${softSkillScores[key].name}`}
                      className="mb-2 w-100"
                      name={[name, "percentage"]}
                    >
                      <InputNumber
                        className="w-100"
                        value={softSkillScores[key].percentage}
                        min={1}
                        max={100}
                        placeholder="0%"
                      />
                    </Form.Item>
                  ))}
                </Fragment>
              )}
            </Form.List> */}

            <div
              className={`${
                validationMessages &&
                validationMessages.error &&
                validationMessages.error !== "There is a conflict event." &&
                "mb-4 has-error"
              }`}
            >
              {validationMessages &&
                validationMessages.error &&
                validationMessages.error !== "There is a conflict event." && (
                  <AlertContainer data={validationMessages} />
                )}
            </div>
          </Col>
        </Col>

        <div className="sticky-form-footer">
          <Row gutter={24}>
            <Col span={24}>
              <div className="w-100 d-flex mt-3">
                <Button
                  className="btn-breeze-box-radius mr-2 px-10 btn-radius"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Save Event
                </Button>
                <Button
                  className="btn-breeze-box-radius-white px-10 btn-radius"
                  onClick={onReset}
                >
                  Reset Fields
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  );
};

export default connect(null, {
  addEvent,
  deleteEvent,
  updateEvent,
  getSoftSkillsNonPaged,
})(AddEventForm);
