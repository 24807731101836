import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Form, Button, Input, Spin, Row, Col, Alert } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NOT_FOUND, OK } from "../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../helpers/whitespaces";
import breezeWhite from "./../../assets/images/logos/breeze-white.svg";
import signupImage from "./../../assets/images/signup-img.svg";
import AlertContainer from "../../layouts/AlertContainer";

const SignUp = (props: any) => {
  const { onSetEmailData } = props;
  const [isLoading, setIsloading] = useState(false);
  const [validationStatus, setValidationStatus]: any = useState("");
  const [validationMessages, setValidationMessages]: any = useState([]);
  const [messageGreeting, setMessageGreeting]: any = useState("");

  const onFinish = (values: any) => {
    setValidationMessages(new Array());
    console.log("Success:", values);
    setIsloading(true);
    verifyEmail(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const verifyEmail = async (formData: any) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(`/api/Account/register`, formData, config);

      if (res.status === OK) {
        onSetEmailData(res.data);
        return;
      }
    } catch (err: any) {
      console.log(err.response);

      if (err.response.status === NOT_FOUND) {
        setValidationMessages({
          message: "The email you submitted does not exist in our system.",
        });
        setValidationStatus("error");
      } else {
        setValidationMessages({
          message: err.response.data.error,
        });
      }
    }

    setIsloading(false);
  };

  const clearValidation = () => {
    validationMessages(new Array());
    setValidationStatus("");
  };

  useEffect(() => {
    let myDate = new Date();
    let hrs = myDate.getHours();

    if (hrs < 12) {
      setMessageGreeting("Good morning");
    } else if (hrs >= 12 && hrs <= 17) {
      setMessageGreeting("Good afternoon");
    } else if (hrs >= 17 && hrs <= 24) {
      setMessageGreeting("Good evening");
    }
  }, []);

  return (
    <Row>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 10, offset: 7 }}
        xxl={{ span: 8, offset: 7 }}
      >
        <div className="form-container">
          <h2 className="form-title">{messageGreeting}!</h2>
          <h5 className="sub-title">Create your Breeze Account</h5>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            className="custom-alert-simple"
          >
            <div
              className={`mb-3 force-alert ${
                validationMessages != 0 && "has-error"
              }`}
            >
              {validationMessages.length != 0 && (
                <AlertContainer data={validationMessages} />
              )}
            </div>
            <label className="mb-2">School Email Address</label>

            <Form.Item
              name="emailAddress"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The email you entered is invalid",
                },
              ]}
            >
              <Input className="mt-1" placeholder="johndoe@lpu.edu.ph" />
            </Form.Item>
            <Form.Item>
              <Button
                className="btn-breeze-box-radius mt-2"
                disabled={isLoading}
                htmlType="submit"
                loading={isLoading}
              >
                Verify Email
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default SignUp;
