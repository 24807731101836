export const eventTypes = [
  { value: 0, name: "Do Not Repeat" },
  { value: 1, name: "Daily" },
  { value: 2, name: "Weekly" },
  { value: 3, name: "Monthly" },
  { value: 4, name: "Yearly" },
];

export const DoNotRepeat = 0;
export const Daily = 1;
export const Weekly = 2;
export const Monthly = 3;
export const Yearly = 4;

export const mainEventType = ["Public", "Private", "Close"];
