import { Button, Modal } from "antd";
import iconExclamation from "../../assets/images/icons/icon-exclamation.svg";

const ModalDeleteAlert = (props: {
  modalTitle: String;
  isModalDeleteVisible: boolean;
  onModalCancelHandler: Function;
  deleteItemHandler: Function;
  isDeleteLoading: boolean;
}) => {
  return (
    <Modal
      className="modal-notify"
      centered
      visible={props.isModalDeleteVisible}
      onCancel={() => props.onModalCancelHandler()}
      footer={[]}
    >
      <div className="d-flex">
        <div>
          <img src={iconExclamation} />
        </div>
        <div className="d-flex-column ml-2 w-100">
          <div className="mod-title">Deactivate {props.modalTitle}?</div>
          <div className="mt-1">
            <p className="modal-message mt-1">Are you sure?</p>
            <div className="d-flex justify-content-space-between">
              <Button
                key="submit"
                className="btn-breeze-no-bg"
                onClick={() => props.onModalCancelHandler()}
              >
                Go Back
              </Button>
              <Button
                key="submit-2"
                className="btn-breeze-2"
                onClick={() => props.deleteItemHandler()}
                loading={props.isDeleteLoading}
                disabled={props.isDeleteLoading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteAlert;
