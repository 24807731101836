import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Col, Form, Checkbox, Modal, Row, Collapse } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  getClubListImNotPartOf,
  getMyClubs,
  getMyClubsGrouped,
  getMyProposedClubs,
  handleClubInvitation,
  getSuggestedClubList,
  getOtherClubList,
} from "../../../actions/profile";
import {
  getClubListForStudentAndMod,
  leaveClub,
  joinClub,
} from "../../../actions/club";
import { getInterestListNonPaged } from "../../../actions/interest";

import GroupItemGrid from "./GroupItemGrid";
import { ClubItem, MyClubItem } from "../../../interface/Club";
import { Button, Tooltip } from "antd";
import {
  AppstoreOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  FilterOutlined,
  DownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import clubEmpty from "../../../assets/images/club-empty.svg";
import successIcon from "../../../assets/images/icons/success-icon.svg";
import GroupItemList from "./GroupItemList";
import { useNavigate } from "react-router-dom";
import { BAD_REQUEST, NO_CONTENT, OK } from "../../../helpers/statuscodes";
import TextArea from "antd/lib/input/TextArea";
import { userPermissions } from "../../../permissions/user-permissions";
import MyClubItemGrid from "./MyClubItemGrid";
import MyClubsItemList from "./MyClubsItemList";
import MiniPageHeader from "../layouts/MiniPageHeader";
import ModalDeclineReason from "../Modals/ModalDeclineReason";
import ModalSuccess from "../Modals/ModalSuccess";
const { Panel } = Collapse;
interface SelectItem {
  label: string;
  value: string;
}
const GroupList = (props: {
  auth: any;
  getMyClubs: Function;
  getMyClubsGrouped: Function;
  getClubListForStudentAndMod: Function;
  getClubListImNotPartOf: Function;
  leaveClub: Function;
  handleClubInvitation: Function;
  getMyProposedClubs: Function;
  getSuggestedClubList: Function;
  getOtherClubList: Function;
  joinClub: Function;
  getInterestListNonPaged: Function;
  interests: any;
  pushData: any;
  flutterConnect: any;
}) => {
  const {
    auth: { member },
    getMyClubsGrouped,
    leaveClub,
    handleClubInvitation,
    getSuggestedClubList,
    getOtherClubList,
    joinClub,
    getInterestListNonPaged,
    pushData,
    flutterConnect
  } = props;
  const initialPagination = {
    current: 1,
  };
  const navigate = useNavigate();

  const PAGE_SIZE = 9;
  const [pagination, setPagination] = useState(initialPagination);
  const [suggestedClubPagination, setSuggestedClubPagination] =
    useState(initialPagination);
  const [otherClubPagination, setOtherClubPagination] =
    useState(initialPagination);
  const [isSuggestedClubListLoading, setIsSuggestedClubListLoading] =
    useState(false);
  const [isOtherClubListLoading, setIsOtherClubListLoading] = useState(false);
  const [suggestedClubTotal, setSuggestedClubTotal] = useState(0);
  const [otherClubTotal, setOtherClubTotal] = useState(0);

  const [searchString, setSearchString] = useState("");
  const [clubs, setClubs]: any = useState([]);
  const [suggestedClubs, setSuggestedClubs]: any = useState([]);
  const [otherClubs, setOtherClubs]: any = useState([]);
  const [actionIsLoading, setActionIsLoading] = useState(false);

  const [myClubs, setMyClubs] = useState<MyClubItem[]>([]);
  const [clubFilter, setClubFilter] = useState(1);
  const [isViewType, setIsViewType] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false);
  const [suggestedClubHasMorePage, setSuggestedClubHasMorePage] =
    useState(false);
  const [otherClubHasMorePage, setOtherClubHasMorePage] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHandleJoinModalOpen, setIsHandleJoinModalOpen] = useState(false);
  const [isDeclineJoinModalOpen, setIsDeclineJoinModalOpen] = useState(false);
  const [isJoinModalText, setJoinModalText] = useState("");
  const [isLoadingLeaveClub, setIsLoadingLeaveClub] = useState(false);
  const [isLoadingDeclineJoinClub, setIsLoadingDeclineJoinClub] =
    useState(false);

  const [memberGroupId, setMemberGroupId] = useState("");
  const [selectedClubName, setSelectedClubName] = useState("");

  const [clubParams, setClubParams]: any = useState([]);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalType, setModalType] = useState("");

  const [declineClubForm] = Form.useForm();

  const [selectedClubType, setSelectedClubType]: any = useState("myClub");
  const [selectedClubPrivacy, setSelectedClubPrivacy]: any = useState([
    0, 1, 2,
  ]);
  const [selectedClubInterest, setSelectedClubInterest]: any = useState([]);
  const [isInterestLoaded, setIsInterestLoaded] = useState(false);
  const [interestOptions, setInterestOptions] = useState<SelectItem[]>();
  const [moreInterestOptions, setMoreInterestOptions] = useState<SelectItem[]>(
    []
  );
  const [isFilterShown, setIsFilterShown] = useState(false);

  const optionsForClubType = [
    { label: "My Club", value: "myClub" },
    { label: "Search Club", value: "searchClub" },
  ];

  const optionsForClubPrivacy = [
    { label: "Public", value: 0 },
    { label: "Private", value: 1 },
    { label: "Closed", value: 2 },
  ];

  const onChangeClubType = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues?.length > 0) {
      setSelectedClubType(checkedValues[0]);
    }
  };

  const onChangeClubPrivacy = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues?.length > 0) {
      setSelectedClubPrivacy(checkedValues);
    }
  };

  const onChangeClubInterest = (checkedValues: CheckboxValueType[]) => {
    setSelectedClubInterest(checkedValues);
  };

  const moreInterestsTrigger = () => {
    setInterestOptions((prev: any) => [...prev, ...moreInterestOptions]);
    setMoreInterestOptions([]);
  };

  const controller = new AbortController();

  const fetchAllClubs = async (paginationParam: any, isMore: boolean) => {
    setIsListLoading(true);
    if (paginationParam.current === 1) {
      setClubs([]);
    }

    //my clubs
    if (selectedClubType === "myClub") {
      let params = {
        searchString,
        privacyTypes: selectedClubPrivacy.toString(),
        interests: selectedClubInterest.toString(),
      };
      let res = await getMyClubsGrouped(params, controller);
      const formattedData = res?.data?.map((item: any) => {
        // const category = item.category;
        let clubs = item.clubs.map((clubitem: any) => {
          return {
            ...clubitem,
            loading: false,
          };
        });
        return {
          category: item.category,
          clubs,
        };
      });
      setClubs(formattedData);
      setIsListLoading(false);
    } else {
      fetchSuggestedClubs(paginationParam);
      fetchOtherClubs(paginationParam);
    }
  };

  const fetchSuggestedClubs = async (paginationParam: any) => {
    if (paginationParam.current === 1) {
      setSuggestedClubs([]);
    }
    setIsSuggestedClubListLoading(true);
    let params = {
      page: paginationParam.current,
      pageSize: PAGE_SIZE,
      searchString,
      privacyTypes: selectedClubPrivacy.toString(),
      interests: selectedClubInterest.toString(),
    };
    let resSuggested = await getSuggestedClubList(params);

    let items = resSuggested.data.items;
    if (items && items?.length !== 0) {
      setSuggestedClubTotal(resSuggested.data.totalItems);
      if (paginationParam.current !== 1) {
        const formattedData = items.map((item: ClubItem) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setSuggestedClubs(suggestedClubs.concat(formattedData));
      } else {
        const formattedData = items.map((item: ClubItem) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setSuggestedClubs(formattedData);
      }

      const _hasMorePages =
        Math.ceil(resSuggested.data.totalItems / PAGE_SIZE) >
        paginationParam.current;
      setSuggestedClubHasMorePage(_hasMorePages);
      setSuggestedClubPagination({
        current: paginationParam.current + 1,
      });
    }
    setIsSuggestedClubListLoading(false);
  };

  const fetchOtherClubs = async (paginationParam: any) => {
    if (paginationParam.current === 1) {
      setOtherClubs([]);
    }
    setIsOtherClubListLoading(true);
    let params = {
      page: paginationParam.current,
      pageSize: PAGE_SIZE,
      searchString,
      privacyTypes: selectedClubPrivacy.toString(),
      interests: selectedClubInterest.toString(),
    };
    let resOther = await getOtherClubList(params);

    let items = resOther.data.items;
    if (items && items?.length !== 0) {
      setOtherClubTotal(resOther.data.totalItems);
      if (paginationParam.current !== 1) {
        const formattedData = items.map((item: ClubItem) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setOtherClubs(otherClubs.concat(formattedData));
      } else {
        const formattedData = items.map((item: ClubItem) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setOtherClubs(formattedData);
      }
      const _hasMorePages =
        Math.ceil(resOther.data.totalItems / PAGE_SIZE) >
        paginationParam.current;
      setOtherClubHasMorePage(_hasMorePages);
      setOtherClubPagination({
        current: paginationParam.current + 1,
      });
      // const _hasMorePages =
      //   Math.ceil(resSuggested.data.totalItems / PAGE_SIZE) >
      //   pagination.current;
      // setHasMorePage(_hasMorePages);
    } else {
      setOtherClubs([]);
    }
    setIsOtherClubListLoading(false);
  };

  const fetchInterestList = async () => {
    const data = await getInterestListNonPaged();
    let myInterests = props.interests
      ? props.interests.map((data: any) => {
          return data.interestId;
        })
      : [];
    let firstOnList = data.data.filter((item: any) => {
      if (myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let nextOnList = data.data.filter((item: any) => {
      if (!myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let mergedList = firstOnList.concat(nextOnList).map((item: any) => ({
      label: item.name,
      value: item.interestId,
    }));
    let result = mergedList;
    result = result.slice(0, 5);
    setMoreInterestOptions(mergedList.slice(5));
    // const result = data.data.map((item: any) => ({
    //   label: item.name,
    //   value: item.interestId,
    // }));
    setInterestOptions(result);
    // uncomment to enable auto select
    // let selectedInterest = data.data
    //   .filter((item: any) => {
    //     if (myInterests.includes(item.interestId)) {
    //       return item;
    //     }
    //   })
    //   .map((item: any) => item.interestId);
    // setSelectedClubInterest(selectedInterest);
    setIsInterestLoaded(true);
  };

  const handleInvitationRequests = (
    memberGroupId: String,
    loading: boolean,
    type: String
  ) => {
    const formattedData = clubs.map((item: any) => {
      // const category = item.category;
      let clubs = item.clubs.map((clubitem: any) => {
        if (clubitem.memberGroupId === memberGroupId) {
          return {
            ...clubitem,
            loading,
          };
        } else {
          return {
            ...clubitem,
            loading: false,
          };
        }
      });
      return {
        category: item.category,
        clubs,
      };
    });
    setClubs(formattedData);
  };

  const setLoadingToObject = (id: string, obj: any, type: boolean) => {
    return obj.map((item: any) => {
      if (item.memberGroupId === id) {
        return {
          ...item,
          loading: type,
        };
      } else {
        return item;
      }
    });
  };

  const onRemovetoObject = (id: string, obj: any) => {
    return obj.filter((clubitem: any) => {
      if (clubitem.memberGroupId !== id) {
        return {
          clubitem,
        };
      }
    });
  };

  const handleJoinClub = async (memberGroupId: string) => {
    if (suggestedClubs.find((i: any) => i.memberGroupId === memberGroupId)) {
      setSuggestedClubs(
        setLoadingToObject(memberGroupId, suggestedClubs, true)
      );
    } else if (otherClubs.find((i: any) => i.memberGroupId === memberGroupId)) {
      setOtherClubs(setLoadingToObject(memberGroupId, otherClubs, true));
    }
    const res = await joinClub(memberGroupId);
    if (res.status == OK) {
      if (suggestedClubs.find((i: any) => i.memberGroupId === memberGroupId)) {
        setSuggestedClubs(onRemovetoObject(memberGroupId, suggestedClubs));
      } else if (
        otherClubs.find((i: any) => i.memberGroupId === memberGroupId)
      ) {
        setOtherClubs(onRemovetoObject(memberGroupId, otherClubs));
      }
    } else if (res.status === BAD_REQUEST) {
      setModalType("failed");
      setSuccessMessage(res.data.error);
      setIsModalSuccessOpen(true);
    }
    setSuggestedClubs(setLoadingToObject(memberGroupId, suggestedClubs, false));
    setOtherClubs(setLoadingToObject(memberGroupId, otherClubs, false));
    // here
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onHandleConfirm = (clubItem: any) => {
    setMemberGroupId(clubItem.memberGroupId);
    setSelectedClubName(clubItem.name);
    setIsModalOpen(true);
  };

  const onHandleClubInvitation = async (e: any, params: any) => {
    e.stopPropagation();
    // OR
    e.preventDefault();
    setActionIsLoading(true);
    handleInvitationRequests(params.clubId, true, "");

    const res = await handleClubInvitation(params);
    setClubParams(params);
    if (res.status == OK) {
      let formattedData = clubs
        .map((item: any) => {
          // const category = item.category;
          let clubs = item.clubs.filter((clubitem: any) => {
            if (clubitem.memberGroupId !== params.clubId) {
              return {
                clubitem,
              };
            }
          });

          return {
            category: item.category,
            clubs,
          };
        })
        .map((item: any) => {
          // const category = item.category;
          if (item.category == "Joined Clubs") {
            return {
              category: item.category,
              clubs: item.clubs.concat(res.data),
            };
          } else {
            return item;
          }
        });
      setClubs(formattedData);

      setJoinModalText(
        params.type === "accept"
          ? `You have successfully became a ${params.clubName} member.`
          : `You have successfully submitted decline reason for ${params.clubName} invitation.`
      );
      setIsHandleJoinModalOpen(true);
    } else {
      handleInvitationRequests(params.clubId, false, "");
    }
    setActionIsLoading(false);
  };

  const onHandleDeclineClubInvitation = async (e: any, params: any) => {
    e.stopPropagation();
    // OR
    e.preventDefault();
    setClubParams(params);
    setIsDeclineJoinModalOpen(true);
  };

  const onDeclineForm = async (formData: any) => {
    setIsLoadingDeclineJoinClub(true);
    let values = {
      ...formData,
      clubId: clubParams.clubId,
      type: clubParams.type,
    };
    setActionIsLoading(true);
    const res = await handleClubInvitation(values);
    if (res.status == OK) {
      setIsDeclineJoinModalOpen(false);
      declineClubForm.resetFields();

      let formattedData = clubs.map((item: any) => {
        // const category = item.category;
        let clubs = item.clubs.filter((clubitem: any) => {
          if (clubitem.memberGroupId !== clubParams.clubId) {
            return {
              clubitem,
            };
          }
        });

        return {
          category: item.category,
          clubs,
        };
      });

      let formattedData2 = formattedData.map((item: any) => {
        if (item.category == "Declined Club Proposals") {
          return {
            category: item.category,
            clubs: item.clubs.concat(res.data),
          };
        } else {
          return item;
        }
      });

      setClubs(formattedData2);

      setJoinModalText(
        `You have successfully submitted decline reason for ${clubParams.clubName} invitation.`
      );
      setIsHandleJoinModalOpen(true);
    }
    setActionIsLoading(false);
    setIsLoadingDeclineJoinClub(false);
  };

  const leaveGroupHandle = async () => {
    setIsLoadingLeaveClub(true);
    const res = await leaveClub({
      memberId: member.memberId,
      clubId: memberGroupId,
    });

    if (res.status == NO_CONTENT) {
      let formattedData = clubs.map((item: any) => {
        let clubs = item.clubs.filter((clubitem: any) => {
          if (clubitem.memberGroupId !== memberGroupId) {
            return {
              clubitem,
            };
          }
        });

        return {
          category: item.category,
          clubs,
        };
      });
      setClubs(formattedData);
      setIsModalOpen(false);
    }
    setIsLoadingLeaveClub(false);
  };

  const viewMoreHandler = (pageType: any) => {
    if (pageType.groupTitle == "Suggested") {
      fetchSuggestedClubs(suggestedClubPagination);
    } else if (pageType.groupTitle == "Others") {
      fetchOtherClubs(otherClubPagination);
    }
  };
  useEffect(() => {
    fetchInterestList();
  }, []);

  useEffect(() => {
    if (isInterestLoaded) {
      setSuggestedClubs([]);
      setOtherClubs([]);
      setPagination(initialPagination);
      setSuggestedClubPagination(initialPagination);
      setOtherClubPagination(initialPagination);
      setSuggestedClubHasMorePage(false);
      setOtherClubHasMorePage(false);
      setSuggestedClubTotal(0);
      setOtherClubTotal(0);
      fetchAllClubs(initialPagination, false);
    }
    return () => {
      controller.abort();
    };
  }, [
    searchString,
    selectedClubType,
    selectedClubPrivacy,
    selectedClubInterest,
    isInterestLoaded,
  ]);

  useEffect(() => {
    if (pushData.data?.topic === "ClubUpdate") {
      const pushInfo = JSON.parse(pushData.data?.pushData);
      if (pushData.data?.memberId !== member.memberId) {
        setSuggestedClubs([]);
        setOtherClubs([]);
        setPagination(initialPagination);
        setSuggestedClubPagination(initialPagination);
        setOtherClubPagination(initialPagination);
        setSuggestedClubHasMorePage(false);
        setOtherClubHasMorePage(false);
        setSuggestedClubTotal(0);
        setOtherClubTotal(0);
        fetchAllClubs(initialPagination, false);
      }
    }
  }, [pushData]);

  let Permissions = {
    canCreateClub:
      userPermissions[member.memberType] &&
      userPermissions[member.memberType]?.createClub,
  };

  return (
    <Fragment>
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
        type={modalType}
      >
        <p className="text-center">{successMessage}</p>
      </ModalSuccess>
      <Modal
        title="Confirmation"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            shape="round"
            key="submit"
            loading={isLoadingLeaveClub}
            onClick={() => leaveGroupHandle()}
          >
            Yes
          </Button>,
          <Button
            shape="round"
            key="cancel"
            onClick={() => setIsModalOpen(false)}
          >
            No
          </Button>,
        ]}
      >
        <p className="mb-0">
          Are you sure you want to leave the {selectedClubName}?
        </p>
      </Modal>

      <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={onDeclineForm}
        isOpen={isDeclineJoinModalOpen}
        setIsOpen={setIsDeclineJoinModalOpen}
        form={declineClubForm}
        isLoading={isLoadingDeclineJoinClub}
      />
      <Modal
        centered
        className="modal-action-success"
        visible={isHandleJoinModalOpen}
        onCancel={() => setIsHandleJoinModalOpen(false)}
        footer={[]}
      >
        <div className="d-flex justify-content-center w-100">
          <img src={successIcon} height="96px" width="96px" />
        </div>
        <div className="d-flex-column w-100 justify-content-center">
          <h4 className="text-center">Success!</h4>
          <p className="text-center">{isJoinModalText}</p>
        </div>
      </Modal>
      <MiniPageHeader
        onSearch={(e: any) => {
          setSearchString(e);
        }}
        placeholder="Search club name"
        filterToggle={
          <Button
            className="filter-toggler"
            icon={<FilterOutlined />}
            shape="round"
            onClick={() => setIsFilterShown(!isFilterShown)}
          >
            <span className="filter-text">
              {isFilterShown ? "Hide Filters" : "Show Filters"}
            </span>
          </Button>
        }
      >
        <Tooltip title="Grid View">
          <Button
            className={`${isViewType === 1 && "view-active"}`}
            shape="circle"
            icon={<AppstoreOutlined />}
            onClick={() => setIsViewType(1)}
          />
        </Tooltip>
        <Tooltip title="Table View">
          <Button
            className={`${isViewType === 2 && "view-active"}`}
            shape="circle"
            icon={<UnorderedListOutlined />}
            onClick={() => setIsViewType(2)}
          />
        </Tooltip>
        <Tooltip title="Filters" className="filter-sm">
          <Button
            shape="circle"
            icon={<FilterOutlined />}
            onClick={() => setIsFilterShown(!isFilterShown)}
          />
        </Tooltip>
        {Permissions.canCreateClub && (
          <Button
            className="mr-1"
            type="primary"
            icon={<PlusOutlined />}
            shape="round"
            onClick={() => navigate("/club-proposal")}
          >
            Propose a Club
          </Button>
        )}
      </MiniPageHeader>
      <div className="content-wrapper">
        <div className="content-container">
          <div
            className="listing-container"
            style={{ gap: !isFilterShown ? 0 : 24 }}
          >
            <div
              className={`sidebar-placeholder ${
                isFilterShown ? "show-sidebar" : "hide-sidebar"
              }`}
            >
              <div
                className="sidebar-backdrop"
                style={{ background: !isFilterShown ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.2)' }}
                onClick={(event) => event.currentTarget == event.target && setIsFilterShown(!isFilterShown)}
              >
                <div className={`listing-sidebar ${!isFilterShown && "d-none"}`}>
                  <div className="close-filters-sm d-flex align-items-center">
                    <span>Filter</span>
                    <CloseOutlined
                      style={{
                        verticalAlign: 'middle',
                      }}
                      onClick={() => setIsFilterShown(!isFilterShown)}
                    />
                  </div>

                  <Collapse
                    className="collapse-filters"
                    defaultActiveKey={["1"]}
                    bordered={false}
                    expandIconPosition={"end"}
                  >
                    <Panel header="Type" key="1">
                      <Checkbox.Group
                        options={optionsForClubType}
                        value={selectedClubType}
                        onChange={onChangeClubType}
                      />
                    </Panel>
                    <Panel header="Privacy Type" key="2">
                      <Checkbox.Group
                        options={optionsForClubPrivacy}
                        value={selectedClubPrivacy}
                        onChange={onChangeClubPrivacy}
                      />
                    </Panel>
                    <Panel header="Interest" key="3">
                      <Checkbox.Group
                        options={interestOptions}
                        value={selectedClubInterest}
                        onChange={onChangeClubInterest}
                      />
                      {moreInterestOptions?.length > 0 && (
                        <a
                          className="more-dropdown"
                          onClick={() => moreInterestsTrigger()}
                        >
                          More <DownOutlined />
                        </a>
                      )}
                    </Panel>
                  </Collapse>
                </div>
              </div>
              
            </div>
            <div className="listing-content-view">
              <div className="club-list">
                {selectedClubType === "myClub" ? (
                  <Fragment>
                    {isViewType === 1 ? (
                      <MyClubItemGrid
                        clubs={clubs}
                        isListLoading={isListLoading}
                        onHandleConfirm={onHandleConfirm}
                        onHandleClubInvitation={onHandleClubInvitation}
                        onHandleDeclineClubInvitation={
                          onHandleDeclineClubInvitation
                        }
                        actionIsLoading={actionIsLoading}
                      />
                    ) : (
                      <Fragment>
                        <MyClubsItemList
                          clubs={clubs}
                          isListLoading={isListLoading}
                          onHandleConfirm={onHandleConfirm}
                          onHandleClubInvitation={onHandleClubInvitation}
                          onHandleDeclineClubInvitation={
                            onHandleDeclineClubInvitation
                          }
                          actionIsLoading={actionIsLoading}
                        />
                      </Fragment>
                    )}
                    {clubs?.length === 0 && !isListLoading && (
                      <div className={`empty-state d-flex d-flex-column justify-content-center h-100 ${flutterConnect ? 'flutter' : ''}`}>
                        <img src={clubEmpty} height="359px" />
                        <h1 className="text-center mb-2">
                          No Club Record Found
                        </h1>
                        {/* <p className="text-center">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p> */}
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {isViewType === 1 ? (
                      <Fragment>
                        <GroupItemGrid
                          groupTitle="Suggested"
                          hasMorePage={suggestedClubHasMorePage}
                          totalClubs={suggestedClubTotal}
                          clubs={suggestedClubs}
                          handleJoinClub={handleJoinClub}
                          isListLoading={isSuggestedClubListLoading}
                          viewMoreHandler={viewMoreHandler}
                        />

                        <GroupItemGrid
                          groupTitle="Others"
                          hasMorePage={otherClubHasMorePage}
                          totalClubs={otherClubTotal}
                          clubs={otherClubs}
                          handleJoinClub={handleJoinClub}
                          isListLoading={isOtherClubListLoading}
                          viewMoreHandler={viewMoreHandler}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <GroupItemList
                          groupTitle="Suggested"
                          hasMorePage={suggestedClubHasMorePage}
                          totalClubs={suggestedClubTotal}
                          clubs={suggestedClubs}
                          handleJoinClub={handleJoinClub}
                          isListLoading={isSuggestedClubListLoading}
                          viewMoreHandler={viewMoreHandler}
                        />
                        <GroupItemList
                          groupTitle="Others"
                          hasMorePage={otherClubHasMorePage}
                          totalClubs={otherClubTotal}
                          clubs={otherClubs}
                          handleJoinClub={handleJoinClub}
                          isListLoading={isOtherClubListLoading}
                          viewMoreHandler={viewMoreHandler}
                        />
                      </Fragment>
                    )}
                    {suggestedClubs?.length == 0 &&
                      otherClubs?.length == 0 &&
                      !isSuggestedClubListLoading &&
                      !isOtherClubListLoading && (
                        <div className={`empty-state d-flex d-flex-column justify-content-center h-100 ${flutterConnect ? 'flutter' : ''}`}>
                          <img src={clubEmpty} height="359px" />
                          <h1 className="text-center mb-2">
                            No Club Record Found
                          </h1>
                          {/* <p className="text-center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p> */}
                        </div>
                      )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  auth: state.auth,
  interests: state.auth.member.interests,
  pushData: state.pushData,
  flutterConnect: state.flutterConnect,
});

export default connect(mapStateToProps, {
  getMyClubs,
  getMyClubsGrouped,
  getClubListForStudentAndMod,
  getClubListImNotPartOf,
  leaveClub,
  handleClubInvitation,
  getMyProposedClubs,
  getSuggestedClubList,
  getOtherClubList,
  joinClub,
  getInterestListNonPaged,
})(GroupList);
