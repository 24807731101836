import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../assets/scss/components/breezecalendar.scss";
import { Fragment, useState, useEffect, memo } from "react";
import ModalEventView from "../layout/ModalEventView";
import { Button, Dropdown, Menu, Calendar as MiniCal, Popover, Select, Tooltip } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined, ExclamationCircleFilled } from "@ant-design/icons";

const mLocalizer = momentLocalizer(moment);

const BreezeCalendar = (props) => {
  const { eventsFuture, handleCalendarFilterDateChange, refresh } = props;
  const [eventData, setEventData] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [eventItems, setEventItems] = useState([]);
  const [calView, setCalView] = useState("month");
  const [overlappingEvents, setOverlappingEvents] = useState([]);
  const [dateFilter, setDateFilter] = useState(moment().format("YYYY MM DD"));

  const displayEventData = (event) => {
    setEventData(event);
    setIsVisible(true);
  };

  const onNavigate = (data, view) => {
    setCalView(view)
    setDateFilter(moment(data).format("YYYY MM DD"));
  };

  const eventStyle = (event) => {
    let conflicts = overlappingEvents.map(o=>o.eventId);
    if(event.isReminder){
      if(calView !== "month" && conflicts.includes(event.eventId)){
        var style = {
          borderLeft:'8px solid #FA8C16',
          backgroundColor: '#13C2C2',
        };
      }else{
        var style = {
          backgroundColor: '#13C2C2',
        };
      }
      return {
        style: style,
      };
    }else if(event.isSchoolWide){
      if(calView !== "month" && conflicts.includes(event.eventId)){
        var style = {
          borderLeft:'8px solid #FA8C16',
          backgroundColor: '#597EF7',
        };
      }else{
        var style = {
          backgroundColor: '#597EF7',
        };
      }
      return {
        style: style,
      };
    }else{
      if(calView !== "month" && conflicts.includes(event.eventId)){
        var style = {
          borderLeft:'8px solid #FA8C16',
          backgroundColor: '#40A9FF',
        };
      }else{
        var style = {
          backgroundColor: '#40A9FF',
        };
      }
      return {
        style: style,
      };
    }
  }

  const CustomTooltip = ({type, data}) =>{
    let mergedArr=[]
    if(type === "conflict"){
      let conflictData = overlappingEvents.filter(e=>moment(e.date).format('YYYY DD MM') === moment(data).format('YYYY DD MM'));
      mergedArr = [...new Set(conflictData.map(c=>c.conflicts).flat())];
    }
    return(
      <div className="custom-tooltip">
        {
          type==="conflict"&&(
            <>
            <h3 className="conflict-h3">Conflict</h3>
            <p>Scheduled time has conflicts with {mergedArr.map((d, i)=>`${d}${mergedArr.length === (i+2)?", and ": mergedArr.length === (i+1)?"":", "} `)}</p>
            </>
          )
        }
        {
          type==="event"&&(
            <>
            <h3>Event</h3>
            <p>
              {data.title}<br/>
              {moment(data.start).format(
                      "dddd, MMMM D h:mm a"
                    )} - {moment(data.start).isSame(data.end, 'day')? moment(data.end).format(
                      "h:mm a"
                    ) : moment(data.end).format(
                      "dddd, MMMM D h:mm a"
                    )}<br/>
              At {data.location}
            </p>
            </>
          )
        }
      </div>
    )
  }

  const MiniCalendar = ({onNavigate}) =>{
    return(
      <div className="mini-calendar">
        <MiniCal fullscreen={false} 
        mode="year"
        value={moment(dateFilter)}
        onChange={(date)=>setDateFilter(moment(date._d).format("YYYY MM DD"))}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const year = value.year();
          const options = [];
          for (let i = year - 5; i < year + 5; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>,
            );
          }
          return (
            <div className="mini-cal-header">
              <Select
                size="small"
                dropdownMatchSelectWidth={false}
                className="my-year-select"
                value={year}
                onChange={(newYear) => {
                  const now = value.clone().year(newYear);
                  onChange(now);
                }}
              >
                {options}
              </Select>
            </div>
          );
        }}
      />
      <Button className="today-btn" onClick={()=>onNavigate('TODAY')}>
        Today
      </Button>
      </div>
    )
  }

  const DateCell = ({value, children}) => {
    let currdate =((moment(value).format('YYYY MM DD')));
    let overlapDates = overlappingEvents.map(o=>moment(o.date).format('YYYY MM DD'));

    return(
    <div className={(moment().format('YYYY MM DD')===(currdate))?"currDate-cell":"date-cell"}>
      {overlapDates.includes(currdate) && calView === "month" && (
        <Tooltip title={<CustomTooltip type={"conflict"} data={value}/>} className="overlap-tooltip" color="white" >
          <ExclamationCircleFilled className="overlap-alert"/>
        </Tooltip>
      )}
    </div>
    )
  };

  const DayHeader = ({date, localizer}) => {
    return(
    <div className={"week-header-cell"}>
      <h4>{localizer.format(date, 'ddd')}</h4>
      <h1>{localizer.format(date, 'DD')}</h1>
    </div>
    )
  };

  const WeekHeader = ({date, localizer}) => {
    return(
    <div className={"week-header-cell"}>
      <h4>{localizer.format(date, 'ddd')}</h4>
      <h1>{localizer.format(date, 'DD')}</h1>
    </div>
    )
  };
  
  const EventWrapper = ({event, children}) => {
    return(
      <Tooltip title={<CustomTooltip type={"event"} data={event}/>} className="overlap-tooltip" color="white" >
        {children}
      </Tooltip>
    )
  };

  const CalendarToolbar = ({
    date,
    label,
    localizer: { messages },
    onNavigate,
    onView,
    view,
    views,
  }) => {
    setCalView(view);
      return(
      <div className="calendarToolbar">
        <div className="left-side">
        <div className="nav-btn">
          <Button onClick={()=>onNavigate('PREV')}>
            <LeftOutlined/>
          </Button>
          <Button onClick={()=>onNavigate('NEXT')}> 
            <RightOutlined/>
          </Button>
        </div>
        <Popover placement="bottomLeft" content={<MiniCalendar onNavigate={onNavigate}/>} trigger="click">
          <Button className="mini-calendar-btn"> 
          <h2>
            {moment(date).format("MMMM YYYY")} <DownOutlined />
          </h2>
          </Button>
        </Popover>
        </div>

        <Dropdown 
        className="dropdown"
         overlay={(
          <Menu>
            {views.toReversed().map(view => (
              <Menu.Item 
              key={view}
              onClick={() =>{ onView(view);setCalView(view)}}
              >
              {(view).charAt(0).toUpperCase() + (view).slice(1)}
            </Menu.Item>
            ))}
          </Menu>
        )}>
        <Button>
          {(view).charAt(0).toUpperCase() + (view).slice(1)} <DownOutlined />
        </Button>
        </Dropdown>
      </div>
      )
  };

  const formats = {
    eventTimeRangeFormat: () => { //removes time in event container
      return "";
    },
    dayHeaderFormat: ({ start, end }, culture, local) =>
      local.format(start, 'MMMM YYYY', culture),
    dayRangeHeaderFormat: ({ start, end }, culture, local) =>
      local.format(start, 'MMMM YYYY', culture),
  };

  useEffect(() => {
    let overlaps=[]
    let headIndex = 0;
    for(let i=0;i<eventsFuture.length;i++){
      let conflictsWith=[];
      for(let j=headIndex;j<eventsFuture.length;j++){
        if((i===j && j!==eventsFuture.length)){
          continue;
        }
        if(j>i && moment(eventsFuture[j].startDate).isBefore(moment(eventsFuture[i].endDate))){   //working  event j is after i
          conflictsWith.push(eventsFuture[j].title);
        }else if(j<i && moment(eventsFuture[i].startDate).isBefore(moment(eventsFuture[j].endDate))){   //working  event i is after j
          conflictsWith.push(eventsFuture[j].title);
        }

        if(moment(eventsFuture[headIndex].endDate).isBefore(moment(eventsFuture[i].startDate))){     
          //makes subsequent passes shorter
          headIndex++;
          continue;
        }

        if(moment(eventsFuture[j].startDate).isAfter(moment(eventsFuture[i].endDate))){
          //makes subsequent passes shorter
          break;
        }
      }
      if(conflictsWith.length > 0){
        overlaps.push({
          day:moment(eventsFuture[i].startDate).format('D'),
          date:(eventsFuture[i].startDate),
          eventId:eventsFuture[i].eventId,
          eventTitle:eventsFuture[i].title,
          conflicts: conflictsWith,
        })
      }
    }
    setOverlappingEvents(overlaps);
  }, [eventsFuture]);

  useEffect(() => {
    let data = eventsFuture.map((data) => {
      return {
        title: data.title,
        eventId: data.eventId,
        allDay: false,
        start: new Date(data.startDate),
        end: new Date(data.endDate),
        description: data.description,
        location: data.location,
        memberGroup: data.memberGroup,
        organizer: data.eventCreatedByName,
        isSchoolWide: data.isSchoolWideEvent,
        isReminder: (data.eventType===3) ? true : false,
        userInvite: data.userInvite,
      };
    });
    setEventItems(data);
  }, [eventsFuture]);

  useEffect(() => {
    handleCalendarFilterDateChange(dateFilter);
  }, [dateFilter]);

  return (
    <Fragment>
      <ModalEventView
        eventData={eventData}
        isVisible={isVisible}
        conflicts={overlappingEvents?.length > 0 && (overlappingEvents?.find(o=>o?.eventId === eventData?.eventId)??null)}
        onModalCancelHandler={() => setIsVisible(false)}
        saveItemHandler={() => alert("save event")}
        refresh={refresh}
      />
      <Calendar
        dayLayoutAlgorithm={'no-overlap'}
        views={["month", "week", "day"]}
        onNavigate={onNavigate}
        // scrollToTime={new Date(1970, 1, 1, 6)}
        date={dateFilter}
        defaultDate={moment().format("YYYY MM DD")}
        defaultView="month"
        events={eventItems}
        localizer={mLocalizer}
        eventPropGetter={eventStyle}
        onSelectEvent={(event) => displayEventData(event)}
        selectable
        formats={formats} 
        min={new Date(0, 0, 0, 11, 0, 0)}
        max={new Date(0, 0, 0, 18, 0, 0)}
        components={{
          toolbar: CalendarToolbar,
          dateCellWrapper: DateCell,
          day: {
            header: ({ date, localizer }) => <DayHeader date={date} localizer={localizer} />
          },
          week: {
            header: ({ date, localizer }) => <WeekHeader date={date} localizer={localizer} />
          },
          eventWrapper: EventWrapper,
        }}
        tooltipAccessor={null}
      />
      <div className="calendar-view-legends">
        <p className="legend">
          <div className="school-wide-circle"/>
          <span>
            School Wide Events
          </span>
        </p>
        <p className="legend">
          <div className="club-event-circle"/>
          <span>
            Club Events
          </span>
        </p>
        <p className="legend">
          <div className="private-event-circle"/>
          <span>
            Private Events
          </span>
        </p>
      </div>
    </Fragment>
  );
};

export default memo(BreezeCalendar);
