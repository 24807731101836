import { Button, Col, Form, message, Row, Space, Upload } from "antd";
import { CSVLink } from "react-csv";
import { getCategoryList } from "../../../actions/school";
import { UploadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addStudentUserBulk } from "../../../actions/student";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import AlertContainer2 from "../../../layouts/AlertContainer2";

const AddStudentBulkForm = (props: {
  addStudentUserBulk: Function;
  getCategoryList: Function;
}) => {
  const [addStudentBulkForm] = Form.useForm();
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [csvData, setCSVData] = useState<string[][]>([]);
  const [validationMessages, setValidationMessages] = useState<Object>({});

  const getCategories = async () => {
    const response = await props.getCategoryList();

    let data = ["FirstName", "LastName", "EmailAddress", "Gender", "BirthDate"];
    if (response.status === OK) {
      const groups = response.data.map((f: any) => f.name);
      setCSVData([[...data, ...groups]]);
    } else {
      setCSVData([[...data]]);
    }
  };

  const onFinish = async (values: any) => {
    handleUpload(values);
  };

  const handleUpload = async (params: any) => {
    const formData = new FormData();
    fileList.forEach((file: any) => {
      formData.append("csvFile", file);
    });
    setUploading(true);
    const res = await props.addStudentUserBulk(formData, params);
    if (res.status == OK || res.status == CREATED) {
      onReset();
      setFileList([]);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setUploading(false);
  };

  const onReset = () => {
    addStudentBulkForm.resetFields();
    setFileList([]);
    setValidationMessages({});
  };

  const handleFormErrors = (data: any) => {
    if (data && data.errors) {
      let keyString: string;
      let prevKey;
      let dataTemp: any = {};
      for (let property in data) {
        keyString = property.split(".")[0];
        if (prevKey === keyString) {
          for (let i = 0; i < data[property].length; i++) {
            dataTemp[keyString].push(data[property][i]);
          }
        } else {
          dataTemp[keyString] = data[property];
        }
        prevKey = keyString;
      }

      setValidationMessages(dataTemp);
    } else {
      var obj = {};
      for (const [key, value] of Object.entries(data)) {
        Object.assign(obj, { [key]: value });
      }
      setValidationMessages(obj);
    }
  };

  const uploadProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error(`${file.name} is not a csv file`);
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList
  };

  useEffect(() => {
    const initialized = async () => {
      await getCategories();
    };

    initialized();
  }, []);

  return (
    <Row>
      <Col span={6} xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 12 }}>
        <Form
          form={addStudentBulkForm}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          className="breeze-form-1 custom-alert"
        >
          <div className="upload-label mb-1">
            <label>Student List</label>
          </div>
          <div className="upload-container">
            <Form.Item
              name="upload"
              rules={[
                {
                  required: true,
                  message: "Please select a file!"
                }
              ]}
            >
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </div>

          <div className="note-container">
            <p>
              Note: Upload user's list in .csv format using the template below.
            </p>
          </div>

          <div className="sample-file-template">
            <div>
              <FileExcelOutlined />
            </div>
            <div className="">
              <CSVLink data={csvData} filename="csv-student-template.csv">
                student-list-template.csv
              </CSVLink>
            </div>
          </div>
          <div
            className={`mb-3 force-alert ${validationMessages && "has-error"}`}
          >
            {validationMessages && (
              <AlertContainer2 data={validationMessages} />
            )}
          </div>
          <div className="button-container">
            <Space>
              <Button
                className="btn-radius"
                type="primary"
                htmlType="submit"
                disabled={fileList.length === 0}
                loading={uploading}
              >
                Save Student
              </Button>
              <Button className="btn-radius" onClick={onReset}>
                Reset
              </Button>
            </Space>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default connect(null, {
  addStudentUserBulk,
  getCategoryList
})(AddStudentBulkForm);
