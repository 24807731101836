import { Button } from "antd";
import emptyState from "../../../assets/images/empty-state-illustration.svg";
import { connect } from "react-redux";

const EmptyState = (props: {
  onFilterAgain?:Function;
  imgHeight?:Number;
  flutterConnect:boolean;
}) => {
  const { onFilterAgain, imgHeight, flutterConnect } = props;

  return (
    <div className={`empty-state d-flex justify-content-center h-100 state-no-result ${flutterConnect ? 'flutter' : ''}`}>
      <div className="d-flex d-flex-column justify-content-center">
        <h1 className="text-center mb-2">No Results Found!</h1>
        <p>
          Sorry, we couldnt find any results matching your search.
          <br/>
          Please try again with different search terms or filters.
        </p>
        {
          onFilterAgain && (
            <Button
            style={{width: 150, height: 57, borderRadius: 10, fontSize:21 }}
            type="primary"
            shape="default"
            // loading={isLoading}
            // icon={<CheckOutlined />}
            onClick={() => onFilterAgain()}
          >
              Filter Again
          </Button>
          )
        }
      </div>
      <img src={emptyState} height={`${!!imgHeight ? `${imgHeight}px` : '548px'}`} />
      {/* <p className="text-center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  flutterConnect: state.flutterConnect,
});
export default connect(mapStateToProps, {
})(EmptyState);
