import { Column } from "@ant-design/plots";

const ColumnChart = (props: {
  data?: any;
  x?: string;
  y?: string;
  aliasX?: string;
  aliasY?: string;
}) => {
  const {data, x, y, aliasX, aliasY} = props;
  const config = {
    data,
    xField: x ?? '',
    yField: y ?? '',
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [4, 5],
          },
        },
      },
    },
    color: '#02b8f6',
    meta: {},
    autoFit: true,
    style: {
    },
    maxColumnWidth: 10,
    height: 480,
  };

  if (x)
    (config.meta as any)[x] = {
      alias: aliasX
    };

  if (y)
    (config.meta as any)[y] = {
      alias: aliasY
    };

  const staticData = [
    {
      type: "1",
      events: 800,
    },
    {
      type: "2",
      events: 1100,
    },
    {
      type: "3",
      events: 850,
    },
    {
      type: "4",
      events: 450,
    },
    {
      type: "5",
      events: 500,
    },
    {
      type: "6",
      events: 450,
    },
    {
      type: "7",
      events: 500,
    },
    {
      type: "8",
      events: 450,
    },
    {
      type: "9",
      events: 810,
    },
    {
      type: "10",
      events: 810,
    },
    {
      type: "11",
      events: 1200,
    },
    {
      type: "12",
      events: 1000,
    },
  ];

  const staticConfig = {
    data: staticData,
    xField: "type",
    yField: "events",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [4, 5],
          },
        },
      },
    },
    columnStyle: {
      fill: '#02b8f6',
    },
    meta: {
      type: {
        alias: "Month",
      },
      events: {
        alias: "Events",
      },
    },
    minColumnWidth: 40,
    maxColumnWidth: 40,
    height: 480,
  };

  return <Column {...(data ? config : staticConfig)} />;
};

export default ColumnChart;
