import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import { Button, Col, Row } from "antd";
import { connect } from "react-redux";
import { DatePicker } from "antd";
import { DatePickerProps } from "antd";
import { Form } from "antd";
import { getEventDetails } from "../../../actions/event";
import { getInterestListNonPaged } from "../../../actions/interest";
import { Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { RcFile } from "antd/lib/upload";
import { Select } from "antd";
import { Skeleton } from "antd";
import { TimePicker } from "antd";
import { updateEvent } from "../../../actions/event";
import { Upload } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LocationField from "../../super-admin/select-fields/LocationField";
import moment from "moment";
import PageHeader from "../layouts/PageHeader";
import TextArea from "antd/lib/input/TextArea";
import SSDistributionWidget from "../../widget/SSDistributionWidget";
import successImage from "../../../assets/images/event-add-success.svg";
import AlertContainer from "../../../layouts/AlertContainer";

const defaultBanner =
  "https://stbreeze001.blob.core.windows.net/dev/default_banners/Default.png";
const { RangePicker } = DatePicker;

interface SelectItem {
  label: string;
  value: string;
}

const EventForm = (props: {
  getEventDetails: Function;
  getInterestListNonPaged: Function;
  updateEvent: Function;
}) => {
  const { updateEvent, getEventDetails, getInterestListNonPaged } = props;
  const { eventId } = useParams();
  const [interestOptions, setInterestOptions] = useState<SelectItem[]>();
  const [validationMessages, setValidationMessages]: any = useState({});
  const [succesfullyUpdated, setSuccessfullyUpdated] = useState(false);
  const [softSkillScores, setSoftSkillScores] = useState<any>();
  const [source, setSource] = useState<string | null>(null);
  const [banner, setBanner] = useState(defaultBanner);
  const [isLoading, setIsLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState<any>();
  const [eventTitle, setEventTitle] = useState("");
  const [fileRead, setFileRead] = useState<any>();
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [event, setEvent] = useState<any>();
  const [params, setParams] = useSearchParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dateFormat = "YYYY-MM-DD";

  const onBackClick = () => {
    navigate(`/event/${eventId}${source ? "?g=" + source : ""}`);
  };

  const onStartTimeChange = (value: any) => {
    // console.log(moment.utc(value).format("h:mm a"));
    setStartTime(moment(value).format("h:mm a"));
  };

  const onEndTimeChange = (value: any) => {
    // setEndTime(value);
    setEndTime(moment(value).format("h:mm a"));
  };

  const dateChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const fetchEventDetails = async () => {
    setIsLoading(true);
    const res = await getEventDetails({ eventId });
    setIsLoading(false);
    if (res && res.data) {
      setEvent(res.data);

      form.setFieldsValue({
        title: res.data.title,
        dateRange: [moment(res.data.startDate), moment(res.data.endDate)],
        startTime: moment(res.data.startDate),
        endTime: moment(res.data.endDate),
        memberGroupId: res.data.memberGroupId,
        eventType: res.data.eventType,
        eventSeriesType: res.data.eventSeriesType,
        locationId: res.data.locationId,
        onlineLink: res.data.onlineLink,
        description: res.data.description,
        softSkillScores: res.data.softSkillScores,
        interestId: res.data.interestId,
      });
    }
  };

  const fetchInterestList = async () => {
    const data = await getInterestListNonPaged();
    const result = data.data.map((item: any) => ({
      label: item.name,
      value: item.interestId,
    }));
    setInterestOptions(result);
  };

  const onFinish = async (formData: any) => {
    setIsLoading(true);
    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const _startDate = new Date(startDate + " " + startTime);
    const _endData = new Date(endDate + " " + endTime);
    formData = {
      ...formData,
      eventId: eventId,
      startDate: _startDate.toISOString(),
      endDate: _endData.toISOString(),
      participants: event.participants,
      softSkillScores: formData?.softSkillScores
        ? formData.softSkillScores
        : softSkillScores,
      timeZoneId,
      file: imageBlob,
    };

    let res = await updateEvent(formData);
    if (res.status == OK || res.status == CREATED) {
      setSuccessfullyUpdated(true);
      setEvent(res.data);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }
    setIsLoading(false);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const onBeforeUpload = (file: RcFile) => {
    setFileRead(file);

    return false;
  };

  useEffect(() => {
    if (fileRead) {
      const reader = new FileReader();
      reader.onload = () => setImageBlob(reader.result);
      reader.readAsDataURL(fileRead);
    }
  }, [fileRead]);

  useEffect(() => {
    if (event) {
      setEventTitle(event.title);
      setStartDate(moment(event.startDate).format(dateFormat));
      setEndDate(moment(event.endDate).format(dateFormat));
      setStartTime(moment(event.startDate).format("h:mm a"));
      setEndTime(moment(event.endDate).format("h:mm a"));
      if (event.bannerFileUrl) {
        setBanner(event.bannerFileUrl);
      }
    }
  }, [event]);

  useEffect(() => {
    fetchInterestList();
    if (eventId) {
      setSource(params.get("g"));
      fetchEventDetails();
    }
  }, []);

  return (
    <Form
      form={form}
      disabled={isLoading}
      layout="vertical"
      size="middle"
      onFinish={onFinish}
    >
      {!succesfullyUpdated && (
        <PageHeader
          title={event && event.title}
          header={
            <div className="event-list-controls">
              <Button htmlType="submit" type="primary" shape="round">
                Update Event
              </Button>
              <Button shape="round" onClick={onBackClick}>
                Cancel
              </Button>
            </div>
          }
        >
          &nbsp;
        </PageHeader>
      )}

      <div className="content-wrapper" style={{ marginTop: 25 }}>
        <div className="content-container event-page">
          <div className="content-box">
            {!succesfullyUpdated && (
              <div style={{ maxWidth: 948, margin: "auto" }}>
                <h2>Edit Event Details</h2>
                <Form.Item hidden name="memberGroupId">
                  <Input type="hidden" />
                </Form.Item>
                <Row justify="space-between" gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      className="mb-0"
                      name="title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter event Title!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter event title"
                        onChange={(evt) => setEventTitle(evt.target.value)}
                      />
                    </Form.Item>
                    <div
                      className={`mb-4 ${
                        validationMessages &&
                        validationMessages.Title &&
                        "has-error"
                      }`}
                    >
                      {validationMessages && validationMessages.Title && (
                        <AlertContainer data={validationMessages.Title} />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 24 }}>
                  <Col sm={{ span: 24 }} md={{ span: 8 }}>
                    <Form.Item
                      label="Date"
                      className="mr-3 mb-0"
                      name="dateRange"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Start Date",
                        },
                      ]}
                      {...(validationMessages &&
                      validationMessages.error &&
                      validationMessages.error === "There is a conflict event."
                        ? { validateStatus: "error" }
                        : "")}
                      {...(validationMessages &&
                      validationMessages.error &&
                      validationMessages.error === "There is a conflict event."
                        ? {
                            help: validationMessages.error,
                          }
                        : "")}
                    >
                      {!isLoading ? (
                        <RangePicker
                          format="YYYY-MM-DD"
                          onChange={dateChange}
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 12 }} md={{ span: 4 }}>
                    <Form.Item
                      className="mr-3 mb-0"
                      name="startTime"
                      label="Time"
                    >
                      {!isLoading ? (
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          onChange={onStartTimeChange}
                          placeholder="Start Time"
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 12 }} md={{ span: 4 }}>
                    <Form.Item className="mr-3 mb-0" name="endTime" label=" ">
                      {!isLoading ? (
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          onChange={onEndTimeChange}
                          placeholder="End Time"
                        />
                      ) : (
                        <Skeleton.Input active={true} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} md={{ span: 8 }}>
                    &nbsp;
                  </Col>
                </Row>
                <Form.Item name="eventType" label="Event Type">
                  <Radio.Group>
                    <Radio.Button value={0}>Public</Radio.Button>
                    <Radio.Button value={1}>Private</Radio.Button>
                    <Radio.Button value={2}>Closed</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <LocationField
                  className="pb-1"
                  validationMessages={validationMessages}
                />
                <Form.Item name="onlineLink" label="Online Link">
                  <Input placeholder="Enter online link" />
                </Form.Item>
                <Form.Item name="description" label="Event Details">
                  <TextArea
                    placeholder="Enter event description"
                    style={{ height: 120 }}
                    disabled={isLoading}
                  />
                </Form.Item>
                <Form.Item name="bannerImage" label="Banner Image">
                  <div
                    className="banner-img-preview"
                    style={{
                      backgroundImage: `url(${
                        fileRead ? URL.createObjectURL(fileRead) : banner
                      })`,
                    }}
                  >
                    <Upload
                      multiple={false}
                      beforeUpload={onBeforeUpload}
                      fileList={fileRead ? [fileRead] : []}
                      onRemove={() => setFileRead(undefined)}
                      accept="image/*"
                    >
                      <Button shape="round" icon={<PlusOutlined />}>
                        Choose File
                      </Button>
                    </Upload>
                  </div>
                </Form.Item>
                <Form.Item name="interestId" label="Interest">
                  <Select
                    placeholder="Select Interest"
                    options={interestOptions}
                    disabled={isLoading}
                    allowClear
                  ></Select>
                </Form.Item>

                {event && (
                  <Form.Item label="Soft Skills Distribution">
                    <SSDistributionWidget
                      form={form}
                      softSkillScores={event.softSkillScores}
                      onChange={(val) => setSoftSkillScores(val)}
                    />
                  </Form.Item>
                )}
              </div>
            )}

            {succesfullyUpdated && (
              <div className="fadein">
                <div style={{ textAlign: "center" }}>
                  <img
                    height="auto"
                    width="320px"
                    src={successImage}
                    alt="event success image"
                  />
                </div>
                <h2 className="text-center mt-3">
                  Event successfully updated!
                </h2>
                <div style={{ padding: 36, textAlign: "center" }}>
                  <Button
                    onClick={() =>
                      navigate(
                        `/event/${eventId}${source ? "?g=" + source : ""}`
                      )
                    }
                    className="btn-breeze-box-radius w-auto"
                    type="primary"
                    block
                  >
                    Back to event
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};
export default connect(null, {
  updateEvent,
  getEventDetails,
  getInterestListNonPaged,
})(EventForm);
