import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Calendar } from "antd";
import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { connect } from "react-redux";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { Form } from "antd";
import { getInterestListNonPaged } from "../../../actions/interest";
import { getLocationEventList, addEvent } from "../../../actions/event";
import { getSoftSkillsNonPaged } from "../../../actions/softskill";
import { Input } from "antd";
import { InputNumber } from "antd";
import { Layout } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd";
import { Typography } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LocationField from "../../super-admin/select-fields/LocationField";
import moment from "moment";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import TextArea from "antd/lib/input/TextArea";
import HorizontalListWidget from "../../widget/HorizontalListWidget";
import PointDistributionInput from "../../super-admin/interest/PointDistribution";
import successImage from "../../../assets/images/event-add-success.svg";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import AlertContainer from "../../../layouts/AlertContainer";

const { Text } = Typography;
const eventTypeOptions = [
  { label: "Public", value: "0" },
  { label: "Private", value: "1" },
  { label: "Close", value: "2" },
];

interface SelectItem {
  label: string;
  value: string;
}

interface SoftSkill {
  name: string;
  description: string;
}

const EventWizard = (props: {
  getInterestListNonPaged: Function;
  getSoftSkillsNonPaged: Function;
  getLocationEventList: Function;
  addEvent: Function;
}) => {
  const {
    addEvent,
    getLocationEventList,
    getSoftSkillsNonPaged,
    getInterestListNonPaged,
  } = props;
  const { memberGroupId } = useParams();
  const [title, setTitle] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [eventType, setEventType] = useState<number>();
  const [locationId, setLocationId] = useState<string>();
  const [onlineLink, setOnlineLink] = useState<string>();
  const [interestId, setInterestId] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [eventSeriesType, setEventSeriesType] = useState(0);
  const [participants, setParticipants] = useState<any[]>();
  const [softSkillScores, setSoftSkillScores] = useState<any>();

  const [isLoadingSoftSkillList, setIsLoadingSoftSkillList] = useState(false);
  const [eventTimeOptions, setEventTimeOptions] = useState<SelectItem[]>([]);
  const [interestOptions, setInterestOptions] = useState<SelectItem[]>();
  const [validationMessages, setValidationMessages]: any = useState({});
  const [selectedSoftSkill, setSelectedSoftSkill]: any = useState([]);
  const [selectedDuration, setSelectedDuration] = useState<string>();
  const [selectedInterest, setSelectedInterest] = useState<string>();
  const [isSoftSkillValid, setIsSoftSkillValid] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<SoftSkill>();
  const [softSkillList, setSoftSkillList] = useState<any[]>([]);
  const [interestList, setInterestList] = useState<any[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [customMinute, setCustomMinute] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [customHour, setCustomHour] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [form] = Form.useForm();

  const carousel = useRef<CarouselRef | null>(null);
  const mainDiv = useRef<HTMLElement | null>(null);
  const yesterday = moment().add(-1, "day");
  const navigate = useNavigate();

  const fetchInterestList = async () => {
    const data = await getInterestListNonPaged();
    const result = data.data.map((item: any) => ({
      label: item.name,
      value: item.interestId,
    }));
    setInterestOptions(result);
    setInterestList(data.data);
  };

  const fetchSoftSkillList = async () => {
    setIsLoadingSoftSkillList(true);
    const data = await getSoftSkillsNonPaged();
    setSoftSkillList(data.data);
    setIsLoadingSoftSkillList(false);
  };

  const onPageChange = (currentSlide: number) => {
    setPageIndex(currentSlide);
    mainDiv && mainDiv.current && mainDiv.current.scrollTo(0, 0);
  };

  const onBackClick = () => {
    if (carousel && carousel.current && pageIndex !== 0) {
      carousel.current.prev();
    }
  };

  const onCancelClick = () => {
    navigate(`/club/${memberGroupId}`);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const payload = {
        title,
        description,
        locationId,
        onlineLink,
        startDate,
        endDate,
        memberGroupId,
        eventSeriesType,
        eventType,
        timeZoneId,
        interestId,
        softSkillScores,
        participants,
      };
      const result = await addEvent(payload);
      if (result.status == OK || result.status == CREATED) {
        setIsSuccess(true);
      } else if (result.status === BAD_REQUEST) {
        if (result.data) {
          handleFormErrors(result.data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const onContinueClick = async () => {
    if (carousel && carousel.current) {
      if (pageIndex === 0) {
        setLocationId(form.getFieldValue("locationId"));
        await form.validateFields(["locationId", "txt-online-link"]);
      } else if (pageIndex === 1) {
        await form.validateFields(["sel-duration", "sel-time"]);
      } else if (pageIndex === 2) {
        await form.validateFields(["sel-event-type"]);
        const val = form.getFieldValue("participants");
        if (val) {
          setParticipants(
            val.map((item: any) => ({
              id: item.value.split("-")[0],
              type: parseInt(item.value.split("-")[1]),
            }))
          );
        }
      } else if (pageIndex === 3) {
        await form.validateFields(["sel-interest"]);
      } else if (pageIndex === 4) {
        if (!validateSoftSkillScore()) return;
      } else if (pageIndex === 5) {
        await form.validateFields(["txt-title"]);
        onSubmit();
        return;
      }
      carousel.current.next();
    }
  };

  const validateSoftSkillScore = (): boolean => {
    let sum = 0;
    const scores = form.getFieldValue("softSkillScores");
    if (scores) {
      sum = scores.reduce((p: any, c: any) => p + c.percentage, 0);

      setSoftSkillScores(
        scores.map((score: any) => ({
          softSkillId: score.softSkillId,
          percentage: score.percentage,
        }))
      );
    }
    setIsSoftSkillValid(sum !== 100);
    return sum === 100;
  };

  const changeSoftSkillHandler = (key: number, e: any) => {
    setSelectedSoftSkill({
      ...selectedSoftSkill,
      [key]: { key: key, id: e },
    });
  };

  const onDurationChange = (e: RadioChangeEvent) => {
    setSelectedDuration(e.target.value);
    if (selectedDate) {
      const hours =
        e.target.value == "0" ? customHour : parseInt(e.target.value);
      const minutes = e.target.value == "0" ? customMinute : 0;
      generateEventTimeOptions(selectedDate, hours, minutes);
    }
  };

  const onDateChange = (date: moment.Moment) => {
    setSelectedTime(undefined);
    setSelectedDate(date.toDate());
    if (selectedDuration!==undefined) {
      const hours =
        selectedDuration == "0" ? customHour : parseInt(selectedDuration);
      const minutes = selectedDuration == "0" ? customMinute : 0;
      generateEventTimeOptions(date.toDate(), hours, minutes);
    }
  };

  const generateEventTimeOptions = async (
    date: Date,
    duration: number,
    minutes: number
  ) => {
    setEventTimeOptions([]);
    const fromDate = new Date(date.toDateString());
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 1);
    toDate.setSeconds(toDate.getSeconds() - 1);
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    const result = await getLocationEventList({ locationId, from, to });
    const events = result.data as any[];

    let loopTime = fromDate;
    loopTime.setHours(loopTime.getHours() + 6);

    let loopEndTime = new Date(loopTime);
    loopEndTime.setTime(
      loopTime.getTime() + duration * 60 * 60 * 1000 + minutes - 1 * 60 * 1000
    );
    let list = [];
    while (loopTime < toDate) {
      const anyEvent =
        events &&
        events.filter(
          (f: any) =>
            (new Date(f.startDate) >= loopTime &&
              new Date(f.startDate) <= loopEndTime) ||
            (new Date(f.endDate) >= loopTime &&
              new Date(f.endDate) <= loopEndTime)
        );

      const now = new Date();
      list.push({
        label: moment(loopTime).format("hh:mm A"),
        value: loopTime.toISOString(),
        disabled: (anyEvent && anyEvent.length > 0) || loopTime < now,
      });
      loopTime.setTime(loopTime.getTime() + 0.5 * 60 * 60 * 1000);
      loopEndTime.setTime(
        loopTime.getTime() + duration * 60 * 60 * 1000 + minutes - 1 * 60 * 1000
      );
    }
    setEventTimeOptions(list);
  };

  const disabledDate = (date: moment.Moment) => {
    let now = new Date();
    now = new Date(now.toDateString());
    return date.isBefore(now);
  };

  const onEventTimeChange = (e: RadioChangeEvent) => {
    setSelectedTime(e.target.value);
    if (e.target.value !== undefined && selectedDuration !== undefined) {
      setStartDate(e.target.value);
      const hours =
        selectedDuration == "0" ? customHour : parseInt(selectedDuration);
      const minutes = selectedDuration == "0" ? customMinute : 0;
      const _endDate = new Date(e.target.value);
      _endDate.setTime(
        _endDate.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000
      );
      setEndDate(_endDate.toISOString());
    }
  };

  const onPanelChange = () => {};

  const onEventTypeChange = (e: RadioChangeEvent) => {
    setEventType(parseInt(e.target.value));
  };

  const onInterestChange = (e: RadioChangeEvent) => {
    setInterestId(e.target.value);
    const interest = interestList.find((f) => f.interestId === e.target.value);
    let count = 0;
    const scores = interest?.softSkillScores.map((score: any) => ({
      key: count++,
      id: score.softSkillId,
    }));

    setSelectedSoftSkill(scores);
    setSoftSkillScores(scores);
    setSelectedInterest(interest?.name);
    setTotalScore(
      interest?.softSkillScores.reduce((p: any, c: any) => p + c.percentage, 0)
    );
    form.setFieldsValue({
      softSkillScores: interest?.softSkillScores,
    });
  };

  useEffect(() => {
    setSelectedTime(undefined);
    setEventTimeOptions([]);
  }, [locationId]);

  useEffect(() => {
    fetchInterestList();
    fetchSoftSkillList();
  }, []);

  return (
    <Layout className="event-wizard">
      {!isSuccess && (
        <Fragment>
          <Header style={{ background: "unset" }}>
            {pageIndex === 0 ? (
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
                disabled={isLoading}
              >
                Back
              </Button>
            ) : (
              <Button
                type="text"
                disabled={pageIndex === 0 || isLoading}
                icon={<ArrowLeftOutlined />}
                onClick={onBackClick}
              >
                Back
              </Button>
            )}
          </Header>
          <Content ref={mainDiv}>
            <Carousel
              dots={false}
              ref={carousel}
              draggable={false}
              swipeToSlide={false}
              afterChange={onPageChange}
            >
              <div className="wizard-page-container">
                <h2>Where are you planning to set the event?</h2>
                <Form
                  form={form}
                  name="sel-location"
                  layout="vertical"
                  size="large"
                >
                  <LocationField
                    className="pb-1"
                    validationMessages={validationMessages}
                  />
                  <Form.Item
                    name="txt-online-link"
                    label="Please provide event link."
                    rules={[
                      {
                        pattern:
                          /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                        message: "Please enter a valid link.",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => setOnlineLink(e.target.value)}
                      placeholder="Enter online meeting link"
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="wizard-page-container">
                <h2>Let's book your event!</h2>
                <Form form={form} layout="vertical" size="large">
                  <Form.Item
                    name="sel-duration"
                    label="How many hours is your event?"
                    required
                    rules={[
                      {
                        required: true,
                        message:
                          "Please select first the duration of the event.",
                      },
                    ]}
                  >
                    <Radio.Group
                      className="select-duration-option"
                      onChange={onDurationChange}
                      value={selectedDuration}
                      optionType="button"
                    >
                      <Radio value={1}>1 Hour</Radio>
                      <Radio value={2}>2 Hours</Radio>
                      <Radio value={3}>4 Hours</Radio>
                      <Radio value={0}>Custom</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {selectedDuration == '0' && (
                    <Form.Item label="Duration of Event">
                      <Form
                        className="form-item-small"
                        size="middle"
                        layout="inline"
                      >
                        <Form.Item label="Hours">
                          <InputNumber
                            placeholder="Hour"
                            defaultValue={1}
                            min={0}
                            max={12}
                            onChange={(value) => setCustomHour(value)}
                          />
                        </Form.Item>
                        <Form.Item label="Minutes">
                          <InputNumber
                            placeholder="Minute"
                            defaultValue={0}
                            min={0}
                            max={59}
                            step={5}
                            onChange={(value) => setCustomMinute(value)}
                          />
                        </Form.Item>
                      </Form>
                    </Form.Item>
                  )}
                  <Form.Item label="Select your preferred date">
                    <div className="calendar-container">
                      <Calendar
                        fullscreen={false}
                        disabledDate={disabledDate}
                        onChange={onDateChange}
                        defaultValue={yesterday}
                        //onSelect={onDateChange}
                        onPanelChange={onPanelChange}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="sel-time"
                    label="What time do you want to start your event?"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select the time of the event!",
                      },
                    ]}
                  >
                    {eventTimeOptions.length > 0 && (
                      <Radio.Group
                        className="select-time-options"
                        onChange={onEventTimeChange}
                        value={selectedTime}
                        optionType="button"
                      >
                        <HorizontalListWidget items={eventTimeOptions} />
                      </Radio.Group>
                    )}
                  </Form.Item>
                  {eventTimeOptions.length === 0 && (
                    <i>Select first the event duration and date.</i>
                  )}
                </Form>
              </div>
              <div className="wizard-page-container">
                <h2>Who are your participants?</h2>
                <Form form={form} layout="vertical" size="large">
                  <Form.Item
                    name="sel-event-type"
                    label="Select invitation type"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select invitation type!",
                      },
                    ]}
                    tooltip={{
                      title:
                        "Public events are open to all.\nPrivate are events\nClose events ",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Radio.Group
                      options={eventTypeOptions}
                      onChange={onEventTypeChange}
                      value={eventType}
                      optionType="button"
                    />
                  </Form.Item>
                  <ParticipantField
                    className="pb-1"
                    validationMessages={validationMessages}
                    extendedView={true}
                    form={form}
                  />
                </Form>
              </div>
              <div className="wizard-page-container">
                <h2>Set interest and softkills benefits for event.</h2>
                <Form form={form} layout="vertical" size="large">
                  <Form.Item
                    name="sel-interest"
                    label="Select event interest"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select Interest for the event!",
                      },
                    ]}
                  >
                    <Radio.Group
                      className="select-item-row"
                      options={interestOptions}
                      onChange={onInterestChange}
                      value={interestId}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="wizard-page-container">
                <h2>Set interest and softkills benefits for event.</h2>
                <div className="sel-interest">{selectedInterest}</div>
                <span className="form-label">
                  Adjust softskills distribution
                </span>
                <Form
                  className="form-softskill"
                  labelCol={{ span: 16 }}
                  layout="horizontal"
                  size="large"
                  form={form}
                >
                  <h5 className="text-head pb-3">Points Distribution</h5>

                <Form.List name="softSkillScores">
                    {(fields, { add, remove }) => (
                      <>
                        <div className="point-list">
                          {fields.map(({ key, name, ...restField }) => (
                            <PointDistributionInput
                              key={key}
                              name={name}
                              restField={restField}
                              remove={remove}
                              softSkillList={softSkillList}
                              isLoadingSoftSkillList={isLoadingSoftSkillList}
                              changeSoftSkillHandler={changeSoftSkillHandler}
                              selectedSoftSkill={selectedSoftSkill}
                              setSelectedSkill={setSelectedSkill}
                            />
                          ))}
                        </div>
                        
                        <Form.Item>
                          <Button
                            key="submit"
                            className="btn-breeze-box-radius w-auto"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              add();
                              setIsSoftSkillValid(true);
                            }}
                            block
                          >
                            Add Soft Skill
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <div className="div-total">
                    Total:
                    {totalScore}%
                  </div>
                </Form>
                {isSoftSkillValid && (
                  <Text type="danger">
                    Total score must be equivalent to 100.
                  </Text>
                )}
              </div>
              <div className="wizard-page-container">
                <h2>Let's put details on your event.</h2>
                <Form
                  form={form}
                  layout="vertical"
                  size="large"
                  disabled={isLoading}
                >
                  <Form.Item
                    name="txt-title"
                    label="Set title for your event"
                    required
                    rules={[{ required: true, message: "Title is required!" }]}
                  >
                    <Input
                      placeholder="Enter event title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item name="txt-description" label="Set description">
                    <TextArea
                      placeholder="Enter event description"
                      style={{ height: 120 }}
                      disabled={isLoading}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Item>
                  <div
                    className={`${
                      validationMessages &&
                      validationMessages.error &&
                      "mb-4 has-error"
                    }`}
                  >
                    {validationMessages && validationMessages.error && (
                      <AlertContainer data={validationMessages} />
                    )}
                  </div>
                </Form>
              </div>
            </Carousel>
          </Content>
          <Footer>
            <Button
              type="primary"
              className="btn-breeze-box-radius"
              onClick={onContinueClick}
              loading={isLoading}
            >
              Continue
            </Button>
          </Footer>
        </Fragment>
      )}
      {isSuccess && (
        <div className="app-main-content-wrapper fadein">
          <div className="app-main-content">
            <div className="app-main-content-inner h-100-i">
              <div className="d-flex justify-content-center mt-7">
                <div className="pt-5">
                  <img
                    height="auto"
                    width="320px"
                    src={successImage}
                    alt="event success image"
                  />
                  <h2 className="text-center mt-3">
                    Event successfully created!
                  </h2>
                  <div style={{ padding: 36, textAlign: "center" }}>
                    <Button
                      className="btn-breeze-box-radius w-auto"
                      type="primary"
                      onClick={() => navigate(`/club/${memberGroupId}`)}
                      block
                    >
                      Back to event list
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default connect(null, {
  getInterestListNonPaged,
  getSoftSkillsNonPaged,
  getLocationEventList,
  addEvent,
})(EventWizard);
