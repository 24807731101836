import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Col, Divider, Form, Input, Modal, Row, Skeleton } from "antd";
import {
  getClubModeratorList,
  addClubModerator,
  declineClubJoinRequest,
  acceptClubJoinRequest,
  dropClubMember,
  acceptDropModerator,
  rejectDropModerator,
} from "../../../actions/club";
import ClubEventItemGrid from "./ClubEventItemGrid";
import { Button, Tooltip } from "antd";
import {
  TableOutlined,
  AppstoreOutlined,
  FilterOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import ClubEventItemList from "./ClubEventItemList";
import { EventModel } from "../../../interface/Event";
import { useParams } from "react-router-dom";
import ClubMemberItemGrid from "./ClubMemberItemGrid";
import { MemberModel } from "../../../interface/Member";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import {
  BAD_REQUEST,
  CREATED,
  NO_CONTENT,
  OK,
} from "../../../helpers/statuscodes";
import ClubParticipantField from "../../super-admin/select-fields/ClubParticipantField";
import ClubMemberItemList from "./ClubMemberItemList";
import ModalConfirm from "../Modals/ModalDeclineConfirmation";
import ModalDeclineReason from "../Modals/ModalDeclineReason";
import ModalInviteClubMembers from "../Modals/ModalInviteClubMembers";
import { getNonClubMember } from "../../../actions/club";
import ModalSuccess from "../Modals/ModalSuccess";
import ModalCustomForm from "../Modals/ModalCustomForm";
import TextArea from "antd/lib/input/TextArea";
import { ClubMemberModel } from "../../../interface/Club";
import { StudentString } from "../../../helpers/memberTypes";
import { drop } from "lodash";

const { Search } = Input;
interface UserValue {
  label: string;
  value: string;
}
const ClubModerators = (props: {
  Permissions: any;
  getClubModeratorList: Function;
  addClubModerator: Function;
  acceptClubJoinRequest: Function;
  declineClubJoinRequest: Function;
  dropClubMember: Function;
  groupName: string;
  clubUserMemberShipType: string;
  getNonClubMember: Function;
  auth: any;
  acceptDropModerator: Function;
  rejectDropModerator: Function;
  fetchClubDetails: Function;
  pushData: any;
  flutterConnect: boolean;
}) => {
  const { memberGroupId } = useParams();

  const {
    Permissions: { canInviteMember, isDisabled },
    getClubModeratorList,
    addClubModerator,
    acceptClubJoinRequest,
    declineClubJoinRequest,
    dropClubMember,
    groupName,
    clubUserMemberShipType,
    getNonClubMember,
    auth,
    acceptDropModerator,
    rejectDropModerator,
    fetchClubDetails,
    pushData,
    flutterConnect
  } = props;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const initialPagination = {
    current: 1,
  };
  const PAGE_SIZE = 6;
  const [pagination, setPagination] = useState(initialPagination);
  const [searchString, setSearchString] = useState("");
  const [clubMembers, setClubMembers] = useState<MemberModel[]>([]);

  const [isViewType, setIsViewType] = useState(1);

  const [isListLoading, setIsListLoading] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);
  const [hasMorePage, setHasMorePage] = useState(false);

  const [formDeclineJoinRequest] = Form.useForm();

  const [isModalDeclineReasonOpen, setIsModalDeclineReasonOpen] =
    useState(false);
  const [isModalCustomOpen, setIsModalCustomOpen] = useState(false);

  const [selectedMemberId, setSelectedMemberId] = useState<string>("");
  const [selectedMemberType, setSelectedMemberType] = useState<string>("");
  const [selectedDropType, setSelectedDropType] = useState<string>("");

  const [selectedMemberFullName, setSelectedMemberFullName] =
    useState<string>("");

  const [isLoadingModalDeclineReason, setIsLoadingModalDeclineReason] =
    useState(false);
  const [reasonModalType, setReasonModalType] = useState("");
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isLoadingModalConfirm, setIsLoadingModalConfirm] = useState(false);
  const [isModalInviteClubMemberOpen, setIsModalInviteClubMemberOpen] =
    useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [inviteModeratorSearchTerm, setInviteModeratorSearchTerm] =
    useState("");
  const [studentList, setStudentList] = useState([]);
  const [isModalInviteMemberLoading, setIsModalInviteMemberLoading] =
    useState(false);
  const [isModalInviteMemberSearching, setIsModalInviteMemberSearching] =
    useState(false);
  const [invitedModeratorList, setInvitedModeratorList]: any = useState([]);
  const [validationMessages, setValidationMessages]: any = useState({});

  const onApproveRequestHandler = async (memberId: string) => {
    SetLoadingItem(memberId, true);

    let params = {
      clubId: memberGroupId,
      memberId,
    };
    const res = await acceptClubJoinRequest(params);
    if (res.status == OK) {
      fetchClubDetails();
      setIsModalDeclineReasonOpen(false);
      formDeclineJoinRequest.resetFields();
      setClubMembers(
        clubMembers.map((member: any) => {
          if (member.memberId === memberId) {
            return {
              ...member,
              ...res.data,
              loading: false,
            };
          } else {
            return member;
          }
        })
      );
    }
    if (!res.data) {
      SetLoadingItem(memberId, false);
    }
  };

  const onDeclineRequestHandler = (memberId: string) => {
    setSelectedMemberId(memberId);
    setReasonModalType("Decline");
    setIsModalDeclineReasonOpen(true);
  };

  const submitFormDeclineJoinRequest = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);

    let params = {
      ...formData,
      clubId: memberGroupId,
      memberId: selectedMemberId,
    };
    const res = await declineClubJoinRequest(params);
    if (res.status == OK) {
      setIsModalDeclineReasonOpen(false);
      formDeclineJoinRequest.resetFields();
      setClubMembers(
        clubMembers.map((member: any) => {
          if (member.memberId === selectedMemberId) {
            return {
              ...res.data,
              loading: false,
            };
          } else {
            return member;
          }
        })
      );
    }
    setIsLoadingModalDeclineReason(false);
  };

  const submitFormDropMember = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);

    let params = {
      ...formData,
      clubId: memberGroupId,
      memberId: selectedMemberId,
      dropMember: selectedDropType === "both" ? true : false,
    };
    const res = await dropClubMember(params);
    if (res.status == NO_CONTENT || res.status === OK) {
      setIsModalCustomOpen(false);
      formDeclineJoinRequest.resetFields();
      setIsModalDeclineReasonOpen(false);
      setIsModalSuccessOpen(true);
      if (selectedMemberType === StudentString) {
        setSuccessMessage(
          `You have successfully dropped ${selectedMemberFullName} as club moderator`
        );
      } else {
        setSuccessMessage(
          `Admin will approve your request to remove ${selectedMemberFullName} as Moderator.`
        );
      }

      //commented out so student will be removed from mod list even if drooped as moderator only (mad)
      // if (selectedDropType === "both") {
        setClubMembers(
          clubMembers.filter((member: any) => {
            if (member.memberId !== selectedMemberId) {
              return member;
            }
          })
        );
      // } else {
      //   setClubMembers(
      //     clubMembers.map((member: any) => {
      //       if (member.memberId !== selectedMemberId) {
      //         return member;
      //       } else if (member.memberId === selectedMemberId) {
      //         return res.data;
      //       }
      //     })
      //   );
      // }
    }
    setIsLoadingModalDeclineReason(false);
  };

  const onDropMemberHandler = async (member: ClubMemberModel) => {
    setSelectedMemberFullName(member.firstName + " " + member.lastName);
    setSelectedMemberId(member.memberId);
    setSelectedMemberType(member.memberType);
    setIsModalCustomOpen(true);
    // setReasonModalType("Drop");
    // setIsModalDeclineReasonOpen(true);
  };

  const onDropModApprovalHandler = async (memberId: string, type: string) => {
    if (type === "Approve") {
      SetLoadingItem(memberId, true);
      let params = {
        clubId: memberGroupId,
        memberId,
      };
      const res = await acceptDropModerator(params);
      if (res.status == OK) {
        setClubMembers(
          clubMembers.filter((member: any) => {
            if (member.memberId !== memberId) {
              return member;
            }
          })
        );
      }
      if (!res.data) {
        SetLoadingItem(memberId, false);
      }
    } else {
      setSelectedMemberId(memberId);
      setReasonModalType("DeclineDrop");
      setIsModalDeclineReasonOpen(true);
    }
  };

  const submitFormDropModRequest = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);

    let params = {
      ...formData,
      clubId: memberGroupId,
      memberId: selectedMemberId,
    };
    const res = await rejectDropModerator(params);
    if (res.status == OK) {
      setIsModalDeclineReasonOpen(false);
      formDeclineJoinRequest.resetFields();
      setClubMembers(
        clubMembers.map((member: any) => {
          if (member.memberId === selectedMemberId) {
            return {
              ...res.data,
              loading: false,
            };
          } else {
            return member;
          }
        })
      );
    }
    setIsLoadingModalDeclineReason(false);
  };

  const SetLoadingItem = (memberId: any, loading: boolean) => {
    setClubMembers(
      clubMembers.map((member: any) => {
        if (member.memberId === memberId) {
          return {
            ...member,
            loading,
          };
        } else {
          return member;
        }
      })
    );
  };

  const onSubmitModalInviteClubMember = async () => {
    setIsModalInviteMemberLoading(true);

    let res = await addClubModerator(
      {
        memberIds: invitedModeratorList,
        clubId: memberGroupId,
        membershipType: 0,
      },
      memberGroupId
    );
    if (res.status == OK || res.status == CREATED) {
      getListToInvite();
      setInvitedModeratorList([]);
      setIsModalInviteClubMemberOpen(false);
      setIsModalSuccessOpen(true);
      fetchClubModerators(initialPagination, false);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsModalInviteMemberLoading(false);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  const onSelectInviteModerator = (checked: boolean, memberId: string) => {
    if (checked) {
      if (
        invitedModeratorList.filter((item: string) => item.includes(memberId))
          .length == 0
      ) {
        setInvitedModeratorList(invitedModeratorList.concat(memberId));
      }
    } else {
      setInvitedModeratorList(
        invitedModeratorList.filter((item: string) => item !== memberId)
      );
    }
  };

  const getListToInvite = async () => {
    let params = {
      memberGroupId,
      membershipType: 0,
      searchString: inviteModeratorSearchTerm,
    };
    const data = await getNonClubMember(params);
    const formattedData = data.data.filter((item: any) => {
      if (item.memberId !== signedInUserMemberId) {
        return {
          item,
        };
      }
    });
    setStudentList(formattedData);
    setIsModalInviteMemberSearching(false);
  };

  useEffect(() => {
    setIsModalInviteMemberSearching(true);
    const delaySearch = setTimeout(async () => {
      await getListToInvite();
    }, 600);

    return () => clearTimeout(delaySearch);
  }, [inviteModeratorSearchTerm]);

  const fetchClubModerators = async (paginationParam: any, isMore: boolean) => {
    setLockLoading(true);
    setIsListLoading(true);
    if (paginationParam.current === 1) {
      setClubMembers([]);
    }

    let params = {
      page: paginationParam.current,
      pageSize: 6,
      searchString,
      memberGroupId,
    };

    const res = await getClubModeratorList(params);

    let items = res.data.items;
    if (items.length !== 0) {
      if (paginationParam.current !== 1) {
        const formattedData = items.map((item: MemberModel) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setClubMembers(clubMembers.concat(formattedData));
      } else {
        const formattedData = items.map((item: MemberModel) => {
          return {
            ...item,
            loading: false,
          };
        });
        setClubMembers(formattedData);
      }
      setPagination({
        current: paginationParam.current + 1,
      });
      const _hasMorePages =
        Math.ceil(res.data.totalItems / PAGE_SIZE) > pagination.current;
      setHasMorePage(_hasMorePages);
    }
    setIsListLoading(false);
    setTimeout(() => {
      setLockLoading(false);
    }, 500);
  };

  useEffect(() => {
    setPagination(initialPagination);
    fetchClubModerators(initialPagination, false);
  }, [searchString]);

  useEffect(() => {
    window.onscroll = async function (ev) {
      // console.log(ev);
      if (window.innerHeight + window.scrollY >= (flutterConnect ? document.body.scrollHeight - 1  : document.body.scrollHeight)) {
        if (!lockLoading && hasMorePage) {
          await fetchClubModerators(pagination, true);
        }
      }
    };
  }, [pagination, lockLoading, hasMorePage]);

  useEffect(() => {
    if (pushData.data?.topic === "ClubModeratorUpdate") {
      const pushInfo = JSON.parse(pushData.data?.pushData);
      if (
        pushInfo.MemberGroupId === memberGroupId &&
        pushData.data?.memberId !== auth.member.memberId
      ) {
        fetchClubModerators(initialPagination, false);
      }
    }
  }, [pushData]);

  return (
    <Fragment>
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p className="text-center">{successMessage}</p>
      </ModalSuccess>

      <ModalDeclineReason
        title={`${
          reasonModalType === "Decline"
            ? "Enter Decline Reason"
            : reasonModalType === "Drop"
            ? "Enter Removal Reason"
            : reasonModalType === "DeclineDrop" && "Reject Drop Moderator"
        }`}
        handleSubmit={
          reasonModalType === "Drop"
            ? submitFormDropMember
            : reasonModalType === "Decline"
            ? submitFormDeclineJoinRequest
            : reasonModalType === "DeclineDrop" && submitFormDropModRequest
        }
        isOpen={isModalDeclineReasonOpen}
        setIsOpen={setIsModalDeclineReasonOpen}
        form={formDeclineJoinRequest}
        isLoading={isLoadingModalDeclineReason}
      />
      <ModalCustomForm
        title={`Club Moderator Removal`}
        handleSubmit={submitFormDropMember}
        isOpen={isModalCustomOpen}
        setIsOpen={setIsModalCustomOpen}
        form={formDeclineJoinRequest}
        isLoading={isLoadingModalDeclineReason}
        footerArray={
          selectedMemberType === StudentString
            ? [
                <Button
                  type="primary"
                  shape="round"
                  key="submit"
                  loading={isLoadingModalDeclineReason}
                  onClick={() => {
                    setSelectedDropType("both");
                    formDeclineJoinRequest.submit();
                  }}
                >
                  Remove both
                </Button>,
                <Button
                  shape="round"
                  key="submit"
                  loading={isLoadingModalDeclineReason}
                  onClick={() => {
                    setSelectedDropType("moderatorOnly");
                    formDeclineJoinRequest.submit();
                  }}
                >
                  Moderator only
                </Button>,
                <Button
                  shape="round"
                  key="cancel"
                  loading={isLoadingModalDeclineReason}
                  onClick={() => setIsModalCustomOpen(false)}
                >
                  Cancel
                </Button>,
              ]
            : [
                <Button
                  type="primary"
                  shape="round"
                  key="submit"
                  loading={isLoadingModalDeclineReason}
                  onClick={() => {
                    setSelectedDropType("moderatorOnly");
                    formDeclineJoinRequest.submit();
                  }}
                >
                  Submit
                </Button>,
                <Button
                  shape="round"
                  key="cancel"
                  loading={isLoadingModalDeclineReason}
                  onClick={() => setIsModalCustomOpen(false)}
                >
                  Cancel
                </Button>,
              ]
        }
      >
        <Fragment>
          <p className="mb-2">
            {selectedMemberFullName} will be removed as Club Moderator due to
            the following reasons:
          </p>
          <Form.Item
            className="mb-0"
            name={`remarks`}
            rules={[{ required: true, message: "Please input your reason!" }]}
          >
            <TextArea
              // onChange={(e) => setCount(e.target.value.length)}
              rows={3}
              placeholder="Input reason"
              maxLength={1000}
            />
          </Form.Item>
          {selectedMemberType === StudentString && (
            <p className="mt-2 mb-0">
              {selectedMemberFullName} is both student and a moderator, do you
              also want to remove him as member?
            </p>
          )}
        </Fragment>
      </ModalCustomForm>
      <ModalInviteClubMembers
        modalTitle="Invite Club Moderators"
        handleSubmit={onSubmitModalInviteClubMember}
        isOpen={isModalInviteClubMemberOpen}
        isLoading={isModalInviteMemberLoading}
        setIsOpen={setIsModalInviteClubMemberOpen}
        onSearch={(searchTerm: string) =>
          setInviteModeratorSearchTerm(searchTerm)
        }
        list={studentList}
        isSearching={isModalInviteMemberSearching}
        onSelectHandler={onSelectInviteModerator}
        selectedList={invitedModeratorList}
        validationMessages={validationMessages}
      />
      <div className="content-container">
        <div className="club-member-list">
          <div className="list-controls">
            <div className="search-container">
              <Search
                //onKeyUp={onFilterSearchHandler}
                onSearch={(val) => setSearchString(val)}
                allowClear
                placeholder="Search Moderator Name"
                style={{ width: 280 }}
              />
            </div>
            <div className="list-filters">
              <Tooltip title="Table View">
                <Button
                  shape="circle"
                  icon={<TableOutlined />}
                  onClick={() => setIsViewType(2)}
                />
              </Tooltip>
              <Tooltip title="Grid View">
                <Button
                  shape="circle"
                  icon={<AppstoreOutlined />}
                  onClick={() => setIsViewType(1)}
                />
              </Tooltip>
              {canInviteMember && (
                <Button
                  disabled={isDisabled}
                  type="primary"
                  shape="round"
                  icon={<CheckOutlined size={16} />}
                  onClick={() => setIsModalInviteClubMemberOpen(true)}
                >
                  Invite Moderators
                </Button>
              )}
            </div>
          </div>
          <Fragment>
            <Row gutter={16}>
              {isViewType === 1 ? (
                <Fragment>
                  {clubMembers.map((member: any) => (
                    <ClubMemberItemGrid
                      key={member.memberId}
                      member={member}
                      clubUserMemberShipType={clubUserMemberShipType}
                      onDeclineHandler={onDeclineRequestHandler}
                      onApproveHandler={onApproveRequestHandler}
                      onDropMemberHandler={onDropMemberHandler}
                      onPromoteMemberHandler={() => console.log()}
                      onDropModApprovalHandler={onDropModApprovalHandler}
                    />
                  ))}
                  {isListLoading && (
                    <Fragment>
                      {Array(8)
                        .fill(0)
                        .map((_, i) => {
                          return (
                            <Col
                              key={i}
                              span={6}
                              xs={{ span: 24 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 6 }}
                              className="mb-5"
                            >
                              <div className="member-item-grid-view">
                                <div className="skeleton-loader">
                                  <div className="skeleton-loader-header">
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                  </div>
                                  <Skeleton.Image className="skeleton-img w-100 mb-3" />
                                  <div className="d-flex-column justify-content-center align-items-center">
                                    <Skeleton.Input
                                      className="custom-input-sk"
                                      active
                                    />
                                    <Skeleton.Input
                                      className="custom-input-sk"
                                      active
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {clubMembers.map((member: any) => (
                    <ClubMemberItemList
                      key={member.memberId}
                      member={member}
                      clubUserMemberShipType={clubUserMemberShipType}
                      onDeclineHandler={onDeclineRequestHandler}
                      onApproveHandler={onApproveRequestHandler}
                      onDropMemberHandler={onDropMemberHandler}
                      onPromoteMemberHandler={() => console.log()}
                      onDropModApprovalHandler={onDropModApprovalHandler}
                    />
                  ))}
                </Fragment>
              )}
            </Row>
          </Fragment>
          {!hasMorePage && clubMembers.length > 0 && (
            <Divider orientation="center" plain>
              No more members to show
            </Divider>
          )}

          {!isListLoading && !hasMorePage && clubMembers.length === 0 && (
            <Divider orientation="center" plain>
              No members to show
            </Divider>
          )}
        </div>
      </div>
    </Fragment>
  );
};
// const mapStateToProps = (state: { auth: any }) => ({
//   auth: state.auth,
// });

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
  flutterConnect: state.flutterConnect
});

export default connect(mapStateToProps, {
  getClubModeratorList,
  addClubModerator,
  acceptClubJoinRequest,
  declineClubJoinRequest,
  dropClubMember,
  getNonClubMember,
  acceptDropModerator,
  rejectDropModerator,
})(ClubModerators);
