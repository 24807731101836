import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment, { Moment } from "moment";
import { Skeleton, Menu, Button, Table, Space, MenuProps } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { searchUpcomingEvents, getEventsListByDate } from "../../../actions/event";
import noEvents from "../../../assets/images/icons/no_events.svg";
import {
  EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

const DashboardUpcomingEvents = (props: {
    searchUpcomingEvents: Function;
    getEventsListByDate: Function;
    flutterConnect: Boolean;
}) => {
    const { 
      getEventsListByDate,
      flutterConnect
    } = props;
    const today = new Date().getDay().toString();
    const [selectedDay, setSelectedDay] = useState(today);
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState<any[]>([]);

    // const flutterConnect = true;

    let leftMenuItems = [
      {
        key: '1',
        label: <p>Monday</p>,
        disabled: today > "1",
    },
    {
        key: '2',
        label: <p>Tuesday</p>,
        disabled: today > "2",
    },
    {
        key: '3',
        label: <p>Wednesday</p>,
        disabled: today > "3",
    },
    {
        key: '4',
        label: <p>Thursday</p>,
        disabled: today > "4",
    },
    {
        key: '5',
        label: <p>Friday</p>,
        disabled: today > "5",
    },
    {
        key: '6',
        label: <p>Saturday</p>,
        disabled: today > "6",
    },
    {
        key: '0',
        label: <p>Sunday</p>,
    },
    ];

    const onClick: MenuProps['onClick'] = (e) => {
      setSelectedDay(e.key);
  };

    const columnWidth = flutterConnect ? '25%' : undefined;

    const columns = [
        {
            title: "Time",
            key: "time",
            render: (text: any, record: any) => (
              <Space size="middle">
                {`${moment(new Date(record.startDate)).format("LT")}- ${moment(new Date(record.endDate)).format("LT")}`}
              </Space>
            ),
            width: columnWidth
        },
        {
            title: "Event",
            key: "event",
            render: (text: any, record: any) => (
              <Space size="middle">
                {record?.title}
              </Space>
            ),
            width: columnWidth
        },
        {
            title: "Venue",
            key: "venue",
            render: (text: any, record: any) => (
              <Space size="middle">
                {record?.location}
              </Space>
            ),
            width: columnWidth
        },
        {
            title: "Duration",
            key: "duration",
            render: (text: any, record: any) => (
              <Space size="middle">
                {`${moment.duration(moment(record?.endDate).diff(moment(record?.startDate))).asHours()} hours`}
              </Space>
            ),
            width: columnWidth
        },
    ]
    
    const controller = new AbortController();

    const fetchEvents = async () => {
        setEvents([]);
        setIsLoading(true);
        let params;
        params = {
            from: moment().startOf('week').add(parseInt(selectedDay),'day').startOf('day').toISOString(),
            to :moment().startOf('week').add(parseInt(selectedDay),'day').endOf('day').toISOString(),
        }
        const data: any = await getEventsListByDate(params, controller);
        setEvents(data?.data);
        setIsLoading(false);
      };

    useEffect(()=>{
        fetchEvents();
    },[selectedDay])

    const navigateDay = (step: number) => {
      if (!selectedMenu) return;
  
      let index = leftMenuItems.indexOf(selectedMenu);
      index += step;
      index =
        index < 0
          ? leftMenuItems.length - 1
          : index >= leftMenuItems.length
          ? 0
          : index;
  
      setSelectedDay(leftMenuItems[index].key);
    };

    const selectedMenu = !!selectedDay
    ? leftMenuItems[
        selectedDay === "0"
          ? leftMenuItems.length - 1
          : parseInt(selectedDay) - 1
      ]
    : null;

  return (
    <div className="dashboard-upcoming-events">
        <h1>Upcoming Events</h1>
        {/* TODO: Turn into component */}
        {flutterConnect && (
          <div className="navigation">
            <div className="navigation-unary">
              <Button
                className="arrow"
                icon={<LeftOutlined onClick={() => {}} />}
                onClick={() => {
                  navigateDay(-1);
                }}
              />
              <div className="label">
                {selectedMenu ? selectedMenu.label : ""}
              </div>
              <Button
                className="arrow"
                icon={<RightOutlined onClick={() => {}} />}
                onClick={() => {
                  navigateDay(1);
                }}
              />
            </div>
            <div className="navigation-menu">
              <Menu
                className="menus"
                mode="horizontal"
                selectedKeys={[selectedDay]}
                items={leftMenuItems}
                onClick={onClick}
              />
            </div>
          </div>
        )}

        {!flutterConnect && (
          <Menu
            className="menus"
            mode="horizontal"
            selectedKeys={[selectedDay]}
            items={leftMenuItems}
            onClick={onClick}
          />
        )}
        <div className="events">
              {isLoading && (
                <div className="loading-container">
                    <Skeleton loading={true} active />
                     <Skeleton loading={true} active />
                    <Skeleton loading={true} active />
                </div>
            ) }
            {
              flutterConnect && !isLoading && events?.length > 0 && (
                <div
                  className="event-cards"
                >
                  {
                    events.slice(0,5).map(e => (
                      <div className="card">
                        {
                          [
                            {
                              title: 'Time',
                              content: `${moment(new Date(e.startDate)).format("LT")}- ${moment(new Date(e.endDate)).format("LT")}`
                            },
                            {
                              title: 'Event',
                              content: e.title
                            },
                            {
                              title: 'Venue',
                              content: e.location
                            },
                            {
                              title: 'Duration',
                              content: `${moment.duration(moment(e?.endDate).diff(moment(e?.startDate))).asHours()} hours`
                            },
                          ].map(f => (
                            <div className="row">
                              <span className="title">{f.title}</span>
                              <span className="content">{f.content}</span>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              )
            }
            {!isLoading && events?.length>0 &&
                <Table
                className="event-table"
                rowClassName={(record, index) => flutterConnect ? index % 2 === 0 ? 'light' :  'dark' : ''}
                rowKey="eventId"
                columns={columns}
                pagination={false}
                dataSource={events.slice(0,5)}
                loading={isLoading}
              />
                
            }
            {!isLoading && events?.length===0 &&
                  <div className="no-events-container">
                  <img height={120} width={120} src={noEvents} />
                  <h4>No events today.</h4> 
              </div>
            }
        </div>
        <Link to="/admin/events">
            <Button
            type="default"
            shape="default"
            key="submit"
            className="view-all"
            >

              <span className="view-all-content">
                View all Events
              </span>

              <span className="show-more-content">
                Show more
              </span>
            </Button>
        </Link>
    </div>
  );
};


const mapStateToProps = (state: { auth: any; userIsBack: boolean; flutterConnect: boolean; }) => ({
    interests: state.auth.member.interests,
    userIsBack: state.userIsBack,
    flutterConnect: state.flutterConnect,
  });
export default connect(mapStateToProps, {
    searchUpcomingEvents,
    getEventsListByDate,
  })(DashboardUpcomingEvents);