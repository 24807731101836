import { setAlert } from './alert';
import { axiosApiInstance } from '../utils/axiosApiInstance';
import {
  OK,
  CREATED,
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";

import { callApi } from './general';

const baseUrl = '/api/Announcement';

export const createAnnouncement =
  (values, controller) => async (dispatch) => {
    var params = [
      { name: 'title' },
      { name: 'body' },
      { name: 'clubIds' },
      { name: 'startDate' },
      { name: 'endDate' },
    ];
    return await callApi({
      params: params,
      values: values,
      controller: controller,
      dispatch: dispatch,
      type: 'post',
      baseUrl: baseUrl,
      statusAlert: {
        '201': {
          title: 'Anouncement',
          message: 'Announcement added.',
          type: 'success'
        },
        '404': {
          title: 'Error',
          message: 'Unable to add announcement',
          type: 'error'
        }
      }
    });
  };

  export const updateAnnouncement =
  (id, values, controller) => async (dispatch) => {
    var params = [
      { name: 'title' },
      { name: 'body' },
      { name: 'clubIds' },
      { name: 'startDate' },
      { name: 'endDate' },
    ];
    return await callApi({
      api: id,
      params: params,
      values: values,
      controller: controller,
      dispatch: dispatch,
      type: 'put',
      baseUrl: baseUrl,
      statusAlert: {
        '200': {
          title: 'Anouncement',
          message: 'Announcement updated.',
          type: 'success'
        },
        '400': {
          title: 'Error',
          message: 'Unable to update announcement',
          type: 'error'
        },
        '404': {
          title: 'Error',
          message: 'Unable to update announcement',
          type: 'error'
        }
      }
    });
  };

  export const deleteAnnouncement =
  (id, controller) => async (dispatch) => {

    return await callApi({
      api: id,
      controller: controller,
      dispatch: dispatch,
      type: 'delete',
      baseUrl: baseUrl,
      statusAlert: {
        '204': {
          title: 'Anouncement',
          message: 'Announcement deleted.',
          type: 'success'
        },
        '404': {
          title: 'Error',
          message: 'Unable to delete announcement',
          type: 'error'
        }
      }
    });
  };

export const getAnnouncement =
  (id, controller) => async (dispatch) => await callApi({
    api: id,
    controller: controller,
    dispatch: dispatch,
    baseUrl: baseUrl,
  });

export const getAnnouncements =
  (values, controller) => async (dispatch) => {
    var params = [
      { name: 'isActive' },
      { name: 'isSiteWide' },
      { name: 'from' },
      { name: 'to' },
      { name: 'page' },
      { name: 'pageSize' },
      { name: 'searchString' },
    ];

    return await callApi({
      api: 'list',
      params: params,
      values: values,
      controller: controller,
      dispatch: dispatch,
      baseUrl: baseUrl,
    });
  };

  export const getPagedAnnouncements =
  (values, controller) => async (dispatch) => {
    
    var params = [
      { name: 'isActive' },
      { name: 'isSiteWide' },
      { name: 'from' },
      { name: 'to' },
      { name: 'page' },
      { name: 'pageSize' },
      { name: 'searchString' },
      { name: 'isClubFiltered' },
    ];

    return await callApi({
      api: '',
      params: params,
      values: values,
      controller: controller,
      dispatch: dispatch,
      baseUrl: baseUrl,
    });
  };