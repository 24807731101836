import { Button, PageHeader, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getSchoolYearList } from "../../../actions/schoolyear";
import Search from "antd/lib/input/Search";
import moment from "moment";

const SchoolYearList = (props: { getSchoolYearList: Function }) => {
  const columns = [
    {
      title: "Label",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Duration",
      key: "date",
      render: (text: string, item: any) => (
        <Space size="middle">
          {moment(new Date(item.startDate)).format("ll") +
            " - " +
            moment(new Date(item.endDate)).format("ll")}
        </Space>
      )
    },
    {
      title: "For",
      dataIndex: "memberGroupCategory",
      key: "memberGroupCategory"
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, item: any) => (
        <Space size="middle">
          <a
            id={item.softSkillId}
            onClick={() => navigate(`/admin/schoolyears/${item.schoolYearId}`)}
          >
            Edit
          </a>
        </Space>
      )
    }
  ];
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small"
  };
  const [isLoading, setIsLoading] = useState(false);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [pagination, setPagination]: any = useState(initialPagination);
  const navigate = useNavigate();

  const onTableChange = (tablePagination: any) => {
    fetchList(tablePagination);
  };

  const fetchList = async (parameters: any) => {
    setIsLoading(true);
    parameters = { ...parameters, searchString };
    const result = await props.getSchoolYearList(parameters);
    setSchoolYearList(result.data.items);
    setPagination({
      current: result.data.page,
      pageSize: result.data.pageSize,
      total: result.data.totalItems
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString]);

  return (
    <div className="app-main-content-wrapper">
      <PageHeader
        className="site-page-header content-header"
        onBack={() => null}
        title="School Year"
        extra={[
          <Button className="btn-radius" key="add" type="primary">
            <Link to={"/admin/schoolyears/add"}>Set up School Year</Link>
          </Button>
        ]}
      />
      <div className="app-main-content">
        <div className="app-main-content-inner">
          <div className="content-container">
            <div className="table-controls d-flex pb-3">
              <div className="my-auto mr-auto"></div>
              <div>
                <Search
                  allowClear
                  onSearch={(str) => setSearchString(str)}
                  placeholder="Search by School Year Name"
                  style={{ width: 280 }}
                />
              </div>
            </div>
            <div>
              <Table
                rowKey="schoolYearId"
                columns={columns}
                pagination={{
                  ...pagination,
                  position: ["bottomLeft"],
                  showTotal: (total: number, range: [number, number]) =>
                    `Total ${total} items`,
                  size: "default"
                }}
                scroll={{ y: "calc(100vh - 420px)" }}
                dataSource={schoolYearList}
                loading={isLoading}
                onChange={onTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { getSchoolYearList })(SchoolYearList);
