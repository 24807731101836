import { Col, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageBanner from "../layouts/PageBanner";
import PrivacyContent from "./PrivacyContent";

const PrivacyPage = () => {
  const location = useLocation();
  const [activeRef, setActiveRef] = useState("#page1");
  useEffect(() => {
    setActiveRef(location.hash);
  }, [location.hash]);
  return (
    <div className="bg-white">
      <PageBanner title={"Privacy Policy"} />
      <div className="content-wrapper mt-5">
        <div className="content-container">
         <PrivacyContent />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
