import AlertContainer from "../../../layouts/AlertContainer";
import ModalDeleteAlert from "../../layout/ModalDeleteAlert";
import moment from "moment";
import { Form, Input, Button, Select, Col, Row, Radio, DatePicker } from "antd";
import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getSchoolList } from "../../../actions/school";
import { getCategoryList } from "../../../actions/school";
import { getMemberDetails } from "../../../actions/school";
import { deleteMember } from "../../../actions/school";
import { addStudentUser, updateStudentUser } from "../../../actions/student";
import { OK, CREATED, BAD_REQUEST } from "../../../helpers/statuscodes";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import { useLocation } from "react-router-dom";

const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const AddStudentForm = (props: {
  addStudentUser: Function;
  updateStudentUser: Function;
  getCategoryList: Function;
  getMemberDetails: Function;
  deleteMember: Function;
}) => {
  const {
    addStudentUser,
    updateStudentUser,
    getCategoryList,
    getMemberDetails,
    deleteMember
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [birthDate, setBirthDate] = useState("");
  const [isEditMode, setIsEditMode]: any = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [groupCategories, setGroupCategories] = useState<any[]>([]);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [addStudentForm] = Form.useForm();
  const location: any = useLocation();

  const getCategories = async () => {
    const response = await getCategoryList();

    if (response.status === OK) {
      setGroupCategories(response.data);
    }
  };

  const getStudentDetails = async () => {
    const response = await getMemberDetails({
      memberId: location.state.memberId
    });
    if (response.status === OK) {
      const result = response.data;
      const bDate = moment(result.birthDate);
      setBirthDate(bDate.format(dateFormat));
      addStudentForm.setFieldsValue(result);
      addStudentForm.setFieldsValue({ bDate });
      addStudentForm.setFieldsValue(result.internalGroups);
    }
  };

  const onFinish = async (formData: any) => {
    let values = {
      ...formData,
      birthDate: new Date(birthDate).toISOString()
    };
    setValidationMessages(new Array());
    setIsLoading(true);
    let res;
    let internalGroups = {};
    groupCategories.forEach((item) =>
      Reflect.set(
        internalGroups,
        item.groupCategoryId,
        addStudentForm.getFieldValue(item.groupCategoryId)
      )
    );
    if (isEditMode) {
      let customFormData = {
        ...values,
        memberType: 0,
        internalGroups
      };
      res = await updateStudentUser(customFormData, location.state.memberId);
    } else {
      let customFormData = {
        ...values,
        internalGroups
      };
      res = await addStudentUser(customFormData);
    }
    if (res.status == OK || res.status == CREATED) {
      if (!isEditMode) {
        onReset();
      }
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  //deactivate student
  const deleteItemHandler = async () => {
    setIsDeleteLoading(true);
    const res = await deleteMember(location.state.memberId);
    if (res.status === 204) {
      setIsModalDeleteVisible(false);
    }
    setIsDeleteLoading(false);
  };

  const onReset = () => {
    setValidationMessages([]);
    addStudentForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    const initialize = async () => {
      await getCategories();
      if (location && location.state) {
        setIsEditMode(true);
        await getStudentDetails();
      }
    };

    initialize();
  }, []);

  return (
    <Fragment>
      <ModalDeleteAlert
        modalTitle="Student"
        isModalDeleteVisible={isModalDeleteVisible}
        onModalCancelHandler={() => setIsModalDeleteVisible(false)}
        deleteItemHandler={deleteItemHandler}
        isDeleteLoading={isDeleteLoading}
      />
      <Row>
        <Col span={24}>
          <Form
            form={addStudentForm}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="breeze-form-1 custom-alert"
          >
            <Row gutter={24}>
              <Col
                span={8}
                xs={{ span: 24 }}
                lg={{ span: 16 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    { required: true, message: "Please select your gender" }
                  ]}
                >
                  <Radio.Group>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.gender &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.gender && (
                    <AlertContainer data={validationMessages.gender} />
                  )}
                </div>

                <Form.Item
                  name="bDate"
                  label="Birthdate"
                  rules={[
                    { required: true, message: "Please select your Birthdate" }
                  ]}
                >
                  <DatePicker
                    placeholder="Select Birthdate"
                    format={dateFormat} //{customFormat}
                    onChange={(value, dateString) =>
                      value && setBirthDate(dateString)
                    }
                  />
                </Form.Item>

                <Row justify="space-between" gutter={24}>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      className="mb-0"
                      label="First Name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!"
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              //resolve to use original rule
                              return Promise.resolve();
                            }
                            if (!isEmptyOrSpaces(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error("Please input your First Name!")
                              );
                            }
                          }
                        })
                      ]}
                    >
                      <Input placeholder="Enter Given Name" />
                    </Form.Item>
                    <div
                      className={`mb-4 ${
                        validationMessages &&
                        validationMessages.FirstName &&
                        "has-error"
                      }`}
                    >
                      {validationMessages && validationMessages.FirstName && (
                        <AlertContainer data={validationMessages.FirstName} />
                      )}
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      className="mb-0"
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!"
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              //resolve to use original rule
                              return Promise.resolve();
                            }
                            if (!isEmptyOrSpaces(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error("Please input your Last Name!")
                              );
                            }
                          }
                        })
                      ]}
                    >
                      <Input placeholder="Enter Family Name" />
                    </Form.Item>
                    <div
                      className={`mb-4 ${
                        validationMessages &&
                        validationMessages.LastName &&
                        "has-error"
                      }`}
                    >
                      {validationMessages && validationMessages.LastName && (
                        <AlertContainer data={validationMessages.LastName} />
                      )}
                    </div>
                  </Col>
                </Row>
                <Form.Item
                  className="mb-0"
                  label="School Email"
                  name="emailAddress"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The email you entered is invalid!"
                    }
                  ]}
                >
                  <Input placeholder="eg johndoe@xyz.edu.ph " disabled={isEditMode ? true : false} />
                </Form.Item>
                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.EmailAddress &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.EmailAddress && (
                    <AlertContainer data={validationMessages.EmailAddress} />
                  )}
                </div>

                {groupCategories.map((item: any) => (
                  <Form.Item
                    key={item.groupCategoryId}
                    name={item.groupCategoryId}
                    label={item.name}
                  >
                    <Select
                      placeholder={`Select ${item.name}`}
                      disabled={isLoading}
                    >
                      {item.memberGroups.map((catItem: any) => (
                        <Option
                          key={catItem.memberGroupId}
                          value={catItem.memberGroupId}
                        >
                          {catItem.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}

                <div
                  className={`mb-4 ${
                    validationMessages &&
                    validationMessages.schoolGrade &&
                    "has-error"
                  }`}
                >
                  {validationMessages && validationMessages.schoolGrade && (
                    <AlertContainer data={validationMessages.schoolGrade} />
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <div className="w-100 d-flex">
                  <Button
                    className="btn-breeze-box mr-2 btn-radius"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save User Info
                  </Button>
                  <Button
                    className="btn-radius"
                    disabled={isLoading}
                    onClick={onReset}
                  >
                    Reset Fields
                  </Button>
                  {isEditMode && (
                    <Button
                      className="ml-auto btn-radius"
                      disabled={isLoading}
                      onClick={() => setIsModalDeleteVisible(true)}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, {
  addStudentUser,
  updateStudentUser,
  getSchoolList,
  getMemberDetails,
  getCategoryList,
  deleteMember
})(AddStudentForm);
