import { Avatar, Button, Form, List, message, Spin, Typography, Checkbox } from "antd";
import { Fragment, useEffect, useState } from "react";
import VirtualList from "rc-virtual-list";
import {
  getEventParticipantList,
  handleParticipantJoinRequest
} from "../../actions/participant";
import { connect } from "react-redux";
import { CREATED, OK, NO_CONTENT } from "../../helpers/statuscodes";
import { Invite, Attendance } from "../member-app/events/EventInvite";
import { deleteInvite, updateMultipleAttendance } from "../../actions/event";
import { Modal } from "antd";
import { ExclamationCircleOutlined, CheckCircleFilled, CloseCircleFilled, QrcodeOutlined, CloseOutlined } from "@ant-design/icons";
import ModalDeclineReason from "../member-app/Modals/ModalDeclineReason";
import IconMarkAsAttended from '../../assets/images/icons/icon-mark-as-attended.svg'
import {
  getClubModeratorList,
} from "../../actions/club";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import EmptyState from "../member-app/layouts/EmptyState";

const { Text } = Typography;

const fakeDataUrl =
  "https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo";
const ContainerHeight = 400;

type IProps = {
  auth?: any;
  participantStatus: String;
  getEventParticipantList: Function;
  handleParticipantJoinRequest: Function;
  invitedFilter: boolean;
  deleteInvite: Function;
  updateMultipleAttendance: Function;
  onReload?: Function;
  searchString?: string;
  readonly: boolean;
  event: any;
  userId?: string;
  reload?: boolean;
  setReload?: Function;
  getClubModeratorList:Function;
  showClubName?:boolean;
  isAttendanceModal?:boolean;
};


const AttendanceListWidget = (props: IProps) => {
  const {
    auth,
    handleParticipantJoinRequest,
    getEventParticipantList,
    participantStatus,
    invitedFilter,
    deleteInvite,
    updateMultipleAttendance,
    searchString,
    readonly,
    event,
    userId,
    reload,
    setReload,
    getClubModeratorList,
    showClubName = false,
    isAttendanceModal = false,
  } = props;
  const [isListLoading, setIsListLoading] = useState(false);
  const initialPagination = {
    current: 1
  };
  const [pagination, setPagination] = useState(initialPagination);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isAdmin, setIsAdmin] = useState(auth.member.memberType==="Admin"?true:false);
  const [modList, setModList] = useState<any>([]);
  const [selInvite, setSelInvite] = useState<Invite>();
  const [form] = Form.useForm();
  const [data, setData] = useState<Invite[]>([]);
  const [attendance, setAttendance] = useState<{ [key: string]: any }>({});

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      if(participantStatus==="-1"){
      fetchList(pagination);
     }
    }
  };

  const saveChanges = async () => {
    setIsLoading(true);
    const request = Object.keys(attendance)
      .filter(e => attendance[e].isModified)
      .map((e) => {
        return {
          inviteId: e,
          eventId: event.eventId,
          isAttended: attendance[e]?.isAttended,
        }
      });
    var response = await updateMultipleAttendance(request);
    setIsLoading(false);

    if (response.status == 200)
    {
      message.success(`Saved changes.`);
      var tmpAttendance: { [key: string]: any } = { ...attendance };
      
      Object.keys(tmpAttendance).forEach((e: any) => {
        tmpAttendance[e].isModified = false;
      });
      
      setAttendance(tmpAttendance);
      fetchList(initialPagination);
      delayReload();
    }
  }

  const delayReload = () =>{
    if(!isLoading && setReload !== undefined){
      setTimeout(function(){
        setReload(true);
      },3000)
    }
  }

  const checkAttended = (invite: Invite) : boolean => {
    var result = (
      attendance[invite.inviteId]
      && attendance[invite.inviteId]?.isAttended != null
      && attendance[invite.inviteId]?.isAttended == true
    )
    || (invite.attendance
    && (invite.attendance?.isAttended == null || invite.attendance?.isAttended == true))
    ? true
    : false;
    return result;
  };

  const onEditAttendance = (item: Invite, isAttended: boolean) => {
    var editedAttendance: { [key: string]: any } = {
      ...attendance
    };
    if (editedAttendance[item.inviteId]?.isAttended !== isAttended) {
      editedAttendance[item.inviteId] = {
        isAttended: isAttended,
        isModified: !editedAttendance[item.inviteId].isModified,
      };
      setAttendance(editedAttendance);
    }
  }

  const fetchList = async (paginationParam: any) => {
    if (paginationParam.current === 1) {
      setData([]);
    }
    let params = {
      eventId:event.eventId,
      page: paginationParam.current,
      searchString,
      pageSize: 50,
      status: -1,
      joinRequestOnly: !invitedFilter
    };
    const res = await getEventParticipantList(params);

    const items = res.data.items;
    if (items.length !== 0) {

        var att: { [key: string]: any } = {
          ...attendance
        }

        items.forEach((e: Invite) => {
          if (Object.keys(att).length === 0 || att[e.inviteId]===undefined){
            att[e.inviteId] = {
              isModified: false,
              isAttended: checkAttended(e),
            };
          }
        });

        setAttendance(att);
      if (paginationParam.current !== 1) {
        console.log('pagination is not 1');
        setData(data.concat(items));
      } else {
        console.log('pagination is string');
        if(participantStatus==="-1"){
          setData(items);
        }else if(participantStatus==="1"){
          setData(items.filter((d: any) =>att[d.inviteId]?.isAttended===true))
        }else if(participantStatus==="2"){
          setData(items.filter((d: any) =>att[d.inviteId]?.isAttended===false))
        }else if(participantStatus==="3"){
          setData(items);
        }else if(participantStatus==="4"){
          setData(items.filter((d: any) =>d.memberId===userId))
        }
      }
      setPagination({
        current: pagination.current + 1
      });
    }
    props.onReload && props.onReload({ pendingCount: res.data.pendingCount });
    setIsListLoading(false);

    message.success(
      `${
        items.length !== 0
          ? `${items.length} more participants loaded!`
          : "all participants are loaded"
      }`
    );
  };

  const onClickParticipantJoinRequest = async (
    inviteId: string,
    type: string,
    remarks?: string
  ) => {
    let params = {
      eventId:event.eventId,
      inviteId,
      type,
      remarks
    };
    const res = await handleParticipantJoinRequest(params);
    if (res.status == OK || res.status == CREATED) {
      let tempData = data.filter((item: any) => {
        return item.inviteId !== inviteId;
      });
      console.log('join request participant');
      setData(tempData);
    }
    form.resetFields();
    props.onReload && props.onReload();
    setShowModal(false);
  };

  const onRemoveInvite = (invite: Invite, remarks?: string) => {
    Modal.confirm({
      title: "Delete Event",
      icon: <ExclamationCircleOutlined />,
      content: `Would you like to drop ${invite.memberName} from the event?`,
      okText: "Yes",
      onOk: async () => {
        const res = await deleteInvite({
          eventId:event.eventId,
          inviteId: invite.inviteId,
          remarks
        });
        if (res.status === NO_CONTENT) {
          setIsListLoading(true);
          setPagination(initialPagination);
          fetchList(initialPagination);
        }
      },
      okButtonProps: { shape: "round" },
      cancelText: "No",
      cancelButtonProps: { shape: "round" }
    });
  };

  const onModalSubmit = (formData: any) => {
    const remarks = formData.remarks;
    if (selInvite) {
      onClickParticipantJoinRequest(selInvite?.inviteId, "decline", remarks);
    }
  }; 

  const getModList = async () => {
    let params = {
      page: 1,
      pageSize: 10,
      searchString: ``,
      memberGroupId: event.memberGroupId,
    };
    const res = await getClubModeratorList(params);
    setModList(res.data.items)
  };

  const getAdminName = (id: string) => {
    if(modList.length>0){
      let admin = modList.filter((e: any) => e.memberId == id)[0];
      return(`${admin?.firstName!==undefined?admin?.firstName:""} ${admin?.lastName!==undefined?admin?.lastName:""}`)
    }else{
      return('N/A')
    }
  };

  useEffect(() => {
    getModList();
  }, []);

  useEffect(() => {
    if (selInvite) {
      setShowModal(true);
    }
  }, [selInvite]);

  useEffect(() => {
    if (!showModal && selInvite !== undefined) {
      setSelInvite(undefined);
    }
  }, [showModal]);

  useEffect(() => {
    setIsListLoading(true);
    setPagination(initialPagination);
    fetchList(initialPagination);
  }, [participantStatus, invitedFilter, searchString, reload]);

  useEffect(() => {
    if(setReload!==undefined && reload===true){
      setAttendance([]);
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if ((Object.values(attendance)).some((e: any) => e.isModified))
      setIsSaveDisabled(false);
    else
      setIsSaveDisabled(true);
  }, [attendance])

  console.log(participantStatus);

  return (
    <Fragment>
      <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={onModalSubmit}
        isOpen={showModal}
        setIsOpen={setShowModal}
        form={form}
        isLoading={isLoading}
      />
      {data.length !== 0 && !isListLoading ? (
        <List
          header={() => (
            <div>
              asdads
              asdasd
            </div>
          )}
          extra
          className={['1', '4'].includes(participantStatus.toString()) ? "attendance-remarks-full":''}
        >
          {!isAdmin && participantStatus !== '1' && participantStatus !== '4' && (
            <List.Item>
              <List.Item.Meta
              />
              <div
                className="attendance-remarks-solo d-flex px-4"
              >
                <Text strong>Remarks</Text>
              {participantStatus === '3' && (
           
                <Text style={{color:'#808b96', paddingLeft:'5px', fontSize:'12px'}}>{'(Click the remarks to edit attendance)'}</Text>
              )}
              </div>
            </List.Item>
          )}
          {(!isAdmin || isAttendanceModal===true) && participantStatus === '1' && (
            <List.Item>
              <List.Item.Meta
              style={{flex:1.5}}
              />
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Remarks</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Login Time</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Date</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Profile</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Authenticator</Text>
              </div>
            </List.Item>
          )}
          {!isAdmin && participantStatus === '4' && (
            <List.Item>
              <List.Item.Meta
              style={{flex:1.5}}
              />
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Remarks</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Login Time</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Date</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Profile</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Authenticator</Text>
              </div>
              <div
                className="d-flex px-4"
                style={{flex:1}}
              >
                <Text strong>Admin</Text>
              </div>
            </List.Item>
          )}
          
          <VirtualList
            data={data}
            height={ContainerHeight}
            itemHeight={47}
            itemKey="memberId"
            onScroll={onScroll}
            className="attendance-virtual-list"
          >
            {(item: Invite) => (
              <List.Item key={item.memberName} className="table-striped-rows">
                <List.Item.Meta
                  key={item.memberId}
                  avatar={item.profilePicture?<img src={`${item.profilePicture}?${(Date.now()).toString()}`} height={30} width={30} style={{borderRadius:'50%'}}/>:<Avatar src={""} />}
                  title={<a>{item.memberName}</a>}
                  description={
                    item.participationType === 0 ? "Moderator" : "Participants"
                  }
                />
                {(isAdmin && isAttendanceModal===false && participantStatus !== '-1')&&(
                    <div className="attendance-remarks mr-3">
                        <Button 
                          onClick={(e) => onEditAttendance(item, attendance[item.inviteId]?.isAttended===true? false:true)}
                          style={{
                            height: 30,
                            width: 30,
                            border: "1px dashed #D9D9D9",
                            backgroundColor:'#fff',
                            transition: "none"
                          }}
                          shape="circle"
                        >
                          <CloseOutlined style={{ color: attendance[item.inviteId].isModified===true?"#02B8F6":"#c9c9c9"}} />
                        </Button>
                    </div>
                )}
                {(isAdmin && participantStatus === '-1')&&(
                    <div className="attendance-remarks mr-3">
                      {attendance[item.inviteId]?.isAttended===true?
                        <CheckCircleFilled style={{ fontSize: '26px', color: '#169F5B' }}/>
                        : <CloseCircleFilled style={{ fontSize: '26px', color: '#E74731' }}/>
                      }
                    </div>
                )}

                {((!isAdmin || isAttendanceModal===true) && participantStatus !== '3' && participantStatus !== '1' && participantStatus !== '4')&&(   //attendance remark circle all record and absent
                  <div
                  className="attendance-remarks attendance-remarks-solo"
                  style={{paddingLeft: "20px", paddingRight: "20px"}}
                  >
                      {attendance[item.inviteId]?.isAttended===true?
                        <CheckCircleFilled style={{ fontSize: '26px', color: '#169F5B' }}/>
                        : <CloseCircleFilled style={{ fontSize: '26px', color: '#E74731' }}/>
                    }
                    </div>
                  )}
             
                {((!isAdmin || isAttendanceModal===true) && participantStatus !== '3' && (participantStatus == '1' || participantStatus === '4'))&&(   //attendance remark circle
                  <div
                  className="attendance-remarks"
                  style={{flex:participantStatus === '1'? 0.75 :participantStatus === '4'?1: 2.5, paddingLeft:participantStatus === '1'?"50px":participantStatus === '4'?"80px":"20px"}}
                  >
                      {attendance[item.inviteId]?.isAttended===true?
                        <CheckCircleFilled style={{ fontSize: '26px', color: '#169F5B' }}/>
                        : <CloseCircleFilled style={{ fontSize: '26px', color: '#E74731' }}/>
                    }
                    </div>
                  )}
                  
                  {(!isAdmin && participantStatus === '3')&&(   //edit attendance
                    <>
                    <div className="attendance-remarks attendance-remarks-solo">
                      {attendance[item.inviteId]?.isAttended===true?
                      <Button onClick={(e) => onEditAttendance(item, false)}>
                        <CheckCircleFilled style={{ fontSize: '26px', color: '#169F5B' }}/>
                      </Button>
                        : 
                      <Button onClick={(e) => onEditAttendance(item, true)}>
                        <CloseCircleFilled style={{ fontSize: '26px', color: '#E74731' }}/>
                      </Button>
                    }
                    <p className="edited-text">
                      {item.attendance?.isAttended!==undefined?`edited`:""}
                    </p>
                    </div>
                    </>
                  )}

                  {((!isAdmin || isAttendanceModal) && participantStatus === '1') && (
                  <>
                    <div
                      className="d-flex px-4"
                      style={{flex:0.75}}
                    >
                      {typeof item?.attendance?.createdOn!=='undefined' ?
                      new Date(item.attendance?.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                      :"N/A"}
                    </div>
                    <div
                      className="d-flex px-4"
                      style={{flex:0.75}}
                    >
                      {typeof item?.attendance?.createdOn!=='undefined' ?
                      new Date(item.attendance?.createdOn).toLocaleString("en-US",{year: 'numeric', month: 'short', day: '2-digit'})
                       :"N/A"}
                    </div>
                    <div
                      className="d-flex px-4"
                      style={{flex:0.75}}
                    >
                      {item.participationType === 0 ? "Moderator" : "Participant"}
                    </div>
                    <div
                      className="d-flex px-4"
                      style={{flex:0.75}}
                    >
                      {item.attendance?.authTypes!== undefined && item.attendance?.authTypes?.length == 0 ? 
                      "N/A" :
                      item.attendance?.authTypes?.includes(0) ?
                      <QrcodeOutlined style={{ fontSize: '26px'}}/>:
                      item.attendance?.authTypes?.includes(0) ?
                      <img src={IconMarkAsAttended} /> :
                      "N/A"
                      }
                    </div>

                  </>

                  )}
                  {(!isAdmin && participantStatus === '4') && (
                  <>
                    <div
                      className="d-flex px-4"
                      style={{flex:1}}
                    >
                      {typeof item?.attendance?.createdOn!=='undefined' ?
                      new Date(item.attendance?.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                      :"N/A"}
                    </div>
                    <div
                      className="d-flex px-4"
                      style={{flex:1}}
                    >
                      {typeof item?.attendance?.createdOn!=='undefined' ?
                      new Date(item.attendance?.createdOn).toLocaleString("en-US",{year: 'numeric', month: 'short', day: '2-digit'})
                       :"N/A"}
                    </div>
                    <div
                      className="d-flex px-4"
                      style={{flex:1}}
                    >
                      {item.participationType === 0 ? "Moderator" : "Participant"}
                    </div>
                    <div
                      className="d-flex px-4"
                      style={{flex:1}}
                    >
                      {item.attendance?.authTypes!== undefined && item.attendance?.authTypes?.length == 0 ? 
                      "N/A" :
                      item.attendance?.authTypes?.includes(0) ?
                      <QrcodeOutlined style={{ fontSize: '26px'}}/>:
                      item.attendance?.authTypes?.includes(0) ?
                      <img src={IconMarkAsAttended} /> :
                      "N/A"
                      }
                    </div>
                    <div
                      className="d-flex px-4"
                      style={{flex:1}}
                    >
                      {item.createdBy!==null?(
                      <>
                        {getAdminName(item.createdBy)}
                      </>
                        ):
                        ("N/A")}
                    </div>

                  </>

                  )}
              </List.Item>
            )}
          </VirtualList>

          {!isAdmin && participantStatus === '3' && (
            <List.Item>
              <List.Item.Meta
              />
              <div
                className="d-flex px-4"
                style={{width: 100, justifyContent: "center"}}
              >
                <Button
                  htmlType="button"
                  className="mr-1"
                  style={{borderRadius: 5, height: 40}}
                  key="save"
                  disabled={isSaveDisabled}
                  type="primary"
                  loading={isLoading}
                  onClick={saveChanges}
                >
                  Save changes
                </Button>
              </div>
            </List.Item>
          )}
          {isAdmin && isAttendanceModal===false && (participantStatus === '1' || participantStatus === '2') && (
            <List.Item>
              <List.Item.Meta
              />
              <div
                className="d-flex px-4"
                style={{width: 100, justifyContent: "center"}}
              >
                <Button
                  htmlType="button"
                  className="mr-1"
                  style={{borderRadius: 5, height: 40}}
                  key="save"
                  disabled={isSaveDisabled}
                  type="primary"
                  loading={isLoading}
                  onClick={saveChanges}
                >
                  Save changes
                </Button>
              </div>
            </List.Item>
          )}
        </List>
      ) : isListLoading ? (
        <div className="d-flex justify-content-center">
          <Spin tip="Loading Participants..."></Spin>
        </div>
      ) : (
        <div>
          {/* <List
            header={() => (
              <div>
                asdads
                asdasd
              </div>
            )}
            extra
          >
            {!isAdmin && participantStatus !== '1' && participantStatus !== '4' && (
              <List.Item>
                <List.Item.Meta
                />
                <div
                  className="d-flex px-4"
                  style={{flex:2.5}}
                >
                  <Text strong>Remarks</Text>
                {participantStatus === '3' && (
            
                  <Text style={{color:'#808b96', paddingLeft:'5px', fontSize:'12px'}}>{'(Click the remarks to edit attendance)'}</Text>
                )}
                </div>
              </List.Item>
            )}
            {!isAdmin && participantStatus === '1' && (
              <List.Item>
                <List.Item.Meta
                style={{flex:1.5}}
                />
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Remarks</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Login Time</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Date</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Profile</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Authenticator</Text>
                </div>
              </List.Item>
            )}
            {!isAdmin && participantStatus === '4' && (
              <List.Item>
                <List.Item.Meta
                style={{flex:1.5}}
                />
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Remarks</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Login Time</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Date</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Profile</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Authenticator</Text>
                </div>
                <div
                  className="d-flex px-4"
                  style={{flex:1}}
                >
                  <Text strong>Admin</Text>
                </div>
              </List.Item>
            )}
            
          </List> */}
          <div
            className="d-flex justify-content-center"
            style={{overflowY: "scroll", height: "80%"}}
          >
            <EmptyState imgHeight={300} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  deleteInvite,
  updateMultipleAttendance,
  getEventParticipantList,
  handleParticipantJoinRequest,
  getClubModeratorList,
})(AttendanceListWidget);
