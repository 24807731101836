import { addInvites } from "../../actions/event";
import { Button, Form, Modal, Tabs, Row, Progress, Spin, notification, Avatar, Col, Tooltip } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getEventParticipantList,
  getParticipantListNonPaged,
} from "../../actions/participant";
import { OK } from "../../helpers/statuscodes";
import { UserOutlined, CameraOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import AttendanceListWidget from "./AttendanceListWidget";
import ModalExpanding from "./ModalExpanding";
import { userPermissions } from "../../permissions/user-permissions";
import logoQr from "../../assets/images/logos/breeze-logo-qr.svg";
import {
  getClubModeratorList,
} from "../../actions/club";
import emptyState from "../../assets/images/empty-state-illustration-plus.svg";
import { QRCodeSVG } from 'qrcode.react';
import { HubConnectionBuilder, LogLevel, HubConnection } from "@microsoft/signalr";
import {
  authQrCode,
  markAsAttended
} from "../../actions/event";
import type { NotificationPlacement } from 'antd/es/notification';
import { QR_CODE } from "../../helpers/eventAuthTypes";
import { connected } from "node:process";
import { Link } from "react-router-dom";
import { tagQrAuth } from "../../utils/googleTagManager";
import { EVENT_JOIN_SUCCESS } from "../../helpers/eventUpdateStreamCodes";

interface IEventParticipantPageProps {
  auth: any;
  event: any;
  readonly: boolean;
  addInvites: Function;
  getEventParticipantList: Function;
  getParticipantListNonPaged: Function;
  getClubModeratorList: Function;
  authQrCode: Function;
  markAsAttended: Function;
}

const EventQRPane = (props: IEventParticipantPageProps) => {
  const { auth, event, getParticipantListNonPaged, getClubModeratorList, authQrCode, markAsAttended } = props;
  const { eventId } = event;
  let signedInUserMemberId = auth && auth.member && auth.member.memberId;
  const [isClubModerator, setIsClubModerator] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [isAttModalOpen, setIsAttModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitedMemberList, setInvitedMemberList]: any = useState([]);

  const [qrValue, setQrValue] = useState("");
  const [qrTimer, setQrTimer] = useState(0.0);
  const [qrCooldown, setCooldown] = useState(15.0);
  const [connection, setConnection] = useState<HubConnection>();
  const [connectionId, setConnectionId] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [authResponse, setAuthResponse] = useState<any>();
  const [memberPicture, setMemberPicture] = useState('');
  const [memberFullName, setMemberFullName] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [seed, setSeed] = useState(0);

  const qrPercent = (qrTimer / qrCooldown) * 100;

  const initHubConnection = () => {
    const connect = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_API_ENDPOINT}/hubs/attendance`, {
      withCredentials: false
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

    setConnection(connect);
  };

  const generateQrValue = () => {
    const tempCode = generateCode();
    setCode(tempCode);
    setQrValue(`${connectionId};${eventId};${tempCode};`);
  };

  const validateQr = async (args: any) => {
    if (!args)
      return;
    try {
      if (args.memberId)
        tagQrAuth(args.memberId);

      if (eventId && code && args && args.eventId && args.code) {
        if (eventId === args.eventId && code === args.code) {

          const res = await markAsAttended({
            eventId: eventId,
            inviteId: args.inviteId,
            authTypes: [QR_CODE]
          });
  
          if (res && res.stack && res.message && typeof res.stack === 'string' 
          && typeof res.message === 'string')
          {
            throw res;
          }
  
          const img = new Image();
          img.onload = () => {
            setMemberPicture(args.memberPicture);
            setMemberFullName(args.memberFullName);

            connection?.invoke('SendGroupNotification', eventId, EVENT_JOIN_SUCCESS.toString())
            .then(()=> {
              console.log('notification sent to event update stream');
            });
            
            (document.querySelector('.qr-inner') as HTMLElement).style.transform = "rotateY(180deg)";
            notification.success({
              message: 'Authentication Successful!',
              placement: 'bottom',
            });
            setTimeout(() => {
              (document.querySelector('.qr-inner') as HTMLElement).style.transform = "rotateY(0deg)";
              generateQrValue();
            }, 1000);
          };
          
          img.src = args.memberPicture;
        }
        else {
          throw 'error';
        }
      }
    }
    catch (e) {
      notification.error({
        message: 'Authentication Failed.',
        placement: 'bottom',
      });
    }
    
  }

  const onSelectInviteMember = (checked: boolean, id: string) => {
    console.log(checked, id);
    if (checked) {
      if (
        invitedMemberList.filter((item: string) => item.includes(id)).length ==
        0
      ) {
        setInvitedMemberList(invitedMemberList.concat(id));
      }
    } else {
      setInvitedMemberList(
        invitedMemberList.filter((item: string) => item !== id)
      );
    }
  };

  const getClubModeratorStatus = async () => {
    let params = {
      page: 1,
      pageSize: 10,
      searchString: `${auth.member.emailAddress}`,
      memberGroupId: event.memberGroupId,
    };
    const res = await getClubModeratorList(params);
    setIsClubModerator(res.data.items.some((e: any) => e.memberId == auth.member.memberId));
  }
  
  let permissions = {
    canDeleteEvent:
      userPermissions[auth.member.memberType] &&
      userPermissions[auth.member.memberType]?.deleteEvent,
  };

  // authQrCode({connectionId, eventId, code}), 10000);

  useEffect(() => {
    initHubConnection();
    getClubModeratorStatus();
  }, []);

  useEffect(() => {
    if (connection) {
      
      connection
        .start()
        .then(() => {
          connection?.invoke('GetConnectionId')
          .then((id: string) => {
            console.log(id);
            setConnectionId(id);
            setIsConnected(true);

            connection?.invoke('JoinEventGroup', eventId)
            .then(()=> {console.log('event update stream joined')});
          })
          .catch((e: any) => {
            console.log(e);
          });
        })
        .catch((e: any) => {
          console.log(e);
        });

        connection.on('onAuthQr', (response: any) => {
          setAuthResponse(response);
        });

        connection.on('onGroupNotification', (response: any) => {
          console.log('event stream notification received');
          console.log(response);
          
          if (response === EVENT_JOIN_SUCCESS.toString()) {
            console.log('join success');
            //NOTE: signalr workaround
            setSeed(Math.random());
          }
        });

        connection.onreconnecting(() => {
          setIsConnected(false);
        });

        connection.onreconnected(() => {
          setIsConnected(true);
        });
    }

  }, [connection]);

  useEffect(() => {
    validateQr(authResponse);
  }, [authResponse]);
  
  const generateCode = () => String(Math.floor(Math.random() * 999999999)).padStart(9, '0');

  useEffect(() => {
    generateQrValue();

  }, [connectionId]);

  useEffect(() => {
    setQrTimer(qrCooldown);

    const qrInterval = setInterval(() => {
      setQrTimer(oldCounter => {
        if ((oldCounter -= 0.1) <= 0 || !code) {
          generateQrValue();
          return qrCooldown;
        }
        return oldCounter;
      });
    }, 100);

    return () => clearInterval(qrInterval);
  }, [qrValue]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    if (params.get("qr") === '1' ) {
      setIsExpanded(true)
      setIsQRModalOpen(true)
    }
  }, []);

  useEffect(() => {
    if(isExpanded===true){
      setIsQRModalOpen(true)
    }
  }, [isExpanded]);

  return (
    <div className="participants-pane">
      {moment(new Date()).isSame(new Date(event.startDate),'day')&& moment(new Date(),'hh:mm:ss').isBefore(moment(new Date(event.endDate),'hh:mm:ss'))?(
        <>
          <div className="qr-pane-header">
              <h1>Event QR Code</h1>
              <p>Please refer to the link to proceed with login via QR Authenticator</p>
              <Button type="primary" onClick={()=>setIsQRModalOpen(true)}>Show QR Code</Button>
          </div>
          <div className="qr-btn-row">
              <Button type="primary" disabled> <CameraOutlined />Scan QR Code</Button>
              <Button onClick={()=>setIsAttModalOpen(true)}> <UserOutlined />Show Attendees</Button>
          </div>
        </>
      ):moment(new Date()).isBefore(new Date(event.startDate),'day')?(
        <div className="unavailable-qr">
          <div>
            <h1 className="text-center mb-2">Oops You Are Too Early!</h1>
            <p>
              Please Return on {new Date(event.startDate).toLocaleString("en-US",{year: 'numeric', month: 'short', day: '2-digit'})}
            </p>
            <Button type="primary">
              <Link to={auth.member.memberType === "Admin" ? "/admin/events" : "/events"}>
              Back to Events
              </Link>
            </Button>
          </div>
          <img src={emptyState} height={400}/>
        </div>
      ):(
        <div className="unavailable-qr">
          <div>
            <h1 className="text-center mb-2">Thank you for joining {event.title}<br/> last {new Date(event.startDate).toLocaleString("en-US",{year: 'numeric', month: 'short', day: '2-digit'})}</h1>
            <Button type="primary">
              <Link to={auth.member.memberType === "Admin" ? "/admin/events" : "/events"}>
              Back to Events
              </Link>
            </Button>
          </div>
          <img src={emptyState} height={400}/>
        </div>
      )} 
        <ModalExpanding
        isOpen={isQRModalOpen}
        setIsOpen={setIsQRModalOpen}
        isLoading={isLoading}
        title={"Scan QR Code"}
        footer={event.title}
        expanded={isExpanded}
      >
        <div className="qr">
          <div className="qr-inner">
            <div className="qr-front">
              <div className='qr-row qr'>
                {
                  isConnected
                  ? (
                      <div className="qr-container">
                        <Tooltip
                          title="Require the user's devices camera to scan the code"
                        >
                          <QRCodeSVG
                            value={qrValue}
                            size={400}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                            imageSettings={{
                              src: logoQr,
                              x: undefined,
                              y: undefined,
                              height: 96,
                              width: 96,
                              excavate: true,
                            }}
                          />
                        </Tooltip>
                      </div>
                  )
                  : (
                    <div className="qr-container">
                      <div
                        className="spin-container"
                        style={{
                          height: '400px',
                          width: '400px'
                        }}
                      >
                        <Spin className="spin-overlay" size="large"/>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className='qr-row'>
                {
                  isConnected && <Progress percent={(qrTimer / qrCooldown) * 100} showInfo={false} strokeColor={qrPercent < 25 ? 'red' : qrPercent < 60 ? 'orange' : '#02b8f6'} />
                }
              </div>
            </div>
            <div className="qr-back">
              <Row>
                <Col span={24}>
                  <CheckCircleOutlined color="green"/>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Avatar src={memberPicture} size={300} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h1>Welcome {memberFullName}!</h1>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalExpanding>
      <Modal
      className={"modal-attendance"}
      closable={false}
      visible={isAttModalOpen}
      onCancel={()=>setIsAttModalOpen(false)}
    >
      <div style={{width:'100%'}} className="attendance-layout">
        <AttendanceListWidget
          key={seed}
          onReload={(data: any) =>
            setPendingCount((previous) =>
              data ? data.pendingCount : previous - 1
            )
          }
          participantStatus={'1'}
          invitedFilter={true}
          searchString={''}
          readonly={props.readonly}
          reload={false}
          event={event}
          showClubName={true}
          isAttendanceModal={true}
        ></AttendanceListWidget>
      </div>
    </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
});
export default connect(mapStateToProps, {
  addInvites,
  getEventParticipantList,
  getParticipantListNonPaged,
  getClubModeratorList,
  authQrCode,
  markAsAttended
})(EventQRPane);
