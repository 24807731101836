import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteMember, getMemberList } from "../../../actions/school";

import iconExclamation from "../../../assets/images/icons/icon-exclamation.svg";
import { Link, useNavigate } from "react-router-dom";
import { SchoolAdmin, Student } from "../../../helpers/memberTypes";
import { onlySpaces } from "../../../helpers/whitespaces";
import { memberStatus } from "../../../helpers/memberStatus";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const StudentList = (props: any) => {
  let navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
      render: (text: any, record: any) => (
        <a>{record.firstName + " " + record.lastName}</a>
      ),
    },
    {
      title: "School",
      dataIndex: "schoolName",
      key: "school",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => <a>{memberStatus[text]}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a id={record.memberId} onClick={() => editMemberHandler(record)}>
            Edit
          </a>
        </Space>
      ),
    },
  ];

  const { getMemberList, deleteMember } = props;
  const [studentList, setStudentList]: any = useState([]);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [statusFilter, setStatusFilter] = useState(-1);
  const [memberId, setMemberId] = useState();
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination]: any = useState(initialPagination);
  const [searchString, setSearchString]: any = useState("");

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoadingStudentList, setIsLoadingStudentList] = useState(false);

  const onFilterSearchHandler = (searchInput: String) => {
    setSearchString(searchInput);
    // if (!onlySpaces(searchInput) || searchInput === "") {
    //   fetchList({
    //     ...initialPagination,
    //     searchString,
    //   });
    // }
  };

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
  };

  const editMemberHandler = (record: any) => {
    navigate("/admin/students/add", {
      state: {
        memberId: record?.memberId,
        gender: record?.gender,
        birthDate: record?.birthDate,
        firstName: record?.firstName,
        lastName: record?.lastName,
        emailAddress: record?.emailAddress,
        schoolGrade: record?.schoolGrade,
      },
    });
  };

  const fetchList = async (parameters: object) => {
    setIsLoadingStudentList(true);
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        memberType: Student,
        status: statusFilter,
      };
    } else {
      params = { ...parameters, memberType: Student, status: statusFilter };
    }
    const data: any = await getMemberList(params);
    setStudentList(data.data.items);
    setPagination({
      current: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsLoadingStudentList(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString, statusFilter]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };

  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="Students"
          extra={[
            <Button key="1" className="btn-radius">
              <Link to={"/admin/students/add-bulk"}>Upload Students</Link>
            </Button>,
            <Button key="2" type="primary" className="btn-radius">
              <Link to={"/admin/students/add"}>Add New Students</Link>
            </Button>,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    <div className="my-auto mr-auto">
                      Status:{" "}
                      <select onChange={onfilterStatusHandler}>
                        <option value="-1">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                    <div>
                      <Search
                        allowClear
                        // onKeyUp={(e: any) => setSearchString(e.target.value)}
                        onSearch={onFilterSearchHandler}
                        placeholder="Search by Name, School & Job Title"
                        style={{ width: 280 }}
                      />
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="memberId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      scroll={{ y: "calc(100vh - 420px)" }}
                      dataSource={studentList}
                      loading={isLoadingStudentList}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getMemberList, deleteMember })(StudentList);
