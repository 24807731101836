import {
  Button,
  Col,
  PageHeader,
  Row,
  Space,
  Table,
  Input,
  Modal,
  Dropdown,
  Menu,
  Typography,
  Form,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getClubList, handleClubProposal } from "../../../actions/club";

import iconExclamation from "../../../assets/images/icons/icon-exclamation.svg";
import { Link, useNavigate } from "react-router-dom";
import { Teacher } from "../../../helpers/memberTypes";
import { CREATED, OK } from "../../../helpers/statuscodes";
import { ColumnsType } from "antd/lib/table";
import ModalSuccess from "../../member-app/Modals/ModalSuccess";
import ModalDeclineReason from "../../member-app/Modals/ModalDeclineReason";
import moment from "moment";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const ClubList = (props: {
  getClubList: Function;
  handleClubProposal: Function;
  auth: any;
  pushData: any;
}) => {
  const { getClubList, handleClubProposal, auth, pushData } = props;
  let navigate = useNavigate();
  const columns: ColumnsType<any> = [
    {
      title: "Name",
      key: "name",
      render: (record: any) => (
        <a
          id={record.memberGroupId}
          onClick={() => {
            navigate(`/admin/club/${record.memberGroupId}`, {
              state: {
                memberGroupId: record?.memberGroupId,
                name: record?.name,
              },
            });
          }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created On",
      key: "createdOn",
      render: (text: any, record: any) => (
        <Space size="middle">
          {moment(new Date(record.createdOn)).format("ll")}
        </Space>
      ),
    },
    {
      title: "Privacy",
      dataIndex: "privacyType",
      key: "privacyType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Proposed By",
      key: "proposedBy",
      render: (record: any) => (
        <Link target={"_blank"} to={`/profile/${record.createdBy}`}>
          {record.proposedBy}
        </Link>
      ),
    },
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (record: any) => (
        <Space size="middle">
          {record.status === "Pending" && (
            <>
              <Typography.Link
                onClick={() =>
                  onHandleClubProposal(record.memberGroupId, "approve")
                }
              >
                Approve
              </Typography.Link>
              <Typography.Link
                onClick={() =>
                  onHandleDeclineClubProposal(record.memberGroupId, "decline")
                }
              >
                Decline
              </Typography.Link>
            </>
          )}
          <Typography.Link
            id={record.memberId}
            onClick={() => editHandler(record)}
          >
            Edit
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const [clubList, setClubList]: any = useState([]);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [statusFilter, setStatusFilter] = useState(-1);
  const [memberId, setMemberId] = useState();
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination] = useState<any>(initialPagination);
  const [searchString, setSearchString] = useState<String>("");

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [formDeclineJoinRequest] = Form.useForm();
  const [isListLoading, setIsListLoading] = useState(false);
  const [isModalDeclineReasonOpen, setIsModalDeclineReasonOpen] =
    useState(false);
  const [isLoadingModalDeclineReason, setIsLoadingModalDeclineReason] =
    useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [selectedMemberGroupId, setSelectedMemberGroupId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onFilterSearchHandler = (searchInput: String) => {
    setSearchString(searchInput);
  };

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
    // fetchList(pagination);
  };

  const onHandleClubProposal = async (clubId: string, type: String) => {
    let params = {
      clubId: clubId,
      type,
    };

    const res = await handleClubProposal(params);
    if (res.status == OK || res.status == CREATED) {
      let tempData;
      if (type === "approve") {
        tempData = clubList.map((item: any) => {
          if (item.memberGroupId === clubId) {
            return {
              ...item,
              status: type.charAt(0).toUpperCase() + type.slice(1) + "d",
            };
          } else {
            return item;
          }
        });
        setSuccessMessage("You have successfully approved the club proposal.");
        setIsModalSuccessOpen(true);
      } else if (type === "decline") {
        tempData = clubList.filter((item: any) => {
          if (item.memberGroupId !== clubId) {
            return item;
          }
        });
      }

      setClubList(tempData);
    }
  };

  const onHandleDeclineClubProposal = (memberGroupId: string, type: string) => {
    setSelectedMemberGroupId(memberGroupId);
    setIsModalDeclineReasonOpen(true);
  };

  const onHandleClubDeclineProposal = async (formData: any) => {
    setIsLoadingModalDeclineReason(true);
    let params = {
      ...formData,
      clubId: selectedMemberGroupId,
      type: "decline",
    };

    const res = await handleClubProposal(params);
    if (res.status == OK || res.status == CREATED) {
      let tempData = clubList.filter((item: any) => {
        if (item.memberGroupId !== selectedMemberGroupId) {
          return item;
        }
      });
      setClubList(tempData);
      setSuccessMessage("You have successfully declined the club proposal.");
      setIsModalDeclineReasonOpen(false);
      setIsModalSuccessOpen(true);
    }
    setIsLoadingModalDeclineReason(false);
  };

  const onDeleteHandler = async (memberId: any) => {
    setIsModalDeleteVisible(true);
    setMemberId(memberId);
  };

  const deleteClubHandler = async () => {
    // setIsDeleteLoading(true);
    // const res = await deleteInterest(memberId);
    // if (res.status === 204) {
    //   fetchList(pagination);
    //   setIsModalDeleteVisible(false);
    // }
    // setIsDeleteLoading(false);
  };

  const editHandler = (record: {
    memberGroupId: String;
    description: String;
    memberGroupType: Date;
    name: String;
    moderators: String;
    interestId: String;
    privacyType: String;
    status: String;
  }) => {
    navigate("/admin/clubs/add", {
      state: {
        memberGroupId: record?.memberGroupId,
        description: record?.description,
        memberGroupType: record?.memberGroupType,
        name: record?.name,
        moderators: record?.moderators,
        interestId: record?.interestId,
        privacyType: record?.privacyType,
        status: record?.status,
      },
    });
  };

  const fetchList = async (parameters: object) => {
    setIsListLoading(true);
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        memberType: Teacher,
        status: statusFilter,
      };
    } else {
      params = { ...parameters, memberType: Teacher, status: statusFilter };
    }
    const data: any = await getClubList(params);
    setClubList(data.data.items);
    setPagination({
      current: data.data.page,
      pageSize: data.data.pageSize,
      total: data.data.totalItems,
    });
    setIsListLoading(false);
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString, statusFilter]);

  useEffect(() => {
    if (pushData.data?.topic === "ClubUpdate") {
      const pushInfo = JSON.parse(pushData.data?.pushData);
      if (pushData.data?.memberId !== auth.member.memberId) {
        fetchList(initialPagination);
      }
    }
  }, [pushData]);

  const handleTableChange = (tablepagination: object) => {
    fetchList(tablepagination);
  };

  const showTotal = () => {
    return `Total ${pagination?.total} items`;
  };

  return (
    <Fragment>
      <ModalSuccess
        isOpen={isModalSuccessOpen}
        setIsOpen={setIsModalSuccessOpen}
      >
        <p className="text-center">{successMessage}</p>
      </ModalSuccess>
      <ModalDeclineReason
        title={`Enter reason for declining  Club`}
        handleSubmit={onHandleClubDeclineProposal}
        isOpen={isModalDeclineReasonOpen}
        setIsOpen={setIsModalDeclineReasonOpen}
        form={formDeclineJoinRequest}
        isLoading={isLoadingModalDeclineReason}
      />
      <Modal
        className="modal-notify"
        centered
        visible={isModalDeleteVisible}
        onCancel={() => setIsModalDeleteVisible(false)}
        footer={[]}
      >
        <div className="d-flex">
          <div>
            <img src={iconExclamation} />
          </div>
          <div className="d-flex-column ml-2 w-100">
            <div className="mod-title">Delete Member</div>
            <div className="mt-1">
              <p className="modal-message mt-1">Are you sure?</p>
              <div className="d-flex justify-content-space-between">
                <Button
                  key="submit"
                  className="btn-breeze-no-bg"
                  onClick={() => setIsModalDeleteVisible(false)}
                >
                  Go Back
                </Button>
                <Button
                  key="submit-2"
                  className="btn-breeze-2"
                  onClick={deleteClubHandler}
                  loading={isDeleteLoading}
                  disabled={isDeleteLoading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="Clubs"
          extra={[
            <Button key="add" type="primary" className="btn-radius">
              <Link to={"/admin/clubs/add"}>Add New Club</Link>
            </Button>,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <Row>
              <Col span={24}>
                <div className="content-container">
                  <div className="table-controls d-flex pb-3">
                    <div className="my-auto mr-auto">
                      Status:{" "}
                      <select onChange={onfilterStatusHandler}>
                        <option value="-1">All</option>
                        <option value="0">Pending</option>
                        <option value="1">Approved</option>
                        <option value="2">Declined</option>
                      </select>
                    </div>
                    <div>
                      <Search
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search by Club Name"
                        style={{ width: 280 }}
                      />
                    </div>
                  </div>
                  <div>
                    <Table
                      rowKey="memberGroupId"
                      columns={columns}
                      pagination={{
                        ...pagination,
                        position: ["bottomLeft"],
                        showTotal: (total: number, range: [number, number]) =>
                          `Total ${total} items`,
                        size: "medium",
                      }}
                      dataSource={clubList}
                      loading={isListLoading}
                      scroll={{ y: "calc(100vh - 420px)" }}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  pushData: state.pushData,
});

export default connect(mapStateToProps, { getClubList, handleClubProposal })(
  ClubList
);
