import { Button, Col, Form, Input, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../../actions/profile";
import { BAD_REQUEST, CREATED, OK } from "../../../helpers/statuscodes";
import AlertContainer from "../../../layouts/AlertContainer";

const EditProfileform = (props: { updateProfile: Function; auth: any }) => {
  const { updateProfile } = props;
  const {
    auth: { displayName, member },
  } = props;
  const [editProfileForm] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const onFinish = async (formData: any) => {
    setValidationMessages(new Array());
    setIsLoading(true);
    let res = await updateProfile(formData);

    if (res.status == OK) {
      setEditMode(false);
    } else if (res.status === BAD_REQUEST) {
      if (res.data) {
        handleFormErrors(res.data);
      }
    }

    setIsLoading(false);
  };

  const handleSubmit = (e: any) => {
    if (!editMode) {
      setEditMode(true);
    } else {
      if (e.key === "Enter" || !e.key) {
        editProfileForm.submit();
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormErrors = (data: any) => {
    let responseValidation = data && data.errors ? data.errors : data;
    var obj = {};
    for (const [key, value] of Object.entries(responseValidation)) {
      Object.assign(obj, { [key]: value });
    }
    setValidationMessages(obj);
  };

  useEffect(() => {
    editProfileForm.setFieldsValue({
      firstName: member?.firstName,
      lastName: member?.lastName,
      jobTitle: member?.jobTitle,
    });
  }, [member, editMode]);

  return (
    <Fragment>
      <div className="d-flex">
        <h1 className="section-title">Profile Details</h1>
      </div>
      <Form
        onKeyUp={(e: any) => handleSubmit(e)}
        form={editProfileForm}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className={`breeze-form-1 custom-alert pt-4 ${
          !editMode && "not-edit-mode"
        }`}
      >
        <Row>
          <Col lg={{ span: 5 }} className="mt-3 mb-4">
            <label>First Name</label>
          </Col>
          <Col lg={{ span: 12 }}>
            <Form.Item
              className="mb-0"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your first Name!",
                },
                {
                  max: 50,
                  message: "Please input Maximum 50 characters",
                },
              ]}
            >
              <Input
                className="mt-1"
                placeholder="john.smith@yale.edu"
                readOnly={!editMode}
              />
            </Form.Item>
            <div
              className={`${
                validationMessages &&
                validationMessages.FirstName &&
                "mb-4 has-error"
              }`}
            >
              {validationMessages && validationMessages.FirstName && (
                <AlertContainer data={validationMessages.FirstName} />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 5 }} className="mt-3 mb-4">
            <label>Last Name</label>
          </Col>
          <Col lg={{ span: 12 }}>
            <Form.Item
              className="mb-0"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
                {
                  max: 50,
                  message: "Please input Maximum 50 characters",
                },
              ]}
            >
              <Input
                className="mt-1"
                placeholder="john.smith@yale.edu"
                readOnly={!editMode}
              />
            </Form.Item>
            <div
              className={`${
                validationMessages &&
                validationMessages.LastName &&
                "mb-4 has-error"
              }`}
            >
              {validationMessages && validationMessages.LastName && (
                <AlertContainer data={validationMessages.LastName} />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 5 }} className="mt-3 mb-4">
            <label>Job Title</label>
          </Col>
          <Col lg={{ span: 12 }}>
            <Form.Item
              className="mb-0"
              name="jobTitle"
              rules={[
                {
                  required: true,
                  message: "Please input your Job Title!",
                },
                {
                  max: 50,
                  message: "Please input Maximum 50 characters",
                },
              ]}
            >
              <Input
                className="mt-1"
                placeholder="john.smith@yale.edu"
                readOnly={!editMode}
              />
            </Form.Item>
            <div
              className={`${
                validationMessages &&
                validationMessages.jobTitle &&
                "mb-4 has-error"
              }`}
            >
              {validationMessages && validationMessages.jobTitle && (
                <AlertContainer data={validationMessages.jobTitle} />
              )}
            </div>
          </Col>
        </Row>
        <div className="button-container justify-content-end">
          <Row gutter={12}>
            <Col>
              <Button
                loading={isLoading}
                className="btn-breeze-box mr-2 btn-radius"
                type="primary"
                // {...(!editMode ? { htmlType: "submit" } : "")}
                onClick={(e: any) => handleSubmit(e)}
              >
                {editMode ? "Save Changes" : "Edit Profile"}
              </Button>
              {editMode && (
                <Button
                  className="btn-radius"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateProfile,
})(EditProfileform);
